import { Directive, Input } from '@angular/core';
import { ExosBaseSemanticType } from '../../exos.model';

export type HeadlineType = 'sub' | 'paragraph';

@Directive({
  selector: '[exos-headline], [exosHeadline]',
  host: {
    class: 'headline',
    // type
    '[class.headline--sub]': "type === 'sub'",
    '[class.headline--paragraph]': "type === 'paragraph'",
    // icon
    '[class.headline--exos-icon]': 'hasIcon',
    // layout
    '[class.headline--cropped]': 'isCropped',
    '[class.headline--ellipsized]': 'isEllipsized',
    // style
    '[class.headline--bright]': 'isBright',
    // semantic
    '[class.headline--activating]': "semantic === 'activating'",
    '[class.headline--success]': "semantic === 'success'",
    '[class.headline--warning]': "semantic === 'warning'",
    '[class.headline--critical]': "semantic === 'critical'",
    '[class.headline--neutral]': "semantic === 'neutral'",
  },
})
export class HeadlineDirective {
  @Input() type: HeadlineType;
  @Input() semantic: ExosBaseSemanticType;
  @Input() isCropped: boolean;
  @Input() isEllipsized: boolean;
  @Input() isBright: boolean;
  @Input() hasIcon: boolean;
}
