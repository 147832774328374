import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '@dashboard/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutocompleteMapperService } from '../autocomplete-mapper/autocomplete-mapper.service';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private readonly hostname: string = `${this.apiService.getBaseUrl(
    'pmt'
  )}/users/`;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private autocompleteService: AutocompleteMapperService
  ) {}

  findByName(search: string): Observable<User[]> {
    const params = new HttpParams().set('search', search);

    return this.http.get<User[]>(`${this.hostname}`, { params }).pipe(
      map((data) => {
        const users: User[] = this.autocompleteService.map(
          data,
          'name',
          'username'
        );
        return users.map((user) => {
          user.value = user.name;
          return user;
        });
      })
    );
  }
}
