<div
  *ngIf="project$ | async | project as project"
  style="max-width: 1280px; margin: auto"
>
  <div exosSheet class="export_parent">
    <div #pageHeader class="export_hidden">
      <header class="page-header">
        <div class="page-header__block">
          <div class="grid-12 equal-grid-spacing">
            <div class="grid-08 grid-small-12">
              <div class="grid-spacing-both">
                <h1 class="page-header__headline">IONOS Projects</h1>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>

    <div #onepager>
      <div exosSheetSection>
        <div exosSheetBlock>
          <div fxLayout="row" fxLayoutAlign="space-between center">
            <h3 class="mb-3" exosHeadline>
              {{ project.name }}
            </h3>
            <div #download>
              <button
                exosButton
                [isDisabled]="!readyToExport"
                semantic="primary"
                (click)="downloadPDF()"
              >
                Export pdf
              </button>
            </div>
          </div>
          <div fxLayout="row wrap" fxLayoutGap="2rem grid">
            <div fxFlex="50" fxLayout="column" fxLayoutGap="1rem">
              <div>
                <span exosParagraph [isBold]="true">Project Id:</span>
               PRJ.{{ projectId }}
              </div>
              <div>
                <span exosParagraph [isBold]="true">Project Owner:</span>
                {{ project.owner }}
              </div>
              <div>
                <span exosParagraph [isBold]="true">Project Client:</span>
                {{ project.client }}
              </div>
              <div>
                <span exosParagraph [isBold]="true">Project Phase:</span>
                {{ project.phase }}
              </div>
            </div>

            <div fxFlex="50" fxLayout="column" fxLayoutGap="1rem">
              <div>
                <span exosParagraph [isBold]="true">Project Start Date:</span>
                {{ (project.projectedStartDate?.date | date) || 'Not set' }}
              </div>
              <div>
                <span exosParagraph [isBold]="true">Project End Date:</span>
                {{ (project.projectedLaunchDate | date) || 'Not set' }}
              </div>
              <div>
                <span exosParagraph [isBold]="true">Last Approval Date:</span>
                {{ (project.lastApprovalDate | date) || 'Not set' }}
              </div>
              <div>
                <span exosParagraph [isBold]="true">Last Update:</span>
                {{ (project.lastModified | date) || 'Not set' }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div exosSheetSection>
        <div exosSheetBlock>
          <h3 exosHeadline type="sub">Description</h3>

          <quill-view [content]="project.htmlDescription"> </quill-view>
        </div>
      </div>

      <div exosSheetSection>
        <div exosSheetBlock>
          <h3 exosHeadline type="sub">Status Update</h3>

          <div class="formatted-text">
            {{ getOverallStatusComment(project.status) }}
          </div>
        </div>
      </div>

      <div exosSheetSection>
        <div exosSheetBlock>
          <h3 exosHeadline type="sub">Status</h3>
          <div>
            <table
              *ngIf="project.status.length"
              class="table table--responsive"
            >
              <ng-container *ngIf="!project.status">
                <tr class="table__row">
                  <td class="table__cell">
                    <div style="padding: 1rem">No status</div>
                  </td>
                </tr>
              </ng-container>

              <ng-container *ngIf="project.status">
                <tr class="table__header">
                  <td *ngFor="let status of project.status" class="table__cell">
                    {{ status.nameLocalized }}
                  </td>
                </tr>
                <tr class="table__row">
                  <td *ngFor="let status of project.status" class="table__cell">
                    <span exosBadge [semantic]="status.semanticType">
                      {{ status.valueLocalized }}
                    </span>
                  </td>
                </tr>
              </ng-container>
            </table>

            <p *ngIf="!project.status.length" exosParagraph>No status</p>
          </div>
        </div>
      </div>

      <div exosSheetSection>
        <div exosSheetBlock>
          <h3 exosHeadline type="sub">Milestones</h3>
          <div>
            <table
              *ngIf="project.milestones.length"
              class="table table--responsive"
            >
              <ng-container *ngIf="!project.milestones">
                <tr class="table__row">
                  <td class="table__cell">
                    <div style="padding: 1rem">Not milestones added.</div>
                  </td>
                </tr>
              </ng-container>

              <ng-container *ngIf="project.milestones">
                <tr class="table__header">
                  <td class="table__cell">Text</td>
                  <td class="table__cell">Date</td>
                </tr>
                <tr
                  *ngFor="let milestone of project.milestones"
                  class="table__row"
                >
                  <td class="table__cell" width="40%">
                    {{ milestone.text }}
                  </td>
                  <td class="table__cell">
                    {{ milestone.start_date | date: 'EEEE, MMMM d, y' }}
                  </td>
                </tr>
              </ng-container>
            </table>

            <p *ngIf="!project.milestones.length" exosParagraph>
              No milestones
            </p>
          </div>
        </div>
      </div>

      <div exosSheetSection>
        <div exosSheetBlock>
          <h3 exosHeadline type="sub">Risks</h3>

          <div>
            <table *ngIf="project.risks.length" class="table table--responsive">
              <ng-container *ngIf="!project.risks">
                <tr class="table__row">
                  <td class="table__cell">
                    <div style="padding: 1rem">Not risks added.</div>
                  </td>
                </tr>
              </ng-container>

              <ng-container *ngIf="project.risks">
                <tr class="table__header">
                  <td class="table__cell">Description</td>
                  <td class="table__cell">Impact</td>
                  <td class="table__cell">Probability</td>
                  <td class="table__cell">Response plan</td>
                  <td class="table__cell">Owner</td>
                  <td class="table__cell">Status</td>
                </tr>
                <tr
                  *ngFor="let projectRisk of project.risks"
                  class="table__row"
                >
                  <td class="table__cell" width="40%">
                    {{ projectRisk.description }}
                  </td>
                  <td class="table__cell">
                    {{ projectRisk.impact }}
                  </td>
                  <td class="table__cell">
                    {{ projectRisk.probability }}
                  </td>
                  <td class="table__cell">
                    {{ projectRisk.responsePlan }}
                  </td>
                  <td class="table__cell">
                    {{ projectRisk.owner.name }}
                  </td>
                  <td class="table__cell table__cell--align-right">
                    {{ projectRisk.status }}
                  </td>
                </tr>
              </ng-container>
            </table>

            <p *ngIf="!project.risks.length" exosParagraph>No risks</p>
          </div>
        </div>
      </div>
    </div>

    <div #pageFooter class="export_hidden">
      <footer class="page-footer">
        <section class="page-footer__section">
          <div
            class="page-footer__section-item page-footer__section-item--small-hidden"
          ></div>
          <div
            class="page-footer__section-item page-footer__section-item--small-hidden"
          ></div>
          <div
            class="page-footer__section-item page-footer__section-item--align-right page-footer__section-item--small-align-right"
          >
            <img src="assets/ionos.png" alt="logo" />
          </div>
          <div
            class="page-footer__section-item page-footer__section-item--small-align-center"
          >
            <p class="paragraph">INTERNAL USE ONLY</p>
          </div>
        </section>
      </footer>
    </div>
  </div>
</div>
