import { Component } from '@angular/core';
import { ExosDialogConfig, ExosDialogRef } from '@dashboard/exos-dialog';
import { HelpSection } from 'src/app/shared/models/help.config';
import { ComponentLookup } from 'src/app/shared/decorators/component-lookup.config';

@ComponentLookup('ProjectWorkflowHelpDialogComponent')
@Component({
  selector: 'app-project-workflow-help',
  templateUrl: './project-workflow-help-dialog.component.html',
  styleUrls: ['./project-workflow-help-dialog.component.scss'],
})
export class ProjectWorkflowHelpDialogComponent {
  contentText: HelpSection = {
    workflowDefinition: [
      {
        bodyText: [
          'Workflows are the processes behind the respective project. For projects within the IONOS environment please choose the ‘IONOS’ workflow ' +
            '(selected per default), for projects in collaboration with United Internet please choose ‘United Internet’ as your workflow of choice.',
          'As United Internet is following its own predefined project process and is executing some projects in collaboration with IONOS, ' +
            'IONOS Projects offers a streamlined process as a combination of both processes. The workflow for United Internet involved projects serves ' +
            'as a ‘mini-process’ to avoid double-work:',
        ],
      },
    ],
    workflowDetails: [
      {
        bodyText: [
          'Users may flag projects executed in cooperation with the UI within the tool by choosing the workflow ‘United Internet’',
          'Approval gates 1 and 2 are eliminated, as approvals during the project lifecycle will be given by the UI PMO',
          'Approval gate 0 still exists to get the approval for projects moving from the idea to the initiation phase',
          'The initiate, plan and review phases are merged into one phase called ‘in progress’',
          'All further documents can be stored in the ‘documents and links’ tab',
          'Users are able to use certain predefined UI templates that are used frequently within the functional plan section',
        ],
      },
    ],
  };

  constructor(public config: ExosDialogConfig, public dialog: ExosDialogRef) {}

  close() {
    this.dialog.close();
  }
}
