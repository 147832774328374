import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ExosCardDirective,
  ExosCardContentDirective,
  ExosCardSectionDirective,
  ExosCardPreHeadlineDirective,
  ExosCardHeadlineDirective,
  ExosCardSubHeadlineDirective,
  ExosCardFooterDirective,
  ExosCardHeaderDirective,
  ExosCardSeparatorDirective,
  ExosCardRibbonDirective,
  ExosCardVisualDirective,
} from './card.directive';

@NgModule({
  declarations: [
    ExosCardDirective,
    ExosCardContentDirective,
    ExosCardSectionDirective,
    ExosCardPreHeadlineDirective,
    ExosCardHeadlineDirective,
    ExosCardSubHeadlineDirective,
    ExosCardFooterDirective,
    ExosCardHeaderDirective,
    ExosCardSeparatorDirective,
    ExosCardRibbonDirective,
    ExosCardVisualDirective,
  ],
  imports: [CommonModule],
  exports: [
    ExosCardDirective,
    ExosCardContentDirective,
    ExosCardSectionDirective,
    ExosCardPreHeadlineDirective,
    ExosCardHeadlineDirective,
    ExosCardSubHeadlineDirective,
    ExosCardFooterDirective,
    ExosCardHeaderDirective,
    ExosCardSeparatorDirective,
    ExosCardRibbonDirective,
    ExosCardVisualDirective,
  ],
})
export class CardModule {}
