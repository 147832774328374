import {
  Component,
  AfterViewInit,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import {
  FormContainerDirective,
  FormService,
  ExosFormComponent,
  FormEvent,
} from '@dashboard/exos-form';
import { FormGroup } from '@angular/forms';
import { ExosDialogConfig, ExosDialogRef } from '@dashboard/exos-dialog';
import { Subscription } from 'rxjs';
import { GanttChartData } from 'src/app/shared/models/gantt.model';
import { JiraService } from 'src/app/shared/service/jira/jira.service';
import { DashNotificationService } from '@dashboard/core';
import { ExosButtonConfig } from '../../../models/card-form-config';

@Component({
  selector: 'app-jira-issues-dialog',
  templateUrl: './jira-issues-dialog.component.html',
  styleUrls: ['./jira-issues-dialog.component.scss'],
})
export class JiraIssuesDialogComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @ViewChild(FormContainerDirective)
  public formContainer: FormContainerDirective;

  public missingParentIds = [];

  projectId: number;
  ganttData: GanttChartData;

  private modalForm: FormGroup;
  private modalSubs: Subscription[] = [];

  isImportAction = this.config.data.formConfig.buttons.find(
    (item) => item.action === 'import'
  );

  applyAction: ExosButtonConfig = {
    label: 'Apply',
    type: 'button',
    action: 'apply',
    hasLoader: true,
    isPrimary: true,
  };

  importAction: ExosButtonConfig = {
    label: 'Import',
    type: 'button',
    action: 'import',
    hasLoader: true,
    isPrimary: true,
  };

  constructor(
    public config: ExosDialogConfig,
    public dialog: ExosDialogRef,
    private changeDetectorRef: ChangeDetectorRef,
    private exosFormService: FormService,
    private jiraService: JiraService,
    private notificationService: DashNotificationService
  ) {}

  ngOnInit() {
    this.projectId = this.config.data.projectId;
  }

  ngAfterViewInit() {
    const modalForm = this.exosFormService.buildForm(
      this.formContainer.viewContainerRef,
      this.config.data.formConfig
    ).instance;

    this.modalForm = modalForm.form;
    this.handleFormEvents(modalForm);
    this.handleDialogErrors();
    this.changeDetectorRef.detectChanges();

    this.modalForm.controls.jql.valueChanges.subscribe((result) => {
      const isApplyAction = this.config.data.formConfig.buttons.find(
        (item) => item.action === 'apply'
      );
      if (isApplyAction) {
        this.config.data.formConfig.buttons =
          this.config.data.formConfig.buttons.filter(
            (item) => item.action !== 'apply'
          );
        this.config.data.formConfig.buttons.unshift(this.importAction);
        this.ganttData = { data: [], links: [], missingParentsIds: [] };
      }
    });
  }

  private handleFormEvents(form: ExosFormComponent) {
    this.modalSubs.push(
      form.formEvents.subscribe((event: FormEvent) => {
        this.missingParentIds = [];
        if (event.type === 'cancel') {
          this.dialog.close();
        } else if (event.type === 'import') {
          if (event.formIsValid) {
            this.modalForm.markAsPending();
            this.jiraService
              .fetchJiraIssues(this.projectId, event.rawValue, 'true')
              .subscribe(
                (response) => {
                  this.missingParentIds = response.missingParentsIds;

                  this.modalForm.reset(this.modalForm.getRawValue(), {
                    emitEvent: false,
                  });
                  this.ganttData = response;
                  if (this.ganttData.data.length > 0) {
                    this.config.data.formConfig.buttons =
                      this.config.data.formConfig.buttons.filter(
                        (item) => item.action !== 'import'
                      );
                    this.config.data.formConfig.buttons.unshift(
                      this.applyAction
                    );
                  }
                },
                (errors) => {
                  this.dialog.errors(errors.error);
                }
              );
          }
        } else if (event.type === 'apply') {
          if (event.formIsValid) {
            this.modalForm.markAsPending();
            this.jiraService
              .fetchJiraIssues(this.projectId, event.rawValue)
              .subscribe(
                (response) => {
                  this.notificationService.success(
                    'Jira issues have been successfully imported.'
                  );
                  this.dialog.close(response);
                },
                (errors) => {
                  this.dialog.errors(errors.error);
                }
              );
          }
        }
      })
    );
  }

  private handleDialogErrors() {
    this.modalSubs.push(
      this.dialog.afterErrors.subscribe((errors: any) => {
        if (errors) {
          this.exosFormService.setFieldErrors(errors);
        } else {
          this.modalForm.setValue(this.modalForm.getRawValue());
        }
      })
    );
  }

  ngOnDestroy() {
    this.modalSubs.forEach((sub) => sub.unsubscribe());
  }
}
