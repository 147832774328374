import { Injectable } from '@angular/core';
import { ProjectRightsService } from '../user-rights/project-rights/project-rights.service';
import { ProjectModel } from '../../models/project.model';
import { Subject } from 'rxjs';
import { ExosButtonConfig } from '../../models/card-form-config';

@Injectable({
  providedIn: 'root',
})
export class ExosFormButtonsService {
  private readonly buttons: {
    [key: string]: { [key: string]: ExosButtonConfig };
  } = {
    common: {
      canSendToApprove: {
        label: 'Send to Approve',
        type: 'button',
        action: 'ready',
        hasLoader: false,
        isPrimary: false,
      },

      canRejectClientGateApproval: {
        label: 'Reject Project Review',
        type: 'button',
        action: 'rejectClientGateApproval',
        hasLoader: false,
        isPrimary: false,
      },

      canCancelProject: {
        label: 'Cancel Project',
        type: 'button',
        action: 'cancelProject',
        hasLoader: false,
        semantic: 'critical',
      },
      canDelete: {
        label: 'Delete Project',
        type: 'button',
        action: 'deleteProject',
        hasLoader: false,
        semantic: 'critical',
      },
      canReopenProject: {
        label: 'Re-open Project',
        type: 'button',
        action: 'reopenProject',
        hasLoader: false,
      },
    },
    ionos: {
      canApproveInitiate: {
        label: 'Approve Project Idea',
        type: 'button',
        action: 'divisionGateApproval',
        hasLoader: false,
        isPrimary: false,
      },
      canApprovePlanAndExecute: {
        label: 'Approve Project Initiation',
        type: 'button',
        action: 'vminusOneGateApproval',
        hasLoader: false,
        isPrimary: false,
      },
      canApproveReview: {
        label: 'Review project',
        type: 'button',
        action: 'review',
        hasLoader: false,
        isPrimary: false,
      },
      canApproveClose: {
        label: 'Approve Project Review',
        type: 'button',
        action: 'clientGateApproval',
        hasLoader: false,
        isPrimary: false,
      },
      canRejectDivisionGateApproval: {
        label: 'Reject Project Idea',
        type: 'button',
        action: 'rejectDivisionGateApproval',
        hasLoader: false,
        isPrimary: false,
      },
      canRejectVminusOneGateApproval: {
        label: 'Reject Project Initiation',
        type: 'button',
        action: 'rejectVminusOneGateApproval',
        hasLoader: false,
        isPrimary: false,
      },
      canRejectInitiate: {
        label: 'Back to Idea',
        type: 'button',
        action: 'rejectInitiate',
        hasLoader: false,
        isPrimary: false,
      },
      canRejectPlanAndExecute: {
        label: 'Back to Idea Approval',
        type: 'button',
        action: 'rejectPlanAndExecute',
        hasLoader: false,
        isPrimary: false,
      },
      canRejectReview: {
        label: 'Back to Initiate Approval',
        type: 'button',
        action: 'rejectReview',
        hasLoader: false,
        isPrimary: false,
      },
    },
    'united-internet': {
      canApproveProgress: {
        label: 'Start progress',
        type: 'button',
        action: 'clientGateApproval',
        hasLoader: false,
        isPrimary: false,
      },
      canApproveClose: {
        label: 'Close project',
        type: 'button',
        action: 'close',
        hasLoader: false,
        isPrimary: false,
      },
      canRejectProgress: {
        label: 'Back to Idea',
        type: 'button',
        action: 'rejectProgress',
        hasLoader: false,
        isPrimary: false,
      },
    },
  };

  private buttonsSource: Subject<ExosButtonConfig[]> = new Subject();

  public currentButtons$ = this.buttonsSource.asObservable();

  constructor(private userRights: ProjectRightsService) {}

  update(project: ProjectModel) {
    const userRights = this.userRights.getUserRights(project);
    const workflowButtons = Object.assign(
      {},
      this.buttons.common,
      this.buttons[project.workflow]
    );

    const buttons = Object.keys(userRights)
      .map((userRight) =>
        userRights[userRight] ? workflowButtons[userRight] : null
      )
      .filter((btn) => !!btn);

    this.buttonsSource.next(buttons);
  }
}
