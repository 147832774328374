<ng-container *ngIf="routeData$ | async as routeData">
  <header exosPageHeader>
    <div exosPageHeaderBlock>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h1 exosPageHeaderHeadline>
          {{ routeData.title }}
        </h1>

        <ng-container *ngIf="routeData?.button">
          <button
            exosButton
            semantic="primary"
            [routerLink]="routeData.button.link"
          >
            {{ routeData.button.label }}
          </button>
        </ng-container>
      </div>
    </div>
  </header>

  <div exosPageSection>
    <div exosPageSectionBlock>
      <div *ngIf="tableCols">
        <jql-input
          [searchQuery]="searchQuery"
          [searchIsBasic]="searchIsBasic"
          [locales]="jqlLocales"
          [tableColumns]="tableCols"
          (submitSearch)="fetchProjectsByQuery($event)"
          (searchTypeChanged)="handleSearchType($event)"
          (columnChecked)="toggleColumns($event)"
        >
        </jql-input>
      </div>

      <div
        *ngIf="objectives$ | async as objective"
        class="projects-table-container"
      >
        <dash-table
          #table
          [dataSource]="projects"
          (rowClicked)="goToOverviewById($event)"
          [cols]="tableCols | tableColumnFilter"
          [noDataMessage]="tableNotFoundMsg"
        >
          <td dashTableColumn id="name">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }} </span>
              <ng-container *ngIf="col.sortable">
                <i
                  *ngIf="
                    col.sortDirection === 'asc' || col.sortDirection == null
                  "
                  class="material-icons"
                >
                  arrow_upward</i
                >
                <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                  arrow_downward</i
                >
              </ng-container>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <i
                class="exos-icon exos-icon-lock-48 exos-icon-locked stop-row-action"
                exosTooltip="CONFIDENTIAL"
                exosTooltipPosition="above"
                *ngIf="row.confidential"
              ></i>
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <td dashTableColumn id="program">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <td dashTableColumn id="status">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
              <ng-container *ngIf="col.sortable">
                <i
                  *ngIf="
                    col.sortDirection === 'asc' || col.sortDirection == null
                  "
                  class="material-icons"
                >
                  arrow_upward</i
                >
                <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                  arrow_downward</i
                >
              </ng-container>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <div>
                <span exosBadge [semantic]="row.statusItem.semanticType">
                  {{ row.statusItem.valueLocalized }}
                </span>
              </div>
            </ng-container>
          </td>

          <td dashTableColumn id="division">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <td dashTableColumn id="gantt">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <td dashTableColumn id="status_comment">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
              <ng-container *ngIf="col.sortable">
                <i
                  *ngIf="
                    col.sortDirection === 'asc' || col.sortDirection == null
                  "
                  class="material-icons"
                >
                  arrow_upward</i
                >
                <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                  arrow_downward</i
                >
              </ng-container>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <td dashTableColumn id="owner">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
              <ng-container *ngIf="col.sortable">
                <i
                  *ngIf="
                    col.sortDirection === 'asc' || col.sortDirection == null
                  "
                  class="material-icons"
                >
                  arrow_upward</i
                >
                <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                  arrow_downward</i
                >
              </ng-container>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <td dashTableColumn id="client">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
              <ng-container *ngIf="col.sortable">
                <i
                  *ngIf="
                    col.sortDirection === 'asc' || col.sortDirection == null
                  "
                  class="material-icons"
                >
                  arrow_upward</i
                >
                <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                  arrow_downward</i
                >
              </ng-container>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <td dashTableColumn id="id">
            <ng-container *dashTableHeaderTemplate="let col"> </ng-container>

            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <i
                class="exos-icon exos-icon-webanalytics-32 stop-row-action"
                *ngIf="row.workflow === 'ionos'"
                exosLink
                exosTooltip="Project Plan"
                exosTooltipPosition="above"
                visual="external-action"
                (click)="openRoadMap(value)"
              ></i>
            </ng-container>
          </td>

          <td dashTableColumn id="folderId">
            <ng-container *dashTableHeaderTemplate="let col"> </ng-container>

            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <i
                class="exos-icon exos-icon-openwindow-20 stop-row-action"
                *ngIf="row.workflow === 'ionos'"
                exosLink
                exosTooltip="Project Brief"
                exosTooltipPosition="above"
                visual="external-action"
                (click)="openBriefFile(value)"
              ></i>
            </ng-container>
          </td>

          <td dashTableColumn id="prio">
            <div *dashTableHeaderTemplate="let col" style="text-align: center">
              <span>{{ col.name }}</span>
              <ng-container *ngIf="col.sortable">
                <i
                  *ngIf="
                    col.sortDirection === 'asc' || col.sortDirection == null
                  "
                  class="material-icons"
                >
                  arrow_upward</i
                >
                <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                  arrow_downward</i
                >
              </ng-container>
            </div>
            <div
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
              class="table__cell--align-center"
            >
              <span>{{ value }}</span>
            </div>
          </td>

          <td dashTableColumn id="effort">
            <div *dashTableHeaderTemplate="let col" style="text-align: center">
              <span>{{ col.name }}</span>
              <ng-container *ngIf="col.sortable">
                <i
                  *ngIf="
                    col.sortDirection === 'asc' || col.sortDirection == null
                  "
                  class="material-icons"
                >
                  arrow_upward</i
                >
                <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                  arrow_downward</i
                >
              </ng-container>
            </div>
            <div
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
              class="table__cell--align-center"
            >
              <span>{{ value }}</span>
            </div>
          </td>

          <td dashTableColumn id="risks">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <td dashTableColumn id="projectedLaunchDate">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <td dashTableColumn id="objective">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <td dashTableColumn id="keyResult">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <td dashTableColumn id="dependencies_description">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <td dashTableColumn id="dependencies">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <td dashTableColumn id="tags">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <td dashTableColumn id="phase">
            <div *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
              <ng-container *ngIf="col.sortable">
                <i
                  *ngIf="
                    col.sortDirection === 'asc' || col.sortDirection == null
                  "
                  class="material-icons"
                >
                  arrow_upward</i
                >
                <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                  arrow_downward</i
                >
              </ng-container>
            </div>
            <div
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span>{{ value }}</span>
            </div>
          </td>
        </dash-table>
      </div>
      <dash-table-paginator
        class="mt-2"
        [pageContext]="pageContext"
        [perPageValues]="perPageValues"
      >
      </dash-table-paginator>
    </div>
  </div>
</ng-container>
