<div exosSheetSection>
  <div exosSheetBlock>
    <div class="mb-3" fxLayout="row" fxLayoutAlign="space-between center">
      <h3 exosHeadline type="sub" class="mb-0">PROJECT STATUS CHEAT SHEET</h3>

      <button class="link static-overlay__close" (click)="close()">
        <i class="exos-icon exos-icon-closecard-24"></i>
      </button>
    </div>

    <div fxLayout="row wrap" fxLayoutGap="32px grid">
      <div fxFlex="35">
        <div exosSheet>
          <div exosSheetSection type="secondary">
            <div exosSheetBlock>
              <h4 exosHeadline type="sub">FIRST THINGS FIRST: THE BASICS</h4>
            </div>
          </div>
          <ng-container
            *ngFor="let card of contentText.basicsInfo; last as isLast"
          >
            <div exosCard [inheritBackground]="true">
              <div exosCardContent>
                <section exosCardSection>
                  <p exosParagraph [isBold]="true">
                    {{ card.title }}
                  </p>

                  <p exosParagraph *ngFor="let paragraph of card.bodyText">
                    {{ paragraph }}
                  </p>
                </section>
              </div>
            </div>
            <div *ngIf="!isLast" exosCardSerparator></div>
          </ng-container>
        </div>
      </div>

      <div fxFlex="65">
        <div exosSheet>
          <div exosSheetSection type="secondary">
            <div exosSheetBlock>
              <h4 exosHeadline type="sub">
                GREEN IS FOR GO: THE TRAFFIC LIGHTS
              </h4>
            </div>
          </div>
          <div fxLayout="row warp" fxLayoutGap="16px grid">
            <ng-container *ngFor="let card of contentText.traficLightsInfo">
              <div fxFlex="25">
                <div exosCard [inheritBackground]="true">
                  <div exosCardContent>
                    <section exosCardSection align="center">
                      <span exosBadge [semantic]="card.semantic">
                        {{ card.title }}
                      </span>
                    </section>
                    <section exosCardSection>
                      <p exosParagraph *ngFor="let paragraph of card.bodyText">
                        {{ paragraph }}
                      </p>
                    </section>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div exosSheet>
          <div exosSheetSection type="secondary">
            <div exosSheetBlock>
              <h4 exosHeadline type="sub">
                VARIOUS STATUS ITEMS: REPORT IN DETAIL
              </h4>
            </div>
          </div>
          <ul sheetStripes>
            <ng-container *ngFor="let stripe of contentText.statusItemsInfo">
              <li settingsStripe [readonly]="true">
                <div settingsStripeLabel>
                  <b exosParagraph [isBold]="true">{{ stripe.title }}</b>
                </div>
                <div settingsStripeValue>
                  <ng-container *ngFor="let paragraph of stripe.bodyText">
                    {{ paragraph }} <br />
                  </ng-container>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>

    <footer exosSheetFooter>
      <button exosButton (click)="close()">Close</button>
    </footer>
  </div>
</div>
