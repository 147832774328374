import { Injectable } from '@angular/core';
import { DashTableColumnDefinition } from '@dashboard/table';

export interface UserPreferences {
  projects: TablePreferences;
  programs: TablePreferences;
  gantt: TablePreferences;
}

export interface TablePreferences {
  pageSize?: number;
  sort?: string;
  columns?: DashTableColumnDefinition[];
  version?: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesService {
  private readonly PREFERENCES_KEY = 'userPreferences';

  private version = 'up-2.1';

  constructor() {}

  public getVersion(): string {
    return this.version;
  }

  public getAllUserPreferences(): UserPreferences {
    return JSON.parse(localStorage.getItem(this.PREFERENCES_KEY)) || {};
  }
  public getUserPreferences(tableType: string): TablePreferences {
    const currentUserPreferences = this.getAllUserPreferences();
    return currentUserPreferences[tableType] || {};
  }

  public setUserPreferences(tableType: string, data: TablePreferences) {
    data.version = this.version;
    const currentUserPreferences = this.getAllUserPreferences();
    const updatedCurrentUserPreferences = Object.assign(
      currentUserPreferences,
      { [tableType]: data }
    );
    localStorage.setItem(
      this.PREFERENCES_KEY,
      JSON.stringify(updatedCurrentUserPreferences)
    );
  }

  public udateTableColumnsPreferences(
    tableType: string,
    column: DashTableColumnDefinition
  ) {
    const userPreferences = this.getUserPreferences(tableType);
    userPreferences.columns.forEach((col) => {
      if (col.id === column.id) {
        col.display = column.display;
      }
    });
    this.setUserPreferences(tableType, userPreferences);
  }
}
