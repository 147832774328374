import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ProjectService } from '../../../../shared/service/project/project.service';
import { ProjectView } from '../../../../shared/models/project.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectViewResolver implements Resolve<ProjectView> {
  constructor(private router: Router, private projectService: ProjectService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ProjectView> {
    const { id: projectId } = route.parent.params;

    return this.projectService.findProjectById(projectId).pipe(
      mergeMap((project: ProjectView) => of(project)),
      catchError(() => {
        this.router.navigate(['/projects']);
        return EMPTY;
      })
    );
  }
}
