import { JqlConfig } from '../../exos/jql-input-search/config/config';

export const JQL_PROGRAM_CONFIG: JqlConfig = {
  fields: [
    {
      name: 'Id',
      operators: ['=', '!='],
    },
    {
      name: 'Name',
      operators: ['=', '~', '!='],
    },
    {
      name: 'Owner',
      operators: ['=', '~', '!='],
    },
    {
      name: 'Client',
      operators: ['=', '~', '!='],
    },
    {
      name: 'Status',
      operators: ['=', '!='],
      values: [
        'Not Started',
        'On Hold',
        'On Track',
        'Potential Risks',
        'Risk or Roadblock',
      ],
    },
    {
      name: 'Representative',
      operators: ['=', '!='],
    },
  ],
  keywords: ['AND', 'OR'],
};
