import { Inject, Injectable } from '@angular/core';
import { JqlToken } from '../../jql-token';
import { JqlTokenBuilderService } from '../jql-token-builder/jql-token-builder.service';
import { JQL_CONFIG, JqlConfig } from '../../config/config';
import { FILTER_VIEW_TYPE } from 'src/app/shared/models/filter-view-type.model';

export interface JqlTokenSuggestionI {
  value: string;
  token: JqlToken;
}

@Injectable()
export class JqlTokenSuggestionService {
  constructor(
    @Inject(JQL_CONFIG) private jqlConfig: JqlConfig,
    private jqlTokenBuilderService: JqlTokenBuilderService
  ) {}

  public fetchSuggestions(
    query: string,
    type: FILTER_VIEW_TYPE
  ): JqlTokenSuggestionI[] {
    if (query !== null) {
      const tokens = this.jqlTokenBuilderService.buildQuery(query);
      const currentToken = tokens[tokens.length - 1];
      const values = this.checkForSuggestions(currentToken, tokens, type);

      return values.map((value) => ({ value, token: currentToken }));
    }

    return [];
  }

  private checkForSuggestions(
    token: JqlToken,
    tokens: JqlToken[],
    type: FILTER_VIEW_TYPE
  ): string[] {
    switch (token.type) {
      case 'FIELD':
        return this.checkForTokenFieldSuggestions(token, type);
      case 'OPERATOR':
        return this.checkForTokenOperatorSuggestions(token, tokens, type);
      case 'VALUE':
        return this.checkForTokenValueSuggestions(token, tokens, type);
      case 'KEYWORD':
        return this.checkForTokenKeywordSuggestions(token, type);
    }
  }

  private checkForTokenFieldSuggestions(
    token: JqlToken,
    type: FILTER_VIEW_TYPE
  ): string[] {
    const currentValue = token.value.toLocaleLowerCase().replace(/"/g, '');

    return this.jqlConfig[type].fields
      .map((field) => field.name.toLowerCase())
      .filter((fieldName) =>
        fieldName === token.value ? false : fieldName.startsWith(currentValue)
      );
  }

  private checkForTokenOperatorSuggestions(
    token: JqlToken,
    query: JqlToken[],
    type: FILTER_VIEW_TYPE
  ): string[] {
    const prevTokenFieldName = query[token.index - 1].value
      .toLowerCase()
      .replace(/"/g, '');

    return this.jqlConfig[type].fields
      .filter((field) => field.name.toLowerCase() === prevTokenFieldName)
      .map((field) => field.operators)
      .reduce((acc, val) => acc.concat(val), [])
      .filter((fieldName) =>
        fieldName === token.value ? false : fieldName.startsWith(token.value)
      );
  }

  private checkForTokenValueSuggestions(
    token: JqlToken,
    query: JqlToken[],
    type: FILTER_VIEW_TYPE
  ): string[] {
    const prevTokenFieldName = query[token.index - 2].value
      .toLowerCase()
      .replace(/"/g, '');

    return this.jqlConfig[type].fields
      .filter(
        (field) =>
          field.name.toLowerCase() === prevTokenFieldName && field.values
      )
      .map((field) => field.values)
      .reduce((acc, val) => acc.concat(val), [])
      .map((fieldName) => fieldName.toLowerCase())
      .filter((fieldName) =>
        fieldName === token.value ? false : fieldName.startsWith(token.value)
      );
  }

  private checkForTokenKeywordSuggestions(
    token: JqlToken,
    type: FILTER_VIEW_TYPE
  ): string[] {
    const value = token.value.toLocaleLowerCase().replace(/"/g, '');

    return this.jqlConfig[type].keywords.filter((field) =>
      field.toLowerCase().startsWith(value)
    );
  }
}
