<div fxLayout="row wrap" fxLayoutGap="32px grid">
  <div fxFlex="50" fxFlex.lt-lg="100">
    <div exosSheet>
      <div exosSheetSection type="secondary">
        <div exosSheetBlock>
          <h3 exosHeadline type="sub">
            Program (<b>PRG.{{ programId }}</b
            >)
          </h3>
        </div>
      </div>

      <div exosSheetSection>
        <div exosSheetBlock>
          <ng-template libFormContainer> </ng-template>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="programStatus$ | async as programStatus">
    <card-status
      fxFlex="50"
      fxFlex.lt-lg="100"
      [data]="programStatus"
      [canUpdate]="canUpdate$ | async"
      [cardTitle]="'Program status'"
      (updateStatusItem)="updateProgramStatus($event, programStatus)"
    >
    </card-status>
  </ng-container>
</div>

<div
  exosSheet
  *ngIf="canDelete$ | async"
  class="row-buttons row-buttons--sticky"
>
  <div exosSheetSection semantic="neutral">
    <div exosSheetBlock>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div>
          <button
            exosButton
            semantic="critical"
            (click)="deleteProgram()"
            [isDisabled]="updateInProgress$ | async"
            [disabled]="updateInProgress$ | async"
          >
            Delete Program
          </button>
        </div>

        <div *ngIf="updateInProgress$ | async">
          <div class="loading-spin loading-spin--medium"></div>
        </div>
      </div>
    </div>
  </div>
</div>
