import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  convertToParamMap,
  Params,
  Router,
} from '@angular/router';
import { filter, map, pluck, tap } from 'rxjs/operators';

import { ApiService, UserService } from '@dashboard/core';
import {
  DashTableComponent,
  PageContext,
  SortDirection,
  UrlDataSource,
} from '@dashboard/table';

import { ProjectModel } from '../../../shared/models/project.model';
import { StatusNameType } from '../../../shared/models/status';
import { JqlInputComponent } from '../../../exos/jql-input-search/components/jql-input/jql-input.component';
import { JqlTokenBuilderService } from '../../../exos/jql-input-search/services/jql-token-builder/jql-token-builder.service';
import { Observable } from 'rxjs';
import { RouteProjectColumnsService } from '../../../shared/service/route-projects-columns/route-project-columns.service';
import { Location } from '@angular/common';
import { DocumentService } from '../../../shared/service/document/document.service';
import { ProjectStatusItem } from '../../../shared/models/project-status';
import { ListingRouteDataConfig } from '../../../shared/models/listing';
import { JqlTokenSuggestionService } from '../../../exos/jql-input-search/services/jql-token-suggestion/jql-token-suggestion.service';
import { JqlTokenValidatorService } from '../../../exos/jql-input-search/services/jql-token-validator/jql-token-validator.service';
import { JQL_CONFIG } from '../../../exos/jql-input-search/config/config';
import { JQL_PROJECT_CONFIG } from '../../config/jql.config';
import {
  TablePreferences,
  UserPreferencesService,
} from '../../../shared/service/user-preferences/user-preferences.service';
import { Objective } from '../../../shared/models/objective.model';

@Component({
  selector: 'app-projects',
  templateUrl: './projects-route.component.html',
  styleUrls: ['./projects-route.component.scss'],
  providers: [
    JqlTokenSuggestionService,
    JqlTokenValidatorService,
    {
      provide: JQL_CONFIG,
      useValue: JQL_PROJECT_CONFIG,
    },
  ],
})
export class ProjectsRouteComponent implements OnInit, AfterViewInit {
  public toggleDisplay = false;

  public routeData$: Observable<ListingRouteDataConfig>;
  public projects: UrlDataSource<ProjectModel>;

  public readonly perPageValues: Array<number> = [25, 50, 100];

  public readonly pmtHost: string = `${this.apiService.getBaseUrl(
    'pmt'
  )}/projects/list/`;

  public readonly tableNotFoundMsg = 'No projects found.';

  public tableCols: any[];

  public pageContext: PageContext = new PageContext();

  public showStatusColumnTemplate = false;

  public searchIsBasic = false;

  public searchQuery = '';

  public jqlLocales = {
    button: ['Search', 'Filter'],
    placeholder: ['Search projects', 'Filter projects'],
  };

  public objectives: Objective[];

  public objectives$: Observable<Objective[]> = this.route.data.pipe(
    tap((response) => {
      this.objectives = response.objectives as Objective[];
    }),
    map(({ objectives }) => objectives)
  );

  @ViewChild(JqlInputComponent)
  public jqlInputComponent: JqlInputComponent;

  @ViewChild(DashTableComponent)
  private table: DashTableComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private apiService: ApiService,
    private documentService: DocumentService,
    private changeDetectorRef: ChangeDetectorRef,
    private userSessionService: UserService,
    private tableColumnsService: RouteProjectColumnsService,
    private jqlTokenBuilderService: JqlTokenBuilderService,
    private userPreferencesService: UserPreferencesService
  ) {}

  ngOnInit() {
    const userPreferences: TablePreferences =
      this.userPreferencesService.getUserPreferences('projects');

    this.routeData$ = this.route.data.pipe(
      pluck('page'),
      tap((routeData: ListingRouteDataConfig) => {
        const fullname = this.userSessionService.getSession().fullname;
        this.tableCols = routeData.projects.tableCols;

        if (
          userPreferences?.version &&
          userPreferences.version ===
            this.userPreferencesService.getVersion() &&
          userPreferences?.columns
        ) {
          this.tableCols = [...userPreferences.columns];
        } else {
          this.tableCols = routeData.projects.tableCols;
          this.userPreferencesService.setUserPreferences('projects', {
            columns: this.tableCols,
          });
        }

        if (
          routeData.title === 'My Approvals' &&
          routeData.projects.searchQuery === '"current approver" = '
        ) {
          routeData.projects.searchQuery += `"${fullname}"`;
        }

        if (
          routeData.title === 'My Projects' &&
          routeData.projects.searchQuery === 'owner = '
        ) {
          routeData.projects.searchQuery += `"${fullname}"`;
          routeData.projects.searchQuery += ` OR representative = "${fullname}"`;
        }

        this.searchQuery = routeData.projects.searchQuery;
        this.showStatusColumnTemplate = !!routeData.projects.tableCols.filter(
          (col) => col.id === 'status'
        ).length;

        this.projects = new UrlDataSource(this.pmtHost, {
          pageContext: this.pageContext,
          dataExtractor: (response) => this.dataTableExtractor(response),
          totalElementsExtractor: (response) => response.meta.count,
          httpVerb: 'POST',
        });
      })
    );
  }

  ngAfterViewInit() {
    this.route.queryParams
      .pipe(filter((params) => params !== undefined))
      .subscribe((params: Params) => {
        const queryParams = JSON.parse(JSON.stringify(params));
        const paramMap = convertToParamMap(queryParams);

        this.projects.setRequestBody(this.jqlInputComponent.searchQueryTokens);
        this.projects.setQueryParams(queryParams);

        this.searchQuery = paramMap.has('search')
          ? paramMap.get('search')
          : this.searchQuery;
        this.searchIsBasic = paramMap.get('basic') === 'true';

        if (this.searchQuery.trim() === '') {
          this.projects.setRequestBody([]);
        } else {
          const jqlQuery = this.jqlTokenBuilderService.buildQuery(
            this.searchQuery.trim()
          );
          this.projects.setRequestBody(this.searchIsBasic ? [] : jqlQuery);
        }

        this.updateTableWithUserPreferences();
        this.updateUserPreferences();

        if (this.projects && this.projects.isRunning()) {
          this.projects.generate();
        } else {
          this.projects.start();
        }
      });
    this.changeDetectorRef.detectChanges();
  }

  public updateUserPreferences() {
    this.projects.onSuccess(() => {
      const userPreferences: TablePreferences = {
        pageSize: this.pageContext.size,
      };

      if (this.projects.sortValue) {
        userPreferences.sort = this.projects.sortValue;
      }

      if (this.tableCols) {
        userPreferences.columns = this.tableCols;
      }

      this.userPreferencesService.setUserPreferences(
        'projects',
        userPreferences
      );
    });
  }

  public updateTableWithUserPreferences() {
    const userPreferences: TablePreferences =
      this.userPreferencesService.getUserPreferences('projects');

    if (userPreferences?.pageSize) {
      this.pageContext.setSize(userPreferences.pageSize);
    }

    if (userPreferences?.sort) {
      const [sortParamName, sortValue] = userPreferences.sort.split(',');
      const colIndex = this.tableCols.findIndex(
        (col) => col.id === sortParamName
      );
      this.table.sortColumn(colIndex, sortValue as SortDirection);
    }

    if (userPreferences?.columns) {
      this.tableCols = [...userPreferences.columns];
    }
  }

  public goToOverviewById(project: ProjectModel) {
    localStorage.setItem('lastVisitedFilter', this.location.path());

    if (this.tableColumnsService.canViewProjectActions(project)) {
      this.table.loading = true;
      this.router.navigate(['projects', project.id, 'details']);
    }
  }

  public fetchProjectsByQuery(event) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        search: event.query,
        basic: event.basicSearch,
        page: 1,
      },
      queryParamsHandling: 'merge',
    });
  }

  public openBriefFile(folderId) {
    this.table.loading = true;
    this.documentService
      .getProjectDocumentFileInfo(folderId, 'Project Brief')
      .subscribe(({ fileId }) => {
        this.table.loading = false;
        window.open(`https://docs.google.com/document/d/${fileId}/edit`);
      });
  }

  public openRoadMap(id) {
    var currentAbsoluteUrl = window.location.href;
    var currentRelativeUrl = this.router.url;
    var index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
    var baseUrl = currentAbsoluteUrl.substring(0, index);

    window.open(`${baseUrl}/projects/${id}/project-plan`);
  }

  public handleSearchType(value: boolean) {
    this.searchIsBasic = value;
  }

  private dataTableExtractor(response) {
    return response.data.map((project) => {
      if ('name' in project) {
        project.nameItem = { value: project.name, brief: project.folderId };
      }

      if ('phase' in project) {
        project._phase = project.phase;
        project.phase = project.phase
          ? this.camelCaseToSentenceCase(project.phase)
          : '-';
      }

      if ('status' in project) {
        const { value, comment } = project.status.overall;
        project.statusItem = new ProjectStatusItem(
          value,
          comment,
          StatusNameType.OVERALL
        );
        project.status_comment = comment ? comment : '-';
      }

      if ('client' in project) {
        project._client = project.client;
        project.client =
          project.client && project.client.name ? project.client.name : '-';
      }

      if ('owner' in project) {
        project._owner = project.owner;
        project.owner = project.owner.name;
      }

      if ('division' in project) {
        project.division =
          project.division.length > 0
            ? project.division.map((d) => d.item).join()
            : '-';
      }

      if ('gantt' in project) {
        project._gantt = project.gantt;
        project.gantt = project.gantt.filter(
          (task) => task.type === 'milestone'
        );
        project.gantt =
          project.gantt.length > 0
            ? project.gantt.map((milestone) => milestone.text).join()
            : '-';
      } else {
        project.gantt = '-';
      }

      if ('program' in project) {
        project._program = project.program ? project.program.name : '-';
      }

      if ('tags' in project) {
        project._tags =
          project.tags.length > 0
            ? project.tags.map((tag) => tag.name).join()
            : '-';
      }

      if ('lastModified' in project) {
        project.lastModified = project.lastModified
          ? project.lastModified
          : '-';
      }

      if ('projectedLaunchDate' in project) {
        project._projectedLaunchDate = project.projectedLaunchDate
          ? project.projectedLaunchDate[project.projectedLaunchDate.length - 1]
          : '-';
      }

      if ('risks' in project) {
        project._risks =
          project.risks.length > 0
            ? project.risks.map((risk) => risk.description).join()
            : '-';
      }

      if ('dependencies' in project) {
        project.dependencies_name =
          project.dependencies.length > 0
            ? project.dependencies.map((dependency) => dependency.name).join()
            : '-';
        project.dependencies_description =
          project.dependencies.length > 0
            ? project.dependencies
                .map((dependency) => dependency.description)
                .join()
            : '-';
      }

      if ('objectiveId' in project) {
        const { objectiveName, keyResultName } =
          this.findObjectiveAndKeyResultName(
            this.objectives,
            project.objectiveId,
            project.keyResultId
          );

        project._objective = project.objectiveId ? objectiveName : '-';
        project.keyResult = project.keyResultId ? keyResultName : '-';
      } else {
        project._objective = '-';
        project.keyResult = '-';
      }

      return project;
    });
  }

  private findObjectiveAndKeyResultName(
    objectives: Objective[],
    objectiveId: number,
    keyResultId: number
  ): { objectiveName: string; keyResultName: string } {
    const foundObjective = objectives?.find(
      (objective) => objective.id === objectiveId
    );
    const foundKeyResult = foundObjective?.keyResults?.find(
      (keyResult) => keyResult.id === keyResultId
    );

    return {
      objectiveName: foundObjective?.title,
      keyResultName: foundKeyResult?.name,
    };
  }

  private camelCaseToSentenceCase(value: string) {
    if (value === undefined) {
      return value;
    }
    const result = value.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  public toggleColumns({ columnName, visible }) {
    this.tableCols = this.tableCols.map((col) => ({
      ...col,
      display: col.name === columnName ? visible : col.display,
    }));
    this.userPreferencesService.setUserPreferences('projects', {
      columns: this.tableCols,
    });
  }
}
