<div class="table__toolbar">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <h2 exosHeadline type="sub" class="mb-0">Links</h2>

      <i
        *ngIf="routeConfig.link.table?.info?.hasInfoPoint"
        [exosTooltip]="routeConfig.link.table.info.text"
        exosTooltipPosition="above"
        class="exos-icon exos-icon-help-18 ml-05"
        style="font-size: 16px; color: #0b9dcc"
      >
      </i>
    </div>
    <div>
      <button
        *ngIf="canUpdateResources"
        exosButton
        semantic="primary"
        (click)="handleOpenAddModal(routeConfig.link.modal.add)"
      >
        Add link
      </button>

      <button exosButton (click)="openGDriveFolder(project.folderId)">
        View GDrive Folder
      </button>
    </div>
  </div>
</div>

<dash-table
  [dataSource]="dataLinks"
  [cols]="routeConfig.link.table.cols"
  [noDataMessage]="routeConfig.link.table.noResultsFoundMsg"
>
  <dash-table-column id="link">
    <div *dashTableHeaderTemplate="let col">
      <span>{{ col.name }}</span>
    </div>

    <div *dashTableCellTemplate="let row; let value; let index">
      <a exosLink visual="external-action" href="{{ value }}" target="_blank">
        {{ value }}
      </a>
    </div>
  </dash-table-column>
</dash-table>
