import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// guards
import { AuthGuard, RoleGuard } from '@dashboard/core';

// route components
import { ListingRouteComponent } from './containers/listing-route/listing-route.component';
import projectAllColumns from '../project/config/project-all.table.config';
import programAllColumns from '../program/config/program-all.table.config';
import { ObjectiveResolver } from '../shared/service/objective/objective.resolver';
import { UserFiltersResolver } from '../shared/service/user-filters/user-filters.resolver';
import { FilterViewTypeResolver } from '../shared/resolver/entity-type/filter-view-type.resolver';
import { FilterViewType } from '../shared/models/filter-view-type.model';
import { UserPreferencesResolver } from '../shared/service/user-preferences/user-preferences.resolver';

const routes: Routes = [
  {
    path: 'listing/all',
    component: ListingRouteComponent,
    canActivate: [AuthGuard, RoleGuard],
    resolve: {
      objectiveSets: ObjectiveResolver,
      type: FilterViewTypeResolver,
      userPreferences: UserPreferencesResolver,
    },
    data: {
      section: [FilterViewType.projects, FilterViewType.programs],
      page: {
        title: 'Projects & Programs',
        type: FilterViewType.projects,
        mainPage: true,
        projects: {
          searchQuery: '',
          button: { link: '/projects/create', label: 'Create new project' },
          tableCols: projectAllColumns,
        },
        programs: {
          searchQuery: '',
          button: { link: '/programs/create', label: 'Create new program' },
          tableCols: programAllColumns,
        },
      },
    },
  },
  {
    path: 'listing/my-projects',
    component: ListingRouteComponent,
    canActivate: [AuthGuard, RoleGuard],
    resolve: {
      userPreferences: UserPreferencesResolver,
      objectiveSets: ObjectiveResolver,
    },
    data: {
      section: FilterViewType.projects,
      page: {
        title: 'My Projects',
        type: FilterViewType.projects,
        defaultFilter: true,
        projects: {
          searchQuery: 'owner = ',
          button: { link: '/projects/create', label: 'Create new project' },
          tableCols: projectAllColumns,
        },
        programs: {
          searchQuery: '',
          button: { link: '/programs/create', label: 'Create new program' },
          tableCols: programAllColumns,
        },
      },
    },
  },
  {
    path: 'listing/my-programs',
    component: ListingRouteComponent,
    canActivate: [AuthGuard, RoleGuard],
    resolve: {
      userPreferences: UserPreferencesResolver,
    },
    data: {
      section: FilterViewType.programs,
      page: {
        title: 'My Programs',
        type: FilterViewType.programs,
        defaultFilter: true,
        projects: {
          searchQuery: '',
          button: { link: '/projects/create', label: 'Create new project' },
          tableCols: projectAllColumns,
        },
        programs: {
          searchQuery: 'owner = ',
          button: { link: '/programs/create', label: 'Create new program' },
          tableCols: programAllColumns,
        },
      },
    },
  },
  {
    path: 'listing/my-approvals',
    component: ListingRouteComponent,
    canActivate: [AuthGuard, RoleGuard],
    resolve: {
      userPreferences: UserPreferencesResolver,
      objectiveSets: ObjectiveResolver,
    },
    data: {
      section: [FilterViewType.projects, FilterViewType.programs],
      page: {
        title: 'My Approvals',
        type: FilterViewType.projects,
        defaultFilter: true,
        projects: {
          searchQuery: '"current approver" = ',
          button: { link: '/projects/create', label: 'Create new project' },
          tableCols: projectAllColumns,
        },
        programs: {
          searchQuery: '',
          button: { link: '/programs/create', label: 'Create new program' },
          tableCols: programAllColumns,
        },
      },
    },
  },
  {
    path: 'listing/my-filter/:id',
    component: ListingRouteComponent,
    canActivate: [AuthGuard, RoleGuard],
    resolve: {
      objectiveSets: ObjectiveResolver,
      userFilter: UserFiltersResolver,
      userPreferences: UserPreferencesResolver,
    },
    data: {
      section: [FilterViewType.projects, FilterViewType.programs],
      page: {
        title: 'My Filters',
        type: FilterViewType.projects,
        edit: true,
        projects: {
          searchQuery: '',
          button: { link: '/projects/create', label: 'Create new project' },
          tableCols: projectAllColumns,
        },
        programs: {
          searchQuery: '',
          button: { link: '/programs/create', label: 'Create new program' },
          tableCols: programAllColumns,
        },
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ListingRoutingModule {}
