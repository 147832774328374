import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';

const changeRequestFormConfig: FormConfig = {
  action: 'links',

  fields: {
    changeDescription: {
      name: 'changeDescription',
      label: 'Change description',
      placeholder: 'Change description',
      type: 'textarea',
      rows: '5',
      fxFlex: '100',
      value: null,
      resizable: 'vertical',
      infoPoint: {
        hasInfoPoint: true,
        text: 'Brief description of what the change is about',
      },
    },
    changeReason: {
      name: 'changeReason',
      label: 'Change Reason',
      placeholder: 'Change reason',
      type: 'textarea',
      rows: '5',
      fxFlex: '100',
      value: null,
      resizable: 'vertical',
      infoPoint: {
        hasInfoPoint: true,
        text: 'Brief explanation of why the change is being introduced',
      },
    },
    changeOutcome: {
      name: 'changeOutcome',
      label: 'Change Outcome',
      placeholder: 'Change outcome',
      type: 'textarea',
      rows: '5',
      fxFlex: '100',
      value: null,
      resizable: 'vertical',
      infoPoint: {
        hasInfoPoint: true,
        text: 'e.g. BENEFITS: What happens if we make the change? <br> e.g. RISKS: What happens if we don’t make the change?',
      },
    },
    impactOfChange: {
      name: 'impactOfChange',
      label: 'Impact of change',
      placeholder: 'Impact of change',
      type: 'textarea',
      rows: '5',
      fxFlex: '100',
      value: null,
      resizable: 'vertical',
      infoPoint: {
        hasInfoPoint: true,
        text: 'Impact on e.g. budget / resources / scope / timeline',
      },
    },
    proposedAction: {
      name: 'proposedAction',
      label: 'Proposed action',
      placeholder: 'Proposed action',
      type: 'textarea',
      rows: '5',
      fxFlex: '100',
      value: null,
      resizable: 'vertical',
      infoPoint: {
        hasInfoPoint: true,
        text: 'Outline of required steps to address the change',
      },
    },
    fileLink: {
      name: 'fileLink',
      label: 'Link',
      placeholder: 'Link',
      type: 'input',
      fxFlex: '100',
      value: null,
      infoPoint: {
        hasInfoPoint: true,
        text: 'Further documentation that might be helpful',
      },
    },
  },

  buttons: [
    {
      label: 'Save',
      type: 'button',
      action: 'submit',
      hasLoader: true,
      isPrimary: true,
    },
    {
      label: 'Close',
      type: 'button',
      action: 'cancel',
      hasLoader: false,
    },
  ],
};

export const createNewChangeRequestDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Add new change request',
    formConfig: changeRequestFormConfig,
  },
};
