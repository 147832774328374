<form *ngIf="form; else badConfig" [formGroup]="form">
  <div exosSheetSection>
    <div exosSheetBlock>
      <div fxLayout="row wrap" fxLayoutGap="1rem grid">
        <ng-container *ngFor="let field of fields">
          <ng-container *ngIf="field.length">
            <ng-container *ngFor="let subField of field">
              <div [fxFlex]="subField.fxFlex">
                <ng-container
                  dynamicFormField
                  [config]="subField"
                  [group]="form"
                >
                </ng-container>
              </div>
            </ng-container>
          </ng-container>

          <div *ngIf="!field.length" [fxFlex]="field.fxFlex">
            <ng-container dynamicFormField [config]="field" [group]="form">
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
  <div class="row-buttons row-buttons--sticky">
    <div exosSheetSection semantic="neutral">
      <div exosSheetBlock>
        <div
          fxLayout="row wrap"
          fxLayoutAlign="start center"
          fxLayoutGap="1rem grid"
        >
          <ng-container *ngFor="let button of config.buttons">
            <ng-container
              dynamicFormField
              [config]="button"
              [group]="form"
              (handleFormAction)="onFormAction($event)"
            >
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #badConfig>
  <section class="sheet__section sheet__section--warning">
    <div class="sheet__block">
      <p class="paragraph paragraph--warning">Form is bad configured</p>
    </div>
  </section>
</ng-template>
