import { Injectable } from '@angular/core';
import { ApiService, UserService } from '@dashboard/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {
  Category,
  Product,
  ProductSpaceDTO,
} from '../../models/product-space.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoadmapService {
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private userSessionService: UserService
  ) {}

  private apiHost() {
    return `${this.apiService.getBaseUrl('roadmap')}`;
  }

  public productSpaceFetchAll(): Observable<ProductSpaceDTO[]> {
    return this.http.get<ProductSpaceDTO[]>(`${this.apiHost()}/productSpaces`);
  }

  public fetchProducts(productSpaceId): Observable<Product[]> {
    return this.http.get<Product[]>(
      `${this.apiHost()}/productSpaces/${productSpaceId}/products`
    );
  }

  public fetchCategories(productId): Observable<Category[]> {
    return this.http.get<Category[]>(
      `${this.apiHost()}/products/${productId}/categories`
    );
  }

  public findByName(name: string): Observable<ProductSpaceDTO[]> {
    const params: HttpParams = new HttpParams()
      .set('name', name)
      .set('userId', this.userSessionService.getSession().user_id);

    return this.http
      .get<ProductSpaceDTO[]>(`${this.apiHost()}/productSpaces/searchName`, {
        params,
      })
      .pipe(
        map((response: ProductSpaceDTO[]) =>
          response.map((productSpace) => {
            return {
              ...productSpace,
              text: productSpace.name,
              value: productSpace.name,
            };
          })
        )
      );
  }
}
