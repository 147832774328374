import { DashTableColumnDefinition } from '@dashboard/table';

const programAllColumns: DashTableColumnDefinition[] = [
  {
    id: 'id',
    name: 'Program Id',
    sortable: true,
    value: 'id',
    display: true,
    fixed: true,
    colWidth: '115px',
  },
  {
    id: 'name',
    name: 'Program Name',
    sortable: true,
    value: 'name',
    display: true,
    fixed: true,
    colWidth: '300px',
  },
  {
    id: 'client',
    name: 'Program Client',
    sortable: true,
    value: 'client',
    display: true,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'owner',
    name: 'Program Owner',
    sortable: true,
    value: 'owner',
    display: true,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'projects',
    name: 'Projects Name',
    sortable: false,
    value: '_projects',
    display: true,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'status',
    name: 'Status',
    sortable: true,
    value: 'status',
    display: true,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'status_comment',
    name: 'Status Comment',
    sortable: false,
    value: 'status_comment',
    display: true,
    fixed: false,
    colWidth: '200px',
  },
];

export default programAllColumns;
