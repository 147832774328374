import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  ExosFormComponent,
  FormContainerDirective,
  FormEvent,
  FormService,
} from '@dashboard/exos-form';
import { ExosDialogConfig, ExosDialogRef } from '@dashboard/exos-dialog';
import { StatusItem } from '../../../models/status';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-update-status-dialog',
  templateUrl: './project-update-status-dialog.component.html',
  styleUrls: ['./project-update-status-dialog.component.scss'],
})
export class ProjectUpdateStatusDialogComponent
  implements AfterViewInit, OnDestroy
{
  @ViewChild(FormContainerDirective)
  public projectUpdateStatusFormContainer: FormContainerDirective;

  private form: ExosFormComponent;

  private modalSubs: Subscription[] = [];

  constructor(
    public config: ExosDialogConfig,
    public dialog: ExosDialogRef,
    private changeDetectorRef: ChangeDetectorRef,
    private exosFormService: FormService
  ) {}

  ngAfterViewInit() {
    this.form = this.buildFormComponent();
    this.changeDetectorRef.detectChanges();
    this.handleFormEvents();
    this.handleFormErrors();
  }

  ngOnDestroy() {
    this.modalSubs.forEach((sub) => sub.unsubscribe());
  }

  private handleFormEvents() {
    this.modalSubs.push(
      this.form.formEvents.subscribe((event: FormEvent) => {
        switch (event.type) {
          case 'cancel':
            this.dialog.close();
            break;
          case 'save':
            this.form.form.markAsPending();

            this.dialog.submit({
              resourceName: this.config.data.resourceName,
              formValue: event.rawValue as StatusItem,
            });
            break;
        }
      })
    );
  }

  private handleFormErrors() {
    this.modalSubs.push(
      this.dialog.afterErrors.subscribe((errors: any) => {
        if (errors) {
          this.exosFormService.setFieldErrors(errors);
        } else {
          this.form.form.setValue(this.form.form.getRawValue());
        }
      })
    );
  }

  private buildFormComponent(): ExosFormComponent {
    return this.exosFormService.buildForm(
      this.projectUpdateStatusFormContainer.viewContainerRef,
      this.config.data.formConfig
    ).instance;
  }
}
