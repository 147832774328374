<div exos-sheet>
  <div exosSheetSection type="secondary">
    <div exos-sheet-block>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 exosHeadline type="sub" class="mb-0">Project change requests</h2>

        <button
          *ngIf="canCreateChangeRequest"
          exosButton
          (click)="onCreateChangeRequest()"
        >
          New Change Request
        </button>
      </div>
    </div>
  </div>

  <div exosSheet *ngIf="!changeRequests.length">
    <div exosSheetSection>
      <div exosSheetBlock>
        <p exosParagraph>Project doesn't have change requests.</p>
      </div>
    </div>
  </div>

  <ul sheet-stripes>
    <li
      settings-stripe
      *ngFor="let request of changeRequests"
      routerLink="{{ viewRoutePath }}/{{ request.hrefLink }}"
      [hasLink]="request.hasActions"
      [directSelection]="request.hasActions"
      [semantic]="request.semanticColor"
    >
      <div settings-stripe-label>
        <span>{{ request.createdAt | date }}</span>
      </div>

      <div settings-stripe-value>
        <span exosBadge [semantic]="request.semanticColor">{{
          request.readableState
        }}</span>
        <p exosParagraph class="mt-1">
          {{ request.changeDescription }}
        </p>
      </div>

      <div settings-stripe-action>
        <div *ngIf="request.hasActions" fxLayout="column">
          <ng-container *ngFor="let action of request.actions">
            <div>
              <a
                exosLink
                [semantic]="action.semanticColor"
                (click)="onUpdateChangeRequest($event, request, action)"
              >
                {{ action.label }}
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </li>
  </ul>
</div>
