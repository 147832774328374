<div class="table-search">
  <section class="table-search__section">
    <div class="input-button-combination">
      <div class="input-button-combination__input">
        <span class="input-text-group input-text-group--empty">
          <span
            [class.color--success]="searchQueryIsValid"
            [class.color--error]="!searchQueryIsValid"
            class="input-text-group__icon input-text-group__icon--search"
          ></span>

          <input
            #searchInput
            type="text"
            class="input-text"
            [placeholder]="searchInputPlaceholder"
            [(ngModel)]="searchQuery"
            (input)="onSearchChange($event.target.value)"
          />
          <span
            class="input-text-group__action input-text-group__action--reset"
            (click)="resetSearchQuery()"
          ></span>
        </span>

        <div class="autocomplete" *ngIf="suggestions && suggestions.length">
          <app-autocomplete-option
            *ngFor="let suggestion of suggestions"
            [value]="suggestion"
            (selected$)="chooseSuggestion(suggestion)"
          >
            {{ suggestion.value }}
          </app-autocomplete-option>
        </div>
      </div>
      <div class="input-button-combination__action">
        <button class="button" (click)="doSearch()">
          {{ searchBtnLabel }}
        </button>

        <button
          *ngIf="saveBtnLabel"
          [ngClass]="saveBtnDisabled ? 'button button--disabled' : 'button'"
          [disabled]="saveBtnDisabled"
          (click)="saveFilter()"
        >
          {{ saveBtnLabel }}
        </button>

        <button
          *ngIf="deleteBtnLabel"
          [ngClass]="
            saveBtnDisabled
              ? 'button button--critical button--disabled'
              : 'button button--critical'
          "
          [disabled]="deleteBtnDisabled"
          (click)="deleteFilter()"
        >
          {{ deleteBtnLabel }}
        </button>

        <div *ngIf="!disableSearchToggle" class="input-byline">
          <input
            id="basic_search"
            [formControl]="searchFilterTypeControl"
            class="input-checkbox"
            type="checkbox"
          />
          <label
            for="basic_search"
            exosTooltipPosition="before"
            [exosTooltip]="searchInfo"
            >{{ filterCheckboxLabel }}</label
          >
        </div>
      </div>
      <div class="input-byline">
        <button
          mat-icon-button
          id="cols_menu"
          [matMenuTriggerFor]="principalMenu"
          class="menu"
          aria-label="Example icon-button with a menu"
        >
          <mat-icon class="view-column-menu">view_column</mat-icon>
          <label
            for="cols_menu"
            class="column-label"
            exosTooltipPosition="before"
            >Columns</label
          >
        </button>
      </div>

      <mat-menu #principalMenu="matMenu">
        <ng-container *ngFor="let column of tableColumns">
          <mat-checkbox
            class="mat-menu-item"
            *ngIf="!column.fixed || column.id === 'file' || column.id === 'id'"
            [checked]="column.display"
            (click)="$event.stopPropagation()"
            (change)="onColumnChecked(column, $event)"
            style="color: black"
          >
            {{ column.name }}
          </mat-checkbox>
        </ng-container>
      </mat-menu>
    </div>

    <p *ngIf="queryError" class="mt-1 input-byline--error">
      {{ queryError }}
    </p>
  </section>
</div>
