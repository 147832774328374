import { Directive, Input } from '@angular/core';
import { ExosBaseSemanticType } from '../../exos.model';

@Directive({
  selector: '[exos-badge], [exosBadge]',
  host: {
    class: 'badge',
    '[class.badge--success-solid]': "semantic === 'success'",
    '[class.badge--warning-solid]': "semantic === 'warning'",
    '[class.badge--critical-solid]': "semantic === 'critical'",
    '[class.badge--neutral-solid]': "semantic === 'neutral'",
    '[class.badge--positioned]': 'positioned',
  },
})
export class BadgeDirective {
  @Input() semantic: ExosBaseSemanticType;
  @Input() positioned: boolean;
}
