import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { Observable } from 'rxjs';
import { AutocompleteOption } from '@dashboard/exos-form';

@Component({
  selector: 'exos-autocomplete',
  templateUrl: './exos-autocomplete.component.html',
  styleUrls: ['./exos-autocomplete.component.scss'],
})
export class ExosAutocompleteComponent implements OnInit {
  @ViewChild('input')
  public autocompleteInput: ElementRef<HTMLInputElement>;

  @ViewChild('auto')
  public autocomplete: MatAutocomplete;

  @Input()
  public group: FormGroup;

  @Input()
  public name: string;

  @Input()
  public label: string;

  @Input()
  public infoPoint: string;

  @Input()
  public placeholder: string;

  @Input()
  public type: string;

  @Input()
  public icon: string;

  @Input()
  public noResultsMessage: string;

  @Input()
  public items: Observable<any[]>;

  @Input()
  public isLoading: boolean;

  @Output()
  public closed: EventEmitter<any> = new EventEmitter();

  @Output()
  public selectedItem: EventEmitter<any> = new EventEmitter();

  @Output()
  public blurInput: EventEmitter<any> = new EventEmitter();

  @Input()
  public displayNoResultsMsg: boolean;

  private lastSelectedItem: any = null;

  constructor() {}

  ngOnInit(): void {}

  public displayFn(option: AutocompleteOption): string {
    return option && option.text ? option.text : '';
  }

  public onSelectItem(ev: MatAutocompleteSelectedEvent) {
    const value = ev.option.value;

    if (value) {
      this.selectedItem.emit(value);
      this.lastSelectedItem = value;
    }

    this.displayNoResultsMsg = false;
  }

  public handleAutocompleteClose() {
    if (!this.group.get(this.name).value) {
      this.lastSelectedItem = null;
    }
    if (typeof this.group.get(this.name).value === 'string') {
      this.group
        .get(this.name)
        .setValue(this.lastSelectedItem || null, { emitEvent: false });
    }

    this.closed.emit();
    this.isLoading = false;
    this.displayNoResultsMsg = false;
  }

  public onBlur() {
    if (this.autocomplete.isOpen) {
      return;
    } else {
      if (this.lastSelectedItem === null) {
        this.group.get(this.name).setValue(null, { emitEvent: false });
      } else if (
        typeof this.lastSelectedItem === 'string' &&
        this.lastSelectedItem.trim() !== ''
      ) {
        this.group
          .get(this.name)
          .setValue(this.lastSelectedItem || null, { emitEvent: false });
      }
    }

    this.selectedItem.emit(null);
    this.displayNoResultsMsg = false;
  }
}
