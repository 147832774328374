<header exosPageHeader [isOverlapping]="true" layout="narrow">
  <div exosPageHeaderBlock>
    <h1 exosPageHeaderHeadline>IONOS Projects</h1>
    <h2 exosPageHeaderHeadline>Center of Excellence</h2>
  </div>
</header>

<div class="mb-4-i" exosPageSection [isOverlapping]="true" size="narrow">
  <div exosPageSectionBlock>
    <div exosSheet>
      <div exosSheetSection>
        <div exosSheetBlock>
          <p exosParagraph>
            The IONOS Projects Center of Excellence is a group of colleagues who
            serve as a representative and single-point-of-contact for their
            respective unit. In case you have questions or need support related
            to IONOS Projects, please contact your respective member from the
            Center of Excellence (CoE) stated below.
          </p>
          <p exosParagraph>
            Please note, that the Center of Excellence does not function as a
            Project Management Office. CoE members are not taking any decisions
            on running projects or prioritization and are only responsible for
            the success of their own projects.
          </p>
        </div>
      </div>
    </div>

    <table class="table table--responsive">
      <thead>
        <tr class="table__header">
          <td class="table__cell" data-caption="Division Head">
            Division Head
          </td>
          <td class="table__cell" data-caption="Division Name">
            Division Name
          </td>
          <td class="table__cell" data-caption="CoE Member/s">CoE Member/s</td>
        </tr>
      </thead>
      <tbody>
        <tr class="table__row">
          <td class="table__cell">Stephan Schmidt</td>
          <td class="table__cell">Customer Lifecycle Management</td>
          <td class="table__cell">Rebekka Scheller</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Martin E / Heiko Binder</td>
          <td class="table__cell">Agency Business / Agency Program</td>
          <td class="table__cell">Andreas Wörz, Steffen Frey</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Kai-Martin Köhne</td>
          <td class="table__cell">Financial Projects and Systems</td>
          <td class="table__cell">Helene Lehmann-Richter</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Elisabeth Kurek</td>
          <td class="table__cell">Marketing Cloud</td>
          <td class="table__cell">Martin Repplinger</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Elias Rendon Benger</td>
          <td class="table__cell">Global Marketing &amp; Commercial Management</td>
          <td class="table__cell">Alisa-Katarina Litterst</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Patrick Schaudel</td>
          <td class="table__cell">PU SMe + E&amp;O</td>
          <td class="table__cell">Hendrik Brecht, Sandra Hößel</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Alexander Schramm</td>
          <td class="table__cell">Website &amp; eCommerce Platforms</td>
          <td class="table__cell">Helen Farys, Christian Graner</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Rainer Sträter</td>
          <td class="table__cell">Global Platform Hosting</td>
          <td class="table__cell">Camilla Kappenberger</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Andre Schloemer</td>
          <td class="table__cell">Communications</td>
          <td class="table__cell">Nils Döhring</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Lars Krämer</td>
          <td class="table__cell">Controlling</td>
          <td class="table__cell">Markus Reutter</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Stefan Mink</td>
          <td class="table__cell">TechOps Infrastructure</td>
          <td class="table__cell">Uwe Erlenwein, Louis Mörixbauer</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Sascha vom Scheidt</td>
          <td class="table__cell">Global HR Hosting</td>
          <td class="table__cell">Alina Lackner, Yvonne Felic</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Claudio Serrano</td>
          <td class="table__cell">Channel Sales &amp; Field Sales</td>
          <td class="table__cell">Xenia Sausele</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Peter Prahl</td>
          <td class="table__cell">SMB &amp; international Sales</td>
          <td class="table__cell">Erez Tetelman</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Jan Behrens</td>
          <td class="table__cell">Online Marketing Hosting</td>
          <td class="table__cell">Karim Chelly</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Jens Lautenbacher </td>
          <td class="table__cell">Hosting BSS</td>
          <td class="table__cell">Matthias Riesterer, Sven Brunner, Andreas Burkhard</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Frederik Raviol</td>
          <td class="table__cell">Operations - Sales </td>
          <td class="table__cell">Frederik Raviol</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Oliver Kleimann</td>
          <td class="table__cell">Operations - CX</td>
          <td class="table__cell">Marina Bianchi</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Michael Fromm</td>
          <td class="table__cell">Operations - Performance Management</td>
          <td class="table__cell">Petra Schörkl</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Sandro Kopte</td>
          <td class="table__cell">Operations - STRATO</td>
          <td class="table__cell">Daniel Stringe</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Alexander Vierschrodt</td>
          <td class="table__cell">PU Enterprise Cloud, NGS &amp; Dedicated Server</td>
          <td class="table__cell">Henry Böhme</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Patrick Schaudel</td>
          <td class="table__cell">Enterprise Cloud</td>
          <td class="table__cell">Felix Grundmann, Alexandru Galita, Tobias von der Krone</td>
        </tr>
        <tr class="table__row">
          <td class="table__cell">Holger Rüpprich</td>
          <td class="table__cell">Customer Platforms</td>
          <td class="table__cell">Sandra Schmidt</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
