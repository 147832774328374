import { Injectable } from '@angular/core';
import {
  DashDialogService,
  ExosDialogConfig,
  ExosDialogRef,
} from '@dashboard/exos-dialog';
import { Status } from '../../models/status';
import { StatusChangeEvent, StatusNameType } from '../../models/status';
import {
  ProjectModel,
  ProjectStartDates,
  StartDate,
} from '../../models/project.model';
import { ProjectUpdateStatusDialogComponent } from '../../components/dialogs/project-update-status-dialog/project-update-status-dialog.component';
import { projectUpdateStatusModalConfig } from '../../config/dialogs/project-status.config';
import { addprojectedLaunchDateDialogConfig } from 'src/app/shared/config/dialogs/projected-launch-date-dialog.config';
import { AddItemDialogComponent } from '../../components/dialogs/add-item-dialog/add-item-dialog.component';
import { ProjectStatusHelpDialogComponent } from '../../components/dialogs/project-status-help/project-status-help-dialog.component';
import { ProjectStatus, ProjectStatusItem } from '../../models/project-status';
import { ProjectUserRightsSession } from '../../service/user-rights/user-rigths';
import { addprojectedStartDateDialogConfig } from '../../config/dialogs/project-start-date-dialog.config';

@Injectable({
  providedIn: 'root',
})
export class ProjectOverviewFacadeService {
  private changeStatusModalConfig: ExosDialogConfig =
    projectUpdateStatusModalConfig;

  private projectLaunchDateModalConfig: ExosDialogConfig =
    addprojectedLaunchDateDialogConfig;

  private projectStartDateModalConfig: ExosDialogConfig =
    addprojectedStartDateDialogConfig;

  constructor(private dialogService: DashDialogService) {}

  public canUpdateProjectStatus(
    project: ProjectModel,
    userStatus: ProjectUserRightsSession
  ): boolean {
    const projectIsInCancelOrCompletePhase =
      project.phase === 'close' || project.phase === 'canceled';
    const { isOwner, isRepresentative, isAdmin } = userStatus;

    return (
      !projectIsInCancelOrCompletePhase &&
      (isOwner || isRepresentative || isAdmin)
    );
  }

  public parseProjectStatus(status: Status): ProjectStatusItem[] {
    const statuses = Object.keys(status).map((statusName: string) => {
      const currentStatus = status[statusName];
      return new ProjectStatusItem(
        currentStatus.value,
        currentStatus.comment,
        StatusNameType[statusName.toUpperCase()]
      );
    });
    statuses.unshift(statuses.pop());
    return statuses;
  }

  // TODO - END
  public openChangeStatusModal(
    data: ProjectStatusItem,
    projectStatus: ProjectStatusItem[]
  ): ExosDialogRef {
    const { comment, name, value } = data;
    const modalConfig = this.buildChangeStatusModalConfig(projectStatus, {
      comment,
      name,
      value,
    });

    return this.dialogService.open(
      ProjectUpdateStatusDialogComponent,
      modalConfig
    );
  }

  public openAddLaunchDateModal(launchDate: string): ExosDialogRef {
    const config = JSON.parse(
      JSON.stringify(this.projectLaunchDateModalConfig)
    );
    config.data.formConfig.fields.projectedLaunchDate.value = launchDate;
    return this.dialogService.open(AddItemDialogComponent, config);
  }

  public openAddStartDateModal(startDate: StartDate): ExosDialogRef {
    const config = JSON.parse(JSON.stringify(this.projectStartDateModalConfig));
    config.data.formConfig.fields.date.value = startDate;
    return this.dialogService.open(AddItemDialogComponent, config);
  }

  public openStatusHelpModal(): ExosDialogRef {
    return this.dialogService.open(ProjectStatusHelpDialogComponent, {
      data: { title: 'PROJECT STATUS CHEAT SHEET' },
    });
  }

  private buildChangeStatusModalConfig(
    projectStatus: ProjectStatusItem[],
    status: StatusChangeEvent
  ): ExosDialogConfig {
    const { name, value, comment } = status;
    const dialogConfig: ExosDialogConfig = JSON.parse(
      JSON.stringify(this.changeStatusModalConfig)
    );
    const overallStatus = this.findOverallStatus(projectStatus);

    const projectStatusValueOptions =
      dialogConfig.data.formConfig.fields.value.options.filter((item) => {
        if (name !== 'overall') {
          return item.value !== 'CANCELED' && item.value !== 'CLOSED';
        } else {
          return (
            (item.value !== 'CANCELED' && item.value !== 'CLOSED') ||
            item.value === overallStatus
          );
        }
      });

    dialogConfig.data.title = `Edit project ${name} status`;
    dialogConfig.data.formConfig.fields.comment.value = comment;
    dialogConfig.data.formConfig.fields.value.value = value;
    dialogConfig.data.formConfig.fields.value.options =
      projectStatusValueOptions;
    dialogConfig.data.resourceName = name;
    return dialogConfig;
  }

  private findOverallStatus(projectStatus: ProjectStatusItem[]) {
    const statuses: string[] = projectStatus.map((item) => item.value);

    if (statuses.includes('RISK_OR_ROADBLOCK')) {
      return 'RISK_OR_ROADBLOCK';
    } else if (statuses.includes('POTENTIAL_RISKS')) {
      return 'POTENTIAL_RISKS';
    } else if (statuses.includes('ON_TRACK')) {
      return 'ON_TRACK';
    } else {
      return 'NOT_STARTED';
    }
  }
}
