import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@dashboard/core';
import { GanttChartData, GanttLink, GanttTask } from '../../models/gantt.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { gantt } from '../dhtmlx-gantt-service/gantt/dhtmlxgantt';

@Injectable({
  providedIn: 'root',
})
export class GanttService {
  private readonly apiHost: string = `${this.apiService.getBaseUrl(
    'pmt'
  )}/projects`;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  fetchGanttData(projectId: number): Observable<GanttChartData> {
    return this.http
      .get<GanttChartData>(`${this.apiHost}/${projectId}/gantt`)
      .pipe(
        map((response) => {
          response.data.map((task) => {
            task.parent = task.parent.toString();
            // task.progress = Math.round(task.progress * 10) / 10;
          });

          return response;
        })
      );
  }

  createTask(task: GanttTask, projectId: number): Promise<GanttTask> {
    const newGanttTask: GanttTask = this.buildRequestBody(task);

    return this.http
      .post<GanttTask>(`${this.apiHost}/${projectId}/gantt/tasks`, newGanttTask)
      .toPromise();
  }

  updateTask(task: GanttTask, projectId: number): Promise<GanttTask> {
    const updatedGanttTask: GanttTask = this.buildRequestBody(task);

    return this.http
      .put<GanttTask>(
        `${this.apiHost}/${projectId}/gantt/tasks/${task.id}`,
        updatedGanttTask
      )
      .toPromise();
  }

  updateTaskOrder(
    taskId: number,
    projectId: number,
    orderIndex: number
  ): Promise<GanttTask[]> {
    return this.http
      .put<GanttTask[]>(
        `${this.apiHost}/${projectId}/gantt/tasks/${taskId}/order/${orderIndex}`,
        null
      )
      .toPromise();
  }

  updateTaskParentAndOrder(
    taskId: number,
    projectId: number,
    orderIndex: number,
    parentId: string | number
  ): Promise<GanttTask[]> {
    return this.http
      .put<GanttTask[]>(
        `${this.apiHost}/${projectId}/gantt/tasks/${taskId}/parent/${parentId}/order/${orderIndex}`,
        null
      )
      .toPromise();
  }

  deleteTask(taskId: string, projectId: number): Promise<any> {
    return this.http
      .delete<any>(`${this.apiHost}/${projectId}/gantt/tasks/${taskId}`)
      .toPromise();
  }

  createLink(ganttLink: GanttLink, projectId: number): Promise<GanttLink> {
    return this.http
      .post<GanttLink>(`${this.apiHost}/${projectId}/gantt/links`, ganttLink)
      .toPromise();
  }

  updateLink(ganttLink: GanttLink, projectId: number): Promise<GanttLink> {
    return this.http
      .put<GanttLink>(
        `${this.apiHost}/${projectId}/gantt/links/${ganttLink.id}`,
        ganttLink
      )
      .toPromise();
  }

  deleteLink(linkId: string, projectId: number): Promise<any> {
    return this.http
      .delete<any>(`${this.apiHost}/${projectId}/gantt/links/${linkId}`)
      .toPromise();
  }

  private buildRequestBody(task: GanttTask): GanttTask {
    const temp: GanttTask = Object.assign({}, task);

    if (typeof temp.parent === 'string') {
      temp.parent = parseInt(temp.parent, 10);
    }

    if (temp.project) {
      delete temp.project;
    }

    if (!temp.progress) {
      temp.progress = 0;
    } else if (typeof temp.progress === 'string') {
      temp.progress = parseFloat(temp.progress);
    }

    if (!temp.parent) {
      temp.parent = '0';
    }

    const formatFunc = gantt.date.date_to_str('%Y-%m-%d %H:%i:%s');

    temp.start_date = formatFunc(temp.start_date);
    temp.end_date = formatFunc(temp.end_date);

    return temp;
  }
}
