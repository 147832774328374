import { auditTableCols } from '../../../../shared/config/table/audit-table.config';
import { auditDetailsTableCols } from '../../../../shared/config/table/audit-details-table.config';
import { ArrayDataSource, DashTableColumnDefinition } from '@dashboard/table';
import { InfoPointConfig } from '@dashboard/exos-form';

export interface EditableTableRouteConfig {
  [name: string]: {
    table: {
      cols: DashTableColumnDefinition[];
      noResultsFoundMsg: string;
      info?: InfoPointConfig;
      items?: ArrayDataSource<any>;
    };
  };
}

export const auditRouteConfig: EditableTableRouteConfig = {
  audit: {
    table: {
      cols: auditTableCols,
      noResultsFoundMsg: 'No audit data found.',
      info: {
        hasInfoPoint: false,
      },
    },
  },
};

export const auditDetailsConfig: EditableTableRouteConfig = {
  data: {
    table: {
      cols: auditDetailsTableCols,
      noResultsFoundMsg: 'No audit data found.',
      info: {
        hasInfoPoint: false,
      },
    },
  },
};
