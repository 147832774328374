import {
  Directive,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { NgModel } from '@angular/forms';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  skip,
} from 'rxjs/operators';

@Directive({
  selector: '[ngModelDebounceChange]',
})
export class NgModelDebounceChangeDirective implements OnInit, OnDestroy {
  @Output() ngModelDebounceChange = new EventEmitter<any>();

  private subscription: Subscription;

  constructor(private ngModel: NgModel) {}

  ngOnInit(): void {
    this.subscription = this.ngModel.control.valueChanges
      .pipe(
        filter(() => !this.ngModel.control.disabled),
        skip(1),
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe((value) => this.ngModelDebounceChange.emit(value));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
