<form action="" [formGroup]="form">
  <label>
    <textarea
      [formControlName]="config.controlName"
      #autosize="cdkTextareaAutosize"
      cdkTextareaAutosize
      cdkAutosizeMinRows="5"
      class="input-textarea"
      [class.input-textarea--error]="form.hasError('fileLink')"
    ></textarea>
  </label>

  <p *ngIf="form.hasError('fileLink')" class="input-byline--error mt-1 mb-2">
    {{ form.getError('fileLink') }}
  </p>

  <div>
    <button
      (click)="onSubmit($event)"
      exosButton
      [isDisabled]="form.pending"
      [withLoader]="form.pending"
      semantic="primary"
    >
      Save
    </button>

    <button (click)="onCancel($event)" exosButton [isDisabled]="form.pending">
      Cancel
    </button>
  </div>
</form>
