<div class="table__toolbar">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="center">
      <h2 exosHeadline type="sub" class="mb-0">
        {{ tableCaption }}
      </h2>

      <i
        class="exos-icon exos-icon-help-18 ml-05"
        style="color: #0b9dcc; font-size: 16px"
        *ngIf="infopointConfig?.text"
        [exosTooltip]="infopointConfig.text"
        exosTooltipPosition="above"
      >
      </i>

      <ng-container *ngIf="helpConfig !== undefined && helpConfig.hasHelp">
        <a
          exosLink
          visual="helpandlearn"
          (click)="openHelpModal(helpConfig.config)"
          >Help</a
        >
      </ng-container>
    </div>

    <button *ngIf="hasAction" exosButton (click)="onOpenAddModal($event)">
      Add
    </button>
  </div>
</div>

<dash-table
  [dataSource]="tableDatasource"
  [cols]="cols"
  [noDataMessage]="noResultsFoundMsg"
  [useUrl]="false"
>
  <ng-container *ngFor="let col of cols">
    <td dashTableColumn id="{{ col.id }}">
      <div *dashTableHeaderTemplate="let col">
        <span>{{ col.name }}</span>
        <ng-container *ngIf="col.sortable">
          <i
            *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
            class="material-icons"
          >
            arrow_upward</i
          >
          <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
            arrow_downward</i
          >
        </ng-container>
      </div>
      <div *dashTableCellTemplate="let row; let value; let index">
        <span *ngIf="col.id === 'impact'">{{ customColumns[value] }}</span>
        <span *ngIf="col.id === 'probability'">{{ customColumns[value] }}</span>
        <span
          *ngIf="col.id !== 'probability' && col.id !== 'impact'"
          [exosTooltip]="value"
          exosTooltipPosition="above"
        >
          {{ value }}
        </span>
      </div>
    </td>
  </ng-container>
</dash-table>
