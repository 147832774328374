<section exosSheetSection class="w-05">
  <div exosSheetBlock>
    <p exosHeadline type="sub">
      {{ config.data.project.name }}
    </p>

    <form
      *ngIf="!loading"
      [formGroup]="form"
      fxLayout="row wrap"
      fxLayoutGap="32px grid"
    >
      <div exosSheetSection style="width: 480px">
        <div exosSheetBlock>
          <div fxLayout="row wrap" fxLayoutGap="1rem grid">

            <exos-select
              [group]="form"
              fxFlex="100"
              [name]="formConfig.fields?.objectiveYear.name"
              [label]="formConfig.fields?.objectiveYear.label"
              [placeholder]="formConfig.fields?.objectiveYear.placeholder"
              [options]="years"
            >
            </exos-select>

            <exos-select
              [group]="form"
              fxFlex="100"
              [name]="formConfig.fields?.objectiveQuarter.name"
              [label]="formConfig.fields?.objectiveQuarter.label"
              [placeholder]="formConfig.fields?.objectiveQuarter.placeholder"
              [options]="quarters"
              [displayFn]="displayObjectiveQuarterFn"
            >
            </exos-select>

            <exos-select
              [group]="form"
              fxFlex="100"
              [name]="formConfig.fields?.objectiveDivision.name"
              [label]="formConfig.fields?.objectiveDivision.label"
              [placeholder]="formConfig.fields?.objectiveDivision.placeholder"
              [options]="objectiveDivisions"
              [displayFn]="displayObjectiveDivisionFn"
            >
            </exos-select>

            <exos-select
              [group]="form"
              fxFlex="100"
              [name]="formConfig.fields?.objectiveId.name"
              [label]="formConfig.fields?.objectiveId.label"
              [placeholder]="formConfig.fields?.objectiveId.placeholder"
              [options]="objectives"
              [displayFn]="displayObjectiveFn"
              [compareFn]="compareObjectiveFn"
            >
            </exos-select>

            <exos-select
              [group]="form"
              fxFlex="100"
              [name]="formConfig.fields?.keyResultId.name"
              [label]="formConfig.fields?.keyResultId.label"
              [placeholder]="formConfig.fields?.keyResultId.placeholder"
              [options]="keyResults"
              [displayFn]="displayKeyResultFn"
              [compareFn]="compareFnKeyResultFn"
            >
            </exos-select>
          </div>
        </div>
        <div style="display: flex; justify-content: right; padding-top: 32px">
          <div class="request-loading">
            <div
              *ngIf="pendingRequest"
              class="loading-spin loading-spin--medium"
            ></div>
          </div>
          <button
            exosButton
            [isDisabled]="pendingRequest"
            [withLoader]="pendingRequest"
            semantic="primary"
            (click)="submit($event)"
          >
            Save
          </button>

          <button
            exosButton
            [isDisabled]="pendingRequest"
            (click)="closeDialog()"
          >
            Cancel
          </button>
        </div>
      </div>
    </form>

    <div *ngIf="loading">
      <div class="loading-spin loading-spin--large"></div>
    </div>
  </div>
</section>
