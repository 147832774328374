import { JQL_TOKEN_TYPE } from './config/config';

export class JqlToken {
  index: number;
  value: string;
  errIndex: number;
  type: JQL_TOKEN_TYPE;
  prevType: JQL_TOKEN_TYPE;
  nextType: JQL_TOKEN_TYPE;

  constructor(index: number, value: string, errIndex: number) {
    this.index = index;
    this.value = value;
    this.type = JqlToken.setTokenType(index);
    this.prevType = index !== 0 ? JqlToken.setTokenType(index - 1) : null;
    this.nextType = JqlToken.setTokenType(index + 1);
    this.errIndex = errIndex;
  }

  private static setTokenType(index: number): JQL_TOKEN_TYPE {
    if (JqlToken.isField(index)) {
      return 'FIELD';
    }

    if (JqlToken.isOperator(index)) {
      return 'OPERATOR';
    }

    if (JqlToken.isValue(index)) {
      return 'VALUE';
    }

    if (JqlToken.isKeyword(index)) {
      return 'KEYWORD';
    }
  }

  private static isField(index: number): boolean {
    return (index + 4) % 4 === 0;
  }

  private static isOperator(index: number): boolean {
    return (index + 3) % 4 === 0;
  }

  private static isValue(index: number): boolean {
    return (index + 2) % 4 === 0;
  }

  private static isKeyword(index: number): boolean {
    return (index + 1) % 4 === 0;
  }
}
