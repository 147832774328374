<div exos-sheet>
  <div exosSheetSection type="secondary">
    <div exos-sheet-block>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mb-0" exosHeadline type="sub">
          {{ cardTitle }}
        </h2>

        <ng-container *ngIf="hasHelp">
          <a exosLink visual="helpandlearn" (click)="onOpenHelp()">Help</a>
        </ng-container>
      </div>
    </div>
  </div>

  <ul sheet-stripes>
    <li
      *ngFor="let status of data"
      settings-stripe
      (click)="onUpdateStatusItem(status)"
      [directSelection]="canUpdate"
      [hasLink]="true"
      [type]="status.semanticType"
    >
      <div settings-stripe-label>
        {{ status.nameLocalized }}
      </div>

      <div settings-stripe-value>
        <p exosParagraph>
          <span exosBadge [semantic]="status.semanticType">
            {{ status.valueLocalized }}
          </span>
        </p>

        <p exosParagraph *ngIf="status?.comment" class="formatted-text">
          {{ status.comment }}
        </p>
      </div>

      <div *ngIf="canUpdate" settings-stripe-action>
        <a exosLink [isStripeTarget]="true" visual="action"> Change status </a>
      </div>
    </li>
  </ul>
</div>
