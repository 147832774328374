import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IChangeRequest } from '../../models/change-request';
import { Observable } from 'rxjs';
import { ApiService } from '@dashboard/core';
import { ProjectModel } from '../../models/project.model';

@Injectable({
  providedIn: 'root',
})
export class ChangeRequestService {
  private readonly apiHost = this.apiService.getBaseUrl('pmt');

  constructor(private http: HttpClient, private apiService: ApiService) {}

  addChangeRequest(
    body: IChangeRequest,
    projectId: number
  ): Observable<ProjectModel> {
    return this.http.post<ProjectModel>(
      `${this.apiHost}/projects/${projectId}/change-request/`,
      body
    );
  }

  updateChangeRequest(
    body: IChangeRequest,
    projectId: number,
    changeRequestId: number
  ): Observable<ProjectModel> {
    return this.http.put<ProjectModel>(
      `${this.apiHost}/projects/${projectId}/change-request/${changeRequestId}`,
      body
    );
  }
}
