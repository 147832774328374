import { Component } from '@angular/core';
import { ExosDialogConfig, ExosDialogRef } from '@dashboard/exos-dialog';
import { ExosBaseSemanticType } from 'src/app/exos/exos.model';

export interface HelpItem {
  title: string;
  bodyText: string | string[];
  icon?: string;
  semantic?: ExosBaseSemanticType;
}

export interface ProjectStakeholdersHelp {
  [key: string]: HelpItem[];
}

@Component({
  selector: 'app-project-stakeholders-help',
  templateUrl: './project-stakeholders-help-dialog.component.html',
  styleUrls: ['./project-stakeholders-help-dialog.component.scss'],
})
export class ProjectStakeholdersHelpDialogComponent {
  public contentText: ProjectStakeholdersHelp = {
    stakeholderDefinition: [
      {
        title: 'STAKEHOLDER DEFINITION',
        icon: '',
        bodyText:
          'The group of project stakeholders consists of colleagues that have an interest in the project and can either affect or be affected by the project. ' +
          'The main project team is included in the set of stakeholders and consists of core resources assigned to work on the deliverables of the project. ' +
          'The group of main team members are mandatorily attending all project related meetings. ' +
          'Stakeholders in a consulting role can be added optionally, depending on their contribution to the project or meeting, ' +
          'while stakeholders in an informed role simply get information about the progress and status of the project.',
      },
    ],
    stakeholderRoles: [
      {
        title: '',
        bodyText:
          'When assigning roles to the stakeholder, we are following the RACI approach. ',
      },
      {
        title: 'Responsible:',
        bodyText: 'person in charge of the project (project owner)',
      },
      {
        title: 'Accountable:',
        bodyText: 'sponsor of the project (project client)',
      },
      {
        title: 'Consultant:',
        bodyText: 'colleagues who are actively contributing to the project',
      },
      {
        title: 'Informed:',
        bodyText: 'colleagues who need information about the project progress',
      },
    ],
    stakeholderDivisionDescription: [
      {
        title: '',
        bodyText: [
          'Based on past project experiences, we created an exemplary list of stakeholders from frequently involved divisions: ',
        ],
      },
    ],
    stakeholderDivisions: [
      {
        title: '',
        bodyText: [
          'Product Manager / Product Owner',
          'Product Development',
          'OPS Manager',
          'OPS Manager IT',
          'Customer Care Manager',
          'Test Manager',
          'Shop Squad',
          'BSS Contact',
          'Commercial Manager',
          'Marketing / Communications Manager',
          'Legal Contact',
          'Controlling',
          'Procurement',
          'Editor',
        ],
      },
    ],
  };

  constructor(public config: ExosDialogConfig, public dialog: ExosDialogRef) {}

  close() {
    this.dialog.close();
  }
}
