import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  EditableTableRouteConfig,
  linkRouteConfig,
} from './project-links-route.config';
import { ProjectModel } from 'src/app/shared/models/project.model';
import { DocumentLink } from 'src/app/shared/models/project-link.model';
import { DashDialogService, ExosDialogConfig } from '@dashboard/exos-dialog';
import { AddItemDialogComponent } from '../../../../shared/components/dialogs/add-item-dialog/add-item-dialog.component';
import { ProjectService } from 'src/app/shared/service/project/project.service';
import { DashNotificationService } from '@dashboard/core';
import { ConfirmationComponent } from '../../../../shared/components/dialogs/confirmation/confirmation.component';
import { ProjectRightsService } from 'src/app/shared/service/user-rights/project-rights/project-rights.service';
import { ArrayDataSource } from '@dashboard/table';
import { map, pluck } from 'rxjs/operators';
import { combineLatest, Observable } from 'rxjs';

@Component({
  selector: 'app-project-links',
  templateUrl: './project-links-route.component.html',
  styleUrls: ['./project-links-route.component.scss'],
})
export class ProjectLinksRouteComponent implements OnInit {
  canUpdateResources = false;
  routeConfig: EditableTableRouteConfig = linkRouteConfig;

  project: ProjectModel;
  dataLinks: ArrayDataSource<DocumentLink>;

  constructor(
    private dialogService: DashDialogService,
    private notificationService: DashNotificationService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private userRightsService: ProjectRightsService
  ) {}

  ngOnInit() {
    this.route.parent.data.subscribe(({ project }) => {
      const { isClient, isOwner, isMainTeamMember, isRepresentative, isAdmin } =
        this.userRightsService.getUserSessionStatus(project);
      const userHasRightsToUpdateProject =
        isClient || isOwner || isMainTeamMember || isRepresentative || isAdmin;

      this.project = project;
      this.dataLinks = new ArrayDataSource(project.links);

      this.dataLinks.start();

      if (userHasRightsToUpdateProject) {
        this.canUpdateResources = true;
        this.updateTablesColsWithActions();
      } else {
        this.routeConfig.link.table.cols =
          this.routeConfig.link.table.cols.filter((c) => c.id !== 'actions');
      }
    });

    const project$: Observable<ProjectModel> = this.route.parent.data.pipe(
      pluck('project')
    );

    const projectLinks$: Observable<DocumentLink[]> = this.route.data.pipe(
      pluck('projectLinks')
    );

    combineLatest(project$, projectLinks$)
      .pipe(map(([project, projectLinks]) => ({ project, projectLinks })))
      .subscribe(({ project, projectLinks }) => {
        this.project = project;
        const {
          isClient,
          isOwner,
          isMainTeamMember,
          isRepresentative,
          isAdmin,
        } = this.userRightsService.getUserSessionStatus(project);
        const userHasRightsToUpdateProject =
          isClient ||
          isOwner ||
          isMainTeamMember ||
          isRepresentative ||
          isAdmin;

        this.dataLinks = new ArrayDataSource(projectLinks);
        this.dataLinks.start();

        if (userHasRightsToUpdateProject) {
          this.canUpdateResources = true;
          this.updateTablesColsWithActions();
        } else {
          this.routeConfig.link.table.cols =
            this.routeConfig.link.table.cols.filter((c) => c.id !== 'actions');
        }
      });
  }

  public handleOpenAddModal(dialogConfig: ExosDialogConfig) {
    const dialog = this.dialogService.open(
      AddItemDialogComponent,
      dialogConfig
    );
    const { action: resource, fields: formFields } =
      dialogConfig.data.formConfig;

    // tslint:disable-next-line: forin
    for (const field in formFields) {
      if (formFields[field]) {
        formFields[field].value = '';
      }

      if (formFields[field].selectedItems) {
        formFields[field].items.next([]);
        formFields[field].selectedItems = [];
      }
    }

    dialog.afterSubmit.subscribe((formData: ProjectModel) => {
      this.handleEditProjectItem(formData, resource, dialog);
    });
  }

  public openGDriveFolder(fileId) {
    if (fileId != null) {
      window.open(`https://drive.google.com/drive/folders/${fileId}`);
    }
  }

  private updateTablesColsWithActions() {
    Object.keys(this.routeConfig).forEach((config) => {
      const editModal = this.routeConfig[config].modal.edit;
      const deleteModal = this.routeConfig[config].modal.delete;

      const rowActions = {
        id: 'actions',
        name: '',
        sortable: false,
        value: null,
        display: true,
        actions: (row: DocumentLink) => {
          let defaultActions = [
            {
              name: 'Edit',
              action: (row) => {
                this.handleOpenEditModal(editModal, row);
              },
            },
            {
              name: 'Delete',
              action: (row) => {
                this.handleOpenDeleteModal(deleteModal, row);
              },
            },
          ];

          if (row.fromGDrive) {
            defaultActions = [];
          }
          return defaultActions;
        },
      };

      this.routeConfig[config].table.cols.push(rowActions);
    });
  }

  private handleOpenDeleteModal(dialogConfig: ExosDialogConfig, item: any) {
    const dialog = this.dialogService.open(ConfirmationComponent, dialogConfig);
    const projectId = this.project.id;
    const resource = dialogConfig.data.action;

    dialog.afterSubmit.subscribe(() => {
      this.projectService
        .deleteItem(projectId, item.id, resource)
        .subscribe((projectLinks) => {
          this.notificationService.success('Successfully updated ' + resource);
          dialog.close();
          this.dataLinks.setData(projectLinks);
        });
    });
  }

  private handleOpenEditModal(dialogConfig: ExosDialogConfig, item: any) {
    const dialog = this.dialogService.open(
      AddItemDialogComponent,
      dialogConfig
    );
    const projectId = this.project.id;
    const resource = dialogConfig.data.formConfig.action;
    const { fields: formFields } = dialogConfig.data.formConfig;

    for (const field in formFields) {
      if (formFields[field]) {
        const currentField = formFields[field];
        currentField.value = item[field];

        if (currentField.selectedItems) {
          currentField.selectedItems = item[field];
        }
      }
    }

    dialog.afterSubmit.subscribe((formData: ProjectModel) => {
      this.projectService
        .editItem(projectId, item.id, formData, resource)
        .subscribe(
          (projectLinks) => {
            this.notificationService.success(
              'Successfully updated ' + resource
            );
            dialog.close();
            this.dataLinks.setData(projectLinks);
          },
          ({ error }) => {
            dialog.errors(error);
          }
        );
    });
  }

  private handleEditProjectItem(reqBody: any, resource: string, dialog: any) {
    this.projectService.addItem(this.project.id, reqBody, resource).subscribe(
      (projectLinks) => {
        this.notificationService.success('Successfully updated ' + resource);
        this.dataLinks.setData(projectLinks);
        dialog.close();
      },
      ({ error }) => {
        dialog.errors(error);
      }
    );
  }
}
