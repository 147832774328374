import {
  addLinkDialogConfig,
  editLinkDialogConfig,
} from '../../../../shared/config/dialogs/link-dialog.config';
import { linkTableCols } from '../../../../shared/config/table/link-table.config';
import { DashTableColumnDefinition } from '@dashboard/table';
import { ExosDialogConfig } from '@dashboard/exos-dialog';
import infopoints from '../../../../shared/locale/infopoints.json';
import { InfoPointConfig } from '@dashboard/exos-form';

export interface EditableTableRouteConfig {
  [name: string]: {
    modal: {
      add: ExosDialogConfig;
      edit?: ExosDialogConfig;
      delete?: ExosDialogConfig;
    };
    table: {
      cols: DashTableColumnDefinition[];
      noResultsFoundMsg: string;
      info?: InfoPointConfig;
    };
  };
}

export const linkRouteConfig: EditableTableRouteConfig = {
  link: {
    modal: {
      add: addLinkDialogConfig,
      edit: editLinkDialogConfig,
      delete: {
        data: {
          title: 'Delete link',
          message: 'Are you sure ?',
          action: 'links',
        },
      },
    },
    table: {
      cols: linkTableCols,
      noResultsFoundMsg: 'No links found.',
      info: {
        hasInfoPoint: true,
        text: infopoints.LINKS,
      },
    },
  },
};
