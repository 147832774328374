import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { NewsLetter } from "src/app/shared/models/newsletter.model";
import { NewsletterService } from "src/app/shared/service/newsletter/newsletter.service";

@Injectable({
    providedIn: 'root',
  })
  export class NewsLetterResolver implements Resolve<NewsLetter> {
    constructor(
      private newsLetterService: NewsletterService,
    ) {}
  
    resolve():  Observable<NewsLetter> {
      return this.newsLetterService
        .findById(1);
    }
  }