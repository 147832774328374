import { Injectable } from '@angular/core';
import { FILTER_VIEW_TYPE } from '../../models/filter-view-type.model';
import { PartialObjective } from '../../models/objective.model';
import { Program } from '../../models/program.model';
import { ProjectStatusItem } from '../../models/project-status';
import { ProjectModel } from '../../models/project.model';
import { StatusNameType } from '../../models/status';

export interface ListResponse {
  data: ProjectModel[] | Program[];
  meta: {
    count: number;
    page: number;
    size: number;
  };
}

@Injectable({
  providedIn: 'root',
})
export class DataTableExtractorService {
  constructor() {}

  public extract(
    type: FILTER_VIEW_TYPE,
    response: any,
    objectives?: PartialObjective[]
  ): (ProjectModel | Program)[] {
    if (type === 'projects') {
      return this.extractProjects(response, objectives);
    } else if (type === 'programs') {
      return this.extractPrograms(response);
    } else {
      throw new Error('Type not found.');
    }
  }

  // project extractor logic
  private extractProjects(
    response: ListResponse,
    objectives: PartialObjective[]
  ): ProjectModel[] {
    // TODO
    // const data = response.data as ProjectModel[];

    //@ts-ignore
    return response.data.map((project) => {
      if ('phase' in project) {
        project._phase = project.phase;
        project.phase = project.phase
          ? this.camelCaseToSentenceCase(project.phase)
          : '-';
      }

      if ('status' in project) {
        const { value, comment } = project.status.overall;
        project.statusItem = new ProjectStatusItem(
          value,
          comment,
          StatusNameType.OVERALL
        );
        project.status_comment = comment ? comment : '-';
      }

      if ('client' in project) {
        project._client = project.client;
        project.client =
          project.client && project.client.name ? project.client.name : '-';
      }

      if ('owner' in project) {
        project._owner = project.owner;
        project.owner = project.owner.name;
      }

      if ('division' in project) {
        project.division =
          project.division.length > 0
            ? project.division.map((d) => d.item).join()
            : '-';
      }

      if ('gantt' in project) {
        project._gantt = project.gantt;
        project.gantt = project.gantt.filter(
          (task) => task.type === 'milestone'
        );
        project.gantt =
          project.gantt.length > 0
            ? project.gantt.map((milestone) => milestone.text).join()
            : '-';
      } else {
        project.gantt = '-';
      }

      if ('program' in project) {
        project._program = project.program ? project.program.name : '-';
      }

      if ('tags' in project) {
        project._tags =
          project.tags.length > 0
            ? project.tags.map((tag) => tag.name).join()
            : '-';
      }

      if ('projectedLaunchDate' in project) {
        project._projectedLaunchDate = project.projectedLaunchDate
          ? project.projectedLaunchDate[project.projectedLaunchDate.length - 1]
          : '-';
      }

      if ('projectedStartDate' in project) {
        project._projectedStartDate = project.projectedStartDate
          ? project.projectedStartDate[project.projectedStartDate.length - 1]
              .date
          : '-';
      }

      if ('risks' in project) {
        project._risks =
          project.risks.length > 0
            ? project.risks.map((risk) => risk.description).join()
            : '-';
      }

      if ('dependencies' in project) {
        project.dependencies_name =
          project.dependencies.length > 0
            ? project.dependencies.map((dependency) => dependency.name).join()
            : '-';
        project.dependencies_description =
          project.dependencies.length > 0
            ? project.dependencies
                .map((dependency) => dependency.description)
                .join()
            : '-';
      }

      if ('objectiveId' in project) {
        const { objectiveName, keyResultName } =
          this.findObjectiveAndKeyResultName(
            objectives,
            project.objectiveId,
            project.keyResultId
          );

        project._objective = project.objectiveId ? objectiveName : '-';
        project.keyResult = project.keyResultId ? keyResultName : '-';
      } else {
        project._objective = '-';
        project.keyResult = '-';
      }

      return project;
    });
  }

  private findObjectiveAndKeyResultName(
    objectives: PartialObjective[],
    objectiveId: number,
    keyResultId: number
  ): { objectiveName: string; keyResultName: string } {
    const foundObjective = objectives?.find(
      (objective) => objective.id === objectiveId
    );
    const foundKeyResult = foundObjective?.keyResults?.find(
      (keyResult) => keyResult.id === keyResultId
    );

    return {
      objectiveName: foundObjective?.title,
      keyResultName: foundKeyResult?.name,
    };
  }

  private camelCaseToSentenceCase(value: string) {
    if (value === undefined) {
      return value;
    }
    const result = value.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  // programs extractor logic
  private extractPrograms(response: ListResponse): Program[] {
    //@ts-ignore
    return response.data.map((program) => {
      if ('projects' in program) {
        program._projects =
          program.projects.length > 0
            ? program.projects.map((project) => project.name).join()
            : '-';
      }

      if ('status' in program) {
        const { value, comment } = program.status.overall;
        program.statusItem = new ProjectStatusItem(
          value,
          comment,
          StatusNameType.OVERALL
        );
        program.status_comment = comment ? comment : '-';
      }

      if ('client' in program) {
        program._client = program.client;
        program.client =
          program.client && program.client.name ? program.client.name : '-';
      }

      if ('owner' in program) {
        program._owner = program.owner;
        program.owner = program.owner.name;
      }

      return program;
    });
  }
}
