import { LOCALE_STATUS } from '../locale/status';

export enum StatusType {
  RISK_OR_ROADBLOCK = 'RISK_OR_ROADBLOCK',
  POTENTIAL_RISKS = 'POTENTIAL_RISKS',
  ON_TRACK = 'ON_TRACK',
  NOT_STARTED = 'NOT_STARTED',
  ON_HOLD = 'ON_HOLD',
  CLOSED = 'CLOSED',
  CANCELED = 'CANCELED',
}

export enum SemanticStatusType {
  RISK_OR_ROADBLOCK = 'critical',
  POTENTIAL_RISKS = 'warning',
  ON_TRACK = 'success',
  NOT_STARTED = 'neutral',
  ON_HOLD = 'neutral',
  CLOSED = 'neutral',
  CANCELED = 'neutral',
}

export enum StatusNameType {
  SCOPE = 'scope',
  BUDGET = 'budget',
  SCHEDULE = 'schedule',
  RESOURCES = 'resources',
  OVERALL = 'overall',
}

export interface StatusChangeEvent {
  value: StatusType;
  comment: string;
  name: string;
}

export interface StatusItem {
  value: StatusType;
  comment: string;
}

export interface Status {
  scope: StatusItem;
  schedule: StatusItem;
  budget: StatusItem;
  resources: StatusItem;
  overall: StatusItem;
}

export class StatusItemImpl implements StatusItem {
  value: StatusType;
  comment: string;
  name: StatusNameType;
  nameLocalized: string;
  valueLocalized: string;
  semanticType: string;
  date?: string;

  constructor(
    value: StatusType,
    comment: string,
    name: StatusNameType,
    date: string = null
  ) {
    this.value = value;
    this.comment = comment;
    this.name = name;
    this.date = date;
    this.nameLocalized =
      LOCALE_STATUS[`label_status_name_${name.toLowerCase()}`];
    this.valueLocalized =
      LOCALE_STATUS[`label_status_value_${value.toLowerCase()}`];
    this.semanticType = SemanticStatusType[this.value];
  }
}
