import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FILTER_VIEW_TYPE } from '../../models/filter-view-type.model';

export interface UserFilters {
  [key: string]: UserFilter;
}

export interface UserFilter {
  id?: string;
  key?: string;
  uid?: string;
  title: string;
  type: FILTER_VIEW_TYPE;
  searchQuery: string;
}

@Injectable({
  providedIn: 'root',
})
export class UserFiltersService {
  private readonly FLITERS_KEY = 'userFilters';
  private readonly MAX_FILTERS_NUMBER = 10;

  constructor(private location: Location, private route: ActivatedRoute) {}

  private _getUserFilters(): UserFilters {
    return JSON.parse(localStorage.getItem(this.FLITERS_KEY)) || {};
  }

  public _setUserFilters(userFilters: UserFilters) {
    localStorage.setItem(this.FLITERS_KEY, JSON.stringify(userFilters));
  }

  public getUserFilters(): UserFilters {
    const currentUserFilters = this._getUserFilters();
    return currentUserFilters || {};
  }

  public getUserFilter(key: string): UserFilter {
    const currentUserFilter = this._getUserFilters()[key] || null;
    return currentUserFilter;
  }

  public addUserFilter(filter: UserFilter): string {
    const updatedCurrentUserFilters = this._getUserFilters();

    updatedCurrentUserFilters[filter.key] = filter;

    this._setUserFilters(updatedCurrentUserFilters);

    return filter.key;
  }

  public editUserFilter(filter: UserFilter, key: string) {
    const updatedCurrentUserFilters = this._getUserFilters();

    filter.key = key;
    updatedCurrentUserFilters[key] = filter;

    this._setUserFilters(updatedCurrentUserFilters);
  }

  public deleteUserFilter(key: string) {
    const updatedCurrentUserFilters = this._getUserFilters();

    delete updatedCurrentUserFilters[key];

    this._setUserFilters(updatedCurrentUserFilters);
  }

  public deleteUserFilters() {
    localStorage.removeItem(this.FLITERS_KEY);
  }

  public isMaxFilterCount(): boolean {
    return (
      Object.keys(this._getUserFilters()).length >= this.MAX_FILTERS_NUMBER
    );
  }
}
