import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';

export const projectUpdateDescriptionFormConfig: FormConfig = {
  fields: {
    html_description: {
      name: 'html_description',
      type: 'wysiwig',
      fxFlex: '100',
      styles: { height: '500px' },
      value: null,
      buttons: [],
    },
  },

  buttons: [
    {
      label: 'Save',
      type: 'button',
      action: 'UPDATE',
      hasLoader: true,
      isPrimary: true,
    },
    {
      label: 'Close',
      type: 'button',
      action: 'CLOSE',
      hasLoader: false,
    },
  ],
};

export const projectUpdateDescriptionModalConfig: ExosDialogConfig = {
  data: {
    title: 'Edit project description',
    formConfig: projectUpdateDescriptionFormConfig,
    size: 'lg',
  },
};
