import { Injectable } from '@angular/core';

export type GanttScaleLevel = 'day' | 'week' | 'month' | 'quarter' | 'year';

export interface UserGantSettings {
  scale?: GanttScaleLevel;
  displayCriticalPath?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserGanttSettingsService {
  constructor() {}

  public fetchUserGanttSettings(): UserGantSettings {
    return (
      JSON.parse(localStorage.getItem(`gantt_settings`)) || {
        scale: 'day',
        displayCriticalPath: false,
      }
    );
  }

  public getZoomLevel() {
    return this.fetchUserGanttSettings().scale;
  }

  public getDisplayCriticalPath() {
    return this.fetchUserGanttSettings().displayCriticalPath;
  }

  public saveZoomScale(zoomLevel: GanttScaleLevel) {
    this.cacheSettings({
      scale: zoomLevel,
    });
  }

  public saveCriticalPath(show: boolean) {
    this.cacheSettings({
      displayCriticalPath: show,
    });
  }

  private cacheSettings(settings: any) {
    const updatedSettings = {
      ...this.fetchUserGanttSettings(),
      ...settings,
    };

    localStorage.setItem(`gantt_settings`, JSON.stringify(updatedSettings));
  }
}
