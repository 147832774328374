import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SheetStripesDirective,
  ExosStripeDirective,
  ExosStripeElementDirective,
  ExosStripeItemDirective,
  ActionStripeDirective,
  ActionStripeActionDirective,
  ActionStripeLabelDirective,
  SettingsStripeDirective,
  SettingsStripeActionDirective,
  SettingsStripeLabelDirective,
  SettingsStripeValueDirective,
} from './stripe.directive';

@NgModule({
  declarations: [
    SheetStripesDirective,
    ActionStripeDirective,
    ActionStripeActionDirective,
    ActionStripeLabelDirective,
    SettingsStripeDirective,
    SettingsStripeActionDirective,
    SettingsStripeLabelDirective,
    SettingsStripeValueDirective,
    ExosStripeDirective,
    ExosStripeElementDirective,
    ExosStripeItemDirective,
  ],
  imports: [CommonModule],
  exports: [
    SheetStripesDirective,
    ActionStripeDirective,
    ActionStripeActionDirective,
    ActionStripeLabelDirective,
    SettingsStripeDirective,
    SettingsStripeActionDirective,
    SettingsStripeLabelDirective,
    SettingsStripeValueDirective,
    ExosStripeDirective,
    ExosStripeElementDirective,
    ExosStripeItemDirective,
  ],
})
export class StripeModule {}
