import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkDirective } from './link.directive';

@NgModule({
  declarations: [LinkDirective],
  exports: [LinkDirective],
  imports: [CommonModule],
})
export class LinkModule {}
