import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manual',
  templateUrl: './manual.component.html',
})
export class ManualComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    const url =
      'https://docs.google.com/document/d/1FgHr2eTwhp-0LpbgRCmb6sT-g9Hx4KPWRnpfskK45iQ/edit?usp=sharing';
    window.open(url, '_blank');
  }
}
