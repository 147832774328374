import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AuthGuard, RoleGuard } from '@dashboard/core';
import { ProgramEditRouteComponent } from './containers/program-route/program-edit-route/program-edit-route.component';
import { ProgramFilesRouteComponent } from './containers/program-route/program-files-route/program-files-route.component';
import { ProgramRouteComponent } from './containers/program-route/program-route.component';
import { ProgramCreateRouteComponent } from './containers/program-create-route/program-create-route.component';
import { ProgramResolver } from '../shared/resolver/program/program.resolver';
import { ProgramProjectsComponent } from './containers/program-route/program-projects/program-projects.component';
import { ProgramPlanRouteComponent } from './containers/program-route/program-plan-route/program-plan-route.component';
import { ProgramGanttResolver } from '../shared/resolver/program-gantt-resolver/program-gantt.resolver';
import projectAllColumns from './../project/config/project-all.table.config';
import { ObjectiveResolver } from '../shared/service/objective/objective.resolver';
import { DeprecatedLinksRedirectComponent } from '../shared/components/deprecated-links-redirect/deprecated-links-redirect.component';
import { FilterViewType } from '../shared/models/filter-view-type.model';
import programGanttAllColumns from '../gantt/program-gantt-all.table.config';
import { UserPreferencesResolver } from '../shared/service/user-preferences/user-preferences.resolver';

const routes: Routes = [
  //////// Old links redirect /////////
  {
    path: 'programs',
    component: DeprecatedLinksRedirectComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      type: FilterViewType.programs,
    },
  },
  {
    path: 'programs/my-programs',
    component: DeprecatedLinksRedirectComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      type: FilterViewType.programs,
    },
  },
  ////////////////////////////////////
  {
    path: 'programs/create',
    component: ProgramCreateRouteComponent,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'programs/:id',
    component: ProgramRouteComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      program: ProgramResolver,
    },
    canActivate: [AuthGuard, RoleGuard],
    children: [
      {
        path: '',
        redirectTo: 'edit',
        pathMatch: 'full',
      },
      {
        path: 'edit',
        component: ProgramEditRouteComponent,
      },
      {
        path: 'documents-links',
        component: ProgramFilesRouteComponent,
      },
      {
        path: 'program-plan',
        component: ProgramPlanRouteComponent,
        resolve: {
          ganttData: ProgramGanttResolver,
        },
        data: {
          page: {
            tableCols: programGanttAllColumns,
          },
        },
      },
      {
        path: 'projects',
        component: ProgramProjectsComponent,
        resolve: {
          userPreferences: UserPreferencesResolver,
          objectiveSets: ObjectiveResolver,
        },
        data: {
          section: FilterViewType.projects,
          page: {
            searchQuery: '',
            tableCols: projectAllColumns,
          },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProgramRoutingModule {}
