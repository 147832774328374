import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'exos-input',
  templateUrl: './exos-input.component.html',
  styleUrls: ['./exos-input.component.scss'],
})
export class ExosInputComponent implements OnInit {
  @Input()
  public group: FormGroup;

  @Input()
  public name: string;

  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public type: string;

  @Input()
  public icon: string;

  @Input()
  public infoPoint: string;

  constructor() {}

  ngOnInit(): void {}
}
