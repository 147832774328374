import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';

export const releaseNotesUpdateFormConfig: FormConfig = {
  fields: {
    html_release_notes: {
      name: 'html_release_notes',
      type: 'wysiwig',
      fxFlex: '100',
      styles: { height: '500px' },
      value: null,
      buttons: [],
    },
  },

  buttons: [
    {
      label: 'Save',
      type: 'button',
      action: 'UPDATE',
      hasLoader: true,
      isPrimary: true,
    },
    {
      label: 'Close',
      type: 'button',
      action: 'CLOSE',
      hasLoader: false,
    },
  ],
};

export const releaseNotesUpdateModalConfig: ExosDialogConfig = {
  data: {
    title: 'Edit release notes',
    formConfig: releaseNotesUpdateFormConfig,
    size: 'lg',
  },
};
