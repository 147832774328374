import { Directive, Input } from '@angular/core';

export type PageHeaderLayoutType = 'narrow' | 'short';
export type PageHeaderSemanticType =
  | 'activating-solid'
  | 'success-solid'
  | 'warning-solid'
  | 'critical-solid'
  | 'neutral-solid';

@Directive({
  selector: '[exosPageHeader]',
  host: {
    class: 'page-header',
    '[class.page-header--overlapping]': 'isOverlapping',
    // layout
    '[class.page-header--narrow]': "layout === 'narrow'",
    '[class.page-header--short]': "layout === 'short'",
    // semantic
    '[class.page-header--activating-solid]': "semantic === 'activating-solid'",
    '[class.page-header--success-solid]': "semantic === 'success-solid'",
    '[class.page-header--warning-solid]': "semantic === 'warning-solid'",
    '[class.page-header--critical-solid]': "semantic === 'critical-solid'",
    '[class.page-header--neutral-solid]': "semantic === 'neutral-solid'",
  },
})
export class PageHeaderDirective {
  @Input() isOverlapping: boolean;
  @Input() layout: PageHeaderLayoutType;
  @Input() semantic: PageHeaderSemanticType;

  constructor() {}
}

@Directive({
  selector: '[exosPageHeaderBlock]',
  host: {
    class: 'page-header__block',
  },
})
export class PageHeaderBlockDirective {}

@Directive({
  selector: '[exosPageHeaderHeadline]',
  host: {
    class: 'page-header__headline',
    '[class.page-header--sub]': 'isSubHeadline',
    '[class.page-header--ellipsized]': 'isEllipsized',
  },
})
export class PageHeaderHeadlineDirective {
  @Input() isSubHeadline: boolean;
  @Input() isEllipsized: boolean;
}

@Directive({
  selector: '[exosPageHeaderParagraph]',
  host: {
    class: 'page-header__paragraph',
  },
})
export class PageHeaderParagraphDirective {}

@Directive({
  selector: '[exosPageHeaderBackButton]',
  host: {
    class: 'page-header__back-button',
  },
})
export class PageHeaderBackButtonDirective {}

@Directive({
  selector: '[exosPageHeaderHelpAndLearn]',
  host: {
    class: 'page-header__helpandlearn',
  },
})
export class PageHeaderHelpAndLearnDirective {}
