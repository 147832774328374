import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {
  ProjectStartDates,
  ProjectView,
  StartDate,
} from '../../../../shared/models/project.model';
import JsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { ProjectStatusItem } from 'src/app/shared/models/project-status';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss'],
})
export class ProjectViewComponent implements OnInit, AfterViewInit {
  @ViewChild('onepager')
  public onepager: ElementRef;

  @ViewChild('download')
  public download: ElementRef;

  @ViewChild('pageHeader')
  public pageHeader: ElementRef;

  @ViewChild('pageFooter')
  public pageFooter: ElementRef;

  private header: string;
  private footer: string;
  public readyToExport = false;
  public projectId: number;

  public project$: Observable<ProjectView> = this.route.data.pipe(
    map(({ projectView }) => projectView)
  );

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.projectId = parseInt(
      this.route.snapshot.parent.paramMap.get('id'),
      10
    );
  }

  ngAfterViewInit() {
    const pageHeader = html2canvas(this.pageHeader.nativeElement);
    const pageFooter = html2canvas(this.pageFooter.nativeElement);

    Promise.all([pageHeader, pageFooter]).then(([pageHeader, pageFooter]) => {
      this.header = pageHeader.toDataURL('image/png');
      this.footer = pageFooter.toDataURL('image/png');
      this.readyToExport = true;
    });
  }

  public downloadPDF() {
    this.download.nativeElement.style.display = 'none';
    const a4width = 595;
    const a4height = 842;
    const doc = new JsPDF('p', 'px', [a4width, a4height]);
    const div = this.onepager.nativeElement;
    const clientWidth = this.onepager.nativeElement.clientWidth;
    let scaleFactor = 1;

    if (clientWidth > a4width) {
      scaleFactor = (a4width - 30) / clientWidth;
    }

    doc
      .html(div, {
        margin: [60, 10, 60, 10],
        autoPaging: true,
        jsPDF: doc,
        html2canvas: {
          scale: scaleFactor,
        },
      })
      .then(
        () => {
          this.download.nativeElement.style.display = 'block';
          const numberPages = doc.getNumberOfPages();

          for (let i = 1; i <= numberPages; i++) {
            doc.setPage(i);
            doc.addImage(
              this.header,
              'PNG',
              0,
              0,
              this.pageHeader.nativeElement.width,
              this.pageHeader.nativeElement.height
            );
            doc.addImage(
              this.footer,
              'PNG',
              -110,
              a4height - 40,
              this.pageFooter.nativeElement.width,
              this.pageFooter.nativeElement.height
            );
            doc.setFontSize(10);
            doc.setTextColor('black');
            doc.text(`${i}`, a4width / 2, a4height - 10);
          }

          doc.save(`project_${this.projectId}_${new Date()}`);
        },
        (err) => {
          console.log(err);
        }
      );
  }

  public changeLayout() {}

  public getOverallStatusComment(status: ProjectStatusItem[]) {
    return status.filter((item) => item.name === 'overall')[0].comment;
  }
}
