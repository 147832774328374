import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ExosButtonDirective,
  ExosButtonLoaderComponent,
} from './exos-button.directive';

@NgModule({
  declarations: [ExosButtonDirective, ExosButtonLoaderComponent],
  exports: [ExosButtonDirective, ExosButtonLoaderComponent],
  entryComponents: [ExosButtonLoaderComponent],
  imports: [CommonModule],
})
export class ButtonModule {}
