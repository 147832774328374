import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineEditDirective } from './inline-edit.directive';
import { InlineEditFormComponent } from './inline-edit-form/inline-edit-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TextFieldModule } from '@angular/cdk/text-field';
import { ButtonModule } from '../components/button/button.module';

@NgModule({
  declarations: [InlineEditDirective, InlineEditFormComponent],
  imports: [CommonModule, ReactiveFormsModule, TextFieldModule, ButtonModule],
  exports: [InlineEditDirective],
  entryComponents: [InlineEditFormComponent],
})
export class InlineEditModule {}
