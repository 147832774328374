import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'exos-multiselect',
  templateUrl: './exos-multiselect.component.html',
  styleUrls: ['./exos-multiselect.component.scss'],
})
export class ExosMultiselectComponent implements OnInit {
  @Input()
  public group: FormGroup;

  @Input()
  public name: string;

  @Input()
  public label: string;

  @Input()
  public infoPoint: string;

  @Input()
  public placeholder: string;

  @Input()
  public type: string;

  @Input()
  public icon: string;

  @Input()
  public items: any[] = [];

  @Input()
  public results: Observable<any[]>;

  @Input()
  public isLoading: boolean;

  @Input()
  public noResultsMessage: string;

  @Input()
  public displayNoResultsMsg: string | boolean;

  @Output()
  public selectedItem: EventEmitter<any> = new EventEmitter();

  @Output()
  public removedItem: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onSelect(selectedOption) {
    if (selectedOption === null) {
      this.group.get(this.name).setValue(this.items);
      return;
    }

    const isSelected = this.items.find((s) => s.text === selectedOption.text);

    if (!isSelected) {
      this.items.push(selectedOption);
    }

    this.group.get(this.name).setValue(this.items);
    this.selectedItem.emit(selectedOption);
  }

  removeItem(index: any) {
    if (this.group.disabled) {
      return;
    }
    this.items.splice(index, 1);
    this.group.get(this.name).setValue(this.items);
    this.removedItem.emit(index);
  }

  removeItems() {
    this.items = [];
  }
}
