import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramRoutingModule } from './program-routing.module';
import { ProgramRouteComponent } from './containers/program-route/program-route.component';
import { ProgramsRouteComponent } from './containers/programs-route/programs-route.component';
import { ProgramCreateRouteComponent } from './containers/program-create-route/program-create-route.component';
import { ProgramEditRouteComponent } from './containers/program-route/program-edit-route/program-edit-route.component';
import { ProgramFilesRouteComponent } from './containers/program-route/program-files-route/program-files-route.component';
import { DashCoreModule } from '@dashboard/core';
import { ExosFormModule } from '@dashboard/exos-form';
import { DashTableModule } from '@dashboard/table';
import { ExosTooltipModule } from '@dashboard/exos-tooltip';
import { CustomCreateExosFormComponent } from './components/custom-create-exos-form/custom-create-exos-form.component';
import { SharedModule } from '../shared/shared.module';
import { JqlInputSearchModule } from '../exos/jql-input-search/jql-input-search.module';
import { JQL_PROGRAM_CONFIG } from './config/jql.config';
import { ExosModule } from '../exos/exos.module';
import { ProgramProjectsComponent } from './containers/program-route/program-projects/program-projects.component';
import { ProgramPlanRouteComponent } from './containers/program-route/program-plan-route/program-plan-route.component';

@NgModule({
  declarations: [
    ProgramRouteComponent,
    ProgramsRouteComponent,
    ProgramCreateRouteComponent,
    ProgramEditRouteComponent,
    ProgramFilesRouteComponent,
    CustomCreateExosFormComponent,
    ProgramProjectsComponent,
    ProgramPlanRouteComponent,
  ],
  imports: [
    CommonModule,
    DashCoreModule,
    SharedModule,
    ExosFormModule,
    DashTableModule,
    ExosTooltipModule,
    ExosModule,
    ProgramRoutingModule,
    JqlInputSearchModule.forChild(JQL_PROGRAM_CONFIG),
  ],
})
export class ProgramModule {}
