import { Injectable } from '@angular/core';
import { DashDialogService, ExosDialogRef } from '@dashboard/exos-dialog';
import { DashNotificationService } from '@dashboard/core';
import { GanttService } from '../gantt-service/gantt.service';
import { GanttLink, GanttTask, GanttTaskDTO } from '../../models/gantt.model';
import { gantt } from '../dhtmlx-gantt-service/gantt/dhtmlxgantt';
import {
  createNewGanttTaskDialogConfig,
  editNewGanttTaskDialogConfig,
} from '../../components/dialogs/gantt-task-dialog/gantt-task.dialog-config';
import { GanttTaskDialogComponent } from '../../components/dialogs/gantt-task-dialog/gantt-task-dialog.component';
import { ConfirmationComponent } from '../../components/dialogs/confirmation/confirmation.component';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root',
})
export class ProjectGanttProcessorService {
  private dialogCreateOrEdit: ExosDialogRef;

  private dialogConfirmation: ExosDialogRef;

  private ganttEvents = [];

  constructor(
    private ganttService: GanttService,
    private notifyService: DashNotificationService,
    private dialogService: DashDialogService
  ) {}

  private handleCreateTask(task: GanttTaskDTO, projectId: number) {
    this.ganttService
      .createTask(task, projectId)
      .then((updatedTask: GanttTask) => {
        // @ts-ignore
        const currentTask: GanttTask = gantt.getTask(updatedTask.id);

        currentTask.progressColor = updatedTask.progress === 1 ? '#38CC59' : '';
        currentTask.$new = false;
        currentTask.orderIndex = updatedTask.orderIndex;

        this.dialogCreateOrEdit.close();
        this.notifyService.success(`Task successfully created.`);

        if (task.dependencies?.length) {
          gantt.batchUpdate(() => {
            for (var i = 0; i < task.dependencies.length; i++) {
              gantt.addLink({
                source: task.dependencies[i].id,
                target: task.id,
                type: 1,
              });
            }
          });
        }
      })
      .catch(() => {
        this.notifyService.warning(`Task could not be created.`);
        this.dialogCreateOrEdit.errors({ errors: { fields: {} } });
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleUpdateTask(task: GanttTaskDTO, projectId: number) {
    this.ganttService
      .updateTask(task, projectId)
      .then((updatedTask: GanttTask) => {
        // @ts-ignore
        gantt.getTask(updatedTask.id).progressColor =
          updatedTask.progress === 1 ? '#38CC59' : '';

        if (this.dialogCreateOrEdit) {
          this.dialogCreateOrEdit.close();
        }
        this.notifyService.success(`Task successfully updated.`);

        /* If not changes have been made to the dependencies, this field will be undefined*/
        if (task.dependencies) {
          const links: {
            [key: string]: GanttLink;
          } = {};
          const ganttLinks = gantt
            .serialize()
            ?.links.map((link) => {
              if (Number(link.target) === Number(task.id)) {
                links[link.source] = link;
                return Number(link.source);
              }
              return null;
            })
            .filter((id) => !!id);
          const taskLinks = task.dependencies?.map((dep) => dep.id);
          /* Deleting links to removed dependencies */
          const depsToDelete = ganttLinks.filter((x) => !taskLinks.includes(x));
          /* Adding links with newly selected dependencies */
          const depsToAdd = taskLinks.filter((x) => !ganttLinks.includes(x));
          gantt.batchUpdate(() => {
            for (var i = 0; i < depsToDelete.length; i++) {
              const id = links[depsToDelete[i]].id;
              gantt.deleteLink(id);
            }
            for (var i = 0; i < depsToAdd.length; i++) {
              gantt.addLink({
                source: depsToAdd[i],
                target: task.id,
                type: 1,
              });
            }
          });
        }
      })
      .catch(() => {
        this.notifyService.warning(`Task could not be updated.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleDeleteTask(taskId: string, projectId: number) {
    this.ganttService
      .deleteTask(taskId, projectId)
      .then(() => {
        this.dialogConfirmation.close();
        this.dialogCreateOrEdit.close();
        this.notifyService.success(`Task was deleted.`);
      })
      .catch(() => {
        this.dialogConfirmation.close();
        this.notifyService.warning(`Task could not be deleted.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleCreateLink(link: GanttLink, projectId: number) {
    this.ganttService
      .createLink(link, projectId)
      .then(() => {
        this.notifyService.success(`Link created.`);
      })
      .catch(() => {
        this.notifyService.warning(`Link could not be created.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleUpdateLink(link: GanttLink, projectId: number) {
    this.ganttService
      .updateLink(link, projectId)
      .then(() => {
        this.notifyService.success(`Link successfully updated.`);
      })
      .catch(() => {
        this.notifyService.warning(`Link could not be updated.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleDeleteLink(linkId: string, projectId: number) {
    this.ganttService
      .deleteLink(linkId, projectId)
      .then(() => {
        this.notifyService.success(`Link was deleted.`);
      })
      .catch(() => {
        this.notifyService.warning(`Link could not be deleted.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleUpdateTaskOrder(
    taskId: number,
    projectId: number,
    orderIndex: number,
    parentId: number
  ) {
    gantt.config.readonly = true;

    if (gantt.getParent(taskId) !== parentId) {
      this.ganttService
        .updateTaskParentAndOrder(
          taskId,
          projectId,
          orderIndex,
          gantt.getParent(taskId)
        )
        .then((ganttTasks) => {
          ganttTasks?.forEach(
            (task) => (gantt.getTask(task.id).orderIndex = task.orderIndex)
          );
          gantt.sort('orderIndex');
          this.notifyService.success(`Task order was updated.`);
        })
        .catch(() => {
          this.notifyService.warning(`Task cannot be moved.`);
          gantt.undo();
        })
        .finally(() => {
          gantt.render();
        });
    } else {
      this.ganttService
        .updateTaskOrder(taskId, projectId, orderIndex)
        .then((ganttTasks) => {
          ganttTasks?.forEach(
            (task) => (gantt.getTask(task.id).orderIndex = task.orderIndex)
          );
          gantt.sort('orderIndex');
          this.notifyService.success(`Task order was updated.`);
        })
        .catch(() => {
          this.notifyService.warning(`Task cannot be moved.`);
          gantt.undo();
        })
        .finally(() => {
          gantt.render();
        });
    }
    gantt.config.readonly = false;
  }

  private calculateSummaryProgress(task) {
    if (task.type != gantt.config.types.project) return task.progress;
    var totalToDo = 0;
    var totalDone = 0;
    gantt.eachTask(function (child) {
      if (child.type != gantt.config.types.project) {
        totalToDo += child.duration;
        totalDone += (child.progress || 0) * child.duration;
      }
    }, task.id);
    if (!totalToDo) return 0;
    else
      return Math.round((totalDone / totalToDo + Number.EPSILON) * 100) / 100;
  }

  private refreshSummaryProgress(id) {
    if (!gantt.isTaskExists(id)) return;

    var task = gantt.getTask(id);
    var newProgress = this.calculateSummaryProgress(task);

    if (newProgress !== task.progress) {
      task.progress = newProgress;
      gantt.refreshTask(id);
    }

    if (gantt.getParent(id) !== gantt.config.root_id) {
      this.refreshSummaryProgress(gantt.getParent(id));
    }
  }

  private initProgressCalculation() {
    gantt.attachEvent(
      'onParse',
      () => {
        gantt.eachTask((task) => {
          task.progress = this.calculateSummaryProgress(task);
        });
      },
      {}
    );
    gantt.attachEvent(
      'onBeforeTaskUpdate',
      (id) => {
        this.refreshSummaryProgress(gantt.getParent(id));
      },
      {}
    );
    gantt.attachEvent(
      'onTaskDrag',
      (id) => {
        this.refreshSummaryProgress(gantt.getParent(id));
      },
      {}
    );
    gantt.attachEvent(
      'onAfterTaskAdd',
      (id) => {
        this.refreshSummaryProgress(gantt.getParent(id));
      },
      {}
    );
    gantt.attachEvent(
      'onAfterTaskDelete',
      (id, task) => {
        this.refreshSummaryProgress(task.parent);
      },
      {}
    );
    gantt.attachEvent(
      'onBeforeRowDragEnd',
      (id, parent, taskIndex) => {
        const ganttTask: GanttTask = gantt.getTask(id);
        /*
          parent            - previous parent
          ganttTask.parent  - new parent
        */
        this.refreshSummaryProgress(parent);
        this.refreshSummaryProgress(ganttTask.parent);
      },
      {}
    );
    gantt.templates.task_class = (start, end, task) => {
      if (task.type == gantt.config.types.project)
        return 'hide_project_progress_drag';
    };
  }

  // data processor
  public initDataProcessor(projectId: number) {
    this.initProgressCalculation();
    this.initProjectType();
    const onBeforeTaskAdd = gantt.attachEvent(
      'onBeforeTaskAdd',
      (id, task: GanttTask) => {
        if (
          task.start_date.toString() === task.end_date.toString() &&
          task.type !== 'milestone'
        ) {
          task.end_date = gantt.date.add(new Date(task.start_date), 1, 'day');
          task.duration = 1;
        }
        this.handleProject();
        this.handleCreateTask(task, projectId);
      },
      {}
    );

    const onBeforeTaskUpdate = gantt.attachEvent(
      'onBeforeTaskUpdate',
      (id, task: GanttTask) => {
        if (
          task.start_date.toString() === task.end_date.toString() &&
          task.type !== 'milestone'
        ) {
          task.end_date = gantt.date.add(new Date(task.start_date), 1, 'day');
          task.duration = 1;
        }
        this.handleProject();
        this.handleUpdateTask(task, projectId);
      },
      {}
    );

    const onBeforeTaskDelete = gantt.attachEvent(
      'onBeforeTaskDelete',
      (taskId) => {
        const taskIsNew = !!gantt.getTask(taskId).$new;
        this.handleProject();

        if (!taskIsNew) {
          this.handleDeleteTask(taskId, projectId);
        }
      },
      {}
    );

    const onAfterLinkAdd = gantt.attachEvent(
      'onAfterLinkAdd',
      (id, link: GanttLink) => {
        this.handleCreateLink(link, projectId);
      },
      {}
    );

    const onAfterTaLinkUpdate = gantt.attachEvent(
      'onAfterLinkUpdate',
      (id, link: GanttLink) => {
        this.handleUpdateLink(link, projectId);
      },
      {}
    );

    const onAfterTaLinkDelete = gantt.attachEvent(
      'onAfterLinkDelete',
      (linkId) => {
        this.handleDeleteLink(linkId, projectId);
      },
      {}
    );

    const onBeforeLightbox = gantt.attachEvent(
      'onBeforeLightbox',
      (id: string | number) => {
        const task = gantt.getTask(id);
        if (task.$new) {
          const today = new Date();
          const startDay = today.setHours(0, 0, 0, 0);

          task.start_date = new Date(startDay);
          if (task.type !== 'milestone') {
            task.end_date = gantt.date.add(new Date(startDay), 1, 'day');
          }
        }

        if (task.start_date.toString() === task.end_date.toString()) {
          if (task.type !== 'milestone') {
            task.end_date = gantt.date.add(new Date(task.start_date), 1, 'day');
            task.duration = 1;
          }
        }
        this.dialogCreateOrEdit = this.openCreateOrEditDialog(task);
        this.handleCreateOrEditDialogActions(task);
        return false;
      },
      {}
    );

    const onAfterTaskMove = gantt.attachEvent(
      'onAfterTaskMove',
      (id, parent, taskIndex) => {
        const ganttTask: GanttTask = gantt.getTask(id);
        ganttTask.orderIndex = taskIndex;
      },
      null
    );

    const onBeforeRowDragEnd = gantt.attachEvent(
      'onBeforeRowDragEnd',
      (id, parent, orderIndex) => {
        /***
         * parent           - old parent
         * ganttTask.parent - new parent
         */
        const ganttTask: GanttTask = gantt.getTask(id);
        if (
          ganttTask.orderIndex !== orderIndex ||
          ganttTask.parent !== parent
        ) {
          // @ts-ignore
          if (gantt.hasChild(ganttTask.parent) === 1) {
            ganttTask.orderIndex = 0;
          }
          this.handleUpdateTaskOrder(
            parseInt(ganttTask.id, 10),
            projectId,
            ganttTask.orderIndex,
            parent
          );
        }
      },
      {}
    );

    this.ganttEvents.push(
      onBeforeLightbox,
      onBeforeTaskAdd,
      onBeforeTaskUpdate,
      onBeforeTaskDelete,
      onAfterLinkAdd,
      onAfterTaLinkUpdate,
      onAfterTaLinkDelete,
      onAfterTaskMove,
      onBeforeRowDragEnd
    );
  }

  public flushProcessor() {
    this.ganttEvents.forEach((ev) => gantt.detachEvent(ev));
  }

  // dialogs
  public handleCreateOrEditDialogActions(task: GanttTask) {
    this.dialogCreateOrEdit.afterSubmit.subscribe((action) => {
      if (action.type === 'CLOSE') {
        if (task?.$new) {
          gantt.deleteTask(task.id);
        }
      } else if (action.type === 'CREATE') {
        const newTask = Object.assign(task, action.payload);
        gantt.addTask(newTask);
      } else if (action.type === 'UPDATE') {
        const newTask = Object.assign(task, action.payload);
        gantt.updateTask(newTask.id, newTask);
      } else if (action.type === 'DELETE') {
        this.handleDeleteDialogActions(task);
      }
    });
  }

  private handleDeleteDialogActions(task: GanttTask) {
    this.dialogCreateOrEdit.close();
    const deleteDialog = this.openDialogConfirmation();

    this.dialogConfirmation = deleteDialog;

    deleteDialog.afterSubmit.subscribe(() => {
      gantt.deleteTask(task.id);
    });

    deleteDialog.afterClosed.subscribe(() => {
      this.dialogCreateOrEdit = this.openCreateOrEditDialog(task);
      this.handleCreateOrEditDialogActions(task);
    });
  }

  private openCreateOrEditDialog(task: GanttTaskDTO): ExosDialogRef {
    // @ts-ignore
    const config = JSON.parse(
      JSON.stringify(
        task.$new
          ? createNewGanttTaskDialogConfig
          : editNewGanttTaskDialogConfig
      )
    );
    config.data.formConfig.fields.type.options.push({
      text: 'Work Package',
      value: 'project',
    });

    const configFormFields = Object.keys(config.data.formConfig.fields);
    config.data.task = task;

    // @ts-ignore
    const currentAllTasks = Object.keys(
      // @ts-ignore
      gantt.$data.tasksStore.pull
    )
      .map((taskId) => ({
        text: gantt.getTask(taskId).text,
        value: taskId,
      }))
      .filter((option) => {
        const optionId = parseInt(option.value, 10);
        const taskId = parseInt(task.id, 10);
        const parentId = parseInt(gantt.getTask(optionId).parent, 10);

        const isSameTask = optionId !== taskId;
        const isChildTask = parentId === taskId;

        return isSameTask && !isChildTask;
      });

    configFormFields.forEach((fieldName) => {
      if (task.$new && (fieldName === 'text' || fieldName === 'type')) {
        return;
      }
      /**
       * Process User object so it can be displayed
       */
      if (fieldName === 'assignee' && task[fieldName]) {
        config.data.formConfig.fields[fieldName].value =
          this.makeUserDisplayable(task[fieldName]);
        return;
      }

      config.data.formConfig.fields[fieldName].value = task[fieldName];
    });

    config.data.formConfig.fields['dependencies'].options =
      gantt.serialize()?.data.filter((t: GanttTask) => {
        return t.id != task.id && t.type !== 'project';
      }) || [];

    /**
     * Populate pre-existent dependencies
     */
    if (!task.$new) {
      config.data.formConfig.fields['dependencies'].selectedItems = task.$target
        ?.map((id) => gantt.getTask(gantt.getLink(id).source))
        .filter((x) => !!x);
    }

    config.data.formConfig.fields.parent.options = [
      ...config.data.formConfig.fields.parent.options,
      ...currentAllTasks,
    ];
    config.data.type = 'project';
    return this.dialogService.open(GanttTaskDialogComponent, config);
  }

  private openDialogConfirmation() {
    return this.dialogService.open(ConfirmationComponent, {
      data: {
        title: 'Delete task',
        text: 'Are you sure?',
      },
    });
  }

  public initProjectType() {
    gantt.templates.grid_file = function (item) {
      if (item.type === 'project') {
        return "<div class='gantt_tree_icon gantt_folder_closed'></div>";
      }
    };
    gantt.attachEvent(
      'onBeforeGanttRender',
      () => {
        this.handleProject();
      },
      {}
    );

    gantt.attachEvent(
      'onAfterTaskAdd',
      () => {
        this.handleProject();
      },
      {}
    );
    gantt.attachEvent(
      'onAfterTaskUpdate',
      () => {
        this.handleProject();
      },
      {}
    );
  }
  public handleProject() {
    gantt.templates.tooltip_text = (start, end, task) => {
      if (task.type === 'project' && gantt.getChildren(task.id).length == 0) {
        task.hide_bar = true;
      }
      if (task.type !== 'milestone') {
        switch (task.progress) {
          case 0:
            task.color = '#A6ACAF';
            break;
          case 1:
            task.color = '#38CC59';
            break;
          default:
            task.color = '#3DB9D3';
            break;
        }
      }
      if (task.type === 'milestone') {
        task.color = null;
      }

      if (task.type === 'project') {
        if (gantt.getChildren(task.id).length == 0) {
          start = new Date();
          task.start_date = new Date();
          end = start;
          task.duration = 1;
        } else if (gantt.getChildren(task.id).length > 0) {
          task.hide_bar = false;
        }

        return (
          '<b>Work Package:</b> ' +
          task.text +
          '<br/>' +
          '<b>Start date:</b> ' +
          gantt.templates.tooltip_date_format(start) +
          '<br/><b>End date:</b> ' +
          gantt.templates.tooltip_date_format(end)
        );
      }

      return (
        '<b>Task:</b> ' +
        task.text +
        '<br/>' +
        '<b>Start date:</b> ' +
        gantt.templates.tooltip_date_format(start) +
        '<br/><b>End date:</b> ' +
        gantt.templates.tooltip_date_format(end)
      );
    };
  }

  private makeUserDisplayable(user: User) {
    /**
     * Form fields need these extra properties in order to display the User
     */
    user.text = user.name;
    user.value = user.name;
    return user;
  }
}
