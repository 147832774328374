import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DashNotificationService, UserService } from '@dashboard/core';
import { DashDialogService, ExosDialogConfig } from '@dashboard/exos-dialog';
import { FormEvent } from '@dashboard/exos-form';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { WysiwygDialogComponent } from 'src/app/shared/components/dialogs/wysiwyg-dialog/wysiwyg-dialog.component';
import { productNewsUpdateModalConfig } from 'src/app/shared/config/dialogs/news.dialog.config';
import { releaseNotesUpdateModalConfig } from 'src/app/shared/config/dialogs/release_notes.dialog.config';
import { ExosCardFormConfig } from 'src/app/shared/models/card-form-config';
import { NewsLetter } from 'src/app/shared/models/newsletter.model';
import { NewsletterService } from 'src/app/shared/service/newsletter/newsletter.service';
import { newsHomeFormsConfig } from './news-form.config';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss'],
})
export class NewsComponent implements OnInit, OnDestroy {
  public formConfig: {
    [key: string]: ExosCardFormConfig;
  } = JSON.parse(JSON.stringify(newsHomeFormsConfig));

  public form: FormGroup = new FormGroup({
    html_product_news: new FormControl(),
    html_release_notes: new FormControl(),
  });

  private subs: Subscription[] = [];

  public newsLetter: NewsLetter;

  public newsLetter$: Observable<NewsLetter> = this.activatedRoute.data.pipe(
    map(({ newsLetter }) => newsLetter)
  );

  //modal for product news
  public productNewsModalConfig: ExosDialogConfig =
    productNewsUpdateModalConfig;

  //modal for release notes
  public releaseNotesModalConfig: ExosDialogConfig =
    releaseNotesUpdateModalConfig;

  constructor(
    private dashDialogService: DashDialogService,
    private newsLetterService: NewsletterService,
    private notificationService: DashNotificationService,
    private activatedRoute: ActivatedRoute,
    private session: UserService
  ) {}

  ngOnInit(): void {
    if (this.isAdmin()) {
      //@ts-ignore
      this.formConfig.product_news.fields.html_product_news.buttons.forEach(
        (element) => {
          element.hideOnDisabled = false;
        }
      );
      //@ts-ignore
      this.formConfig.release_notes.fields.html_release_notes.buttons.forEach(
        (element) => {
          element.hideOnDisabled = false;
        }
      );
    }
    this.newsLetter$.subscribe((newsLetter) => {
      this.newsLetter = newsLetter;
      this.form.patchValue(
        {
          html_product_news: this.newsLetter.htmlProductNews,
          html_release_notes: this.newsLetter.htmlReleaseNotes,
        },
        { emitEvent: false }
      );
    });

    this.form.disable({ emitEvent: false });
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  public openEditProductNewsModal(ev: FormEvent) {
    const modalConfig: ExosDialogConfig = JSON.parse(
      JSON.stringify(this.productNewsModalConfig)
    );

    this.newsLetterService.findById(1).subscribe(
      (newsLetter) => {
        this.newsLetter = newsLetter;
        this.form.patchValue(
          {
            html_product_news: this.newsLetter.htmlProductNews,
          },
          { emitEvent: false }
        );
      },
      ({ error }) => {
        modal.errors(error);
      }
    );

    const readOnly = ev.type === 'SHOW_NEWS';

    modalConfig.data.formConfig.fields.html_product_news.value =
      this.form.controls.html_product_news.value;
    modalConfig.data.formConfig.fields.html_product_news.readOnly = readOnly;

    modalConfig.data.formConfig.buttons =
      modalConfig.data.formConfig.buttons.filter((button) =>
        readOnly ? button.action === 'CLOSE' : true
      );

    modalConfig.data.title = readOnly ? 'Product News' : 'Edit product news';

    const modal = this.dashDialogService.open(
      WysiwygDialogComponent,
      modalConfig
    );

    modal.afterSubmit.subscribe(
      (payload) => {
        payload.html_product_news = payload.html_product_news || '';
        payload.product_news =
          payload.html_product_news === null
            ? ''
            : payload.html_product_news.replace(/(<([^>]+)>)/gi, ' ');
        this.formConfig.html_product_news = payload.html_product_news;

        this.newsLetter = {
          id: 1,
          htmlProductNews: payload.html_product_news,
        };

        this.newsLetterService
          .updateNewsLetter(this.newsLetter)
          .subscribe((response) => {
            this.newsLetter = response;
            this.form.patchValue(
              {
                html_product_news: this.newsLetter.htmlProductNews,
                html_release_notes: this.newsLetter.htmlReleaseNotes,
              },
              { emitEvent: false }
            );
            this.notificationService.success('Successfully updated ');
          });
        modal.close();
      },
      ({ error }) => {
        modal.errors(error);
        modal.close();
      }
    );
  }

  public openEditReleaseNotesModal(ev: FormEvent) {
    const modalConfig: ExosDialogConfig = JSON.parse(
      JSON.stringify(this.releaseNotesModalConfig)
    );

    this.newsLetterService.findById(1).subscribe(
      (newsLetter) => {
        this.newsLetter = newsLetter;
        this.form.patchValue(
          {
            html_release_notes: this.newsLetter.htmlReleaseNotes,
          },
          { emitEvent: false }
        );
      },
      ({ error }) => {
        modal.errors(error);
      }
    );

    const readOnly = ev.type === 'SHOW_NOTES';
    modalConfig.data.formConfig.fields.html_release_notes.value =
      this.form.controls.html_release_notes.value;
    modalConfig.data.formConfig.fields.html_release_notes.readOnly = readOnly;

    modalConfig.data.formConfig.buttons =
      modalConfig.data.formConfig.buttons.filter((button) =>
        readOnly ? button.action === 'CLOSE' : true
      );

    modalConfig.data.title = readOnly ? 'Release Notes' : 'Edit release notes';

    const modal = this.dashDialogService.open(
      WysiwygDialogComponent,
      modalConfig
    );

    modal.afterSubmit.subscribe(
      (payload) => {
        payload.html_release_notes = payload.html_release_notes || '';
        payload.release_notes =
          payload.html_release_notes === null
            ? ''
            : payload.html_release_notes.replace(/(<([^>]+)>)/gi, ' ');

        this.formConfig.html_release_notes = payload.html_release_notes;

        this.newsLetter = {
          id: 1,
          htmlReleaseNotes: payload.html_release_notes,
        };

        this.newsLetterService
          .updateNewsLetter(this.newsLetter)
          .subscribe((response) => {
            this.newsLetter = response;
            this.form.patchValue(
              {
                html_product_news: this.newsLetter.htmlProductNews,
                html_release_notes: this.newsLetter.htmlReleaseNotes,
              },
              { emitEvent: false }
            );
            this.notificationService.success('Successfully updated ');
          });
        modal.close();
      },
      ({ error }) => {
        modal.errors(error);
        modal.close();
      }
    );
  }

  private isAdmin() {
    return this.session
      .getAllRoles()
      .some(
        (item) => item.getName() === 'admin' && item.getAppCode() === 'pmt'
      );
  }
}
