import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
} from '@angular/core';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';

export type ButtonPredefinedIconType =
  | 'download'
  | 'helpandlearn'
  | 'next'
  | 'performance'
  | 'previous'
  | 'upselling';

export type ButtonSemanticType =
  | 'primary'
  | 'tertiary'
  | 'dark'
  | 'bright'
  | 'success'
  | 'warning'
  | 'critical';

@Directive({
  selector: '[exosButton]',
  host: {
    class: 'button',
    // icon
    '[class.button--download]': "icon === 'download'",
    '[class.button--helpandlearn]': "icon === 'helpandlearn'",
    '[class.button--next]': "icon === 'next'",
    '[class.button--performance]': "icon === 'performance'",
    '[class.button--previous]': "icon === 'previous'",
    '[class.button--upselling]': "icon === 'upselling'",
    // style
    '[class.button--disabled]': 'isDisabled',
    // layout
    '[class.button--ellipsized]': 'isEllipsized',
    '[class.button--full-width]': 'isFullWidth',
    '[class.button--bright]': 'isBright',
    // semantic
    '[class.button--primary]': "semantic === 'primary'",
    '[class.button--tertiary]': "semantic === 'tertiary'",
    '[class.button--success]': "semantic === 'success'",
    '[class.button--warning]': "semantic === 'warning'",
    '[class.button--critical]': "semantic === 'critical'",
    '[class.button--dark]': "semantic === 'dark'",
  },
})
export class ExosButtonDirective {
  private loaderPortalOutlet: DomPortalOutlet;
  private readonly loaderCmpPortal: ComponentPortal<ExosButtonLoaderComponent>;
  private _isLoading = false;

  @Input() semantic: ButtonSemanticType;
  @Input() withLoader: boolean;
  @Input() icon: ButtonPredefinedIconType;
  @Input() isBright: boolean;
  @Input() isDisabled: boolean;
  @Input() isEllipsized: boolean;
  @Input() isFullWidth: boolean;

  @Input() set isLoading(value: boolean) {
    this._isLoading = value;
    this.isDisabled = value;

    if (this.withLoader && !this._isLoading) {
      this.loaderPortalOutlet.detach();
    }
  }

  @HostListener('click') clickHandler() {
    if (this.isDisabled || this._isLoading) {
      return;
    }

    if (this.withLoader && !this._isLoading) {
      this.loaderPortalOutlet.attachComponentPortal(this.loaderCmpPortal);
    }
  }

  constructor(
    private elementRef: ElementRef,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    private cmpFactoryResolver: ComponentFactoryResolver
  ) {
    this.loaderPortalOutlet = new DomPortalOutlet(
      this.elementRef.nativeElement,
      this.cmpFactoryResolver,
      this.applicationRef,
      this.injector
    );

    this.loaderCmpPortal = new ComponentPortal<ExosButtonLoaderComponent>(
      ExosButtonLoaderComponent
    );
  }
}

@Component({
  selector: 'exos-button-loader',
  host: {
    class: 'button_loader',
  },
  template: `
    <div class="loading-circle loading-circle--small loading-circle--bright">
      <span class="loading-circle__circle"></span>
      <span class="loading-circle__circle"></span>
      <span class="loading-circle__circle"></span>
    </div>
  `,
})
export class ExosButtonLoaderComponent {
  constructor() {}
}
