import { Injectable } from '@angular/core';
import { Program } from '../../models/program.model';
import { ProgramService } from '../../service/program/program.service';
import { ProgramRightsService } from '../../service/user-rights/program-rigths/program-rights.service';
import { DashNotificationService } from '@dashboard/core';
import { BehaviorSubject } from 'rxjs';
import { Status, StatusItem, StatusNameType } from '../../models/status';
import { ProgramStatusItem } from '../../models/program-status';
import { ExosDialogPromiseResult } from '../../config/dialogs/dialog';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProgramEditFacadeService {
  public program: Program;

  private _program = new BehaviorSubject<Program>(null);
  public program$ = this._program.asObservable();

  private _canUpdateProgram = new BehaviorSubject<boolean>(null);
  public canUpdateProgram$ = this._canUpdateProgram.asObservable();

  private _canDeleteProgram = new BehaviorSubject<boolean>(null);
  public canDeleteProgram$ = this._canDeleteProgram.asObservable();

  private _canUpdateOwner = new BehaviorSubject<boolean>(null);
  public canUpdateOwner$ = this._canUpdateOwner.asObservable();

  private _programStatus = new BehaviorSubject<ProgramStatusItem[]>(null);
  public programStatus$ = this._programStatus.asObservable();

  private _programUpdateErrors = new BehaviorSubject<any>(null);
  public programUpdateErrors$ = this._programUpdateErrors.asObservable();

  private _updateInProgress = new BehaviorSubject<boolean>(null);
  public updateInProgress$ = this._updateInProgress.asObservable();

  constructor(
    private programService: ProgramService,
    private programRightsService: ProgramRightsService
  ) {}

  public dispatchLoadProgram(program: Program) {
    const programStatus = this.parseProgramStatus(program.status);
    const { canDelete, canUpdate, canUpdateOwner } =
      this.programRightsService.getUserRights(program);

    this.program = program;
    this._program.next(program);
    this._canUpdateProgram.next(canUpdate);
    this._canDeleteProgram.next(canDelete);
    this._canUpdateOwner.next(canUpdateOwner);
    this._programStatus.next(programStatus);
  }

  public dispatchUpdateProgram(reqBody: Program) {
    this._updateInProgress.next(true);

    return this.programService
      .updateProgramById(this.program.id.toString(), reqBody)
      .toPromise()
      .then<ExosDialogPromiseResult>((program: Program) => {
        this.dispatchLoadProgram(program);
        return { updated: true };
      })
      .catch<ExosDialogPromiseResult>((error) => {
        this._programUpdateErrors.next(error);
        return { error: error.error, updated: false };
      })
      .finally(() => {
        this._updateInProgress.next(false);
      });
  }

  public dispatchDeleteProgram() {
    this._updateInProgress.next(true);

    return this.programService
      .deleteProgramById(this.program.id.toString())
      .toPromise()
      .then<ExosDialogPromiseResult>(() => {
        return {
          updated: true,
        };
      })
      .catch<ExosDialogPromiseResult>((error) => {
        return {
          updated: false,
          error: error.error,
        };
      })
      .finally(() => {
        this._updateInProgress.next(false);
      });
  }

  public dispatchUpdateProgramStatus(
    programId: string,
    statusType: StatusNameType,
    payload: StatusItem
  ) {
    this._updateInProgress.next(true);

    return this.programService
      .updateProgramStatusById(programId, statusType, payload)
      .toPromise()
      .then<ExosDialogPromiseResult>((response) => {
        const programStatus = this.parseProgramStatus(response);
        this._programStatus.next(programStatus);
        return {
          updated: true,
        };
      })
      .catch<ExosDialogPromiseResult>((error) => {
        return {
          updated: false,
          error: error.error,
        };
      })
      .finally(() => {
        this._updateInProgress.next(false);
      });
  }

  private parseProgramStatus(status: Status): ProgramStatusItem[] {
    const statuses = Object.keys(status).map((statusName: string) => {
      const { value, comment } = status[statusName] as StatusItem;
      return new ProgramStatusItem(
        value,
        comment,
        StatusNameType[statusName.toUpperCase()]
      );
    });
    statuses.unshift(statuses.pop());
    return statuses;
  }

  private canUpdateProgram(program: Program): boolean {
    const currentUserRights = this.programRightsService.getUserRights(program);
    return currentUserRights.canUpdate;
  }

  private canDeleteProgram(program: Program) {
    const currentUserRights = this.programRightsService.getUserRights(program);
    return currentUserRights.canDelete;
  }
}
