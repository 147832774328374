import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ProgramService } from '../../../shared/service/program/program.service';
import { Observable } from 'rxjs';
import {
  DashTableComponent,
  PageContext,
  SortDirection,
  UrlDataSource,
} from '@dashboard/table';
import { ProjectModel } from '../../../shared/models/project.model';
import { ApiService, UserService } from '@dashboard/core';
import { filter, pluck, tap } from 'rxjs/operators';
import {
  ActivatedRoute,
  convertToParamMap,
  Params,
  Router,
} from '@angular/router';
import { ProjectStatusItem } from '../../../shared/models/project-status';
import { StatusNameType } from '../../../shared/models/status';
import { JqlInputComponent } from '../../../exos/jql-input-search/components/jql-input/jql-input.component';
import { JqlTokenBuilderService } from '../../../exos/jql-input-search/services/jql-token-builder/jql-token-builder.service';
import { ListingRouteDataConfig } from '../../../shared/models/listing';
import { Program } from '../../../shared/models/program.model';
import { Location } from '@angular/common';
import { JqlTokenSuggestionService } from '../../../exos/jql-input-search/services/jql-token-suggestion/jql-token-suggestion.service';
import { JqlTokenValidatorService } from '../../../exos/jql-input-search/services/jql-token-validator/jql-token-validator.service';
import { JQL_CONFIG } from '../../../exos/jql-input-search/config/config';
import { JQL_PROGRAM_CONFIG } from '../../config/jql.config';
import { ProgramRightsService } from 'src/app/shared/service/user-rights/program-rigths/program-rights.service';
import {
  TablePreferences,
  UserPreferencesService,
} from '../../../shared/service/user-preferences/user-preferences.service';

@Component({
  selector: 'app-programs-route',
  templateUrl: './programs-route.component.html',
  styleUrls: ['./programs-route.component.scss'],
  providers: [
    JqlTokenSuggestionService,
    JqlTokenValidatorService,
    {
      provide: JQL_CONFIG,
      useValue: JQL_PROGRAM_CONFIG,
    },
  ],
})
export class ProgramsRouteComponent implements OnInit, AfterViewInit {
  public routeData$: Observable<ListingRouteDataConfig>;
  public programs: UrlDataSource<ProjectModel>;

  public readonly perPageValues: Array<number> = [25, 50, 100];
  public readonly pmtHost: string = `${this.apiService.getBaseUrl(
    'pmt'
  )}/programs/list/`;
  public readonly tableNotFoundMsg = 'No programs found.';
  public pageContext: PageContext = new PageContext();
  public showStatusColumnTemplate = true;
  public searchIsBasic = false;
  public searchQuery = '';
  public jqlLocales = {
    button: ['Search', 'Filter'],
    placeholder: ['Search programs', 'Filter programs'],
  };

  public canCreateProgram = false;

  public tableCols: any[];

  @ViewChild(JqlInputComponent)
  public jqlInputComponent: JqlInputComponent;

  @ViewChild(DashTableComponent)
  private table: DashTableComponent;

  constructor(
    private programService: ProgramService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private userSessionService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private jqlTokenBuilderService: JqlTokenBuilderService,
    private router: Router,
    private location: Location,
    private userRights: ProgramRightsService,
    private userPreferencesService: UserPreferencesService
  ) {}

  ngOnInit() {
    const userPreferences: TablePreferences =
      this.userPreferencesService.getUserPreferences('programs');

    this.routeData$ = this.route.data.pipe(
      pluck('page'),
      tap((data: any) => {
        const fullName = this.userSessionService.getSession().fullname;
        this.tableCols = data.programs.tableCols;

        if (
          userPreferences?.version &&
          userPreferences.version ===
            this.userPreferencesService.getVersion() &&
          userPreferences?.columns
        ) {
          this.tableCols = [...userPreferences.columns];
        } else {
          this.tableCols = data.tableCols;
          this.userPreferencesService.setUserPreferences('programs', {
            columns: this.tableCols,
          });
        }

        if (
          data.title === 'My Programs' &&
          data.programs.searchQuery === 'owner = '
        ) {
          data.programs.searchQuery += `"${fullName}"`;
          data.programs.searchQuery += ` OR representative = "${fullName}"`;
        }

        this.searchQuery = data.searchQuery;
        this.showStatusColumnTemplate = !!data.tableCols.filter(
          (col) => col.id === 'status'
        ).length;

        this.programs = new UrlDataSource(this.pmtHost, {
          pageContext: this.pageContext,
          dataExtractor: (response) => this.dataTableExtractor(response),
          totalElementsExtractor: (response) => response.meta.count,
          httpVerb: 'POST',
        });
      })
    );

    this.canCreateProgram = this.userRights.isProgramManager();
  }

  ngAfterViewInit() {
    this.route.queryParams
      .pipe(filter((params) => params !== undefined))
      .subscribe((params: Params) => {
        const queryParams = JSON.parse(JSON.stringify(params));
        const paramMap = convertToParamMap(params);

        this.programs.setRequestBody(this.jqlInputComponent.searchQueryTokens);
        this.programs.setQueryParams(queryParams);

        this.searchQuery = paramMap.has('search')
          ? paramMap.get('search')
          : this.searchQuery;
        this.searchIsBasic = paramMap.get('basic') === 'true';

        if (this.searchQuery.trim() === '') {
          this.programs.setRequestBody([]);
        } else {
          const jqlQuery = this.jqlTokenBuilderService.buildQuery(
            this.searchQuery.trim()
          );
          this.programs.setRequestBody(this.searchIsBasic ? [] : jqlQuery);
        }

        this.updateTableWithUserPreferences();
        this.updateUserPreferences();

        if (this.programs && this.programs.isRunning()) {
          this.programs.generate();
        } else {
          this.programs.start();
        }
      });

    this.changeDetectorRef.detectChanges();
  }

  public updateUserPreferences() {
    this.programs.onSuccess(() => {
      const userPreferences: TablePreferences = {
        pageSize: this.pageContext.size,
      };

      if (this.programs.sortValue) {
        userPreferences.sort = this.programs.sortValue;
      }

      if (this.tableCols) {
        userPreferences.columns = this.tableCols;
      }

      this.userPreferencesService.setUserPreferences(
        'programs',
        userPreferences
      );
    });
  }

  public updateTableWithUserPreferences() {
    const userPreferences: TablePreferences =
      this.userPreferencesService.getUserPreferences('programs');

    if (userPreferences?.pageSize) {
      this.pageContext.setSize(userPreferences.pageSize);
    }

    if (userPreferences?.sort) {
      const [sortParamName, sortValue] = userPreferences.sort.split(',');
      const colIndex = this.tableCols.findIndex(
        (col) => col.id === sortParamName
      );
      this.table.sortColumn(colIndex, sortValue as SortDirection);
    }

    if (userPreferences?.columns) {
      this.tableCols = [...userPreferences.columns];
    }
  }

  private dataTableExtractor(response) {
    return response.data.map((program) => {
      if ('projects' in program) {
        program._projects =
          program.projects.length > 0
            ? program.projects.map((project) => project.name).join()
            : '-';
      }

      if ('status' in program) {
        const { value, comment } = program.status.overall;
        program.statusItem = new ProjectStatusItem(
          value,
          comment,
          StatusNameType.OVERALL
        );
        program.status_comment = comment ? comment : '-';
      }

      if ('client' in program) {
        program._client = program.client;
        program.client =
          program.client && program.client.name ? program.client.name : '-';
      }

      if ('owner' in program) {
        program._owner = program.owner;
        program.owner = program.owner.name;
      }

      return program;
    });
  }

  public fetchProjectsByQuery(event) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        search: event.query,
        basic: event.basicSearch,
        page: 1,
      },
      queryParamsHandling: 'merge',
    });
  }

  public handleSearchType(value: boolean) {
    this.searchIsBasic = value;
  }

  private camelCaseToSentenceCase(value: string) {
    if (value === undefined) {
      return value;
    }
    const result = value.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  public goToEditById(program: Program) {
    this.table.loading = true;
    localStorage.setItem('lastProgramVisitedFilter', this.location.path());

    this.router.navigate([`/programs/${program.id}/edit`]).catch((error) => {
      // TODO Handle error
      // console.log(error);
    });
  }

  public toggleColumns({ columnName, visible }) {
    this.tableCols = this.tableCols.map((col) => ({
      ...col,
      display: col.name === columnName ? visible : col.display,
    }));
    this.userPreferencesService.setUserPreferences('programs', {
      columns: this.tableCols,
    });
  }
}
