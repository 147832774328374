import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DashNotificationService } from '@dashboard/core';
import { Router } from '@angular/router';

@Injectable()
export class NotAuthorizedInterceptor implements HttpInterceptor {
  constructor(
    private notifyService: DashNotificationService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (!(error.error instanceof ErrorEvent)) {
            const { status, error: resError } = error;

            if (status === 403) {
              if (request.url.includes('/dev/')) {
                this.router.navigate(['home']);
              } else {
                this.notifyService.warning(
                  `You don't have rights to update.`
                );
              }
            } else if (
              !request.url.includes('project-document-file-info') &&
              status === 500
            ) {
              this.notifyService.warning('Oops! Something went wrong!');
            } else if (status === 413) {
              this.notifyService.warning(
                `Image size is too large.`
              );
            }

            if (resError && resError.errors && resError.errors.fields) {
              const errorFields = resError.errors.fields;
              const errorKeys = Object.keys(errorFields);

              errorKeys.forEach((key) => {
                const currentField = errorFields[key];

                if (
                  typeof currentField === 'string' &&
                  currentField.includes('must be')
                ) {
                  error.error.errors.fields[key] = 'Invalid value.';
                }
              });
            }
          }
        }

        return throwError(error);
      })
    );
  }
}
