<dash-table
  [dataSource]="auditData"
  [cols]="routeConfig.audit.table.cols"
  [noDataMessage]="routeConfig.audit.table.noResultsFoundMsg"
>
  <dash-table-column id="payload">
    <div *dashTableHeaderTemplate="let col">
      <span>{{ col.name }}</span>
    </div>

    <div *dashTableCellTemplate="let row; let value; let index">
      <a
        *ngIf="value !== null"
        class="link"
        (click)="viewProjectUpdateDetails(value)"
        >Details</a
      >
    </div>
  </dash-table-column>
</dash-table>

<dash-table-paginator
  class="mt-2"
  [pageContext]="pageContext"
  [perPageValues]="perPageValues"
>
</dash-table-paginator>
