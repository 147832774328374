<div [formGroup]="group">
  <input
    type="checkbox"
    class="input-checkbox"
    [class.input-text-group--disabled]="group.get(name).disabled"
    [class.input-text-group--error]="group.get(name).invalid"
    [id]="name"
    [name]="name"
    [formControlName]="name"
    [attr.disabled]="group.get(name).disabled ? 'disabled' : null"
    (change)="onToggle()"
  />

  <label [for]="name">
    {{ label }}
    <!--    <i class="exos-icon exos-icon-help-18" *ngIf="infoPoint?.hasInfoPoint" [exosTooltip]="infoPoint.text"-->
    <!--       exosTooltipPosition="above" style="color: #0b9dcc"></i>-->
  </label>

  <p *ngIf="group.get(name).invalid" class="input-byline input-byline--error">
    {{ group.get(name).getError('error') }}
  </p>
</div>
