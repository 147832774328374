import { EnvironmentType } from '@dashboard/core';

/* patch an environment definition with values from environment variables formatted like this
   environment__X__Y__Z where X, Y, Z replace json paths (X.Y.Z) in the environment structure */
export const patch = (env: EnvironmentType, diff: {[key: string]: string}) => {
  for (const v of Object.keys(diff)) {
    const t = v.split('__');
    let x = env;

    for (let i = 1; i < t.length - 1; i++) {
      if (!x[t[i]]) {
        x[t[i]] = {};
      }

      x = x[t[i]];
    }

    x[t[t.length - 1]] = diff[v];
  }

  return env;
};

