import { Pipe, PipeTransform } from '@angular/core';
import { DashTableColumnDefinition } from '@dashboard/table';

@Pipe({
  name: 'tableColumnFilter',
})
export class TableColumnFilterPipe implements PipeTransform {
  transform(
    value: DashTableColumnDefinition[],
    ...args: unknown[]
  ): DashTableColumnDefinition[] {
      const filter = value.filter((column) => column?.display);
      return filter;
  }
}
