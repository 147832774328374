import { Directive, Input } from '@angular/core';
import { ExosBaseSemanticType } from '../../exos.model';

export type ExosStripeAlignType = 'center' | 'left' | 'right';
export type ExosStripeVerticalAlignType = 'bottom' | 'center' | 'top';

@Directive({
  selector: '[sheet-stripes], [sheetStripes]',
  host: {
    class: 'sheet__stripes',
  },
})
export class SheetStripesDirective {}

@Directive({
  selector: '[exos-stripe], [exosStripe]',
  host: {
    class: 'stripe',
  },
})
export class ExosStripeDirective {}

@Directive({
  selector: '[exos-stripe-item], [exosStripeItem]',
  host: {
    class: 'stripe__item',
    '[class.stripe__item--align-center]': "align === 'center'",
    '[class.stripe__item--align-left]': "align === 'left'",
    '[class.stripe__item--align-right]': "align === 'right'",
    '[class.stripe__item--vertical-align-bottom]': "verticalAlign === 'bottom'",
    '[class.stripe__item--vertical-align-center]': "verticalAlign === 'center'",
    '[class.stripe__item--vertical-align-top]': "verticalAlign === 'top'",
  },
})
export class ExosStripeItemDirective {
  @Input() align: ExosStripeAlignType;
  @Input() verticalAlign: ExosStripeVerticalAlignType;
}

@Directive({
  selector: '[exos-stripe-element], [exosStripeElement]',
  host: {
    class: 'stripe__element',
    '[class.stripe__element--inline]': 'inline === true',
  },
})
export class ExosStripeElementDirective {
  @Input() inline: boolean;
}

@Directive({
  selector: '[action-stripe], [actionStripe]',
  host: {
    class: 'action-stripe',
    '[class.__direct-selection]': 'directSelection && !disabled',
    '[class.action-stripe--disabled]': 'disabled',
    '[class.action-stripe--link]': 'actionIsLink',
  },
})
export class ActionStripeDirective {
  @Input() directSelection: boolean;
  @Input() disabled: boolean;
  @Input() actionIsLink: boolean;
}

@Directive({
  selector: '[stripe-label], [stripeLabel]',
  host: {
    class: 'action-stripe__label',
  },
})
export class ActionStripeLabelDirective {}

@Directive({
  selector: '[action-stripe-action], [actionStripeAction]',
  host: {
    class: 'action-stripe__action',
  },
})
export class ActionStripeActionDirective {}

@Directive({
  selector: '[settings-stripe], [settingsStripe]',
  host: {
    class: 'settings-stripe',
    '[class.__direct-selection]': 'directSelection && !disabled',
    '[class.settings-stripe--readonly]': 'readonly',
    '[class.settings-stripe--switch]': 'hasSwitch',
    '[class.settings-stripe--menu]': 'hasMenu',
    '[class.settings-stripe--link]': 'hasLink',
    '[class.settings-stripe--disabled]': 'disabled',
    '[class.action-stripe--semantic-bar-success]': "semantic === 'success'",
    '[class.action-stripe--semantic-bar-warning]': "semantic === 'warning'",
    '[class.action-stripe--semantic-bar-critical]': "semantic === 'critical'",
    '[class.action-stripe--semantic-bar-neutral]': "semantic === 'neutral'",
  },
})
export class SettingsStripeDirective {
  @Input() directSelection: boolean;
  @Input() readonly: boolean;
  @Input() hasSwitch: boolean;
  @Input() hasMenu: boolean;
  @Input() hasLink: boolean;
  @Input() disabled: boolean;
  @Input() semantic: ExosBaseSemanticType;
}

@Directive({
  selector: '[settings-stripe-label], [settingsStripeLabel]',
  host: {
    class: 'settings-stripe__label',
  },
})
export class SettingsStripeLabelDirective {}

@Directive({
  selector: '[settings-stripe-value], [settingsStripeValue]',
  host: {
    class: 'settings-stripe__value',
  },
})
export class SettingsStripeValueDirective {}

@Directive({
  selector: '[settings-stripe-action], [settingsStripeAction]',
  host: {
    class: 'settings-stripe__action',
  },
})
export class SettingsStripeActionDirective {}
