import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExosModule } from '../exos/exos.module';
import { StaticRoutingModule } from './static-routing.module';
import { DashCoreModule } from '@dashboard/core';
import { HomeRouteComponent } from './containers/home-route/home-route.component';
import { ManualComponent } from './containers/manual/manual.component';
import { CoeRouteComponent } from './containers/coe-route/coe-route.component';
import { NewsComponent } from './containers/news/news.component';
import { SharedModule } from '../shared/shared.module';
import { RolesRouteComponent } from './containers/roles-route/roles-route.component';

@NgModule({
  declarations: [
    HomeRouteComponent,
    ManualComponent,
    CoeRouteComponent,
    NewsComponent,
    RolesRouteComponent,
  ],
  imports: [
    CommonModule,
    DashCoreModule,
    ExosModule,
    StaticRoutingModule,
    SharedModule,
  ],
})
export class StaticModule {}
