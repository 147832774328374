<header exosPageHeader [isOverlapping]="true" layout="narrow">
    <div exosPageHeaderBlock>
      <h1 exosPageHeaderHeadline>IONOS Projects HOME</h1>
    </div>
  </header>
    <div class="mb-4-i" exosPageSection [isOverlapping]="true" size="narrow">
      <div exosPageSectionBlock>
        <div exosSheet>
          <div exosSheetSection>
            <div exosSheetBlock>
              <h3 exosHeadline type="sub">
                Product News
              </h3>
              <div fxLayout="row wrap" fxLayoutGap="1rem grid">
                <exos-wysiwyg
                    fxFlex="100"
                    [group]="form"
                    [config]="formConfig.product_news.fields?.html_product_news"
                    [name]="formConfig.product_news.fields?.html_product_news.name"
                    [label]="formConfig.product_news.fields?.html_product_news.label"
                    [placeholder]="
                    formConfig.product_news.fields?.html_product_news.placeholder
                    "
                    (event)="openEditProductNewsModal($event)"
                    >
                    </exos-wysiwyg>
                  </div>
            </div>
          </div>
    
          <div exosSheetSection>
              <div exosSheetBlock>
                  <h3 exosHeadline type="sub">
                      Release Notes
                    </h3>
                    <div fxLayout="row wrap" fxLayoutGap="1rem grid">
                      <exos-wysiwyg
                      fxFlex="100"
                      [group]="form"
                      [config]="formConfig.release_notes.fields?.html_release_notes"
                      [name]="formConfig.release_notes.fields?.html_release_notes.name"
                      [label]="formConfig.release_notes.fields?.html_release_notes.label"
                      [placeholder]="
                      formConfig.release_notes.fields?.html_release_notes.placeholder
                      "
                      (event)="openEditReleaseNotesModal($event)"
                      >
                      </exos-wysiwyg>
                    </div>
                </div>
          </div>
    </div>
 