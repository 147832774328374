import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';

const functionalPlanFormConfig: { [key: string]: FormConfig } = {
  ionos: {
    action: 'new-functional-stream',
    fields: {
      template: {
        name: 'template',
        label: 'File Template',
        type: 'select',
        placeholder: 'Not selected',
        options: [
          { text: 'Empty', value: 'Functional Plan Stream' },
          {
            text: 'Technical Concept',
            value: 'Engineering - Technical Concept',
          },
          { text: 'Go To Market Concept', value: 'Marketing - GTM Concept' },
        ],
        value: null,
        fxFlex: '100',
        infoPoint: {
          hasInfoPoint: false,
        },
      },
      streamName: {
        name: 'streamName',
        label: 'Stream Name',
        placeholder: 'Stream Name',
        type: 'input',
        fxFlex: '100',
        value: null,
      },
      fileName: {
        name: 'fileName',
        label: 'File Name',
        placeholder: 'File Name',
        type: 'input',
        fxFlex: '100',
        value: null,
      },
      responsible: {
        name: 'responsible',
        label: 'Responsible',
        placeholder: 'Responsible Name',
        type: 'autocomplete',
        items: null,
        fxFlex: '100',
        value: null,
        icon: 'exos-icon exos-icon-nav-user-16',
        service: {
          name: 'usersService',
          action: 'findByName',
        },
      },
    },

    buttons: [
      {
        label: 'Save',
        type: 'button',
        action: 'submit',
        hasLoader: true,
        isPrimary: true,
      },
      {
        label: 'Close',
        type: 'button',
        action: 'cancel',
        hasLoader: false,
      },
    ],
  },
  'united-internet': {
    action: 'new-functional-stream',
    fields: {
      template: {
        name: 'template',
        label: 'File Template',
        type: 'select',
        placeholder: 'Not selected',
        options: [
          {
            text: 'Projektauftrag (optional)',
            value: 'Projektauftrag (optional)',
          },
          {
            text: 'Project Kick-Off (optional)',
            value: 'Project Kick-Off (optional)',
          },
          { text: 'Abschluss - One Pager', value: 'Abschluss - One Pager' },
          {
            text: 'Change Request - One Pager',
            value: 'Change Request - One Pager',
          },
          {
            text: 'Finance Projektideen - Business Case',
            value: 'Finance Projektideen - Business Case',
          },
          {
            text: 'Finance Projektideen - One Pager',
            value: 'Finance Projektideen - One Pager',
          },
        ],
        value: null,
        fxFlex: '100',
        infoPoint: {
          hasInfoPoint: false,
        },
      },
      responsible: {
        name: 'responsible',
        label: 'Responsible',
        placeholder: 'Responsible Name',
        type: 'autocomplete',
        items: null,
        fxFlex: '100',
        value: null,
        icon: 'exos-icon exos-icon-nav-user-16',
        service: {
          name: 'usersService',
          action: 'findByName',
        },
      },
    },

    buttons: [
      {
        label: 'Generate',
        type: 'button',
        action: 'submit',
        hasLoader: true,
        isPrimary: true,
      },
      {
        label: 'Close',
        type: 'button',
        action: 'cancel',
        hasLoader: false,
      },
    ],
  },
};

export const addFunctionalPlanDialogConfig: {
  [key: string]: ExosDialogConfig;
} = {
  ionos: {
    data: {
      title: 'Add new functional plan stream',
      formConfig: functionalPlanFormConfig.ionos,
    },
  },
  'united-internet': {
    data: {
      title: 'Add new functional plan file',
      formConfig: functionalPlanFormConfig['united-internet'],
    },
  },
};
