const config = {
  data: {
    formConfig: {
      action: 'jira-issues',

      fields: {
        jql: {
          name: 'jql',
          label: 'Jql',
          placeholder: 'Jql',
          type: 'textarea',
          fxFlex: '100',
          value: null,
          infoPoint: {
            hasInfoPoint: true,
            text: 'In the text area below insert the JQL filter from Jira. Only tickets that have a "Start Date" and an "Due date" will be taken into consideration. \
                  Imported tasks will be added to the existing list. \
                  Last import filter is saved and if reapplied it will automatically update the existing issues if there were changes in JIRA.',
          },
        },
      },

      buttons: [
        {
          label: 'Import',
          type: 'button',
          action: 'import',
          hasLoader: true,
          isPrimary: true,
        },
        {
          label: 'Close',
          type: 'button',
          action: 'cancel',
          hasLoader: false,
        },
      ],
    },
    title: 'Import jira issues',
    warning:
      'Parent task/project with id {ids} could not be imported! \
      <br/> \
      Some tasks will be imported without a parent and some links will be missing.',
  },
};

export default config;
