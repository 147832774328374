import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';

export enum customColumnsMapping {
  Low = 1,
  Medium = 2,
  High = 3,
}

const riskFormConfig: FormConfig = {
  action: 'risks',

  fields: {
    description: {
      name: 'description',
      label: 'Description',
      placeholder: 'Description',
      type: 'textarea',
      resizable: 'vertical',
      fxFlex: '100',
      value: '',
    },
    impact: {
      name: 'impact',
      label: 'Impact',
      type: 'select',
      options: [
        { text: 'Not selected', value: '' },
        { text: 'Low', value: 'Low' },
        { text: 'Medium', value: 'Medium' },
        { text: 'High', value: 'High' },
      ],
      fxFlex: '100',
      value: '',
    },
    probability: {
      name: 'probability',
      label: 'Probability',
      type: 'select',
      options: [
        { text: 'Not selected', value: '' },
        { text: 'Low', value: 'Low' },
        { text: 'Medium', value: 'Medium' },
        { text: 'High', value: 'High' },
      ],
      fxFlex: '100',
      value: '',
    },
    responsePlan: {
      name: 'responsePlan',
      label: 'Response plan',
      placeholder: 'Response plan',
      type: 'input',
      fxFlex: '100',
      value: null,
    },
    owner: {
      name: 'owner',
      label: 'Owner',
      placeholder: 'Owner',
      type: 'autocomplete',
      items: null,
      fxFlex: '100',
      value: null,
      infoPoint: {
        hasInfoPoint: false,
      },
      service: {
        name: 'usersService',
        action: 'findByName',
      },
      icon: 'exos-icon-nav-user-16',
      noResultsMessage: 'No user found.',
    },
    status: {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: [
        { text: 'Not selected', value: '' },
        { text: 'OPEN', value: 'OPEN' },
        { text: 'CLOSED', value: 'CLOSED' },
      ],
      fxFlex: '100',
      value: null,
    },
  },

  buttons: [
    {
      label: 'Save',
      type: 'button',
      action: 'submit',
      hasLoader: true,
      isPrimary: true,
    },
    {
      label: 'Close',
      type: 'button',
      action: 'cancel',
      hasLoader: false,
    },
  ],
};

export const addRiskDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Add new risk',
    formConfig: riskFormConfig,
  },
};

export const editRiskDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Edit risk',
    formConfig: riskFormConfig,
  },
};
