import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { DocumentService } from '../../../../shared/service/document/document.service';
import { ProjectDocumentFileInfo } from '../../../../shared/models/google-doc.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectFunctionalPlanResolver
  implements Resolve<ProjectDocumentFileInfo[]>
{
  constructor(private documentService: DocumentService) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<ProjectDocumentFileInfo[]> {
    const projectId = route.parent.data.project.id;

    return this.documentService.getProjectPlanSections(projectId).pipe(
      mergeMap((projectPlanSections) => of(projectPlanSections)),
      catchError(() => {
        return of([]);
      })
    );
  }
}
