import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { InlineEditConfig } from '../inline-edit.directive';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { takeUntil } from 'rxjs/operators';

export interface InlineEditFormConfig {
  controlName: string;
  controlValue: string;
}

@Component({
  selector: 'app-inline-edit-form',
  templateUrl: './inline-edit-form.component.html',
  styleUrls: ['./inline-edit-form.component.scss'],
})
export class InlineEditFormComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private readonly destroyed$ = new Subject<void>();
  private readonly submit$ = new Subject<any>();
  private readonly cancel$ = new Subject<any>();

  public onSubmit$ = this.submit$.asObservable();
  public onCancel$ = this.cancel$.asObservable();

  public form: FormGroup;

  @Input() config: InlineEditConfig;
  @Input() formId: number;

  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(private formBuilder: FormBuilder) {}

  setError(error) {
    this.form.setErrors(error);
    this.form.markAsUntouched();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      [this.config.controlName]: [
        this.config.controlValue,
        Validators.required,
      ],
    });

    this.form.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    this.cancel$.complete();
    this.submit$.complete();
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onSubmit(ev: Event) {
    ev.stopPropagation();
    ev.stopImmediatePropagation();
    this.form.markAsPending();
    this.submit$.next(this.form.value);
  }

  onCancel(ev: Event) {
    ev.stopPropagation();
    ev.stopImmediatePropagation();
    this.cancel$.next(true);
  }
}
