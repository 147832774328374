import { ElementRef, Injectable } from '@angular/core';
import { gantt, GanttInitializationConfig } from './dhtmlx-gantt';
import { UserGanttSettingsService } from '../user-gantt-settings/user-gantt-settings.service';
import { Observable, Subject } from 'rxjs';
import { GanttChartData, GanttTask } from '../../models/gantt.model';
import { tap } from 'rxjs/operators';

const DHTMLX_GANTT_INIT_ZOOM_CONFIG = {
  levels: [
    {
      name: 'day',
      scale_height: 50,
      min_column_width: 40,
      scales: [{ unit: 'day', step: 1, format: '%d %M' }],
    },
    {
      name: 'week',
      scale_height: 50,
      min_column_width: 25,
      scales: [
        {
          unit: 'week',
          step: 1,
          format: (date) => {
            const dateToStr = gantt.date.date_to_str('%d %M');
            const endDate = gantt.date.add(date, 6, 'day');
            const weekNum = gantt.date.date_to_str('%W')(date);
            return (
              '#' +
              weekNum +
              ', ' +
              dateToStr(date) +
              ' - ' +
              dateToStr(endDate)
            );
          },
        },
        { unit: 'day', step: 1, format: '%j %D' },
      ],
    },
    {
      name: 'month',
      scale_height: 50,
      min_column_width: 60,
      scales: [
        { unit: 'month', step: 1, format: '%F, %Y' },
        { unit: 'week', step: 1, format: 'Week #%W' },
      ],
    },
    {
      name: 'quarter',
      height: 25,
      min_column_width: 45,
      scales: [
        { unit: 'month', step: 1, format: '%M' },
        {
          unit: 'quarter',
          step: 1,
          format: (date) => {
            const dateToStr = gantt.date.date_to_str('%M');
            const endDate = gantt.date.add(
              gantt.date.add(date, 3, 'month'),
              -1,
              'day'
            );
            return dateToStr(date) + ' - ' + dateToStr(endDate);
          },
        },
      ],
    },
    {
      name: 'year',
      scale_height: 50,
      min_column_width: 15,
      scales: [{ unit: 'year', step: 1, format: '%Y' }],
    },
  ],
  element: null,
};

const DHTMLX_GANTT_INIT_CONFIG: GanttInitializationConfig = {
  config: {
    sort: true,
    readonly: true,
    date_format: '%Y-%m-%d %H:%i',
    show_progress: true,
    min_column_width: 30,
    duration_unit: 'day',
    work_time: true,
    scale_height: 100,
    row_height: 23,
    min_duration: 24 * 60 * 60 * 1000,
    auto_scheduling: true,
    // auto_scheduling_strict: true,
    auto_scheduling_compatibility: true,
    // reorder tasks
    order_branch: true,
    order_branch_free: true,
    columns: [
      {name:"text",       label:"Task name",  width:"*", tree:true },
      {name:"start_date", label:"Start time", align:"center" },
      {name:"end_date",   label:"End time",   align:"center" },
      {name:"progress",   label:"Progress",   align:"center", template:function(obj){
        return Number(obj.progress * 100).toFixed(2) + '%'} },
      {name:"duration",   label:"Duration",   align:"center" },
      {name:"add",        label:"",           width:44 },
    ],
    scales: [
      { unit: 'day', step: 1, format: '%j, %D' },
      { unit: 'week', step: 1, format: 'Week #%W' },
      { unit: 'month', step: 1, format: '%F, %Y' },
      {
        unit: 'quarter',
        step: 1,
        format: (date) => {
          const dateToStr = gantt.date.date_to_str('%M');
          const endDate = gantt.date.add(
            gantt.date.add(date, 3, 'month'),
            -1,
            'day'
          );
          return dateToStr(date) + ' - ' + dateToStr(endDate);
        },
      },
      { unit: 'year', step: 1, format: 'Calendar year %Y' },
    ],
  },
  plugins: {
    marker: true,
    tooltip: true,
    drag_timeline: true,
    undo: true,
    auto_scheduling: true,
    critical_path: true,
    fullscreen: true,
    // keyboard_navigation: true,
  },
  locale: 'en'
};

@Injectable({
  providedIn: 'root',
})
export class DhtmlxGanttService {
  private zoomService: any = gantt.ext.zoom;

  private currentZoomLevel = new Subject<string>();

  public currentZoomLevel$: Observable<string> =
    this.currentZoomLevel.asObservable();

  public zoomLevels = ['day', 'week', 'month', 'quarter', 'year'];

  private criticalPathVisibility = new Subject<boolean>();

  public criticalPathVisibility$ = this.criticalPathVisibility
    .asObservable()
    .pipe(tap((value) => (gantt.config.highlight_critical_path = !!value)));

  constructor(private userGanttSettingsService: UserGanttSettingsService) {}

  // update
  public setCanUpdate(flag: boolean) {
    gantt.config.readonly = flag;
  }

  // settings
  public zoomIn() {
    const currentLevel = this.zoomService.getCurrentLevel();

    if (currentLevel === 0) {
      return;
    }

    const zoomLevel = this.zoomService.getLevels()[currentLevel - 1].name;

    this.zoomService.setLevel(zoomLevel);
    this.userGanttSettingsService.saveZoomScale(zoomLevel);
    this.currentZoomLevel.next(zoomLevel);
  }

  public zoomOut() {
    const currentLevel = this.zoomService.getCurrentLevel();

    if (currentLevel === this.zoomLevels.length) {
      return;
    }

    const zoomLevel = this.zoomService.getLevels()[currentLevel + 1].name;

    this.zoomService.setLevel(zoomLevel);
    this.userGanttSettingsService.saveZoomScale(zoomLevel);
    this.currentZoomLevel.next(zoomLevel);
  }

  public setZoomLevel(level) {
    this.zoomService.setLevel(level);
    this.userGanttSettingsService.saveZoomScale(level);
  }

  public setCriticalPathVisibility(flag: boolean) {
    this.criticalPathVisibility.next(flag);
    this.userGanttSettingsService.saveCriticalPath(flag);

    gantt.config.highlight_critical_path = flag;
    gantt.render();
  }

  // init
  public initGanttChart(
    ganttData: GanttChartData,
    el: ElementRef,
    canUpdate: boolean,
  ) {
    this.ganttInitConfig(canUpdate);
    this.initZoomModule(el);

    gantt.init(el.nativeElement);
    gantt.parse(ganttData);
    gantt.sort('orderIndex');

    this.displayTaskNameOnRight();
    this.ganttAddTodayMarker();
    this.ganttMarkWeekends();
    this.addTooltipToLinks();
    this.fetchZoomLevelFromUserPreferences();
    this.fetchCriticalPathFromUserPreferences();
    this.openAllTasks();
    this.highlightAvailableDropPlaces();
    this.showCurrentDate();
    // this.blockDragOnOtherBranches();

    gantt.render();
  }

  private fetchZoomLevelFromUserPreferences() {
    this.currentZoomLevel.next(this.userGanttSettingsService.getZoomLevel());
  }

  private fetchCriticalPathFromUserPreferences() {
    this.criticalPathVisibility.next(
      this.userGanttSettingsService.getDisplayCriticalPath()
    );
  }

  private openAllTasks() {
    gantt.eachTask((t) => (t.$open = true));
  }

  private closeAllTasks() {
    gantt.eachTask((t) => (t.$open = false));
  }

  private highlightAvailableDropPlaces() {
    let dragId: number = null;
    let openedTasksBeforeDrag = null;

    gantt.attachEvent(
      'onRowDragStart',
      (id) => {
        dragId = parseInt(id, 10);

        const currentPullTasks = gantt.getDatastore('task').pull;

        const currentTasks = Object.keys(currentPullTasks)
          .filter((taskId) => currentPullTasks[taskId].$open)
          .map((taskId) => ({
            $open: currentPullTasks[taskId].$open,
            id: taskId,
          }));

        openedTasksBeforeDrag = JSON.parse(JSON.stringify(currentTasks));

        // if (parseInt(gantt.getTask(id).parent, 10) === 0) {
        //   this.closeAllTasks();
        // }

        return true;
      },
      null
    );

    gantt.attachEvent(
      'onRowDragEnd',
      () => {
        openedTasksBeforeDrag.forEach((task) => {
          gantt.getTask(task.id).$open = true;
        });

        dragId = null;
        openedTasksBeforeDrag = null;

        gantt.render();
      },
      null
    );

    gantt.templates.grid_row_class = (start, end, task: GanttTask) => {
      if (dragId !== null) {
        const dragTask = gantt.getTask(dragId);
        const taskId = parseInt(task.id, 10);

        if (dragId === taskId) {
          return 'current-drag-el';
        }

        if (taskId !== dragId) {
          if (dragTask.parent !== task.parent) {
            return 'cant-drop';
          }
        }
      }

      return '';
    };
  }

  private blockDragOnOtherBranches() {
    gantt.attachEvent(
      'onBeforeTaskMove',
      (id, parent) =>
        parseInt(gantt.getTask(id).parent, 10) === parseInt(parent, 10),
      null
    );
  }

  private displayTaskNameOnRight() {
    gantt.templates.rightside_text = (start, end, task) => {
      return task.type === gantt.config.types.milestone ? task.text : '';
    };
  }

  private ganttAddTodayMarker() {
    const dateToStr = gantt.date.date_to_str(gantt.config.task_date);
    const today = new Date();

    gantt.addMarker({
      start_date: today,
      css: 'today',
      text: 'Today',
      title: 'Today: ' + dateToStr(today),
    });
  }

  private ganttMarkWeekends() {
    gantt.templates.scale_cell_class = (date) => {
      if (!gantt.isWorkTime(date)) {
        return 'weekend';
      }
    };
    gantt.templates.timeline_cell_class = (item, date) => {
      if (!gantt.isWorkTime({ date, task: item })) {
        return 'weekend';
      }
    };
  }

  private ganttInitConfig(canUpdate: boolean) {
    const config = DHTMLX_GANTT_INIT_CONFIG.config;
    const plugins = DHTMLX_GANTT_INIT_CONFIG.plugins;

    gantt.plugins(plugins);

    Object.keys(config).forEach((key) => {
      gantt.config[key] = config[key];
    });

    gantt.config.readonly = canUpdate;
  }

  private initZoomModule(el: ElementRef) {
    DHTMLX_GANTT_INIT_ZOOM_CONFIG.element = el.nativeElement;

    this.zoomService.init(DHTMLX_GANTT_INIT_ZOOM_CONFIG);
    this.zoomService.setLevel(this.userGanttSettingsService.getZoomLevel());
  }

  private addTooltipToLinks() {
    const tooltips = gantt.ext.tooltips;
    const linkTypeToString = (linkType) => {
      switch (linkType) {
        case gantt.config.links.start_to_start:
          return 'Start to start';
        case gantt.config.links.start_to_finish:
          return 'Start to finish';
        case gantt.config.links.finish_to_start:
          return 'Finish to start';
        case gantt.config.links.finish_to_finish:
          return 'Finish to finish';
        default:
          return '';
      }
    };

    gantt.templates.tooltip_text = (start, end, task) => {
      if (end === start) {
        end = gantt.date.add(end as Date, 1, 'day');
        task.duration = 1;
      }

      return (
        '<b>Task:</b> ' +
        task.text +
        '<br/>' +
        '<b>Start date:</b> ' +
        gantt.templates.tooltip_date_format(start) +
        '<br/><b>End date:</b> ' +
        gantt.templates.tooltip_date_format(end)
      );
    };

    tooltips.tooltipFor({
      selector: '.gantt_task_link',
      html: (event, node) => {
        const linkId = node.getAttribute(gantt.config.link_attribute);

        if (linkId) {
          const link = gantt.getLink(linkId);
          const from = gantt.getTask(link.source);
          const to = gantt.getTask(link.target);

          return [
            '<b>Link:</b> ' + linkTypeToString(link.type),
            '<b>From: </b> ' + from.text,
            '<b>To: </b> ' + to.text,
          ].join('<br>');
        }
      },
    });
  }

  public exportPDF(projectName: string) {
    gantt.exportToPDF({ name: `Project Plan - ${projectName}` });
  }

  public exportExcel(projectName: string) {
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${day}-${month}-${year}`;
    let fileName = `${projectName} - ${currentDate}.xlsx`;
    gantt.exportToExcel({ name: fileName });
  }

  public showCurrentDate(){
    let date = new Date();
    let date_x = gantt.posFromDate(date);
    let scroll_to = Math.max(date_x - gantt.config.task_scroll_offset, 0);
    gantt.scrollTo(scroll_to, null);
  }
}
