import { Component, Input, Output, EventEmitter } from '@angular/core';

interface HighlighOption {
  setActiveStyles(): void;
  setInactiveStyles(): void;
}

interface AutocompleteOption {
  value: string;
  token: string;
}

@Component({
  selector: 'app-autocomplete-option',
  templateUrl: './autocomplete-option.component.html',
  styleUrls: ['./autocomplete-option.component.scss'],
})
export class AutocompleteOptionComponent implements HighlighOption {
  @Input()
  public value: AutocompleteOption;

  @Output()
  public selected$: EventEmitter<AutocompleteOption> = new EventEmitter();

  public isActive = false;

  constructor() {}

  public setActiveStyles() {
    this.isActive = true;
  }

  public setInactiveStyles() {
    this.isActive = false;
  }

  public onSelect(event: Event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.selected$.emit(this.value);
  }
}
