import { Injectable } from '@angular/core';
import { DashDialogService, ExosDialogConfig } from '@dashboard/exos-dialog';
import { Observable, Subject } from 'rxjs';
import {
  ChangeRequest,
  ChangeRequestState,
  IChangeRequest,
  ChangeRequestActionName,
} from '../../models/change-request';
import { ProjectModel, ProjectPhaseType } from '../../models/project.model';
import { ChangeRequestDialogComponent } from '../../components/dialogs/change-request-dialog/change-request-dialog.component';
import { createNewChangeRequestDialogConfig } from '../../components/dialogs/change-request-dialog/change-request-dialog.config';
import { ChangeRequestService } from '../../service/change-request/change-request.service';
import { ProjectUserRightsSession } from '../../service/user-rights/user-rigths';

export interface ChangeRequestUpdatePayload {
  data: ChangeRequest;
  state: ChangeRequestState;
  actionName: ChangeRequestActionName;
  projectId?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ChangeRequestFacadeService {
  private changeRequestModalConfig: ExosDialogConfig =
    createNewChangeRequestDialogConfig;

  private _project: Subject<ProjectModel> = new Subject();

  public project$: Observable<ProjectModel> = this._project.asObservable();

  constructor(
    private changeRequestService: ChangeRequestService,
    private dialogService: DashDialogService
  ) {}

  public parseChangeRequests(
    project: ProjectModel,
    userStatus: ProjectUserRightsSession
  ): ChangeRequest[] {
    return project.changeRequests.map(
      (cangeRequest) =>
        new ChangeRequest(cangeRequest, userStatus, project.phase)
    );
  }

  public canAddProjectChangeRequest(
    userStatus: ProjectUserRightsSession,
    projectPhase: ProjectPhaseType
  ): boolean {
    return (
      (userStatus.isOwner ||
        userStatus.isRepresentative ||
        userStatus.isAdmin) &&
      this.canViewProjectChangeRequests(projectPhase)
    );
  }

  public canViewProjectChangeRequests(projectPhase: ProjectPhaseType) {
    return projectPhase === 'planAndExecute' || projectPhase === 'review';
  }

  public openChangeRequestModal(data?: ChangeRequest, projectId?: number) {
    const modalConfig = this._buildChangeRequestModalConfig(data);
    const dialog = this.dialogService.open(
      ChangeRequestDialogComponent,
      modalConfig
    );

    dialog.afterSubmit.subscribe((value: IChangeRequest) => {
      this.changeRequestService.addChangeRequest(value, projectId).subscribe(
        (project: ProjectModel) => {
          this._project.next(project);
          dialog.close();
        },
        ({ error }) => {
          dialog.errors(error);
        }
      );
    });
  }

  public handleChangeRequestAction(
    payload: ChangeRequestUpdatePayload
  ): Observable<ProjectModel> {
    const {
      changeReason,
      changeOutcome,
      changeDescription,
      impactOfChange,
      proposedAction,
      id,
    } = payload.data;

    const body: IChangeRequest = {
      changeReason,
      changeOutcome,
      changeDescription,
      impactOfChange,
      proposedAction,
      state: payload.state,
    };

    return this.changeRequestService.updateChangeRequest(
      body,
      payload.projectId,
      id
    );
  }

  private _buildChangeRequestModalConfig(data?: ChangeRequest) {
    const tempConfig: ExosDialogConfig = JSON.parse(
      JSON.stringify(this.changeRequestModalConfig)
    );

    if (data) {
      Object.keys(data).forEach((field) => {
        if (tempConfig.data.formConfig.fields[field] !== undefined) {
          tempConfig.data.formConfig.fields[field].value = data[field];
        }
      });
    }

    return tempConfig;
  }
}
