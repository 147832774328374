<p
  *ngIf="
    (group.get(name)?.invalid &&
      (group.get(name)?.dirty || group.get(name)?.touched)) ||
    group.invalid
  "
  class="input-byline input-byline--error"
>
  <ng-container *ngIf="group.get(name).errors?.required">
    Missing field
  </ng-container>

  <ng-container *ngIf="group.get(name).errors?.bkError">
    {{ group.get(name).errors.bkError }}
  </ng-container>

  <ng-container *ngIf="group.getError(name)">
    {{ group.errors[name] }}
  </ng-container>
</p>
