import { Routes, RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

const appRouter: Routes = [
  {
    path: '**',
    redirectTo: 'home',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRouter)],
})
export class RoutingModule {}
