import { FormConfig } from '@dashboard/exos-form';
import infopoints from '../../../shared/locale/infopoints.json';

export const filterFormConfig: FormConfig = {
  fields: {
    type: {
      name: 'type',
      label: 'Type',
      type: 'select',
      options: [
        { text: 'Projects', value: 'projects' },
        { text: 'Programs', value: 'programs' },
      ],
      value: 'projects',
      fxFlex: '33',
    },
    projectOwner: {
      name: 'projectOwner',
      label: 'Project Owner',
      placeholder: 'Project Owner',
      type: 'autocomplete',
      items: null,
      fxFlex: '33',
      value: null,
      icon: 'exos-icon-nav-user-16',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.OWNER,
      },
      service: {
        name: 'usersService',
        action: 'findByName',
      },
      noResultsMessage: 'No user found.',
    },
    division: {
      name: 'division',
      label: 'Division',
      type: 'multiselect',
      placeholder: 'Divisions',
      items: null,
      selectedItems: [],
      value: null,
      fxFlex: '33',
      icon: 'exos-icon-community-64',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.DIVISION,
      },
      service: {
        name: 'divisionService',
        action: 'findByName',
      },
      noResultsMessage: 'No division found.',
    },
    programOwner: {
      name: 'programOwner',
      label: 'Program Owner',
      placeholder: 'Program Owner',
      type: 'autocomplete',
      items: null,
      fxFlex: '33',
      value: null,
      icon: 'exos-icon-nav-user-16',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.OWNER,
      },
      service: {
        name: 'usersService',
        action: 'findByName',
      },
      noResultsMessage: 'No user found.',
    },
  },
  buttons: [],
};
