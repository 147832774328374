import { Injectable } from '@angular/core';
import { ApiService } from '@dashboard/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutocompleteMapperService } from '../autocomplete-mapper/autocomplete-mapper.service';
import { ObjectiveSet } from '../../models/objective.model';

export interface Division {
  uid?: number;
  id?: number;
  item: string;
  value: string;
  text?: string;
  okrDivisionSet?: ObjectiveSet;
  isCompany?: boolean;
  disabled?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class DivisionService {
  private readonly apiBaseUrl: string = `${this.apiService.getBaseUrl(
    'pmt'
  )}/divisions`;

  constructor(
    private http: HttpClient,
    private apiService: ApiService,
    private autocompleteService: AutocompleteMapperService
  ) {}

  getOkrDivisions(): Observable<Division[]> {
    return this.http
      .get<Division[]>(`${this.apiService.getBaseUrl('okr')}/divisions/`)
      .pipe(
        map((data) => {
          this.autocompleteService.map(data, 'item');
          return data.map((user) => ({
            ...user,
            value: user.item,
            text: user.item,
          }));
        })
      );
  }

  findByName(name: string): Observable<Division[]> {
    const params: HttpParams = new HttpParams().set('name', name);

    return this.http.get<Division[]>(this.apiBaseUrl, { params }).pipe(
      map((data) => {
        this.autocompleteService.map(data, 'item');
        return data.map((user) => ({
          ...user,
          value: user.item,
          text: user.item,
        }));
      })
    );
  }

  findStakeholderDivisions() {
    return this.http.get(
      `${this.apiService.getBaseUrl('pmt')}/stakeholders/divisions/`
    );
  }
}
