import { Injectable } from '@angular/core';
import { JqlToken } from '../../jql-token';

@Injectable({
  providedIn: 'root',
})
export class JqlTokenBuilderService {
  constructor() {}

  public buildQuery(value: string): JqlToken[] {
    const splitBySpace = value.split(' ');
    const tokens = this.buildTokens(splitBySpace);

    return this.build(tokens);
  }

  private build(value: string[]): JqlToken[] {
    let errIndex = 0;

    return value.map((item, index, arr) => {
      errIndex = index ? errIndex + arr[index - 1].length + 1 : errIndex;
      return new JqlToken(index, item, errIndex);
    });
  }

  private buildTokens(value: string[]): string[] {
    return value.reduce((acc, curr) => {
      const prevIndex = acc.length - 1;
      const prev = acc[prevIndex];

      if (prev) {
        if (this.startsWithQuote(prev) && !this.endsWithQuote(prev)) {
          acc[prevIndex] = `${prev} ${curr}`;
          return acc;
        }
      }

      acc.push(curr);

      return acc;
    }, []);
  }

  private startsWithQuote(value: string): boolean {
    return value.startsWith("'") || value.startsWith('"');
  }

  private endsWithQuote(value: string): boolean {
    return value.endsWith("'") || value.endsWith('"');
  }
}
