import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ExosDialogConfig, ExosDialogRef } from '@dashboard/exos-dialog';
import {
  ExosFormComponent,
  FormContainerDirective,
  FormEvent,
  FormService,
} from '@dashboard/exos-form';
import { Subject, Subscription } from 'rxjs';
import { UserFiltersService } from 'src/app/shared/service/user-filters/user-filters.service';

@Component({
  selector: 'app-add-filter-dialog',
  templateUrl: './add-filter-dialog.component.html',
  styleUrls: ['./add-filter-dialog.component.scss'],
})
export class AddFilterDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild(FormContainerDirective)
  public formContainer: FormContainerDirective;
  private modalForm: FormGroup;

  private MAX_CHARACTERS = 45;

  public form: ComponentRef<ExosFormComponent>;

  private subs: Subscription[] = [];

  private filtersList = [];

  public loading = false;

  constructor(
    public config: ExosDialogConfig,
    public dialog: ExosDialogRef,
    private exosFormService: FormService,
    private changeDetectorRef: ChangeDetectorRef,
    private userFiltersService: UserFiltersService
  ) {}

  ngAfterViewInit(): void {
    this.form = this.exosFormService.buildForm(
      this.formContainer.viewContainerRef,
      this.config.data.formConfig
    );

    this.form.instance.form.controls.filterName.setValidators([
      Validators.required,
      Validators.maxLength(this.MAX_CHARACTERS),
    ]);

    const userFilters = this.userFiltersService.getUserFilters();
    this.filtersList = Object.values(userFilters).map((value) => value.title);

    this.handleFormEvents();
    this.handleDialogErrors();

    this.changeDetectorRef.detectChanges();
  }

  private handleFormEvents() {
    this.subs.push(
      this.form.instance.formEvents.subscribe((ev) => {
        switch (ev.type) {
          case 'save':
            if (this.form.instance.form.invalid) {
              if (this.form.instance.form.value.filterName?.length > 0) {
                this.form.instance.form.controls.filterName.setErrors({
                  bkError: `Filter name must have a maximum of ${this.MAX_CHARACTERS} characters.`,
                });
              } else {
                this.form.instance.form.controls.filterName.setErrors({
                  bkError: 'Missing field',
                });
              }
              return;
            }

            if (!this.config.data.edit) {
              if (this.userFiltersService.isMaxFilterCount()) {
                this.form.instance.form.controls.filterName.setErrors({
                  bkError: 'Max filters limit reached.',
                });
                return;
              }

              const existingFilter = this.filtersList.find(
                (filterName) =>
                  filterName === this.form.instance.form.value.filterName
              );
              if (existingFilter) {
                this.form.instance.form.controls.filterName.setErrors({
                  bkError: 'This filter name is already exists.',
                });
                return;
              }
            }

            this.form.instance.form.disable();
            this.form.instance.form.markAsPending();
            this.loading = true;

            this.dialog.submit(this.form.instance.form.value);
            break;

          default:
            this.dialog.close();
            break;
        }
      })
    );
  }

  private handleDialogErrors() {
    this.subs.push(
      this.dialog.afterErrors.subscribe((errors: any) => {
        if (errors) {
          this.exosFormService.setFieldErrors(errors);
        } else {
          this.modalForm.setValue(this.modalForm.getRawValue());
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
