import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeRouteComponent } from './containers/home-route/home-route.component';
import { AuthGuard, RoleGuard } from '@dashboard/core';
import { ManualComponent } from './containers/manual/manual.component';
import { CoeRouteComponent } from './containers/coe-route/coe-route.component';
import { NewsComponent } from './containers/news/news.component';
import { NewsLetterResolver } from './containers/news/news.resolver';
import { RolesRouteComponent } from './containers/roles-route/roles-route.component';

const routes: Routes = [
  {
    path: 'home',
    component: HomeRouteComponent,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'manual',
    component: ManualComponent,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'coe',
    component: CoeRouteComponent,
    canActivate: [AuthGuard, RoleGuard],
  },
  {
    path: 'news',
    component: NewsComponent,
    canActivate: [AuthGuard, RoleGuard],
    resolve: {
      newsLetter: NewsLetterResolver,
    },
  },
  {
    path: 'roles',
    component: RolesRouteComponent,
    canActivate: [AuthGuard, RoleGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StaticRoutingModule {}
