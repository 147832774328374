import { DashTableColumnDefinition } from '@dashboard/table';

export const stakeholderTableCols: DashTableColumnDefinition[] = [
  {
    id: 'userInfo',
    display: true,
    name: 'Name',
    sortable: true,
    value: (id, row) => {
      return row.userInfo == null ? '' : row.userInfo.name;
    },
  },
  {
    id: 'division',
    name: 'Division',
    sortable: true,
    value: 'division',
    display: true,
  },
  { id: 'role', name: 'Role', sortable: true, value: 'role', display: true },
];
