<header exosPageHeader>
  <div exosPageHeaderBlock>
    <a (click)="location.back()" exosPageHeaderBackButton></a>
    <h1 exosPageHeaderHeadline>Create project</h1>
  </div>
</header>

<div exosPageSection>
  <div exosPageSectionBlock>
    <ng-template libFormContainer> </ng-template>
  </div>
</div>
