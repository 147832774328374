import { Injectable } from '@angular/core';

export type StepperItemStatus = 'PASSED' | 'UNFINISHED' | 'PENDING';

export class StepperItem {
  name: string;
  uiName: string;
  icon: string;
  iconSvg: string;
  status: StepperItemStatus;
  link: string;

  constructor(stepperItemBuilder: StepperItemBuilder) {
    this.name = stepperItemBuilder.name;
    this.uiName = stepperItemBuilder.uiName;
    this.icon = stepperItemBuilder.icon;
    this.iconSvg = stepperItemBuilder.iconSvg;
    this.status = stepperItemBuilder.status;
    this.link = stepperItemBuilder.link;
  }
}

@Injectable({
  providedIn: 'root',
})
export class StepperItemBuilder {
  private _name: string;
  private _uiName: string;
  private _icon: string;
  private _iconSvg: string;
  private _status: StepperItemStatus;
  private _link: string;

  constructor() {}

  build() {
    return new StepperItem(this);
  }

  setName(name: string) {
    this._name = name;
    return this;
  }

  setUiName(uiName: string) {
    this._uiName = uiName;
    return this;
  }

  setIcon(icon: string) {
    this._icon = icon;
    return this;
  }

  setIconSvg(iconSvg: string) {
    this._iconSvg = iconSvg;
    return this;
  }

  setStatus(status: StepperItemStatus) {
    this._status = status;
    return this;
  }

  setLink(link: string) {
    this._link = link;
    return this;
  }

  get name() {
    return this._name;
  }

  get uiName() {
    return this._uiName;
  }

  get icon() {
    return this._icon;
  }

  get iconSvg() {
    return this._iconSvg;
  }

  get status() {
    return this._status;
  }

  get link() {
    return this._link;
  }
}
