import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  CheckBoxField,
  CheckboxFormConfig,
} from '../../service/market-form/market-form.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-checkbox-form',
  templateUrl: './checkbox-form.component.html',
  styleUrls: ['./checkbox-form.component.scss'],
})
export class CheckboxFormComponent implements OnInit, AfterViewInit {
  public form: FormGroup = new FormGroup({});

  public fields: CheckBoxField[];

  @Input()
  canUpdate: boolean;

  @Input()
  config: CheckboxFormConfig;

  @Output()
  changed: EventEmitter<any> = new EventEmitter<any>();

  constructor(private formBuilderService: FormBuilder) {}

  ngOnInit() {
    const fields = Object.keys(this.config.fields).map(
      (field) => this.config.fields[field]
    );
    const parent = this.formBuilderService.group({});
    const allFieldsAreChecked = fields.every((field) => field.value);

    this.fields = fields;
    this.form.addControl(
      'all',
      this.formBuilderService.control(allFieldsAreChecked)
    );
    this.form.addControl(this.config.groupName, parent);

    fields.forEach((field) => {
      const { disabled, name, value } = field;
      parent.addControl(
        name,
        this.formBuilderService.control({ disabled, value })
      );
    });
  }

  ngAfterViewInit() {
    this.form.controls[this.config.groupName].valueChanges
      .pipe(debounceTime(1000))
      .subscribe((value) => {
        this.changed.emit({ [this.config.groupName]: value });
      });

    this.form.controls.all.valueChanges.subscribe((value) => {
      const groupField = this.form.controls[this.config.groupName] as FormGroup;

      Object.keys(groupField.value).forEach((field) => {
        groupField.controls[field].patchValue(value);
      });
    });

    if (!this.canUpdate) {
      this.form.disable({ emitEvent: false });
    }
  }
}
