import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { DocumentService } from '../../../../shared/service/document/document.service';
import { ProjectDocumentFileInfo } from '../../../../shared/models/google-doc.model';
import { ProjectModel } from 'src/app/shared/models/project.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectBriefFileResolver
  implements Resolve<ProjectDocumentFileInfo>
{
  constructor(
    private documentService: DocumentService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ProjectDocumentFileInfo> {
    const project: ProjectModel = route.parent.data.project;
    if (project.workflow === 'united-internet') {
      this.router.navigate([`/projects/${project.id}/details`]);
    }

    return this.documentService
      .getProjectDocumentFileInfo(project.folderId, 'Project Brief')
      .pipe(
        mergeMap((projectBriefFile) => of(projectBriefFile)),
        catchError(() => {
          return of({ sections: null });
        })
      );
  }
}
