import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ExosDialogConfig, ExosDialogRef } from '@dashboard/exos-dialog';
import {
  ExosFormComponent,
  FormContainerDirective,
  FormService,
} from '@dashboard/exos-form';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wysiwyg-dialog',
  templateUrl: './wysiwyg-dialog.component.html',
  styleUrls: ['./wysiwyg-dialog.component.scss'],
})
export class WysiwygDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild(FormContainerDirective)
  public formContainer: FormContainerDirective;

  public form: ComponentRef<ExosFormComponent>;

  private subs: Subscription[] = [];

  constructor(
    public config: ExosDialogConfig,
    public dialog: ExosDialogRef,
    private exosFormService: FormService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.form = this.exosFormService.buildForm(
      this.formContainer.viewContainerRef,
      this.config.data.formConfig
    );

    this.handleFormEvents();
    this.handleFormErrors();

    this.changeDetectorRef.detectChanges();
  }

  private handleFormEvents() {
    this.subs.push(
      this.form.instance.formEvents.subscribe((ev) => {
        switch (ev.type) {
          case 'UPDATE':
            if (this.form.instance.form.invalid) {
              return;
            }

            this.form.instance.form.disable();
            this.form.instance.form.markAsPending();
            this.dialog.submit(this.form.instance.form.value);
            break;
          case 'CLOSE':
            this.dialog.close();
            break;
          default:
            this.dialog.close();
        }
      })
    );
  }

  private handleFormErrors() {}

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
