<header exosPageHeader [isOverlapping]="true" layout="narrow">
  <div exosPageHeaderBlock>
    <h1 exosPageHeaderHeadline>Welcome to IONOS Projects!</h1>
  </div>
</header>

<div class="mb-4-i" exosPageSection [isOverlapping]="true" size="narrow">
  <div exosPageSectionBlock>
    <div exosSheet>
      <div exosSheetSection>
        <div exosSheetBlock>
          <h3 exosHeadline type="sub">
            IONOS Projects is a tool, supporting the organization in planning
            and managing projects at every step of the project lifecycle from
            the initial idea to the successful implementation.
          </h3>
        </div>
      </div>

      <div exosSheetSection>
        <div exosSheetBlock>
          <p exosParagraph class="mb-3">
            <img
              src="assets/IONOS-Projects-logo-horizontal-long_IONOS_blue.png"
              style="max-width: 300px; display: block; margin: auto"
              alt="Projects"
            />
          </p>

          <p exosParagraph>
            <video
              controls
              width="640"
              height="360"
              style="margin: 0 auto; display: block"
              poster="assets/media/teaser.jpg"
            >
              <source
                src="assets/media/IONOS_Projects_Teaser.mp4"
                type="video/mp4"
              />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </p>
        </div>

        <div exosSheetBlock>
          <h3 exosHeadline type="sub" class="mt-2">
            IONOS Projects was created to support you to:
          </h3>
          <ul exosCheckList class="mb-2">
            <li>
              hand in project ideas and consolidate first input for your
              project-pitch
            </li>
            <li>
              involve the right set of supporters and contributors and get their
              buy-in
            </li>
            <li>
              write and keep track of your project and functional plan,
              preparing for execution
            </li>
            <li>
              review the the positive and negative lessons learned during
              projects
            </li>
            <li>
              create a single-point-of-truth for all documents and further links
            </li>
          </ul>
          <p exosParagraph>
            Feel free to upload all previously created, project-related
            documents into IONOS Projects, using the upload functionality and be
            part of creating a single-point-of-truth under the ‘Documents &
            Links’ tab for every project.
          </p>
          <p exosParagraph>
            To further optimize the way we work today and drive collaboration
            IONOS Projects includes a Google G Suite integration, enabling us to
            benefit from comprehensive collaboration features fueling
            transparency, team work and documentation in real-time.
          </p>
          <p exosParagraph>
            To ensure a seamless user experience with IONOS Projects, we
            recommend to use the latest version of the Google Chrome browser on
            Microsoft Windows or MAC systems.
          </p>
          <h3 exosHeadline type="sub">Get your projects started!</h3>
        </div>
      </div>

      <div exosSheetSection type="emphasized">
        <div exosSheetBlock>
          <h4 exosHeadline type="sub" [isBright]="true">
            If you need technical assistance, want to give feedback or share
            some love feel free to leave the team a message at
            <a
              style="font-size: 19px; text-decoration: underline"
              exosLink
              [isBright]="true"
              href="mailto:ionosprojects@ionos.org"
              >ionosprojects@ionos.org</a
            >
          </h4>

          <footer>
            <a
              href="mailto:ionosprojects@ionos.org"
              exosButton
              [isBright]="true"
              >Send email</a
            >
          </footer>
        </div>
      </div>
    </div>
  </div>
</div>
