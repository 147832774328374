import { Injectable } from '@angular/core';
import {
  ProgramUserRights,
  UserRightsAbstract,
  UserRightsSession,
} from '../user-rigths';
import { Program } from '../../../models/program.model';
import { UserService } from '@dashboard/core';
import { User } from '../../../models/user';

@Injectable({
  providedIn: 'root',
})
export class ProgramRightsService extends UserRightsAbstract {
  constructor(private session: UserService) {
    super();
  }

  public getUserSessionStatus(program: Program): UserRightsSession {
    return {
      isAdmin: this.currentUserIsAdmin(),
      isClient: this.currentUserIsClient(program.client),
      isOwner: this.currentUserIsOwner(program.owner),
      isRepresentative: this.currentUserIsRepresentative(
        program.representative
      ),
    };
  }

  public getUserRights(program: Program): ProgramUserRights {
    const { isOwner, isRepresentative, isAdmin } =
      this.getUserSessionStatus(program);
    const userCanDelete = this.currentUserCanDelete();

    return {
      canUpdate: isOwner || isRepresentative,
      canUpdateOwner: isOwner,
      canDelete: userCanDelete,
    };
  }

  public isClient(client: User): boolean {
    return this.currentUserIsClient(client);
  }

  public isOwner(owner: User): boolean {
    return this.currentUserIsOwner(owner);
  }

  public isRepresentative(representative: User[]): boolean {
    return this.currentUserIsRepresentative(representative);
  }

  public isAdmin() {
    return this.currentUserIsAdmin();
  }

  public isProgramManager() {
    return this.currentUserIsProgramManager();
  }

  public isBoardMember() {
    return this.currentUserIsBoardMember();
  }

  private currentUserIsAdmin() {
    return this.session
      .getAllRoles()
      .some(
        (item) => item.getName() === 'admin' && item.getAppCode() === 'pmt'
      );
  }

  private currentUserIsClient(client: User): boolean {
    return client ? client.id === this.session.getId().toString() : false;
  }

  private currentUserIsOwner(owner: User): boolean {
    return owner ? owner.id === this.session.getId().toString() : false;
  }

  private currentUserIsRepresentative(representative: User[]): boolean {
    const currentUserId = this.session.getId().toString();
    return representative
      ? representative.some((member) => member.id === currentUserId)
      : false;
  }

  private currentUserIsProgramManager() {
    return this.session
      .getAllRoles()
      .some(
        (item) =>
          item.getName() === 'program_manager' && item.getAppCode() === 'pmt'
      );
  }

  private currentUserIsBoardMember() {
    return this.session
      .getAllRoles()
      .some(
        (item) =>
          item.getName() === 'board_member' && item.getAppCode() === 'pmt'
      );
  }

  private currentUserCanDelete() {
    return this.session
      .getAllRoles()
      .some(
        (item) => item.getName() === 'can_delete' && item.getAppCode() === 'pmt'
      );
  }
}
