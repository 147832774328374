import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@dashboard/core';
import { Observable } from 'rxjs';
import { NewsLetter } from '../../models/newsletter.model';

@Injectable({
  providedIn: 'root',
})
export class NewsletterService {
  private readonly apiBaseUrl = `${this.apiService.getBaseUrl(
    'pmt'
  )}/newsLetters`;

  constructor(private httpClient: HttpClient, private apiService: ApiService) {}

  public findById(id: number): Observable<NewsLetter> {
    return this.httpClient.get<NewsLetter>(`${this.apiBaseUrl}/${id}/`);
  }

  public updateNewsLetter(payload: NewsLetter): Observable<NewsLetter> {
    return this.httpClient.put<NewsLetter>(`${this.apiBaseUrl}/${payload.id}/`, payload);
  }
}
