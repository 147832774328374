import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProjectLaunchDates } from '../../../models/project.model';

@Component({
  selector: 'widget-projected-launch-date',
  templateUrl: './widget-projected-launch-date.component.html',
  styleUrls: ['./widget-projected-launch-date.component.scss'],
})
export class WidgetProjectedLaunchDateComponent implements OnInit {
  @Input()
  data: ProjectLaunchDates[];

  @Input()
  canUpdate: boolean;

  @Input()
  heading: string;

  @Output()
  update: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onUpdate() {
    this.update.emit(this.data[0] ? this.data[0].date : null);
  }
}
