import { Injectable } from '@angular/core';
import { ApiService, AuthService, UserService } from '@dashboard/core';
import { HttpClient } from '@angular/common/http';
import { UserFilter } from './user-filters.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserFilterApiService {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private userService: UserService,
    private http: HttpClient
  ) {}

  public getFiltersForUser(userId: string): Observable<UserFilter[]> {
    return this.http.get<UserFilter[]>(
      `${this.apiService.getBaseUrl('pmt')}/filters/userFilters/${userId}`
    );
  }

  public getFilter(id: string): Observable<UserFilter> {
    return this.http.get<UserFilter>(
      `${this.apiService.getBaseUrl('pmt')}/filters/${id}`
    );
  }

  public editFilter(filter: UserFilter): Observable<UserFilter> {
    return this.http.put<UserFilter>(
      `${this.apiService.getBaseUrl('pmt')}/filters/${filter.id}`,
      filter
    );
  }

  public addFilterForUser(
    filter: UserFilter & { uid: string }
  ): Observable<UserFilter> {
    return this.http.post<UserFilter>(
      `${this.apiService.getBaseUrl('pmt')}/filters`,
      filter
    );
  }

  public addAllFiltersForUser(
    filters: UserFilter[]
  ): Observable<UserFilter[]> {
    return this.http.post<UserFilter[]>(
      `${this.apiService.getBaseUrl('pmt')}/filters/userFilters`,
      filters
    );
  }

  public deleteFilter(id: string): Observable<any> {
    return this.http.delete<UserFilter>(
      `${this.apiService.getBaseUrl('pmt')}/filters/${id}`
    );
  }
}
