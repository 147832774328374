<ng-container *ngIf="project$ | async as project">
  <header exosPageHeader>
    <div exosPageHeaderBlock>
      <a (click)="backToLastFilter()" class="page-header__back-button"></a>
      <h1 exosPageHeaderHeadline>
        {{ projectName$ | async }}
      </h1>

      <ng-container
        *ngIf="projectStepperConfig$ | async as projectStepperConfig"
      >
        <project-stepper [steps]="projectStepperConfig"></project-stepper>
      </ng-container>
    </div>
  </header>

  <dash-tabs [tabs]="tabs"> </dash-tabs>

  <div exosPageSection>
    <div exosPageSectionBlock>
      <ng-template #loader>
        <dash-loader [hasContainer]="true"></dash-loader>
      </ng-template>

      <router-outlet
        *ngIf="!(routeIsLoading$ | async); else loader"
      ></router-outlet>
    </div>
  </div>
</ng-container>
