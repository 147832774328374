import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';

const projectedStartDateFormConfig: FormConfig = {
  action: 'projectedStartDates',

  fields: {
    date: {
      name: 'date',
      label: 'Projected start date',
      placeholder: 'Projected start date',
      type: 'datepicker',
      fxFlex: '100',
      value: null,
    },
    comment: {
      name: 'comment',
      label: 'Comment',
      type: 'textarea',
      fxFlex: '100',
      resizable: 'vertical',
      rows: '5',
      value: null,
    },
  },

  buttons: [
    {
      label: 'Save',
      type: 'button',
      action: 'submit',
      hasLoader: true,
      isPrimary: true,
    },
    {
      label: 'Close',
      type: 'button',
      action: 'cancel',
      hasLoader: false,
    },
  ],
};

export const addprojectedStartDateDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Add new project start date',
    formConfig: projectedStartDateFormConfig,
  },
};
