import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'exos-select',
  templateUrl: './exos-select.component.html',
  styleUrls: ['./exos-select.component.scss'],
})
export class ExosSelectComponent implements OnInit {
  @Input()
  public group: FormGroup;

  @Input()
  public name: string;

  @Input()
  public label: string;

  @Input()
  public infoPoint: string;

  @Input()
  public placeholder: string;

  @Input()
  public type: string;

  @Input()
  public icon: string;

  @Input()
  public options: any[];

  @Input()
  public disabled: boolean;

  @Input()
  public displayFn: ((option: any) => string) | null = null;

  @Input()
  public compareFn: (current, value: any) => boolean = (current, value) =>
    current === value;

  constructor() {}

  ngOnInit(): void { }
}
