import { InjectionToken } from '@angular/core';

export type JQL_TOKEN_TYPE = 'FIELD' | 'OPERATOR' | 'VALUE' | 'KEYWORD';

export type JQL_KEYWORDS = 'AND' | 'OR';

export interface TokenFieldConfigI {
  name: string;
  operators: string[];
  values?: string[];
}

export interface JqlConfig {
  fields: TokenFieldConfigI[];
  keywords: JQL_KEYWORDS[];
}

export const JQL_CONFIG = new InjectionToken<JqlConfig>('jql.config');
