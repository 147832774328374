import infopoints from '../../../../shared/locale/infopoints.json';
import { ExosCardFormConfig } from '../../../../shared/models/card-form-config';

export const projectDetailsFormConfig: ExosCardFormConfig = {
  heading: 'Project Details',
  disabled: true,
  fields: {
    name: {
      name: 'name',
      label: 'Project Name',
      placeholder: 'Project Name',
      type: 'input',
      fxFlex: '100',
      value: null,
    },
    effort: {
      name: 'effort',
      label: 'Effort',
      type: 'select',
      options: ['S', 'M', 'L', 'XL'],
      value: null,
      fxFlex: '100',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.EFFORT,
      },
    },
    prio: {
      name: 'prio',
      label: 'Prio',
      type: 'select',
      options: ['A', 'B', 'C'],
      value: null,
      fxFlex: '100',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.PRIO,
      },
    },
    workflow: {
      name: 'workflow',
      label: 'Workflow',
      type: 'select',
      options: [
        { text: 'IONOS', value: 'ionos' },
        { text: 'United Internet', value: 'united-internet' },
      ],
      value: null,
      fxFlex: '100',
      infoPoint: {
        hasInfoPoint: false,
      },
    },
    program: {
      name: 'program',
      label: 'Program',
      placeholder: 'Search program',
      type: 'autocomplete',
      fxFlex: '100',
      items: null,
      value: null,
      service: {
        name: 'programService',
        action: 'findByName',
      },
      noResultsMessage: 'No program found.',
    },
    tags: {
      name: 'tags',
      label: 'Project Tags',
      type: 'multiselect',
      placeholder: 'Search tags',
      items: null,
      selectedItems: [],
      value: null,
      fxFlex: '100',
      icon: 'exos-icon-marker-16',
      service: {
        name: 'tagService',
        action: 'findByNameLikePattern',
      },
      // canAddOnEnter: true,
      valueKey: 'name',
    },
  },
  buttons: [],
};

export const projectTeamFormConfig: ExosCardFormConfig = {
  heading: 'Project Team',
  disabled: true,
  fields: {
    client: {
      name: 'client',
      label: 'Project Client',
      placeholder: 'Project Client',
      type: 'autocomplete',
      fxFlex: '100',
      value: null,
      items: null,
      icon: 'exos-icon-nav-user-16',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.CLIENT,
      },
      service: {
        name: 'usersService',
        action: 'findByName',
      },
      noResultsMessage: 'No user found.',
    },
    owner: {
      name: 'owner',
      label: 'Project Owner',
      placeholder: 'Project Owner',
      type: 'autocomplete',
      items: null,
      fxFlex: '100',
      value: null,
      icon: 'exos-icon-nav-user-16',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.OWNER,
      },
      service: {
        name: 'usersService',
        action: 'findByName',
      },
      noResultsMessage: 'No user found.',
    },
    representative: {
      name: 'representative',
      label: 'Project Representative',
      placeholder: 'Project Representative',
      type: 'multiselect',
      items: null,
      selectedItems: [],
      fxFlex: '100',
      value: null,
      icon: 'exos-icon-nav-user-16',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.REPRESENTATIVE,
      },
      service: {
        name: 'usersService',
        action: 'findByName',
      },
      noResultsMessage: 'No user found.',
    },
    division: {
      name: 'division',
      label: 'Divisions',
      type: 'multiselect',
      placeholder: 'Divisions',
      items: null,
      selectedItems: [],
      value: null,
      fxFlex: '100',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.DIVISION,
      },
      icon: 'exos-icon-community-64',
      service: {
        name: 'divisionService',
        action: 'findByName',
      },
      noResultsMessage: 'No division found.',
    },
    mainTeamMembers: {
      name: 'mainTeamMembers',
      label: 'Main Team Members',
      placeholder: 'Main Team Members',
      type: 'multiselect',
      items: null,
      selectedItems: [],
      fxFlex: '100',
      value: null,
      icon: 'exos-icon-nav-user-16',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.MAIN_TEAM_MEMBERS,
      },
      service: {
        name: 'usersService',
        action: 'findByName',
      },
      noResultsMessage: 'No user found.',
    },
  },
  buttons: [],
};

export const projectVisibilityFormConfig: ExosCardFormConfig = {
  heading: 'Project Visibility',
  disabled: true,
  fields: {
    confidential: {
      name: 'confidential',
      label: 'Confidential',
      type: 'checkbox',
      value: false,
    },
  },
  buttons: [],
};

export const projectObjectiveFormConfig: ExosCardFormConfig = {
  heading: 'OKR',
  disabled: true,
  fields: {
    objectiveType: {
      name: 'objectiveType',
      label: 'Type',
      type: 'select',
      options: [],
      value: null,
      fxFlex: '100',
    },
    objectiveDivision: {
      name: 'objectiveDivision',
      label: 'Company/Division',
      type: 'select',
      options: [],
      value: null,
      fxFlex: '100',
    },
    objectiveYear: {
      name: 'objectiveYear',
      label: 'Year',
      type: 'select',
      options: [],
      value: null,
      fxFlex: '100',
    },
    objectiveQuarter: {
      name: 'objectiveQuarter',
      label: 'Quarter',
      type: 'select',
      options: [],
      value: null,
      fxFlex: '100',
    },
    objectiveId: {
      name: 'objectiveId',
      label: 'Objective',
      type: 'select',
      options: [],
      value: null,
      fxFlex: '100',
      placeholder: 'Not selected',
    },
    keyResultId: {
      name: 'keyResultId',
      label: 'Key Result',
      type: 'select',
      options: [],
      value: null,
      fxFlex: '100',
      placeholder: 'Not selected',
    },
  },
  buttons: [],
};

export const projectWorkflowFormConfig: ExosCardFormConfig = {
  heading: 'Project Workflow',
  disabled: true,
  fields: {
    workflow: {
      name: 'workflow',
      label: 'Workflow',
      type: 'select',
      options: [
        { text: 'IONOS', value: 'ionos' },
        { text: 'United Internet', value: 'united-internet' },
      ],
      value: null,
      fxFlex: '100',
      infoPoint: {
        hasInfoPoint: false,
      },
    },
  },
  buttons: [],
};

export const projectDescriptionFormConfig: ExosCardFormConfig = {
  heading: 'Project Description',
  disabled: true,
  name: 'description',
  fields: {
    html_description: {
      name: 'html_description',
      type: 'wysiwig',
      fxFlex: '100',
      rows: '20',
      cols: '30',
      value: null,
      resizable: 'vertical',
      readOnly: true,
      emptyMsg: 'No description added yet.',
      buttons: [{ label: 'View', action: 'SHOW_DESCRIPTION', type: 'button' }],
    },
  },
  buttons: [],
};

export const productRoadmapFormConfig: ExosCardFormConfig = {
  heading: 'Product Roadmap',
  name: 'roadmap',
  fields: {
    show_on_roadmap: {
      name: 'showOnRoadmap',
      label: 'Show on Product Roadmap',
      type: 'checkbox',
      fxFlex: '100',
      value: false,
    },
    product_space: {
      name: 'productSpaceId',
      label: 'Product Space',
      placeholder: 'Product Space',
      type: 'autocomplete',
      items: null,
      fxFlex: '100',
      value: null,
      icon: 'exos-icon-nav-user-16',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.SHOW_ON_ROADMAP,
      },
      service: {
        name: 'roadmapService',
        action: 'findByName',
      },
      noResultsMessage: 'No product space found.',
    },
    product: {
      disabled: true,
      name: 'productId',
      label: 'Product',
      placeholder: 'Not Selected',
      type: 'select',
      options: [],
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.PRODUCT,
      },
    },
    category: {
      disabled: true,
      name: 'categoryId',
      label: 'Category',
      placeholder: 'Not Selected',
      type: 'select',
      options: [],
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.CATEGORY,
      },
    },
  },
  buttons: [],
};

export const projectOverviewFormsConfig: {
  [key: string]: ExosCardFormConfig;
} = {
  description: projectDescriptionFormConfig,
  details: projectDetailsFormConfig,
  roadmap: productRoadmapFormConfig,
  team: projectTeamFormConfig,
  visibility: projectVisibilityFormConfig,
  okr: projectObjectiveFormConfig,
};
