<div [formGroup]="group">
  <exos-label
    *ngIf="label"
    [name]="name"
    [text]="label"
    [infoPointText]="infoPoint"
  ></exos-label>

  <div
    class="input-text-group"
    style="padding-left: 12px; padding-right: 12px"
    fxLayout="row warp"
    fxLayoutGap="0 grid"
    fxLayoutAlign="space-between center"
    [class.input-text-group--disabled]="group.get(name).disabled"
    [class.input-text-group--error]="group.get(name).invalid"
  >
    <input
      class="input-text"
      [class.input-text--error]="group.get(name).invalid"
      [class.input-text--disabled]="group.get(name).disabled"
      [id]="name"
      [name]="name"
      [placeholder]="placeholder"
      [formControlName]="name"
      [type]="type || 'text'"
      [attr.disabled]="group.get(name).disabled ? 'disabled' : null"
    />

    <i *ngIf="icon" class="input-text-group__icon exos-icon {{ icon }}"></i>
  </div>

  <exos-error [group]="group" [name]="name"></exos-error>
</div>
