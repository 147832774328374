<ng-container *ngIf="projectActionsButtons$ | async as projectActionsButtons">
  <div *ngIf="projectActionsButtons.length" class="sticky" exosSheet>
    <div exosSheetSection semantic="neutral">
      <div exosSheetBlock>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>
            <ng-container *ngFor="let button of projectActionsButtons">
              <button
                exosButton
                [disabled]="updateInProgress$ | async"
                [isDisabled]="updateInProgress$ | async"
                [semantic]="button.semantic"
                (click)="onUpdate(button.action)"
              >
                {{ button.label }}
              </button>
            </ng-container>
          </div>
          <div>
            <div
              *ngIf="updateInProgress$ | async"
              class="loading-spin loading-spin--medium"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
