import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PageSectionBlockDirective,
  PageSectionDirective,
} from './page-section.directive';

@NgModule({
  declarations: [PageSectionDirective, PageSectionBlockDirective],
  exports: [PageSectionDirective, PageSectionBlockDirective],
  imports: [CommonModule],
})
export class PageSectionModule {}
