import { DashTableColumnDefinition } from '@dashboard/table';

const ganttAllColumns: DashTableColumnDefinition[] = [
  {
    id: 'text',
    name: 'Task Name',
    sortable: true,
    value: 'text',
    display: true,
    fixed: true,
    colWidth: '150px',
  },
  {
    id: 'start_date',
    name: 'Start Date',
    sortable: true,
    value: 'start_date',
    display: true,
    fixed: false,
    colWidth: '100px',
  },
  {
    id: 'end_date',
    name: ' End Date',
    sortable: true,
    value: 'end_date',
    display: true,
    fixed: false,
    colWidth: '100px',
  },
  {
    id: 'progress',
    name: 'Progress',
    sortable: true,
    value: 'progress',
    display: true,
    fixed: false,
    colWidth: '100px',
  },
  {
    id: 'duration',
    name: 'Duration',
    sortable: true,
    value: 'duration',
    display: true,
    fixed: false,
    colWidth: '100px',
  },
  {
    id: 'add',
    name: 'Add',
    sortable: true,
    value: 'add',
    display: true,
    fixed: true,
    colWidth: '50px',
  },
];

export default ganttAllColumns;
