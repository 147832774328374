import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { DocumentService } from '../../../../shared/service/document/document.service';
import {
  ProjectDocumentFileInfo,
  ProjectGoogleFile,
} from '../../../../shared/models/google-doc.model';
import { ProjectModel } from 'src/app/shared/models/project.model';

export interface ProjectReviewRouteData {
  projectReviewFileSections: ProjectDocumentFileInfo;
  projectReviewFile: ProjectGoogleFile;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectReviewResolver implements Resolve<ProjectDocumentFileInfo> {
  constructor(
    private documentService: DocumentService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ProjectDocumentFileInfo> {
    const project: ProjectModel = route.parent.data.project;
    if (project.workflow === 'united-internet') {
      this.router.navigate([`/projects/${project.id}/details`]);
    }

    return this.documentService
      .getProjectDocumentFileInfo(project.folderId, 'Project Review')
      .pipe(
        mergeMap((projectReviewFile) => of(projectReviewFile)),
        catchError(() => {
          return of({ sections: null });
        })
      );
  }
}
