import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProjectStatusItem } from '../../models/project-status';
import { StatusItemImpl } from '../../models/status';

@Component({
  selector: 'card-status',
  templateUrl: './card-status.component.html',
  styleUrls: ['./card-status.component.scss'],
})
export class CardStatusComponent {
  @Input()
  data: StatusItemImpl[];

  @Input()
  cardTitle: string;

  @Input()
  canUpdate: boolean;

  @Output()
  updateStatusItem: EventEmitter<ProjectStatusItem> = new EventEmitter();

  @Output()
  openHelp: EventEmitter<any> = new EventEmitter();

  @Input()
  hasHelp: boolean;

  constructor() {}

  public onUpdateStatusItem(statusItem: ProjectStatusItem) {
    if (this.canUpdate) {
      this.updateStatusItem.emit(statusItem);
    }
  }

  public onOpenHelp() {
    this.openHelp.emit();
  }
}
