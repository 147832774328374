import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import {
  FILTER_VIEW_TYPE,
  FILTER_VIEW_TYPES,
} from '../../models/filter-view-type.model';

@Injectable({
  providedIn: 'root',
})
export class FilterViewTypeResolver implements Resolve<void> {
  constructor(private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): void {
    const type = route.queryParamMap.get('type');

    if (FILTER_VIEW_TYPES.includes(type as FILTER_VIEW_TYPE)) {
      route.data.page.type = type;
    }
  }
}
