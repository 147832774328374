<div
  *ngIf="steps"
  class="stepper"
  fxLayout="row"
  fxLayoutAlign="space-between start"
>
  <ng-container *ngFor="let step of steps; let last = last">
    <a stepperItem [routerLink]="step.link" [status]="step.status">
      <div class="stepper-icon-container">
        <div *ngIf="step.icon" class="material-icons-outlined stepper-icon">
          {{ step.icon }}
        </div>
        <mat-icon
          *ngIf="step.iconSvg"
          [svgIcon]="step.iconSvg"
          class="stepper-icon"
        ></mat-icon>
      </div>

      <span class="stepper-item-name">
        {{ step.uiName }}
      </span>
    </a>
  </ng-container>
</div>
