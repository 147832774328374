<ng-container *ngIf="this.program$ | async">
  <jql-input
    [searchQuery]="searchQuery"
    [searchIsBasic]="searchIsBasic"
    [locales]="jqlLocales"
    [tableColumns]="tableCols"
    (submitSearch)="fetchProjectsByQuery($event)"
    (searchTypeChanged)="handleSearchType($event)"
    (columnChecked)="toggleColumns($event)"
    [filterType]="dataType"
  >
  </jql-input>

  <ng-container *ngIf="routeData$ | async as routeData">
    <div
      *ngIf="canUpdate$ | async"
      class="table__toolbar"
      fxLayout="row"
      fxLayoutAlign="end center"
    >
      <button
        exosButton
        (click)="addProject()"
        [isDisabled]="loading || disabledBtn"
        [disabled]="loading || disabledBtn"
      >
        Add project
      </button>
    </div>

    <div
      *ngIf="objectiveSets$ | async"
      [ngClass]="
        existingFile && existingProjectId
          ? 'projects-one-icon-id-table-container'
          : existingFile && !existingProjectId
          ? 'projects-one-icon-table-container'
          : !existingFile && existingProjectId
          ? 'projects-id-table-container'
          : 'projects-table-container'
      "
      [ngStyle]="{ display: loading ? 'none' : null }"
    >
      <dash-table
        #table
        [dataSource]="projects"
        (rowClicked)="goToOverviewById($event)"
        [cols]="tableCols | tableColumnFilter"
        [noDataMessage]="tableNotFoundMsg"
        (sortClicked)="pushToUpdatePrefsQueue($event)"
      >
        <td dashTableColumn id="id">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above"
              >PRJ.{{ value }}</span
            >
          </ng-container>
        </td>

        <td dashTableColumn id="name">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }} </span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <i
              class="exos-icon exos-icon-lock-48 exos-icon-locked"
              exosTooltip="CONFIDENTIAL"
              exosTooltipPosition="above"
              *ngIf="row.confidential"
            ></i>
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="program">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="status">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <div>
              <span exosBadge [semantic]="row.statusItem.semanticType">
                {{ row.statusItem.valueLocalized }}
              </span>
            </div>
          </ng-container>
        </td>

        <td dashTableColumn id="division">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="gantt">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="status_comment">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="owner">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="client">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="file">
          <ng-container *dashTableHeaderTemplate="let col">
            {{ col.name }}</ng-container
          >

          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <i
              class="exos-icon file-icon exos-icon-flyoutmenu-16 stop-row-action"
              exosLink
              visual="external-action"
              [matMenuTriggerFor]="fileMenu"
              [matMenuTriggerData]="{ row: row }"
            ></i>
          </ng-container>
        </td>

        <td dashTableColumn id="prio">
          <div *dashTableHeaderTemplate="let col" style="text-align: center">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </div>
          <div
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
            class="table__cell--align-center"
          >
            <span>{{ value }}</span>
          </div>
        </td>

        <td dashTableColumn id="effort">
          <div *dashTableHeaderTemplate="let col" style="text-align: center">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </div>
          <div
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
            class="table__cell--align-center"
          >
            <span>{{ value }}</span>
          </div>
        </td>

        <td dashTableColumn id="risks">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="projectedLaunchDate">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="projectedStartDate">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="objective">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="keyResult">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="dependencies_description">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="dependencies">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="tags">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="phase">
          <div *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </div>
          <div
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span>{{ value }}</span>
          </div>
        </td>
      </dash-table>
    </div>

    <dash-table-paginator
      class="mt-2"
      [pageContext]="pageContext"
      [perPageValues]="perPageValues"
      (onPageSizeChange)="pushToUpdatePrefsQueue($event)"
    >
    </dash-table-paginator>

    <dash-loader *ngIf="displayObjectivesLoader" [hasContainer]="true">
    </dash-loader>
  </ng-container>
</ng-container>

<mat-menu #fileMenu="matMenu" xPosition="after" yPosition="below">
  <ng-template matMenuContent let-row="row">
    <!-- TODO to be activated after overview download is implemented -->
    <!-- <div
      mat-menu-item
      style="color: #113e8a"
      (click)="openBriefFile(row.folderId)"
    >
      <i
        class="exos-icon exos-icon-openwindow-20 menu-icon"
        *ngIf="row.workflow === 'ionos'"
        visual="external-action"
      ></i
      >Download Overview
    </div> -->
    <div
      *ngIf="row.workflow === 'ionos'"
      mat-menu-item
      style="color: #113e8a"
      (click)="openBriefFile(row.folderId)"
    >
      <i
        class="exos-icon exos-icon-openwindow-20 menu-icon"
        visual="external-action"
      ></i
      >Project Brief
    </div>
    <div
      *ngIf="row.workflow === 'ionos'"
      mat-menu-item
      style="color: #113e8a"
      (click)="openRoadMap(row.id)"
    >
      <i
        class="exos-icon exos-icon-webanalytics-32 menu-icon"
        visual="external-action"
      ></i
      >Project Plan
    </div>
    <a
      *ngIf="row.objectiveId && row.keyResultId"
      mat-menu-item
      [ngStyle]="{ color: !!okrBaseUrl ? '#113e8a' : '#555' }"
      disabled="{{ !okrBaseUrl }}"
      [href]="generateOkrLink(row)"
    >
      <i
        class="exos-icon exos-icon-openwindow-20 menu-icon"
        visual="external-action"
      ></i
      >OKR
    </a>
    <div
      *ngIf="!row.objectiveId || !row.keyResultId"
      mat-menu-item
      style="color: #113e8a"
      (click)="openOkrModal(row)"
    >
      <i
        class="exos-icon exos-icon-more-48 menu-icon"
        visual="external-action"
      ></i
      >Add OKR
    </div>
  </ng-template>
</mat-menu>
