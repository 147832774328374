import { Directive, Input } from '@angular/core';

export type PageSectionSemanticType =
  | 'default'
  | 'primary'
  | 'secondary'
  | 'emphasized'
  | 'advertising'
  | 'beta';
export type PageSectionLayoutType = 'narrow' | 'short';

@Directive({
  selector: '[exosPageSection]',
  host: {
    class: 'page-section',
    // semantic
    '[class.page-section--default]': "semantic === 'default'",
    '[class.page-section--primary]': "semantic === 'primary'",
    '[class.page-section--secondary]': "semantic === 'secondary'",
    '[class.page-section--emphasized]': "semantic === 'emphasized'",
    '[class.page-section--advertising]': "semantic === 'advertising'",
    '[class.page-section--beta]': "semantic === 'beta'",
    // layout
    '[class.page-section--narrow]': "size === 'narrow'",
    '[class.page-section--short]': "size === 'short'",
    // behavior
    '[class.page-section--overlapping]': 'isOverlapping',
  },
})
export class PageSectionDirective {
  @Input() semantic: PageSectionSemanticType;
  @Input() size: PageSectionLayoutType;
  @Input() isOverlapping: boolean;

  constructor() {}
}

@Directive({
  selector: '[exosPageSectionBlock]',
  host: {
    class: 'page-section__block',
  },
})
export class PageSectionBlockDirective {}
