<div exosSheet *ngIf="changeRequest" class="pt-2">
  <span exosBadge [semantic]="changeRequest.semanticColor" [positioned]="true">
    {{ changeRequest.readableState }}
  </span>

  <div exosSheetSection>
    <div exosSheetBlock>
      <div
        *ngIf="changeRequest.hasActions"
        fxLayout="row"
        fxLayoutAlign="end top"
        class="mb-2"
        style="margin-top: -2rem"
      >
        <button exosButton [routerLink]="['../../brief']">
          Back to project brief
        </button>

        <ng-container *ngFor="let action of changeRequest.actions">
          <button
            exosButton
            [withLoader]="true"
            [semantic]="action.semanticColor"
            (click)="handleEditChangeRequestState(action.value)"
          >
            {{ action.label }}
          </button>
        </ng-container>
      </div>

      <ng-container *ngFor="let section of changeRequestSections; index as i">
        <div
          inlineEdit
          [inlineEditConfig]="section.formConfig"
          [inlineEditDisabled]="!changeRequest.canBeEdited"
          (inlineEditValue)="handleEditChangeRequest($event, i)"
        >
          <h3 exosHeadline type="sub">
            {{ section.name }}
            <i
              class="exos-icon exos-icon-help-18"
              [exosTooltip]="section.info"
              exosTooltipPosition="above"
            ></i>
          </h3>

          <p exosParagraph class="inline-edit-box--text">
            {{ changeRequest[section.key] }}

            <span class="inline-edit-actions">
              <i
                exosTooltip="Edit"
                exosTooltipPosition="above"
                class="exos-icon exos-icon-edit-16"
              ></i>
            </span>
          </p>
          <div [cdkPortalOutlet]=""></div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
