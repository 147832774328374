import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { ProjectModel } from '../../models/project.model';
import { ProjectService } from './project.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectResolver implements Resolve<ProjectModel> {
  constructor(private projectService: ProjectService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ProjectModel> {
    const id = parseInt(route.paramMap.get('id'), 10);

    return this.projectService.findById(id).pipe(
      mergeMap((project) => of(project)),
      catchError(() => {
        this.router.navigate(['/projects']);
        return EMPTY;
      })
    );
  }
}
