import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'exos-label',
  templateUrl: './exos-label.component.html',
  styleUrls: ['./exos-label.component.scss'],
})
export class ExosLabelComponent implements OnInit {
  @Input()
  text: string;

  @Input()
  public name: string;

  @Input()
  public infoPointText: string;

  constructor() {}

  ngOnInit(): void {}
}
