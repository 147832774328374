import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PageHeaderBackButtonDirective,
  PageHeaderBlockDirective,
  PageHeaderDirective,
  PageHeaderHeadlineDirective,
  PageHeaderHelpAndLearnDirective,
  PageHeaderParagraphDirective,
} from './page-header.directive';

@NgModule({
  declarations: [
    PageHeaderDirective,
    PageHeaderBlockDirective,
    PageHeaderHeadlineDirective,
    PageHeaderParagraphDirective,
    PageHeaderBackButtonDirective,
    PageHeaderHelpAndLearnDirective,
  ],
  exports: [
    PageHeaderDirective,
    PageHeaderBlockDirective,
    PageHeaderHeadlineDirective,
    PageHeaderParagraphDirective,
    PageHeaderBackButtonDirective,
    PageHeaderHelpAndLearnDirective,
  ],
  imports: [CommonModule],
})
export class PageHeaderModule {}
