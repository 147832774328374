import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { IframeConfig } from '../../../shared/models/iframe.interface';

@Component({
  selector: 'app-home',
  templateUrl: './home-route.component.html',
  styleUrls: ['./home-route.component.scss'],
})
export class HomeRouteComponent {
  newsletterDialogConfig: IframeConfig = {
    src: this.domSanitizer.bypassSecurityTrustResourceUrl(
      'https://email-marketing.ionos.de/widget/iframe/3g0w/Med'
    ),
    scrolling: 'no',
    height: '550px',
  };

  constructor(private domSanitizer: DomSanitizer) {}
}
