import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { ProgramGanttService } from '../../service/program-gantt-service/program-gantt.service';
import { GanttChartData } from '../../models/gantt.model';

export interface ProgramGanttPageData {
  data?: GanttChartData;
  fetchError?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ProgramGanttResolver implements Resolve<ProgramGanttPageData> {
  constructor(private programGanttService: ProgramGanttService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<ProgramGanttPageData> {
    const programId = parseInt(route.parent.paramMap.get('id'), 10);

    return this.programGanttService.fetchGanttData(programId).pipe(
      mergeMap((data: GanttChartData) => of({ data })),
      catchError(() => {
        return of({ fetchError: true });
      })
    );
  }
}
