import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JqlInputComponent } from './components/jql-input/jql-input.component';
import {
  MatAutocompleteModule,
  MatCheckboxModule,
  MatIconModule,
  MatMenuModule,
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AutocompleteOptionComponent } from './components/autocomplete-option/autocomplete-option.component';
import { ExosTooltipModule } from '@dashboard/exos-tooltip';
import { JQL_CONFIG, JqlConfig } from './config/config';
import { JqlTokenValidatorService } from './services/jql-token-validator/jql-token-validator.service';
import { JqlTokenSuggestionService } from './services/jql-token-suggestion/jql-token-suggestion.service';

@NgModule({
  declarations: [JqlInputComponent, AutocompleteOptionComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    ExosTooltipModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
  ],
  exports: [JqlInputComponent, AutocompleteOptionComponent],
})
export class JqlInputSearchModule {
  constructor() {}

  static forChild(conf?: JqlConfig): ModuleWithProviders {
    return {
      ngModule: JqlInputSearchModule,
      providers: [
        { provide: JQL_CONFIG, useValue: conf },
        JqlTokenValidatorService,
        JqlTokenSuggestionService,
      ],
    };
  }
}
