import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { fromEvent, merge, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { JqlToken } from '../../jql-token';
import { FormControl } from '@angular/forms';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { DOWN_ARROW, ENTER, ESCAPE, UP_ARROW } from '@angular/cdk/keycodes';
import { AutocompleteOptionComponent } from '../autocomplete-option/autocomplete-option.component';
import {
  JqlTokenSuggestionI,
  JqlTokenSuggestionService,
} from '../../services/jql-token-suggestion/jql-token-suggestion.service';
import { JqlTokenBuilderService } from '../../services/jql-token-builder/jql-token-builder.service';
import { JqlTokenValidatorService } from '../../services/jql-token-validator/jql-token-validator.service';
import { DashTableColumnDefinition } from '@dashboard/table';
import { MatCheckboxChange } from '@angular/material';
import {
  FilterViewType,
  FILTER_VIEW_TYPE,
} from 'src/app/shared/models/filter-view-type.model';

const SEARCH_INFO =
  'The basic search function (set as default) is a full text search, screening all columns for a specific text, Smart filtering gives you the option to build up various column filters. Use ‘=’ for exact search and ‘˜’ for an approximate search.';

@Component({
  selector: 'jql-input',
  templateUrl: './jql-input.component.html',
  styleUrls: ['./jql-input.component.scss'],
})
export class JqlInputComponent implements OnInit, AfterViewInit, OnChanges {
  @Input()
  public searchQuery = '';

  @Input()
  public searchIsBasic;

  @Input()
  public saveBtnDisabled = false;

  @Input()
  public deleteBtnDisabled = false;

  @Output()
  public submitSearch: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public submitSave: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public submitDelete: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public searchTypeChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public searchInputChanged: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('searchInput')
  public searchInput: ElementRef;

  @ViewChildren(AutocompleteOptionComponent)
  public autocompleteItems: QueryList<AutocompleteOptionComponent>;

  @Input()
  public locales: { button: string[]; placeholder: string[] };

  @Input()
  public tableColumns: DashTableColumnDefinition[];

  @Input()
  public disableSearchToggle: Boolean = false;

  @Input() saveBtnLabel: string;

  @Input() deleteBtnLabel: string;

  @Input() filterType: FILTER_VIEW_TYPE = FilterViewType.projects;

  @Output()
  public columnChecked: EventEmitter<{ columnName: string; visible: boolean }> =
    new EventEmitter<{ columnName: string; visible: boolean }>();

  public columns: DashTableColumnDefinition[] = [];
  public searchBtnLabel;
  public queryError: string;
  public searchInputPlaceholder;
  public suggestions: JqlTokenSuggestionI[];
  public searchFilterTypeControl: FormControl = new FormControl();
  public searchQueryTokens: JqlToken[] = [];
  public searchQueryIsValid = true;
  public filterCheckboxLabel = 'Smart filtering';
  public searchInfo = SEARCH_INFO;
  private keyManager: ActiveDescendantKeyManager<any>;

  constructor(
    private jqlTokenSuggestionService: JqlTokenSuggestionService,
    private jqlTokenBuilderService: JqlTokenBuilderService,
    private jqlTokenValidatorService: JqlTokenValidatorService
  ) {}

  ngOnInit() {
    this.searchInputPlaceholder =
      this.locales.placeholder[+!this.searchIsBasic];
    this.searchBtnLabel = this.locales.button[+!this.searchIsBasic];
    this.searchFilterTypeControl.patchValue(!this.searchIsBasic, {
      emitEvent: false,
    });

    this.searchFilterTypeControl.valueChanges.subscribe((value) => {
      this.searchInputPlaceholder = this.locales.placeholder[+value];
      this.searchBtnLabel = this.locales.button[+value];
      this.searchIsBasic = !value;
      this.searchTypeChanged.emit(!value);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.searchInputPlaceholder =
      this.locales.placeholder[+!this.searchIsBasic];
    this.searchBtnLabel = this.locales.button[+!this.searchIsBasic];
    this.searchFilterTypeControl.patchValue(!this.searchIsBasic, {
      emitEvent: false,
    });
    this.queryError = null;
  }

  ngAfterViewInit() {
    const clickEv$: Observable<KeyboardEvent> = fromEvent(
      this.searchInput.nativeElement,
      'click'
    );
    const keyupEv$: Observable<MouseEvent> = fromEvent(
      this.searchInput.nativeElement,
      'keyup'
    );
    const focusOutEv$: Observable<MouseEvent> = fromEvent(
      this.searchInput.nativeElement,
      'focusout'
    );

    this.keyManager = new ActiveDescendantKeyManager(
      this.autocompleteItems
    ).withWrap();

    merge(clickEv$, keyupEv$, focusOutEv$)
      .pipe(
        filter((event) => this.filterInputValues(event)),
        map((event) => event.target as HTMLInputElement)
      )
      .subscribe((event) => {
        this.updateComp(event);
      });
  }

  public onSearchChange(searchValue: string): void {
    this.searchInputChanged.emit(searchValue);
  }

  public resetSearchQuery() {
    this.searchQuery = '';
    this.searchInputChanged.emit(null);
  }

  public updateComp(event: HTMLInputElement) {
    const { selectionStart, value } = event;
    const selectedValue = value.slice(0, selectionStart || 0);

    this.searchQuery = value;
    this.queryError = null;

    if (this.searchIsBasic) {
      this.searchQueryTokens = [];
      this.searchQueryIsValid = true;
      this.suggestions = [];
    } else {
      this.searchQueryTokens = this.jqlTokenBuilderService.buildQuery(value);
      this.searchQueryIsValid = this.jqlTokenValidatorService.check(
        value,
        this.filterType
      );
      this.queryError = null;
      this.suggestions = this.jqlTokenSuggestionService.fetchSuggestions(
        selectedValue,
        this.filterType
      );
    }
  }

  public chooseSuggestion(suggestion) {
    const { token } = suggestion;
    let { value } = suggestion;

    if (value.search(' ') !== -1) {
      value = `"${value}"`;
    }

    this.searchQueryTokens[token.index].value = value;
    this.searchQuery = this.searchQueryTokens.map((t) => t.value).join(' ');
    this.suggestions = null;
    this.searchInput.nativeElement.focus();
  }

  public doSearch() {
    this.handleAction(() =>
      this.submitSearch.emit({
        query: this.searchQuery,
        basicSearch: this.searchIsBasic,
      })
    );
  }

  public saveFilter(): void {
    this.handleAction(() =>
      this.submitSave.emit({ path: '/listing/my-filter?page=1&size=25' })
    );
  }

  public deleteFilter(): void {
    this.submitDelete.emit();
  }

  private handleAction(emitterFn: Function): void {
    this.suggestions = null;
    this.searchQueryIsValid = this.jqlTokenValidatorService.check(
      this.searchQuery,
      this.filterType
    );

    if (!this.searchQueryIsValid && !this.searchIsBasic) {
      this.queryError = this.jqlTokenValidatorService.update(
        this.searchQuery,
        this.filterType
      );
      return;
    }

    emitterFn();
  }

  private filterInputValues(ev: MouseEvent | KeyboardEvent) {
    if (ev instanceof FocusEvent) {
      const { type } = ev;

      if (type === 'focusout') {
        setTimeout(() => {
          if (this.suggestions) {
            this.suggestions = null;
            this.keyManager.setActiveItem(null);
          }
        }, 250);

        return false;
      }
    }

    if (ev instanceof KeyboardEvent) {
      const { keyCode } = ev;

      if (keyCode === ESCAPE) {
        this.suggestions = null;
        this.keyManager.setActiveItem(null);
        return false;
      }

      if (keyCode === ENTER && this.keyManager.activeItem) {
        this.chooseSuggestion(this.keyManager.activeItem.value);
        this.keyManager.setActiveItem(null);
        return false;
      }

      if (keyCode === ENTER && !this.keyManager.activeItem) {
        this.doSearch();
        return false;
      }

      if (keyCode === UP_ARROW || keyCode === DOWN_ARROW) {
        if (this.suggestions && this.suggestions.length) {
          this.keyManager.onKeydown(ev);
        }
        return false;
      }
    }

    return true;
  }

  public onColumnChecked(
    column: DashTableColumnDefinition,
    value: MatCheckboxChange
  ): void {
    column.display = value.checked;
    this.columnChecked.emit({
      columnName: column.name,
      visible: value.checked,
    });
  }
}
