export const dependencyTableCols = [
  { id: 'name', name: 'Name', sortable: true, value: 'name', display: true },
  {
    id: 'description',
    name: 'Description',
    sortable: true,
    value: 'description',
    display: true,
  },
  {
    id: 'status',
    name: 'Status',
    sortable: true,
    value: 'status',
    display: true,
  },
];
