<section exosSheetSection>
  <div exosSheetBlock>
    <h2 exosHeadline type="sub" semantic="critical">
      {{ config.data.title }}
    </h2>

    <p exosParagraph>
      {{ config.data.message }}
    </p>

    <footer exosSheetFooter *ngIf="config.data.buttons">
      <button
        *ngIf="config.data.buttons.cancel"
        (click)="onClose($event)"
        exosButton
        [semantic]="config.data.buttons.cancel.semantic"
        [isLoading]="isLoading"
        [isDisabled]="isLoading"
        type="button"
      >
        {{ config.data.buttons.cancel.label }}
      </button>

      <button
        *ngIf="config.data.buttons.submit"
        (click)="onSubmit($event)"
        exosButton
        semantic="critical"
        [withLoader]="config.data.buttons.submit.loader"
        [isLoading]="isLoading"
        [isDisabled]="isLoading"
        type="button"
      >
        {{ config.data.buttons.submit.label }}
      </button>
    </footer>

    <footer exosSheetFooter *ngIf="!config.data.buttons">
      <button
        (click)="onSubmit($event)"
        exosButton
        type="button"
        semantic="critical"
        [isDisabled]="isLoading"
        [isLoading]="isLoading"
      >
        Yes
      </button>

      <button (click)="onClose($event)" exosButton type="button">Cancel</button>
    </footer>
  </div>
  <div *ngIf="isLoading">
    <div class="loading-spin loading-spin--form"></div>
  </div>
</section>
