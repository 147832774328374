import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@dashboard/core';
import { Observable } from 'rxjs';
import {
  ProjectCreateModel,
  ProjectModel,
  ProjectModelUpdate,
  ProjectSearchItem,
  ProjectView,
} from '../../models/project.model';
import { map } from 'rxjs/operators';
import { JqlToken } from '../../../exos/jql-input-search/jql-token';
import { ProjectStakeholder } from '../../models/stakeholder.model';
import { ProjectRisk } from '../../models/project-risk.model';
import { ProjectStatusService } from '../project-status/project-status.service';

export interface ProjectPhaseUpdatePayload {
  projectId: number;
  payload?: ProjectModelUpdate;
  gate?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private readonly apiHost = `${this.apiService.getBaseUrl('pmt')}/projects`;

  private readonly userFields = [
    { name: 'client', type: 'text' },
    { name: 'program', type: 'text' },
    { name: 'owner', type: 'text' },
    { name: 'userInfo', type: 'text' },
    { name: 'division', type: 'list' },
    { name: 'mainTeamMembers', type: 'list' },
    { name: 'representative', type: 'list' },
    { name: 'risks', type: 'list' },
    { name: 'stakeholders', type: 'list' },
  ];

  constructor(private http: HttpClient, private apiService: ApiService) {}

  static mapSingleUserToInput(item: any, field: any) {
    if (field.type === 'text') {
      item.text = item.name;
    }

    if (field.type === 'list' && item instanceof Array) {
      item = item.map((member) => {
        member.text = member.name ? member.name : member.item;
        return member;
      });
    }

    return item;
  }

  public fetchByJql(query: JqlToken[]): Observable<any> {
    return this.http.post<any>(`${this.apiHost}/list/`, query);
  }

  public findById(id: number, params?: any): Observable<ProjectModel> {
    return this.http
      .get<ProjectModel>(`${this.apiHost}/${id}/`, { params })
      .pipe(
        map((project) => {
          project.html_description = project.html_description || '';
          project.htmlDescriptionTrimmed = project.html_description.slice(
            0,
            1000
          );
          if (project.tags) {
            project.tags.forEach((tag) => (tag.text = tag.name));
          }

          if (project.stakeholders) {
            project.stakeholders = project.stakeholders.sort((s) =>
              s.predefined ? 0 : -1
            );
          }

          return this.mapUserToInput(project);
        })
      );
  }

  public create(body: ProjectCreateModel): Observable<ProjectModel> {
    const requestBody = this.clearInvalidUserValues(body);

    if (Object.keys(requestBody).includes('market')) {
      requestBody.market = Object.keys(requestBody.market)
        .map((m) => (requestBody.market[m] ? m : false))
        .filter((m) => m);
    }

    if (Object.keys(requestBody).includes('tags')) {
      requestBody.tags =
        typeof requestBody.tags === 'string' ? null : requestBody.tags;
    }

    return this.http.post<ProjectModel>(`${this.apiHost}/`, requestBody);
  }

  public addProjectedLaunchDate(
    projectId: number,
    body: ProjectModel
  ): Observable<ProjectModel> {
    if (!body.projectedLaunchDate) {
      delete body.projectedLaunchDate;
    } else {
      const date = new Date(body.projectedLaunchDate + '');
      const finalDate = `${date.getFullYear()}-${(
        '0' +
        (date.getMonth() + 1)
      ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
      body.projectedLaunchDate = [finalDate];
    }

    return this.http.put<ProjectModel>(`${this.apiHost}/${projectId}/`, body);
  }

  public addProjectedStartDate(
    projectId: number,
    body: ProjectModel
  ): Observable<ProjectModel> {
    if (!body.projectedStartDate) {
      delete body.projectedStartDate;
    } else {
      let startDateObj = JSON.parse(JSON.stringify(body.projectedStartDate));
      const date = new Date(startDateObj.date + '');
      const finalDate = `${date.getFullYear()}-${(
        '0' +
        (date.getMonth() + 1)
      ).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
      let startDate = { date: finalDate, comment: startDateObj.comment };
      body.projectedStartDate = [startDate];
    }

    return this.http.put<ProjectModel>(`${this.apiHost}/${projectId}/`, body);
  }

  public addItem(
    projectId: number,
    body: any,
    resource: string
  ): Observable<any> {
    const requestBody = this.clearInvalidUserValues(body);
    return this.http.post<any>(
      `${this.apiHost}/${projectId}/${resource}/`,
      requestBody
    );
  }

  public editItem(
    projectId: number,
    itemId: string,
    body: any,
    resource: string
  ): Observable<any> {
    const requestBody = this.clearInvalidUserValues(body);
    return this.http.put<any>(
      `${this.apiHost}/${projectId}/${resource}/${itemId}/`,
      requestBody
    );
  }

  public deleteItem(
    projectId: number,
    itemId: string,
    resource: string
  ): Observable<any> {
    return this.http.delete<any>(
      `${this.apiHost}/${projectId}/${resource}/${itemId}/`
    );
  }

  public fetchProjectResource(
    projectId: number,
    resource: string
  ): Observable<any> {
    return this.http
      .get<any>(`${this.apiHost}/${projectId}/${resource}/`)
      .pipe(map((project) => this.mapUserToInput(project)));
  }

  public save(payload: ProjectPhaseUpdatePayload): Observable<ProjectModel> {
    const requestBody = this.clearInvalidUserValues(payload.payload);

    if (Object.keys(requestBody).includes('market')) {
      requestBody.market = Object.keys(requestBody.market)
        .map((m) => (requestBody.market[m] ? m : false))
        .filter((m) => m);
    }

    if (Object.keys(requestBody).includes('tags')) {
      requestBody.tags =
        typeof requestBody.tags === 'string' ? null : requestBody.tags;
    }
    return this.http
      .put<ProjectModel>(`${this.apiHost}/${payload.projectId}/`, requestBody)
      .pipe(map((project) => this.mapUserToInput(project)));
  }

  public update(payload, projectId): Observable<ProjectModel> {
    const requestBody = this.clearInvalidUserValues(payload);

    if (Object.keys(requestBody).includes('market')) {
      requestBody.market = Object.keys(requestBody.market)
        .map((m) => (requestBody.market[m] ? m : false))
        .filter((m) => m);
    }

    if (Object.keys(requestBody).includes('tags')) {
      requestBody.tags =
        typeof requestBody.tags === 'string' ? null : requestBody.tags;
    }

    return this.http
      .put<ProjectModel>(`${this.apiHost}/${projectId}/`, requestBody)
      .pipe(
        map((project) => {
          project.html_description = project.html_description || '';
          project.htmlDescriptionTrimmed = project.html_description.slice(
            0,
            1000
          );
          return this.mapUserToInput(project);
        })
      );
  }

  public setProjectReadyToApprove(
    payload: ProjectPhaseUpdatePayload
  ): Observable<ProjectModel> {
    return this.http
      .put<ProjectModel>(`${this.apiHost}/${payload.projectId}/ready/`, null)
      .pipe(map((project) => this.mapUserToInput(project)));
  }

  public approveGate(
    payload: ProjectPhaseUpdatePayload
  ): Observable<ProjectModel> {
    return this.http
      .put<ProjectModel>(
        `${this.apiHost}/${payload.projectId}/gates/${payload.gate}/`,
        { approve: true }
      )
      .pipe(map((project) => this.mapUserToInput(project)));
  }

  public rejectGate(
    payload: ProjectPhaseUpdatePayload
  ): Observable<ProjectModel> {
    payload.gate = payload.gate
      .slice(6)
      .replace(/^\w/, (chr) => chr.toLocaleLowerCase());

    return this.http
      .put<ProjectModel>(
        `${this.apiHost}/${payload.projectId}/gates/${payload.gate}/`,
        { reject: true }
      )
      .pipe(map((project) => this.mapUserToInput(project)));
  }

  public rejectPhase(
    payload: ProjectPhaseUpdatePayload
  ): Observable<ProjectModel> {
    return this.http
      .put<ProjectModel>(`${this.apiHost}/${payload.projectId}/phase/`, {
        reject: true,
      })
      .pipe(map((project) => this.mapUserToInput(project)));
  }

  public cancelProject(
    payload: ProjectPhaseUpdatePayload
  ): Observable<ProjectModel> {
    return this.http
      .put<ProjectModel>(`${this.apiHost}/${payload.projectId}/cancel/`, null)
      .pipe(map((project) => this.mapUserToInput(project)));
  }

  public approvePhase(
    payload: ProjectPhaseUpdatePayload
  ): Observable<ProjectModel> {
    return this.http
      .put<ProjectModel>(`${this.apiHost}/${payload.projectId}/phase/`, {
        approve: true,
      })
      .pipe(map((project) => this.mapUserToInput(project)));
  }

  public deleteProject(
    payload: ProjectPhaseUpdatePayload
  ): Observable<ProjectModel> {
    return this.http.delete<ProjectModel>(
      `${this.apiHost}/${payload.projectId}/`
    );
  }

  public findProjectById(projectId: string): Observable<ProjectView> {
    return this.http.get<ProjectView>(`${this.apiHost}/${projectId}/view`);
  }

  private clearInvalidUserValues(body: any) {
    const requestBody = JSON.parse(JSON.stringify(body));

    this.userFields.forEach((field) => {
      if (
        typeof requestBody[field.name] === 'string' &&
        requestBody[field.name] === ''
      ) {
        requestBody[field.name] = null;
      }
    });

    return requestBody;
  }

  private mapUserToInput(project: any) {
    const response = JSON.parse(JSON.stringify(project));

    this.userFields.forEach((field) => {
      if (field.name === 'stakeholders' && !!response[field.name]) {
        const stakeholders: ProjectStakeholder[] = response[field.name];
        stakeholders.forEach((stakeholder) => {
          if (stakeholder.userInfo != null) {
            stakeholder.userInfo.text = stakeholder.userInfo.name;
          }
          // stakeholder.division.forEach(division => {
          //   division.text = division.item;
          // });
        });
      } else if (field.name === 'risks' && !!response[field.name]) {
        const risks: ProjectRisk[] = response[field.name];

        risks.forEach((risk) => {
          risk.owner.text = risk.owner.name;
        });
      } else {
        if (typeof response.length === 'number') {
          response.forEach((item) => {
            if (item && item[field.name]) {
              item[field.name] = ProjectService.mapSingleUserToInput(
                item[field.name],
                field
              );
            }
          });
        } else {
          if (response && response[field.name]) {
            response[field.name] = ProjectService.mapSingleUserToInput(
              response[field.name],
              field
            );
          }
        }
      }
    });
    return response;
  }

  private findProjectByName(search: string): Observable<ProjectSearchItem[]> {
    return this.http
      .get<ProjectModel[]>(`${this.apiHost}/`, { params: { search } })
      .pipe(
        map((response) => {
          return response.map((project) => {
            return {
              name: project.name,
              text: project.name,
              value: project.name,
              id: project.id,
            };
          });
        })
      );
  }

  public updateProjectVisibility(
    projectId: string,
    state: string
  ): Observable<ProjectModel> {
    return this.http.put<ProjectModel>(
      `${this.apiHost}/${projectId}/visibility/${state}`,
      {}
    );
  }

  public reopenProject(
    payload: ProjectPhaseUpdatePayload
  ): Observable<ProjectModel> {
    return this.http.put<ProjectModel>(
      `${this.apiHost}/${payload.projectId}/reopen/`,
      {}
    );
  }
}
