<div exosSheetSection>
  <div exosSheetBlock>
    <h3 exosHeadline type="sub">
      {{ config.data.title }}
    </h3>

    <ng-template libFormContainer> </ng-template>

    <div
      *ngIf="missingParentIds?.length > 0"
      class="mt-2 input-byline--error"
      [innerHtml]="
        config.data.warning.replace('{ids}', missingParentIds.join(', '))
      "
    ></div>

    <table
      *ngIf="ganttData !== undefined && ganttData.data.length > 0"
      class="table table--responsive"
      style="margin-top: 50px"
    >
      <colgroup>
        <col width="20%" />
      </colgroup>
      <thead>
        <tr class="table__header">
          <th class="table__cell">Text</th>
          <th class="table__cell">Start date</th>
          <th class="table__cell">End date</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let task of ganttData.data" class="table__row">
          <td class="table__cell">{{ task.text }}</td>
          <td class="table__cell">
            {{ task.start_date | date: 'EEEE, MMMM d, y' }}
          </td>
          <td class="table__cell">
            {{ task.end_date | date: 'EEEE, MMMM d, y' }}
          </td>
        </tr>
      </tbody>
    </table>

    <ng-container
      *ngIf="
        isImportAction && ganttData !== undefined && ganttData.data.length === 0
      "
    >
      <section class="sheet__section sheet__section--activating mt-2">
        <div class="sheet__block">
          <span class="headline headline--sub headline--activating"
            >No issues found.</span
          >
        </div>
      </section>
    </ng-container>
  </div>
</div>
