import { Directive, Input } from '@angular/core';
import { ExosBaseSemanticType } from '../../exos.model';

@Directive({
  selector: '[exos-sheet], [exosSheet]',
  host: {
    class: 'sheet',
  },
})
export class SheetDirective {}

export type SheetSectionType =
  | 'secondary'
  | 'active'
  | 'emphasized'
  | 'beta'
  | 'visualized';

@Directive({
  selector: '[exos-sheet-section], [exosSheetSection]',
  host: {
    class: 'sheet__section',
    '[class.sheet__section--secondary]': "type === 'secondary'",
    '[class.sheet__section--active]': "type === 'active'",
    '[class.sheet__section--emphasized]': "type === 'emphasized'",
    '[class.sheet__section--advertsing]': "type === 'advertsing'",
    '[class.sheet__section--beta]': "type === 'beta'",
    '[class.sheet__section--visualized]': "type === 'visualized'",
    // semantic
    '[class.sheet__section--activating]': "semantic === 'activating'",
    '[class.sheet__section--success]': "semantic === 'success'",
    '[class.sheet__section--warning]': "semantic === 'warning'",
    '[class.sheet__section--critical]': "semantic === 'critical'",
    '[class.sheet__section--neutral]': "semantic === 'neutral'",
    // semantic bar
    '[class.sheet__section--semantic-bar-activating]':
      "semanticBar === 'activating'",
    '[class.sheet__section--semantic-bar-success]': "semanticBar === 'success'",
    '[class.sheet__section--semantic-bar-warning]': "semanticBar === 'warning'",
    '[class.sheet__section--semantic-bar-critical]':
      "semanticBar === 'critical'",
    '[class.sheet__section--semantic-bar-neutral]': "semanticBar === 'neutral'",
  },
})
export class SheetSectionDirective {
  @Input() type: SheetSectionType;
  @Input() semantic: ExosBaseSemanticType;
  @Input() semanticBar: ExosBaseSemanticType;
}

@Directive({
  selector: '[exos-sheet-block], [exosSheetBlock]',
  host: {
    class: 'sheet__block',
  },
})
export class SheetBlockDirective {}

@Directive({
  selector: '[exos-sheet-footer], [exosSheetFooter]',
  host: {
    class: 'sheet__footer',
  },
})
export class SheetFooterDirective {}

@Directive({
  selector: '[exos-sheet-visual], [exosSheetVisual]',
  host: {
    class: 'sheet__visual',
  },
})
export class SheetVisualDirective {}

@Directive({
  selector: '[exos-sheet-close], [exosSheetClose]',
  host: {
    class: 'sheet__close',
  },
})
export class SheetCloseDirective {}

@Directive({
  selector: '[exos-sheet-separator], [exosSheetSeparator]',
  host: {
    class: 'sheet__separator',
  },
})
export class SheetSeparatorDirective {}
