<section exosSheetSection>
  <div exosSheetBlock>
    <p exosHeadline type="sub" semantic="activating">Details</p>
    <div class="editable-table">
      <dash-table
        [dataSource]="config.data.table.items"
        [cols]="config.data.table.cols"
        [noDataMessage]="config.data.table.noResultsFoundMsg"
      >
        <dash-table-column id="value">
          <div *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </div>
          <div
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </div>
        </dash-table-column>
      </dash-table>
    </div>
    <footer exosSheetFooter>
      <button (click)="onClose($event)" exosButton type="button">Close</button>
    </footer>
  </div>
</section>
