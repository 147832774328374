import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const projectObjectiveValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const objective = control.get('objectiveId');
  const keyResult = control.get('keyResultId');

  return objective.value && !keyResult.value
  ? {
      keyResultId:
        'In order to add an objective you must also select a key result.',
    }
  : null;
};
