import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';
import { StatusType } from '../../models/status';
import { LOCALE_STATUS } from '../../locale/status';

const projectUpdateStatusFormConfig: FormConfig = {
  fields: {
    value: {
      name: 'value',
      label: 'Status',
      type: 'select',
      options: [
        {
          value: StatusType[StatusType.NOT_STARTED],
          text: LOCALE_STATUS[
            `label_status_value_${StatusType.NOT_STARTED.toLowerCase()}`
          ],
        },
        {
          value: StatusType[StatusType.ON_HOLD],
          text: LOCALE_STATUS[
            `label_status_value_${StatusType.ON_HOLD.toLowerCase()}`
          ],
        },
        {
          value: StatusType[StatusType.ON_TRACK],
          text: LOCALE_STATUS[
            `label_status_value_${StatusType.ON_TRACK.toLowerCase()}`
          ],
        },
        {
          value: StatusType[StatusType.POTENTIAL_RISKS],
          text: LOCALE_STATUS[
            `label_status_value_${StatusType.POTENTIAL_RISKS.toLowerCase()}`
          ],
        },
        {
          value: StatusType[StatusType.RISK_OR_ROADBLOCK],
          text: LOCALE_STATUS[
            `label_status_value_${StatusType.RISK_OR_ROADBLOCK.toLowerCase()}`
          ],
        },
        {
          value: StatusType[StatusType.CANCELED],
          text: LOCALE_STATUS[
            `label_status_value_${StatusType.CANCELED.toLowerCase()}`
          ],
        },
        {
          value: StatusType[StatusType.CLOSED],
          text: LOCALE_STATUS[
            `label_status_value_${StatusType.CLOSED.toLowerCase()}`
          ],
        },
      ],
      fxFlex: '100',
      value: null,
    },
    comment: {
      name: 'comment',
      label: 'Comment',
      type: 'textarea',
      fxFlex: '100',
      resizable: 'vertical',
      rows: '5',
      value: null,
    },
  },

  buttons: [
    {
      label: 'Save',
      type: 'button',
      action: 'save',
      hasLoader: true,
      isPrimary: true,
    },
    {
      label: 'Close',
      type: 'button',
      action: 'cancel',
      hasLoader: false,
    },
  ],
};

export const projectUpdateStatusModalConfig: ExosDialogConfig = {
  data: {
    formConfig: projectUpdateStatusFormConfig,
  },
};
