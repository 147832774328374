<div exosSheet *ngIf="fetchError$ | async">
  <div exosSheetSection semantic="warning">
    <div exosSheetBlock>
      <h2 exosHeadline type="sub" semantic="warning">Something went wrong</h2>

      <p exosParagraph>We couldn't fetch yout Gantt Data. Please try again.</p>
    </div>
  </div>
</div>

<ng-container *ngIf="ganttData$ | async as ganttData">
  <dhtmlx-gantt [datasource]="ganttData"></dhtmlx-gantt>
</ng-container>
