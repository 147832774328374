<div [formGroup]="group">
  <exos-label
    *ngIf="label"
    [name]="name"
    [text]="label"
    [infoPointText]="infoPoint"
  ></exos-label>

  <select
    class="input-select"
    [class.input-text-group--disabled]="group.get(name).disabled || disabled"
    [class.input-text-group--error]="group.get(name).invalid"
    [id]="name"
    [name]="name"
    [formControlName]="name"
    [attr.disabled]="group.get(name).disabled || disabled ? 'disabled' : null"
  >
    <option *ngIf="placeholder" [ngValue]="null">
      {{ placeholder }}
    </option>

    <ng-container *ngIf="options?.length">
      <ng-container *ngFor="let option of options">
        <option
          [ngValue]="option"
          [selected]="compareFn(option, group.get(name).value)"
          [disabled]="option.disabled"
          [ngClass]="
            option?.isCompany
              ? option.disabled
                ? 'company-disabled'
                : 'company'
              : ''
          "
        >
          {{ displayFn ? displayFn(option) : option }}
        </option>
      </ng-container>
    </ng-container>
  </select>

  <exos-error [group]="group" [name]="name"></exos-error>
</div>
