import { StatusItemImpl } from './status';
import { ProgramStatusItem } from './program-status';

export class ProjectStatusItem extends StatusItemImpl {}

export interface ProjectStatus {
  budget: ProgramStatusItem;
  overall: ProgramStatusItem;
  resources: ProgramStatusItem;
  schedule: ProgramStatusItem;
  scope: ProgramStatusItem;
}
