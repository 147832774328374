<div exosSheet>
  <div exosSheetSection>
    <div exosSheetBlock>
      <div class="mb-2" fxLayout="row" fxLayoutAlign="space-between center">
        <div
          fxLayout="row wrap"
          fxLayoutAlign="start center"
          fxLayoutGap="16px grid"
        >
          <div>Zoom level:</div>

          <div>
            <exos-select
              name="zoom"
              [group]="zoomForm"
              [options]="zoomLevelItems"
            >
            </exos-select>
          </div>

          <div>
            <button
              exosButton
              [isDisabled]="
                (zoomForm.controls.zoom.valueChanges | async) === 'day'
              "
              (click)="zoomIn()"
            >
              Zoom In
            </button>
          </div>

          <div>
            <button
              exosButton
              [isDisabled]="
                (zoomForm.controls.zoom.valueChanges | async) === 'year'
              "
              (click)="zoomOut()"
            >
              Zoom Out
            </button>
          </div>
        </div>

        <div>
          <mat-slide-toggle [formControl]="criticalPathToggle">
            Show critical path
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>
</div>

<div #gantt_container class="gantt-chart mb-2"></div>
