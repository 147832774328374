import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'exos-checkbox',
  templateUrl: './exos-checkbox.component.html',
  styleUrls: ['./exos-checkbox.component.scss'],
})
export class ExosCheckboxComponent implements OnInit {
  @Input()
  public group: FormGroup;

  @Input()
  public name: string;

  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public type: string;

  @Input()
  public icon: string;

  @Output()
  update: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  onToggle() {
    this.update.emit(this.group.value.show_on_roadmap);
  }
}
