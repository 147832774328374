import { DashTableColumnDefinition } from '@dashboard/table';
import { formatDate } from '@angular/common';

export const projectedLaunchDateTableCols: DashTableColumnDefinition[] = [
  {
    id: 'date_parsed',
    name: 'Date',
    sortable: false,
    value: (id, row, index) => {
      return formatDate(row.date, 'EEEE, MMMM d, y', 'en-US');
    },
    display: true,
  },
];
