import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ExosButtonConfig } from '../../../shared/models/card-form-config';

@Component({
  selector: 'project-actions',
  templateUrl: './project-actions.component.html',
  styleUrls: ['./project-actions.component.scss'],
})
export class ProjectActionsComponent implements OnInit {
  @Input() projectActionsButtons$: Observable<ExosButtonConfig[]>;

  @Output() update: EventEmitter<string> = new EventEmitter<string>();

  @Input() updateInProgress$: Observable<boolean>;

  constructor() {}

  ngOnInit(): void {}

  public onUpdate(action: string) {
    this.update.emit(action);
  }
}
