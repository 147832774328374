<div exosSheet>
  <div exosSheetSection type="secondary">
    <div exosSheetBlock>
      <h3 exosHeadline type="sub">
        {{ config.heading }}
      </h3>
    </div>
  </div>

  <div exosSheetSection>
    <div exosSheetBlock>
      <ng-template libFormContainer> </ng-template>
    </div>
  </div>
</div>
