import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';

const dependencyFormConfig: FormConfig = {
  action: 'dependencies',

  fields: {
    name: {
      name: 'name',
      label: 'Name',
      placeholder: 'Name',
      type: 'input',
      fxFlex: '100',
      value: null,
    },
    status: {
      name: 'status',
      label: 'Status',
      type: 'select',
      options: [
        { text: 'Not selected', value: '' },
        { text: 'OPEN', value: 'OPEN' },
        { text: 'CLOSED', value: 'CLOSED' },
      ],
      fxFlex: '100',
      value: null,
    },
    description: {
      name: 'description',
      label: 'Description',
      placeholder: 'description',
      type: 'textarea',
      rows: '7',
      fxFlex: '100',
      resizable: 'vertical',
      value: null,
    },
  },

  buttons: [
    {
      label: 'Save',
      type: 'button',
      action: 'submit',
      hasLoader: true,
      isPrimary: true,
    },
    {
      label: 'Close',
      type: 'button',
      action: 'cancel',
      hasLoader: false,
    },
  ],
};

export const adddependencyDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Add new dependency',
    formConfig: dependencyFormConfig,
  },
};

export const editdependencyDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Edit dependency',
    formConfig: dependencyFormConfig,
  },
};
