export const customEnvVars: {[key: string]: string} = {
  stage: 'production',
  environment__services__sentinel__base_url: "https://dashboard.platform.ionos.org/gph--service-sentinel",
  environment__services__discovery__base_url: "https://dashboard.platform.ionos.org/gph--service-discovery",
  environment__services__audit__base_url: "DASHBOARD_PLACEHOLDER__SERVICE_AUDIT_BASEURL",
  environment__services__ldap__base_url: "https://dashboard.platform.ionos.org/gph--service-ldap",
  environment__services__auth__base_url: "https://dashboard.platform.ionos.org/gph--service-auth",
  environment__services__pmt__base_url: "https://dashboard.platform.ionos.org/gph--service-pmt",
  environment__services__okr__base_url: "https://dashboard.platform.ionos.org/gph--service-okr",
  environment__services__preferences__base_url: "https://dashboard.platform.ionos.org/gph--service-preferences",
  environment__services__roadmap__base_url: "https://dashboard.platform.ionos.org/gph--service-roadmap",
};
