import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export const projectRoadmapValidator: ValidatorFn = (
  control: AbstractControl
): ValidationErrors | null => {
  const productSpace = control.get('productSpaceId');
  const product = control.get('productId');
  const category = control.get('categoryId');

  return productSpace?.value && (!product?.value || !category?.value)
    ? {
        productId: !product?.value
        ? 'Please select a product for your product space.'
        : null,
        categoryId: !category?.value
          ? 'Please select a category for your product.'
          : null,
      }
    : null;
};
