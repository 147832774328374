import { InjectorService, MenuType, UserService } from '@dashboard/core';
import { of } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { UserFilterApiService } from './shared/service/user-filters/user-filters-api.service';
import { UserFilter } from './shared/service/user-filters/user-filters.service';

const userFilters =
    (JSON.parse(localStorage.getItem('userFilters')) as UserFilter) || {},
  localMenu = {};
Object.keys(userFilters).forEach(
  (key) =>
    (localMenu[
      userFilters[key].title
    ] = `listing/my-filter/${key}?page=1&size=25`)
);

// tslint:disable-next-line:object-literal-key-quotes
export const menuData: MenuType = {
  menu: (auth: any, http: any) => {
    const userService: UserService = InjectorService.inject(UserService);
    const userFilterApiService: UserFilterApiService =
      InjectorService.inject(UserFilterApiService);

    let baseRoutes = {
      '': {
        Home: 'news',
      },
      'Projects & Programs': {
        permissions: {
          tenant: ['oneandone', 'arsys', 'fasthosts'],
        },
        'Projects & Programs': '/listing/all?page=1&size=25',
      },
      Filters: {
        permissions: {
          tenant: ['oneandone', 'arsys', 'fasthosts'],
        },
        'My Projects': '/listing/my-projects?page=1&size=25',
        'My Programs': '/listing/my-programs?page=1&size=25',
        'My Approvals': '/listing/my-approvals?page=1&size=25',
      },
      Help: {
        permissions: {
          tenant: ['oneandone', 'arsys', 'fasthosts'],
        },
        Manual: 'manual',
        CoE: 'coe',
        About: 'home',
        'User Roles': 'roles',
      },
    };

    return userFilterApiService
      .getFiltersForUser(userService.getSession().user_id)
      .pipe(
        mergeMap((filters: UserFilter[]) => {
          filters
            .sort((a, b) => parseInt(a.id) - parseInt(b.id))
            .forEach(
              (filter) =>
                (baseRoutes['Filters'][
                  filter.title
                ] = `listing/my-filter/${filter.id}?page=1&size=25`)
            );
          return of(baseRoutes);
        }),
        catchError(() => {
          return of(baseRoutes);
        })
      );
  },
  app_name: 'pmt',
  app_code: 'pmt',
  icon: 'supervisor_account',
  home_route: {
    url: 'listing/all',
    params: {},
  },
};
