import { Component, OnInit } from '@angular/core';
import { ProjectModel } from '../../../shared/models/project.model';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck, tap } from 'rxjs/operators';
import { ProjectStepperService } from '../../../shared/service/project-stepper-service/project-stepper.service';
import { ProjectNameProviderService } from '../../../shared/service/project-name-provider/project-name-provider.service';
import { StepperItem } from '../../../shared/components/project-stepper/stepper-item';
import { TABS } from '../../config/tabs.config';

@Component({
  selector: 'app-project',
  templateUrl: './project-route.component.html',
  styleUrls: ['./project-route.component.scss'],
})
export class ProjectRouteComponent implements OnInit {
  public tabs = {};

  public project$: Observable<ProjectModel>;
  public projectStepperConfig$: Observable<StepperItem[]>;
  public routeIsLoading$: Observable<boolean>;
  public projectName$: Observable<string>;

  constructor(
    private route: ActivatedRoute,
    private projectStepperService: ProjectStepperService,
    private projectNameProviderService: ProjectNameProviderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.projectStepperConfig$ = this.projectStepperService.currentProjectSteps;

    this.routeIsLoading$ = this.router.events.pipe(
      distinctUntilChanged(),
      filter((ev: RouterEvent) => {
        return (
          ev instanceof NavigationStart ||
          ev instanceof NavigationEnd ||
          ev instanceof NavigationCancel ||
          ev instanceof NavigationError
        );
      }),
      map((ev: RouterEvent) => ev instanceof NavigationStart)
    );

    this.projectName$ = this.projectNameProviderService.projectName$;

    this.project$ = this.route.data.pipe(
      pluck('project'),
      tap((project) => {
        this.tabs = TABS[project.workflow];
        this.projectNameProviderService.update(project.name);
        this.projectStepperService.update(project);
      })
    );
  }

  public async backToLastFilter() {
    await this.router.navigateByUrl(localStorage.getItem('lastVisitedFilter'));
  }
}
