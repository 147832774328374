<header exosPageHeader>
  <div exosPageHeaderBlock>
    <a (click)="backToLastFilter()" exosPageHeaderBackButton></a>

    <h1 exosPageHeaderHeadline>
      {{ programName$ | async }}
    </h1>
  </div>
</header>

<dash-tabs [tabs]="tabs"> </dash-tabs>

<div exosPageSection>
  <div exosPageSectionBlock>
    <ng-template #loader>
      <dash-loader [hasContainer]="true"></dash-loader>
    </ng-template>

    <router-outlet
      *ngIf="!(routeIsLoading$ | async); else loader"
    ></router-outlet>
  </div>
</div>
