import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectNameProviderService {
  private projectName: BehaviorSubject<string> = new BehaviorSubject<string>(
    ''
  );
  public projectName$: Observable<string> = this.projectName.asObservable();

  constructor() {}

  public update(name: string) {
    this.projectName.next(name);
  }
}
