<div exosSheet>
  <div exosSheetSection type="secondary">
    <div exosSheetBlock>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 exosHeadline type="sub" class="mb-0">Project review file</h2>

        <ng-container *ngIf="fileId">
          <a
            exosButton
            semantic="primary"
            target="_blank"
            href="https://docs.google.com/document/d/{{ fileId }}/edit"
          >
            View File
          </a>
        </ng-container>

        <ng-container *ngIf="!fileId && canGenerateFile">
          <button
            exosButton
            semantic="primary"
            [withLoader]="true"
            [isDisabled]="disableGenerateButton"
            [disabled]="disableGenerateButton"
            (click)="generateReviewFile(project.id)"
          >
            Generate Review File
          </button>
        </ng-container>
      </div>
    </div>
  </div>

  <dash-sheet>
    <ul sheetStripes>
      <ng-container *ngFor="let section of reviewFileSections">
        <li
          actionStripe
          [directSelection]="true"
          (click)="openFileInNewTab(fileId, section.headingId)"
        >
          <div settingsStripeLabel>
            <strong>{{ section.heading }}</strong>
          </div>
          <div settingsStripeValue [innerHtml]="section.description"></div>
          <div settingsStripeAction>
            <span
              *ngIf="fileId"
              exosLink
              visual="next"
              [isStripeTarget]="true"
            ></span>
          </div>
        </li>
      </ng-container>
    </ul>
  </dash-sheet>
</div>
