import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';

export const productNewsUpdateFormConfig: FormConfig = {
  fields: {
    html_product_news: {
      name: 'html_product_news',
      type: 'wysiwig',
      fxFlex: '100',
      styles: { height: '500px' },
      value: null,
      buttons: [],
    },
  },

  buttons: [
    {
      label: 'Save',
      type: 'button',
      action: 'UPDATE',
      hasLoader: true,
      isPrimary: true,
    },
    {
      label: 'Close',
      type: 'button',
      action: 'CLOSE',
      hasLoader: false,
    },
  ],
};

export const productNewsUpdateModalConfig: ExosDialogConfig = {
  data: {
    title: 'Edit product news',
    formConfig: productNewsUpdateFormConfig,
    size: 'lg',
  },
};
