export const requirementTableCols = [
  { id: 'name', name: 'Name', sortable: true, value: 'name', display: true },
  {
    id: 'Priority',
    name: 'Priority',
    sortable: true,
    value: 'priority',
    display: true,
  },
  {
    id: 'description',
    name: 'Description',
    sortable: true,
    value: 'description',
    display: true,
  },
];
