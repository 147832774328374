import { Component, OnInit } from '@angular/core';
import { ExosDialogRef, ExosDialogConfig } from '@dashboard/exos-dialog';

@Component({
  selector: 'app-project-history-details',
  templateUrl: './project-history-details-dialog.component.html',
  styleUrls: ['./project-history-details-dialog.component.scss'],
})
export class ProjectHistoryDetailsDialogComponent implements OnInit {
  constructor(public config: ExosDialogConfig, public dialog: ExosDialogRef) {}

  ngOnInit() {}

  onClose(event: Event) {
    event.stopPropagation();
    this.dialog.close();
  }
}
