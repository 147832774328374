import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ApiService } from '@dashboard/core';
import { Observable } from 'rxjs';
import {
  ProjectDocumentFileInfo,
  ProjectGoogleFile,
} from '../../models/google-doc.model';
import { ProjectModel } from '../../models/project.model';
import { map } from 'rxjs/operators';
import { User } from '../../models/user';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  private readonly apiHost: string = `${this.apiService.getBaseUrl(
    'pmt'
  )}/projects`;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  getProjectPlanSections(
    projectId: number
  ): Observable<ProjectDocumentFileInfo[]> {
    return this.http
      .get<ProjectDocumentFileInfo[]>(
        `${this.apiHost}/${projectId}/plan-documents/`
      )
      .pipe(
        map((response) =>
          response.sort((a, b) => (a.custom && a.fileId > b.fileId ? -1 : 0))
        )
      );
  }

  public getProjectFolderId(
    projectId: number
  ): Observable<ProjectDocumentFileInfo> {
    return this.http.get<ProjectDocumentFileInfo>(
      `${this.apiHost}/${projectId}/folderId/`
    );
  }

  getFileByName(
    projectId: string,
    documentName: string
  ): Observable<ProjectGoogleFile[]> {
    const params: HttpParams = new HttpParams().set(
      'documentName',
      documentName
    );

    return this.http.get<ProjectGoogleFile[]>(
      `${this.apiHost}/${projectId}/documents/`,
      { params }
    );
  }

  generateReviewFile(projectId: number): Observable<ProjectGoogleFile> {
    return this.http.post<ProjectGoogleFile>(
      `${this.apiHost}/${projectId}/generate-review-file/`,
      {}
    );
  }

  generateProjectBriefFile(
    projectId: number
  ): Observable<ProjectDocumentFileInfo> {
    return this.http.post<ProjectDocumentFileInfo>(
      `${this.apiHost}/${projectId}/generate-project-brief-file/`,
      {}
    );
  }

  generatePlanFile(
    projectId: number,
    projectPlanFileId: number
  ): Observable<ProjectGoogleFile> {
    return this.http.post<ProjectGoogleFile>(
      `${this.apiHost}/${projectId}/generate-plan-file/${projectPlanFileId}/`,
      {}
    );
  }

  saveResponsible(projectId: number, projectPlanFileId: string, body: User) {
    const documentResponsible = !body ? {} : body;
    return this.http.post(
      `${this.apiHost}/${projectId}/responsible-plan-file/${projectPlanFileId}`,
      documentResponsible
    );
  }

  getProjectDocumentFileInfo(
    folderId: string,
    documentName: string
  ): Observable<ProjectDocumentFileInfo> {
    return this.http.get<ProjectDocumentFileInfo>(
      `${this.apiHost}/project-document-file-info/${folderId}/${documentName}/`
    );
  }

  fetchDocumentsById(projectId: number): Observable<ProjectGoogleFile[]> {
    return this.http.get<ProjectGoogleFile[]>(
      `${this.apiHost}/${projectId}/documents/`
    );
  }

  addNewFunctionalPlanStream(
    projectId: number,
    body: object
  ): Observable<ProjectModel> {
    return this.http.post<ProjectModel>(
      `${this.apiHost}/${projectId}/functional-plan-file/`,
      body
    );
  }

  deleteFunctionalPlanStream(
    projectId: number,
    fileId: number
  ): Observable<any> {
    return this.http.delete<any>(
      `${this.apiHost}/${projectId}/functional-plan-file/${fileId}/`
    );
  }

  addNewUnitedInternetPlanFile(
    projectId: number,
    body: object
  ): Observable<ProjectModel> {
    return this.http.post<ProjectModel>(
      `${this.apiHost}/${projectId}/united-internet/functional-plan-file/`,
      body
    );
  }
}
