import { StatusItemImpl } from './status';

export class ProgramStatusItem extends StatusItemImpl {}

export interface ProgramStatus {
  budget: ProgramStatusItem;
  overall: ProgramStatusItem;
  resources: ProgramStatusItem;
  schedule: ProgramStatusItem;
  scope: ProgramStatusItem;
}
