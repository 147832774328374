export const riskTableCols = [
  {
    id: 'description',
    name: 'Description',
    sortable: true,
    value: 'description',
    display: true,
  },
  {
    id: 'impact',
    name: 'Impact',
    sortable: true,
    value: 'impact',
    display: true,
  },
  {
    id: 'probability',
    name: 'Probability',
    sortable: true,
    value: 'probability',
    display: true,
  },
  {
    id: 'responsePlan',
    name: 'Response Plan',
    sortable: true,
    value: 'responsePlan',
    display: true,
  },
  {
    id: 'owner',
    name: 'Owner',
    sortable: true,
    value: (id, row) => {
      return row.owner.name;
    },
    display: true,
  },
  {
    id: 'status',
    name: 'Status',
    sortable: true,
    value: 'status',
    display: true,
  },
];
