import { Component } from '@angular/core';
import { ExosFormComponent } from '@dashboard/exos-form';

@Component({
  selector: 'app-custom-create-exos-form',
  templateUrl: './custom-create-exos-form.component.html',
  styleUrls: ['./custom-create-exos-form.component.scss'],
})
export class CustomCreateExosFormComponent extends ExosFormComponent {
  constructor() {
    super();
  }
}
