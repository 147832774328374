import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Program, ProgramCreateReqBody } from '../../models/program.model';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@dashboard/core';
import { map } from 'rxjs/operators';
import { StatusItem, StatusNameType } from '../../models/status';
import { ProgramStatus } from '../../models/program-status';
import { DocumentLink } from '../../models/project-link.model';

@Injectable({
  providedIn: 'root',
})
export class ProgramService {
  private readonly apiBaseUrl = `${this.apiService.getBaseUrl('pmt')}/programs`;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  public findByName(search: string): Observable<Program[]> {
    return this.http
      .get<Program[]>(`${this.apiBaseUrl}/`, { params: { search } })
      .pipe(
        map((response: Program[]) =>
          response.map((program) => {
            return {
              ...program,
              text: program.name,
              value: program.name,
            };
          })
        )
      );
  }

  public findProgramById(programId: string): Observable<Program> {
    return this.http.get<Program>(`${this.apiBaseUrl}/${programId}/`);
  }

  public createProgram(payload: ProgramCreateReqBody): Observable<Program> {
    return this.http.post<Program>(`${this.apiBaseUrl}/`, payload);
  }

  public updateProgramById(
    programId: string,
    payload: Program
  ): Observable<Program> {
    return this.http.put<Program>(`${this.apiBaseUrl}/${programId}/`, payload);
  }

  public deleteProgramById(programId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiBaseUrl}/${programId}/`);
  }

  public updateProgramStatusById(
    programId: string,
    statusType: StatusNameType,
    payload: StatusItem
  ): Observable<ProgramStatus> {
    return this.http.put<ProgramStatus>(
      `${this.apiBaseUrl}/${programId}/status/${statusType}/`,
      payload
    );
  }

  public addDocumentProgramLink(
    programId: string,
    payload: DocumentLink
  ): Observable<Program> {
    return this.http.post<Program>(
      `${this.apiBaseUrl}/${programId}/links/`,
      payload
    );
  }

  public editDocumentProgramLink(
    programId: string,
    documentLinkId: string,
    payload: DocumentLink
  ): Observable<Program> {
    return this.http.put<Program>(
      `${this.apiBaseUrl}/${programId}/links/${documentLinkId}/`,
      payload
    );
  }

  public deleteDocumentProgramLinkById(
    programId: string,
    linkId: string
  ): Observable<Program> {
    return this.http.delete<Program>(
      `${this.apiBaseUrl}/${programId}/links/${linkId}/`
    );
  }
}
