import { Injectable } from '@angular/core';
import {
  ProjectPhaseUpdatePayload,
  ProjectService,
} from '../../../../shared/service/project/project.service';
import { ProjectStepperService } from '../../../../shared/service/project-stepper-service/project-stepper.service';
import { FormEvent } from '@dashboard/exos-form';
import { ProjectModel } from '../../../../shared/models/project.model';
import { filter, tap } from 'rxjs/operators';
import { ExosFormButtonsService } from '../../../../shared/service/exos-form-buttons/exos-form-buttons.service';
import { DashNotificationService } from '@dashboard/core';
import { Observable, Subject } from 'rxjs';
import { DashDialogService } from '@dashboard/exos-dialog';
import { ConfirmationComponent } from '../../../../shared/components/dialogs/confirmation/confirmation.component';
import { ProjectNameProviderService } from '../../../../shared/service/project-name-provider/project-name-provider.service';
import { Router } from '@angular/router';
import { ProjectStatusService } from 'src/app/shared/service/project-status/project-status.service';

export interface ProjectOverviewEvent {
  projectId: number;
  event: FormEvent;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectOverviewService {
  private eventMap: any = {
    submit: {
      action: 'save',
    },
    ready: { action: 'setProjectReadyToApprove' },
    divisionGateApproval: { action: 'approveGate' },
    vminusOneGateApproval: { action: 'approveGate' },
    review: { action: 'approvePhase' },
    close: { action: 'approvePhase' },
    clientGateApproval: { action: 'approveGate' },
    rejectDivisionGateApproval: {
      action: 'rejectGate',
      confirmation: {
        data: {
          title: 'Reject approval gate',
          message: 'Are you sure ?',
          text: 'Reject',
        },
      },
    },
    rejectVminusOneGateApproval: {
      action: 'rejectGate',
      confirmation: {
        data: {
          title: 'Reject approval gate',
          message: 'Are you sure ?',
          text: 'Reject',
        },
      },
    },
    rejectClientGateApproval: {
      action: 'rejectGate',
      confirmation: {
        data: {
          title: 'Reject approval gate',
          message: 'Are you sure ?',
          text: 'Reject',
        },
      },
    },

    rejectPlanAndExecute: {
      action: 'rejectPhase',
      confirmation: {
        data: {
          title: 'Back to Idea approval',
          message: 'Are you sure ?',
          text: 'Back',
        },
      },
    },
    rejectInitiate: {
      action: 'rejectPhase',
      confirmation: {
        data: {
          title: 'Back to Idea',
          message: 'Are you sure ?',
          text: 'Back',
        },
      },
    },
    rejectReview: {
      action: 'rejectPhase',
      confirmation: {
        data: {
          title: 'Back to Initiate approval',
          message: 'Are you sure ?',
          text: 'Back',
        },
      },
    },

    cancelProject: {
      action: 'cancelProject',
      confirmation: {
        data: {
          title: 'WARNING - PROJECT WILL BE CANCELED PERMANENTLY',
          message:
            'Are you sure you want to cancel this project? The project will be canceled permanently and may not be reopened at a ' +
            'later stage. If you want to put the project on hold, please do so by changing the overall projects status.',
          text: 'Cancel project',
          buttons: {
            submit: {
              label: 'Cancel project',
              semantic: 'critical',
            },
            cancel: {
              label: 'Keep project',
              semantic: 'primary',
            },
          },
        },
      },
    },
    deleteProject: {
      action: 'deleteProject',
      confirmation: {
        data: {
          title: 'Delete project',
          message: 'Are you sure ?',
          text: 'Delete',
        },
      },
    },
    rejectProgress: {
      action: 'rejectPhase',
      confirmation: {
        data: {
          title: 'Back to Idea',
          message: 'Are you sure ?',
          text: 'Back',
        },
      },
    },
    reopenProject: {
      action: 'reopenProject',
      confirmation: {
        data: {
          title: 'Re-open project',
          message: 'Are you sure ?',
          text: 'Back',
        },
      },
    },
  };

  private projectOverviewFormEvents: Subject<ProjectOverviewEvent> =
    new Subject();
  public currentEvent = this.projectOverviewFormEvents.asObservable();

  private projectOverviewFormErrors: Subject<any> = new Subject();
  public currentErrors = this.projectOverviewFormErrors.asObservable();

  private projectOverviewFormValue: Subject<ProjectModel> = new Subject();
  public currentFormValue = this.projectOverviewFormValue.asObservable();

  private _actionInProgress: Subject<boolean> = new Subject();
  public actionInProgress$: Observable<boolean> =
    this._actionInProgress.asObservable();

  constructor(
    private projectNameProviderService: ProjectNameProviderService,
    private projectService: ProjectService,
    private stepperService: ProjectStepperService,
    private buttonsService: ExosFormButtonsService,
    private notificationService: DashNotificationService,
    private dialogService: DashDialogService,
    private router: Router,
    private statusService: ProjectStatusService
  ) {
    this.currentEvent.pipe(filter((value) => !!value)).subscribe((event) => {
      this.handleEvent(event);
    });
  }

  public sendNextEvent(event: ProjectOverviewEvent) {
    this.projectOverviewFormEvents.next(event);
  }

  private handleEvent(event: ProjectOverviewEvent) {
    const currentActionConfig = this.eventMap[event.event.type];
    const currentAction = currentActionConfig.action;
    const params: ProjectPhaseUpdatePayload = {
      projectId: event.projectId,
      payload: event.event.rawValue,
      gate: event.event.type,
    };

    let dialog = null;

    if (
      'confirmation' in currentActionConfig &&
      'data' in currentActionConfig.confirmation
    ) {
      dialog = this.dialogService.open(
        ConfirmationComponent,
        currentActionConfig.confirmation
      );

      dialog.afterSubmit.subscribe(() => {
        this.handleAction(currentAction, params, dialog);
      });

      dialog.afterClosed.subscribe(() => {
        this._actionInProgress.next(false);
      });
    } else {
      this.handleAction(currentAction, params, null);
    }
  }

  private handleAction(action: string, params: any, dialog: any) {
    this._actionInProgress.next(true);

    this.projectService[action](params)
      .pipe(
        tap((updatedProject: ProjectModel) => {
          if (updatedProject) {
            this.projectOverviewFormValue.next(updatedProject);
            this.stepperService.update(updatedProject);
            this.buttonsService.update(updatedProject);
          }
          this._actionInProgress.next(false);
        })
      )
      .subscribe(
        (project: ProjectModel) => {
          if (dialog !== null) {
            dialog.close();
          }

          if (action === 'deleteProject') {
            this.notificationService.success(`Project has been deleted.`);
            this.router.navigate(['/listing/all']);
          } else if (
            project.phase === 'close' ||
            action === 'reopenProject' ||
            action === 'cancelProject'
          ) {
            window.location.reload();
          } else {
            this.notificationService.success('Project updated.');
            this.notifyLocalStorage();
            this.projectNameProviderService.update(project.name);
          }
        },
        (error) => {
          this.handleEventError(error);
          if (dialog !== null) {
            dialog.close();
          }
        }
      );
  }

  private notifyLocalStorage() {
    localStorage.setItem(
      'projectChanges',
      `${
        parseInt(
          localStorage.getItem('projectChanges')
            ? localStorage.getItem('projectChanges')
            : '0',
          10
        ) + 1
      }`
    );
  }

  private handleEventError(error: any) {
    this._actionInProgress.next(false);
    this.projectOverviewFormErrors.next(error);
  }
}
