<div exosSheetSection>
  <div exosSheetBlock>
    <div class="mb-3" fxLayout="row" fxLayoutAlign="space-between center">
      <h3 exosHeadline type="sub" class="mb-0">WORKFLOW CHEAT SHEET</h3>

      <button class="link static-overlay__close" (click)="close()">
        <i class="exos-icon exos-icon-closecard-24"></i>
      </button>
    </div>

    <p exosParagraph>
      <ng-container
        *ngFor="let item of contentText.workflowDefinition[0].bodyText"
      >
        {{ item }} <br />
      </ng-container>
    </p>

    <ol class="ordered-list">
      <ng-container
        *ngFor="let item of contentText.workflowDetails[0].bodyText"
      >
        <li>{{ item }} <br /></li>
      </ng-container>
    </ol>

    <footer exosSheetFooter>
      <button exosButton (click)="close()">Close</button>
    </footer>
  </div>
</div>
