<exos-autocomplete
  [icon]="icon"
  [name]="name"
  [group]="group"
  [label]="label"
  [items]="results"
  [infoPoint]="infoPoint"
  [isLoading]="isLoading"
  [placeholder]="placeholder"
  [noResultsMessage]="noResultsMessage"
  [displayNoResultsMsg]="displayNoResultsMsg"
  (selectedItem)="onSelect($event)"
>
</exos-autocomplete>

<ng-container *ngIf="items.length">
  <div
    class="items-container"
    fxLayout="row wrap"
    fxLayoutAlign=" center"
    fxLayoutGap="1rem grid"
  >
    <ng-container *ngFor="let result of items; index as index">
      <div>
        <button
          class="button"
          [class.button--disabled]="group.get(name).disabled"
        >
          {{ result.text }}

          <ng-container *ngIf="!group.get(name).disabled">
            <i
              (click)="removeItem(index)"
              class="exos-icon exos-icon-delete-14"
            ></i>
          </ng-container>
        </button>
      </div>
    </ng-container>
  </div>
</ng-container>
