import { Injectable } from '@angular/core';

export interface CheckBoxField {
  name: string;
  label: string;
  value: boolean;
  disabled?: boolean;
  rowWidth?: string;
}

export interface CheckboxFormConfig {
  groupName: string;
  groupLabel: string;
  fields: { [key: string]: CheckBoxField };
}

@Injectable({
  providedIn: 'root',
})
export class MarketFormService {
  private readonly markets: string[] = [
    'de',
    'ca',
    'es',
    'fr',
    'it',
    'mx',
    'pl',
    'ro',
    'uk',
    'us',
    'nl',
    'se',
    'others',
  ];

  constructor() {}

  public generateConfig(
    groupName: string,
    groupLabel: string
  ): CheckboxFormConfig {
    return {
      groupName,
      groupLabel,
      fields: this.generateConfigFields(),
    };
  }

  private generateConfigFields(): { [key: string]: CheckBoxField } {
    return this.markets.reduce((prev, market) => {
      prev[market] = {
        name: market,
        label: market.toUpperCase(),
        value: false,
        rowWidth: '25',
      };

      return prev;
    }, {});
  }
}
