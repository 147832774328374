import { JqlConfig } from 'src/app/exos/jql-input-search/config/config';

const JQL_PROJECT_CONFIG: JqlConfig = {
  fields: [
    {
      name: 'Id',
      operators: ['=', '!='],
    },
    {
      name: 'Name',
      operators: ['=', '~', '!='],
    },
    {
      name: 'Owner',
      operators: ['=', '~', '!='],
    },
    {
      name: 'Client',
      operators: ['=', '~', '!='],
    },
    {
      name: 'Phase',
      operators: ['=', '!='],
      values: [
        'Idea',
        'Initiate',
        'Plan and Execute',
        'Review',
        'Close',
        'Canceled',
      ],
    },
    {
      name: 'Status',
      operators: ['=', '!='],
      values: [
        'Not Started',
        'On Hold',
        'On Track',
        'Potential Risks',
        'Risk or Roadblock',
        'Canceled',
        'Closed',
      ],
    },
    {
      name: 'Effort',
      operators: ['=', '!='],
      values: ['S', 'M', 'L', 'XL'],
    },
    {
      name: 'Prio',
      operators: ['=', '!='],
      values: ['A', 'B', 'C'],
    },
    {
      name: 'Main Team Members',
      operators: ['=', '!='],
    },
    {
      name: 'Division',
      operators: ['=', '~', '!='],
    },
    {
      name: 'Current Approver',
      operators: ['=', '~', '!='],
    },
    {
      name: 'Representative',
      operators: ['=', '!='],
    },
    {
      name: 'Program',
      operators: ['=', '!=', '~'],
    },
    {
      name: 'tag',
      operators: ['=', '!=', '~'],
    },
  ],
  keywords: ['AND', 'OR'],
};

const JQL_PROGRAM_CONFIG: JqlConfig = {
  fields: [
    {
      name: 'Id',
      operators: ['=', '!='],
    },
    {
      name: 'Name',
      operators: ['=', '~', '!='],
    },
    {
      name: 'Owner',
      operators: ['=', '~', '!='],
    },
    {
      name: 'Client',
      operators: ['=', '~', '!='],
    },
    {
      name: 'Status',
      operators: ['=', '!='],
      values: [
        'Not Started',
        'On Hold',
        'On Track',
        'Potential Risks',
        'Risk or Roadblock',
      ],
    },
    {
      name: 'Representative',
      operators: ['=', '!='],
    },
  ],
  keywords: ['AND', 'OR'],
};

export const JQL_CONFIG_MERGED = {
  projects: JQL_PROJECT_CONFIG,
  programs: JQL_PROGRAM_CONFIG,
};
