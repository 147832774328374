<ng-container *ngIf="routeData$ | async as routeData">
  <header exosPageHeader>
    <div exosPageHeaderBlock>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h1 exosPageHeaderHeadline>
          {{ routeData.title }}
        </h1>

        <ng-container *ngIf="routeData[dataType].button">
          <button
            exosButton
            semantic="primary"
            [routerLink]="routeData[dataType].button.link"
          >
            {{ routeData[dataType].button.label }}
          </button>
        </ng-container>
      </div>
    </div>
  </header>

  <div exosPageSection>
    <!-- <div exosPageSectionBlock> -->
    <div *ngIf="tableCols && userPreferences">
      <jql-input
        [searchQuery]="searchQuery"
        [searchIsBasic]="searchIsBasic"
        (searchTypeChanged)="onSearchTypeChange($event)"
        [locales]="
          dataType === 'projects' ? jqlLocales.projects : jqlLocales.programs
        "
        [tableColumns]="tableCols"
        [saveBtnLabel]="routeData.edit ? 'Edit' : 'Save as'"
        [saveBtnDisabled]="disableJqlButtons() || searchIsBasic"
        (submitSearch)="changeRouteQueryParams($event)"
        [deleteBtnLabel]="routeData.edit ? 'Delete' : null"
        [deleteBtnDisabled]="disableJqlButtons()"
        (submitDelete)="handleOpenDeleteModal()"
        (submitSave)="onSaveButton($event)"
        (columnChecked)="toggleColumns($event)"
        (searchInputChanged)="onSearchInputChanged()"
        [filterType]="dataType"
      >
      </jql-input>
    </div>

    <div exosSheet>
      <div exosSheetSection type="secondary">
        <div exosSheetBlock>
          <form [formGroup]="form" fxLayout="row wrap" fxLayoutGap="32px grid">
            <exos-select
              [group]="form"
              [disabled]="routeData.defaultFilter"
              fxFlex="33"
              [name]="formConfig.fields?.type.name"
              [label]="formConfig.fields?.type.label"
              [placeholder]="formConfig.fields?.type.placeholder"
              [options]="formConfig.fields?.type.options"
              [displayFn]="displayTypeFn"
              [compareFn]="compareTypeFn"
            >
            </exos-select>
            <exos-autocomplete
              *ngIf="dataType === 'projects'"
              icon="exos-icon-nav-user-16"
              [group]="form"
              fxFlex="33"
              [name]="formConfig.fields?.projectOwner.name"
              [label]="formConfig.fields?.projectOwner.label"
              [placeholder]="formConfig.fields?.projectOwner.placeholder"
              [items]="projectOwner$"
              [infoPoint]="formConfig.fields?.projectOwner.infoPoint.text"
              [noResultsMessage]="
                formConfig.fields?.projectOwner.noResultsMessage
              "
              (selectedItem)="handleSelectedItem($event, 'projectOwner')"
            >
            </exos-autocomplete>
            <exos-multiselect
              *ngIf="dataType === 'projects'"
              icon="exos-icon-community-64"
              [group]="form"
              fxFlex="33"
              [results]="division$"
              [name]="formConfig.fields?.division.name"
              [label]="formConfig.fields?.division.label"
              [placeholder]="formConfig.fields?.division.placeholder"
              [infoPoint]="formConfig.fields?.division.infoPoint.text"
              [noResultsMessage]="formConfig.fields?.division.noResultsMessage"
              (selectedItem)="handleSelectedItem($event, 'division')"
              (removedItem)="handleSelectedItem($event, 'division')"
            >
            </exos-multiselect>
            <exos-autocomplete
              *ngIf="dataType === 'programs'"
              icon="exos-icon-nav-user-16"
              [group]="form"
              fxFlex="33"
              [name]="formConfig.fields?.programOwner.name"
              [label]="formConfig.fields?.programOwner.label"
              [placeholder]="formConfig.fields?.programOwner.placeholder"
              [items]="programOwner$"
              [infoPoint]="formConfig.fields?.programOwner.infoPoint.text"
              [noResultsMessage]="
                formConfig.fields?.programOwner.noResultsMessage
              "
              (selectedItem)="handleSelectedItem($event, 'programOwner')"
            >
            </exos-autocomplete>
          </form>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        (objectiveSets$ | async) ||
        (routeData.type === 'programs' && routeData.defaultFilter)
      "
      [ngClass]="
        dataType === 'projects' && existingFile && existingProjectId
          ? 'projects-one-icon-id-table-container'
          : dataType === 'projects' && existingFile && !existingProjectId
          ? 'projects-one-icon-table-container'
          : dataType === 'projects' && !existingFile && existingProjectId
          ? 'projects-id-table-container'
          : dataType === 'projects' && !existingFile && !existingProjectId
          ? 'projects-table-container'
          : dataType === 'programs' && existingProgramId
          ? 'programs-id-table-container'
          : 'programs-table-container'
      "
      [ngStyle]="{ display: displayLoader ? 'none' : null }"
    >
      <dash-table
        #table
        [dataSource]="tableData"
        (rowClicked)="goToOverviewById($event)"
        (sortClicked)="pushToUpdatePrefsQueue($event)"
        [cols]="tableCols | tableColumnFilter"
        [noDataMessage]="
          dataType === 'projects'
            ? tableNotFoundMsgProjects
            : tableNotFoundMsgPrograms
        "
      >
        <td dashTableColumn id="id">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span
              [exosTooltip]="value"
              exosTooltipPosition="above"
              *ngIf="dataType === 'projects'"
              >PRJ.{{ value }}</span
            >
            <span
              [exosTooltip]="value"
              exosTooltipPosition="above"
              *ngIf="dataType === 'programs'"
              >PRG.{{ value }}</span
            >
          </ng-container>
        </td>

        <td dashTableColumn id="name">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }} </span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <i
              class="exos-icon exos-icon-lock-48 exos-icon-locked"
              exosTooltip="CONFIDENTIAL"
              exosTooltipPosition="above"
              *ngIf="row.confidential"
            ></i>
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="program">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="status">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <div>
              <span exosBadge [semantic]="row.statusItem.semanticType">
                {{ row.statusItem.valueLocalized }}
              </span>
            </div>
          </ng-container>
        </td>

        <td dashTableColumn id="division">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="gantt">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="status_comment">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="owner">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="client">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="file">
          <ng-container *dashTableHeaderTemplate="let col">
            {{ col.name }}
          </ng-container>

          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <i
              class="exos-icon file-icon exos-icon-flyoutmenu-16 stop-row-action"
              visual="external-action"
              [matMenuTriggerFor]="fileMenu"
              [matMenuTriggerData]="{ row: row }"
            ></i>
          </ng-container>
        </td>

        <td dashTableColumn id="prio">
          <div *dashTableHeaderTemplate="let col" style="text-align: center">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </div>
          <div
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
            class="table__cell--align-center"
          >
            <span>{{ value }}</span>
          </div>
        </td>

        <td dashTableColumn id="effort">
          <div *dashTableHeaderTemplate="let col" style="text-align: center">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </div>
          <div
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
            class="table__cell--align-center"
          >
            <span>{{ value }}</span>
          </div>
        </td>

        <td dashTableColumn id="risks">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="projectedLaunchDate">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="projectedStartDate">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="objective">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="keyResult">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="dependencies_description">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="dependencies">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="tags">
          <ng-container *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
          </ng-container>
          <ng-container
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span [exosTooltip]="value" exosTooltipPosition="above">{{
              value
            }}</span>
          </ng-container>
        </td>

        <td dashTableColumn id="phase">
          <div *dashTableHeaderTemplate="let col">
            <span>{{ col.name }}</span>
            <ng-container *ngIf="col.sortable">
              <i
                *ngIf="col.sortDirection === 'asc' || col.sortDirection == null"
                class="material-icons"
              >
                arrow_upward</i
              >
              <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                arrow_downward</i
              >
            </ng-container>
          </div>
          <div
            *dashTableCellTemplate="
              let row = row;
              let value = value;
              let index = index
            "
          >
            <span>{{ value }}</span>
          </div>
        </td>
      </dash-table>
    </div>
    <dash-table-paginator
      class="mt-2"
      [pageContext]="pageContext"
      [perPageValues]="perPageValues"
      (onPageSizeChange)="pushToUpdatePrefsQueue($event)"
    >
    </dash-table-paginator>

    <dash-loader *ngIf="displayLoader" [hasContainer]="true"> </dash-loader>
    <!-- </div> -->
  </div>
</ng-container>

<mat-menu #fileMenu="matMenu" xPosition="after" yPosition="below">
  <ng-template matMenuContent let-row="row">
    <!-- TODO to be activated after overview download is implemented -->
    <!-- <div
      mat-menu-item
      style="color: #113e8a"
      (click)="openBriefFile(row.folderId)"
    >
      <i
        class="exos-icon exos-icon-openwindow-20 menu-icon"
        *ngIf="row.workflow === 'ionos'"
        visual="external-action"
      ></i
      >Download Overview
    </div> -->
    <div
      *ngIf="row.workflow === 'ionos'"
      mat-menu-item
      style="color: #113e8a"
      (click)="openBriefFile(row.folderId)"
    >
      <i
        class="exos-icon exos-icon-openwindow-20 menu-icon"
        visual="external-action"
      ></i
      >Project Brief
    </div>
    <div
      *ngIf="row.workflow === 'ionos'"
      mat-menu-item
      style="color: #113e8a"
      (click)="openRoadMap(row.id)"
    >
      <i
        class="exos-icon exos-icon-webanalytics-32 menu-icon"
        visual="external-action"
      ></i
      >Project Plan
    </div>
    <a
      *ngIf="row.objectiveId && row.keyResultId"
      mat-menu-item
      [ngStyle]="{ color: !!okrBaseUrl ? '#113e8a' : '#555' }"
      disabled="{{ !okrBaseUrl }}"
      [href]="generateOkrLink(row)"
    >
      <i
        class="exos-icon exos-icon-openwindow-20 menu-icon"
        visual="external-action"
      ></i
      >OKR
    </a>
    <div
      *ngIf="!row.objectiveId || !row.keyResultId"
      mat-menu-item
      style="color: #113e8a"
      (click)="openOkrModal(row)"
    >
      <i
        class="exos-icon exos-icon-more-48 menu-icon"
        visual="external-action"
      ></i
      >Add OKR
    </div>
  </ng-template>
</mat-menu>
