import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  auditDetailsConfig,
  auditRouteConfig,
  EditableTableRouteConfig,
} from './project-audit-route.config';
import { ProjectModel } from 'src/app/shared/models/project.model';
import { ArrayDataSource, PageContext, UrlDataSource } from '@dashboard/table';
import { ApiService } from '@dashboard/core';
import { ProjectAudit } from 'src/app/shared/models/project-audit.model';
import { pluck } from 'rxjs/operators';
import { DashDialogService } from '@dashboard/exos-dialog';
import { ProjectHistoryDetailsDialogComponent } from '../../../../shared/components/dialogs/project-history-details-dialog/project-history-details-dialog.component';
import { User } from '../../../../shared/models/user';

@Component({
  selector: 'app-project-audit',
  templateUrl: './project-audit-route.component.html',
  styleUrls: ['./project-audit-route.component.scss'],
})
export class ProjectAuditRouteComponent implements OnInit, AfterViewInit {
  routeConfig: EditableTableRouteConfig = auditRouteConfig;
  project: ProjectModel;
  auditData: UrlDataSource<ProjectAudit>;

  public pageContext: PageContext = new PageContext();
  public readonly perPageValues: Array<number> = [25, 50, 100];

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private dialogService: DashDialogService
  ) {}

  ngOnInit() {
    this.route.parent.data
      .pipe(pluck('project'))
      .subscribe((data) => (this.project = data));

    const url = `${this.apiService.getBaseUrl('pmt')}/projects/${
      this.project.id
    }/audit/`;

    this.auditData = new UrlDataSource(url, {
      pageContext: this.pageContext,
      dataExtractor: (response) => this.dataTableExtractor(response),
      totalElementsExtractor: (response) => response.meta.count,
    });
  }

  ngAfterViewInit() {
    this.auditData.start();
  }

  public viewProjectUpdateDetails(value) {
    const dialogConfig = auditDetailsConfig;

    dialogConfig.data.table.items = new ArrayDataSource(value);
    dialogConfig.data.table.items.start();

    this.dialogService.open(ProjectHistoryDetailsDialogComponent, dialogConfig);
  }

  private dataTableExtractor(response) {
    return response.data.map((event) => {
      if ('payload' in event) {
        const payload = event.payload;
        if (payload != null) {
          event.payload = Object.keys(payload).map((key) => {
            const userModelFields = [
              'owner',
              'client',
              'userInfo',
              'responsible',
            ];
            let value = payload[key];
            if (userModelFields.includes(key) && value instanceof Object) {
              value = (value as User).name;
            } else if (key === 'status') {
              if (value instanceof Object) {
                value = value.overall.value;
              }
            } else if (
              key === 'mainTeamMembers' ||
              (key === 'representative' && value != null)
            ) {
              try {
                value = value.map((item) => item.name);
              } catch (error) {
                if (value !== null && value !== undefined) {
                  value = value.name;
                }
              }
            } else if (key == 'projectedStartDate' && value != null) {
              let lastValue = value[value.length - 1];
              let comment = lastValue.comment != null ? lastValue.comment : '';
              value = `${lastValue.date} \n ${comment}`;
            } else if (value instanceof Object) {
              if (value instanceof Array) {
                value = value.map((item) =>
                  item.item !== undefined ? item.item : item
                );
              } else {
                value = value.name !== undefined ? value.name : value;
              }
            }

            return { key, value };
          });
        }
      }
      return event;
    });
  }
}
