import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ExosDialogConfig, ExosDialogRef } from '@dashboard/exos-dialog';
import {
  ExosFormComponent,
  FormContainerDirective,
  FormService,
} from '@dashboard/exos-form';
import { Subscription } from 'rxjs';
import { Validators } from '@angular/forms';
import { gantt } from 'src/app/shared/service/dhtmlx-gantt-service/gantt/dhtmlxgantt';

@Component({
  selector: 'app-gantt-task-dialog',
  templateUrl: './gantt-task-dialog.component.html',
  styleUrls: ['./gantt-task-dialog.component.scss'],
})
export class GanttTaskDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild(FormContainerDirective)
  public changeRequestFormContainer: FormContainerDirective;

  public form: ComponentRef<ExosFormComponent>;

  private modalSubs: Subscription[] = [];

  constructor(
    public config: ExosDialogConfig,
    public dialog: ExosDialogRef,
    private exosFormService: FormService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.form = this.exosFormService.buildForm(
      this.changeRequestFormContainer.viewContainerRef,
      this.config.data.formConfig
    );

    this.handleFormEvents();
    this.handleFormErrors();
    this.handleDatepickerEvents();
    this.handleTypeChange();

    if (this.config.data.type === 'project') {
      if (this.form.instance.form.controls.type.value === 'project') {
        this.form.instance.form.controls.progress.disable({ emitEvent: false });
        this.form.instance.form.controls.assignee.disable({ emitEvent: false });
        this.form.instance.form.controls.note.disable({ emitEvent: false });
        this.form.instance.form.controls.link.disable({ emitEvent: false });
        this.form.instance.form.controls.dependencies.disable({
          emitEvent: false,
        });
        this.form.instance.form.controls.start_date.disable({
          emitEvent: false,
        });
        this.form.instance.form.controls.end_date.disable({ emitEvent: false });
      }
    }

    this.form.instance.form.controls.text.setValidators([Validators.required]);
    this.form.instance.form.controls.type.setValidators([Validators.required]);

    this.changeDetectorRef.detectChanges();
  }

  private handleFormEvents() {
    this.modalSubs.push(
      this.form.instance.formEvents.subscribe((ev) => {
        switch (ev.type) {
          case 'submit':
            if (this.form.instance.form.invalid) {
              const fields: any = {
                ...(this.form.instance.form.controls?.text?.errors
                  ?.required && { text: 'Missing field' }),
                ...(this.form.instance.form.controls?.type?.errors
                  ?.required && { type: 'Missing field' }),
              };
              this.exosFormService.setFieldErrors({
                errors: {
                  fields,
                },
              });
              return;
            }

            this.form.instance.form.disable();
            this.form.instance.form.markAsPending();

            this.dialog.submit({
              type: this.config.data.task.$new ? 'CREATE' : 'UPDATE',
              payload: this.form.instance.form.getRawValue(),
            });
            break;
          case 'close':
            this.dialog.submit({
              type: 'CLOSE',
            });
            this.dialog.close();
            break;
          case 'delete':
            this.form.instance.form.disable();
            this.dialog.submit({
              type: 'DELETE',
              payload: this.form.instance.form.getRawValue(),
            });
            break;
        }
      })
    );
  }

  private handleFormErrors() {
    this.modalSubs.push(
      this.dialog.afterErrors.subscribe((errors: any) => {
        this.form.instance.form.enable({ emitEvent: false });

        if (errors) {
          this.exosFormService.setFieldErrors(errors);
        } else {
          this.form.instance.form.setValue(
            this.form.instance.form.getRawValue()
          );
        }
      })
    );
  }

  private handleDatepickerEvents() {
    const form = this.form.instance.form;
    if (this.form.instance.form.controls.type.value === 'milestone') {
      this.form.instance.form.controls.end_date.disable();
    } else {
      this.form.instance.form.controls.end_date.enable();
    }

    this.config.data.formConfig.fields.end_date.minDate = gantt.date.add(
      this.config.data.formConfig.fields.start_date.value,
      this.form.instance.form.controls.type.value === 'milestone' ? 0 : 1,
      'day'
    );

    this.modalSubs.push(
      form.controls.start_date.valueChanges.subscribe((startDate) => {
        this.config.data.formConfig.fields.start_date.value = startDate;

        this.config.data.formConfig.fields.end_date.minDate = gantt.date.add(
          this.config.data.formConfig.fields.start_date.value,
          this.form.instance.form.controls.type.value === 'milestone' ? 0 : 1,
          'day'
        );

        const currentEndDate = this.form.instance.form.controls.end_date.value;

        if (this.form.instance.form.controls.type.value === 'milestone') {
          this.config.data.formConfig.fields.end_date.value = startDate;

          form.controls.end_date.patchValue(startDate, { emitEvent: false });
        } else if (+startDate > +currentEndDate) {
          form.controls.end_date.patchValue(
            this.config.data.formConfig.fields.end_date.minDate,
            { emitEvent: false }
          );
          // form.controls.end_date.setErrors({
          //   bkError: 'End date must be greater than start date',
          // });
        }
      })
    );
  }

  private handleTypeChange() {
    const form = this.form.instance.form;

    this.modalSubs.push(
      form.controls.type.valueChanges.subscribe((type) => {
        if (this.form.instance.form.disabled) {
          return;
        }
        this.config.data.formConfig.fields.end_date.minDate = gantt.date.add(
          this.config.data.formConfig.fields.start_date.value,
          this.form.instance.form.controls.type.value === 'milestone' ? 0 : 1,
          'day'
        );
        form.controls.end_date.patchValue(
          this.config.data.formConfig.fields.end_date.minDate,
          { emitEvent: false }
        );

        if (type === 'project') {
          form.controls.progress.disable({ emitEvent: false });
          form.controls.assignee?.disable({ emitEvent: false });
          form.controls.note?.disable({ emitEvent: false });
          form.controls.link?.disable({ emitEvent: false });
          form.controls.dependencies?.disable({ emitEvent: false });
          form.controls.start_date.disable({ emitEvent: false });
          form.controls.end_date.disable({ emitEvent: false });
        } else if (form.controls.type.value === 'milestone') {
          form.controls.end_date.disable({ emitEvent: false });
        } else {
          form.controls.progress.enable({ emitEvent: false });
          form.controls.assignee?.enable({ emitEvent: false });
          form.controls.note?.enable({ emitEvent: false });
          form.controls.link?.enable({ emitEvent: false });
          form.controls.dependencies?.enable({ emitEvent: false });
          form.controls.start_date.enable({ emitEvent: false });
          form.controls.end_date.enable({ emitEvent: false });
        }
      })
    );
  }

  ngOnDestroy() {
    this.modalSubs.forEach((sub) => sub.unsubscribe());
  }
}
