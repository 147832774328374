import { Component } from '@angular/core';
import { ExosDialogConfig, ExosDialogRef } from '@dashboard/exos-dialog';
import { HelpSection } from 'src/app/shared/models/help.config';

@Component({
  selector: 'app-project-status-help',
  templateUrl: './project-status-help-dialog.component.html',
  styleUrls: ['./project-status-help-dialog.component.scss'],
})
export class ProjectStatusHelpDialogComponent {
  public contentText: HelpSection = {
    basicsInfo: [
      {
        title: 'RESPONSIBILITY',
        icon: '',
        bodyText: [
          'Project owners are responsible for keeping the respective project status of their project up-to-date at any time. ' +
            'If the status is anything but green (amber / red / grey), the project client is required to be either informed or involved by ' +
            'the project owner.',
        ],
      },
      {
        title: 'PROCESS',
        icon: '',
        bodyText: [
          'The status of each projects is required to be documented in the project status overview within the tool and will be displayed ' +
            'automatically in the projects list / project overview. Project owners may need to raise awareness to their project clients if ' +
            'potential risks or roadblock occur.',
        ],
      },
      {
        title: 'DOCUMENTATION',
        icon: '',
        bodyText: [
          'If the status is not green, project owners are required to add additional information to the status through the comment ' +
            'section. Please keep comments short, simple and clear and include: WHAT (description of the issue), WHO (issue solver) ' +
            'and WHEN (readiness of solution).',
        ],
      },
      {
        title: 'FREQUENCY',
        icon: '',
        bodyText: [
          'The project status is required to be maintained at any time. Project owners need to make sure to review the reported status of' +
            ' each project continuously, especially during the execution phase.',
        ],
      },
    ],
    traficLightsInfo: [
      {
        title: 'On Track',
        bodyText: [
          'Everything is going according to plan.',
          'No mitigation actions required from project owner or project client.',
        ],
        semantic: 'success',
      },
      {
        title: 'Potential Risks',
        bodyText: [
          'A potential risk came up, but the project team is able to fix it and mitigate the risk.',
          'The project client is required to watch further developments within the project closely.',
        ],
        semantic: 'warning',
      },
      {
        title: 'Risk / Roadblock',
        bodyText: [
          'Significant risks or red flags came up that the project team cannot solve.',
          'The matter needs to be escalated to the project client. Project review and identification of action plan needed.',
        ],
        semantic: 'critical',
      },
      {
        title: 'On Hold',
        bodyText: [
          'Project is put on hold due to deprioritization or fast tracking of projects with higher impact on our strategy goals.',
          'Only the overall project status can be flagged as ‘on hold’.',
        ],
        semantic: 'neutral',
      },
    ],
    statusItemsInfo: [
      {
        title: 'OVERALL',
        bodyText: [
          'Green: all status items are on track',
          'Amber: one or more status items are at risk (amber)',
          'Red: one or more status items are at risk or roadblocked (red)',
        ],
      },
      {
        title: 'SCOPE',
        bodyText: [
          'Green: implementation on track, according to aligned scope',
          'Amber: slight changes to the scope with low impact on initially planned outcome',
          'Red: significant changes to the scope, initially planned scope cannot be executed ',
        ],
      },
      {
        title: 'SCHEDULE',
        bodyText: [
          'Green: project on plan and to be completed on time',
          'Amber: slight delays against critical milestones and depending tasks (e.g. 2 weeks)',
          'Red: significant delay against critical milestones and depending tasks (e.g. 4 weeks and over)',
        ],
      },
      {
        title: 'BUDGET',
        bodyText: [
          'Green: forecasted spend is on budget',
          'Amber: slight forecasted overspend against budget (approx. >5%)',
          'Red: significant forecasted overspend against budget (approx. >10%)',
        ],
      },
      {
        title: 'RESOURCES',
        bodyText: [
          'Green: no resource issues, core team and stakeholders working towards deliverables',
          'Amber: lack of resources, which can be resolved by the project team',
          'Red: significant lack of resources / bottlenecks that cannot be resolved by the project team',
        ],
      },
    ],
  };

  constructor(public config: ExosDialogConfig, public dialog: ExosDialogRef) {}

  close() {
    this.dialog.close();
  }
}
