import { Injectable } from '@angular/core';
import { UserService } from '@dashboard/core';
import { ProjectModel } from '../../../models/project.model';
import { User } from '../../../models/user';
import {
  ProjectUserRights,
  ProjectUserRightsSession,
  UserRightsAbstract,
} from '../user-rigths';
import { ChangeRequest } from 'src/app/shared/models/change-request';

@Injectable({
  providedIn: 'root',
})
export class ProjectRightsService extends UserRightsAbstract {
  constructor(private session: UserService) {
    super();
  }

  getUserRights(project: ProjectModel): ProjectUserRights {
    const { isOwner, isApprover, isRepresentative, isAdmin, isClient } =
      this.getUserSessionStatus(project);
    const {
      phase,
      divisionGateApproval,
      vminusOneGateApproval,
      readyToApprove,
      clientGateApproval,
    } = project;
    const projectIsInApprovablePhase =
      phase !== 'close' && phase !== 'canceled';
    const canReject =
      isApprover && readyToApprove && projectIsInApprovablePhase;
    const isOwnerOrRepresentativeOrAdmin =
      isOwner || isRepresentative || isAdmin;
    const projectInIonosWorkflow = project.workflow === 'ionos';
    const projectInUIWorkflow = project.workflow === 'united-internet';
    const canCloseIonosProjectWorkflow =
      projectInIonosWorkflow &&
      phase === 'review' &&
      isApprover &&
      readyToApprove;
    const canCloseUIProjectWorkflow =
      projectInUIWorkflow &&
      phase === 'inProgress' &&
      (isOwner || isRepresentative);
    const canRejectClientGateApproval =
      (projectInIonosWorkflow && phase === 'review') ||
      (projectInUIWorkflow && phase === 'idea');
    const canCancelProjectWorkflow =
      (projectInIonosWorkflow && phase !== 'review') || projectInUIWorkflow;
    const canMarkProjectWorkflowAsReady =
      (projectInIonosWorkflow && phase !== 'planAndExecute') ||
      (projectInUIWorkflow && phase !== 'inProgress');
    const pendingChangeRequests = this.projectHasPendingChangeRequests(project);

    const projectIsInCancelOrCompletePhase =
      project.phase === 'close' || project.phase === 'canceled';

    const userCanDelete = this.currentUserCanDelete();

    return {
      // both workflows
      canUpdate: isOwnerOrRepresentativeOrAdmin && projectIsInApprovablePhase,
      canUpdateOwner: isOwner || isAdmin || isClient,
      canUpdateConfidential: isClient && this.isVMinusOne(),
      canUpdateProjectStatus:
        !projectIsInCancelOrCompletePhase &&
        (isOwner || isRepresentative || isAdmin),
      canSendToApprove:
        isOwnerOrRepresentativeOrAdmin &&
        projectIsInApprovablePhase &&
        !readyToApprove &&
        canMarkProjectWorkflowAsReady,

      // ionos workflow
      canApproveInitiate:
        projectInIonosWorkflow &&
        isApprover &&
        !divisionGateApproval &&
        phase === 'idea' &&
        readyToApprove,
      canApprovePlanAndExecute:
        projectInIonosWorkflow &&
        isApprover &&
        !vminusOneGateApproval &&
        phase === 'initiate' &&
        readyToApprove,
      canApproveReview:
        projectInIonosWorkflow &&
        isOwnerOrRepresentativeOrAdmin &&
        phase === 'planAndExecute',
      canApproveClose:
        canCloseIonosProjectWorkflow || canCloseUIProjectWorkflow,
      canRejectDivisionGateApproval:
        projectInIonosWorkflow && canReject && phase === 'idea',
      canRejectVminusOneGateApproval:
        projectInIonosWorkflow && canReject && phase === 'initiate',

      canRejectPlanAndExecute:
        projectInIonosWorkflow &&
        isOwnerOrRepresentativeOrAdmin &&
        phase === 'planAndExecute',
      canRejectInitiate:
        projectInIonosWorkflow &&
        isOwnerOrRepresentativeOrAdmin &&
        phase === 'initiate' &&
        !pendingChangeRequests,
      canRejectReview:
        projectInIonosWorkflow &&
        isOwnerOrRepresentativeOrAdmin &&
        phase === 'review',

      // united-internet workflow
      canApproveProgress:
        isApprover && !clientGateApproval && phase === 'idea' && readyToApprove,
      canRejectProgress:
        projectInUIWorkflow &&
        isOwnerOrRepresentativeOrAdmin &&
        phase === 'inProgress',

      // both workflows
      canRejectClientGateApproval: canReject && canRejectClientGateApproval,
      canCancelProject:
        isOwnerOrRepresentativeOrAdmin &&
        projectIsInApprovablePhase &&
        canCancelProjectWorkflow,
      canReopenProject: project.phase === 'close' && isAdmin,
      canDelete: isOwnerOrRepresentativeOrAdmin || userCanDelete,
    };
  }

  userCanDelete() {
    return this.currentUserCanDelete();
  }

  isOwner(project: ProjectModel): boolean {
    return this.currentUserIsOwner(project.owner);
  }

  isClient(project: ProjectModel): boolean {
    return this.currentUserIsClient(project.client);
  }

  isMainTeamMember(project: ProjectModel): boolean {
    return this.currentUserIsMainTeamMember(project.mainTeamMembers);
  }

  isRepresentative(project: ProjectModel): boolean {
    return this.currentUserIsRepresentative(project.representative);
  }

  isAdmin() {
    return this.currentUserIsAdmin();
  }

  isVMinusOne() {
    return this.session
      .getAllRoles()
      .some(
        (item) =>
          item.getName() === 'v_minus_one' && item.getAppCode() === 'pmt'
      );
  }

  isBoardMember() {
    return this.currentUserIsBoardMember();
  }

  getUserSessionStatus(project: ProjectModel): ProjectUserRightsSession {
    const {
      client = null,
      owner = null,
      mainTeamMembers = null,
      currentApprover = null,
      representative = null,
    } = project;

    const isOwner = this.currentUserIsOwner(owner);
    const isClient = this.currentUserIsClient(client);
    const isMainTeamMember = this.currentUserIsMainTeamMember(mainTeamMembers);
    const isProjectRepresentative =
      this.currentUserIsRepresentative(representative);
    const isVMinusOne = this.currentUserIsVMinusOne();
    const isBoardMember = this.currentUserIsBoardMember();

    return {
      isOwner,
      isClient,
      isMainTeamMember,
      isVMinusOne,
      isBoardMember,
      isRepresentative: isProjectRepresentative,
      isAdmin: this.currentUserIsAdmin(),
      isApprover: this.currentUserIsApprover(currentApprover),
      isContributor:
        isOwner || isClient || isProjectRepresentative || isMainTeamMember,
    };
  }

  private currentUserIsVMinusOne() {
    return this.session
      .getAllRoles()
      .some(
        (item) =>
          item.getName() === 'v_minus_one' && item.getAppCode() === 'pmt'
      );
  }

  private currentUserIsBoardMember() {
    return this.session
      .getAllRoles()
      .some(
        (item) =>
          item.getName() === 'board_member' && item.getAppCode() === 'pmt'
      );
  }

  private currentUserIsAdmin() {
    return this.session
      .getAllRoles()
      .some(
        (item) => item.getName() === 'admin' && item.getAppCode() === 'pmt'
      );
  }

  private currentUserCanDelete() {
    return this.session
      .getAllRoles()
      .some(
        (item) => item.getName() === 'can_delete' && item.getAppCode() === 'pmt'
      );
  }

  private currentUserIsRepresentative(representative: User[]): boolean {
    const currentUserId = this.session.getId().toString();
    return representative
      ? representative.some((member) => member.id === currentUserId)
      : false;
  }

  private currentUserIsApprover(currentApprover: User | null): boolean {
    const currentUserId = this.session.getId().toString();
    return currentApprover ? currentUserId === currentApprover.id : false;
  }

  private currentUserIsOwner(owner: User): boolean {
    const currentUserId = this.session.getId().toString();
    return currentUserId === owner.id;
  }

  private currentUserIsClient(client: User | null): boolean {
    const currentUserId = this.session.getId().toString();
    return client ? currentUserId === client.id : false;
  }

  private currentUserIsMainTeamMember(mainTeamMembers: User[] | null): boolean {
    const currentUserId = this.session.getId().toString();
    return mainTeamMembers
      ? mainTeamMembers.some((member) => member.id === currentUserId)
      : false;
  }

  private projectHasPendingChangeRequests(project: ProjectModel) {
    return project.changeRequests.some(
      (changeRequest: ChangeRequest) => changeRequest.state === 'PENDING'
    );
  }
}
