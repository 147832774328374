import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { programCreateFormConfig } from './program-create.form-config';
import {
  FieldConfig,
  FormConfig,
  FormContainerDirective,
  FormEvent,
  FormService,
  MultiSelectConfig,
} from '@dashboard/exos-form';
import { ProgramService } from '../../../shared/service/program/program.service';
import {
  ProgramCreateReqBody,
  ProgramFormValue,
} from '../../../shared/models/program.model';
import { FormGroup } from '@angular/forms';
import { filter, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { UserService } from '@dashboard/core';
import { CustomCreateExosFormComponent } from '../../components/custom-create-exos-form/custom-create-exos-form.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-program-create-route',
  templateUrl: './program-create-route.component.html',
  styleUrls: ['./program-create-route.component.scss'],
})
export class ProgramCreateRouteComponent implements OnInit, AfterViewInit {
  @ViewChild(FormContainerDirective, { read: ViewContainerRef })
  private formContainer: ViewContainerRef;

  public formConfig: FormConfig = JSON.parse(
    JSON.stringify(programCreateFormConfig)
  );

  private form: FormGroup;

  constructor(
    private router: Router,
    public location: Location,
    private cdr: ChangeDetectorRef,
    private formService: FormService,
    private userService: UserService,
    private programService: ProgramService
  ) {}

  ngOnInit(): void {
    this.updateOwnerField();
  }

  ngAfterViewInit() {
    const formCmpInstance = this.buildForm().instance;

    this.form = formCmpInstance.form;
    this.form.controls.owner.disable();
    this.handleFormValues(formCmpInstance);
    this.cdr.detectChanges();
  }

  private handleFormValues(formCmpInstance: CustomCreateExosFormComponent) {
    formCmpInstance.formEvents
      .pipe(
        map((event: FormEvent) => {
          event.rawValue = this.setFormEmptyValuesToNull(event.rawValue);
          return event;
        }),
        filter((event) => this.filterFormValue(event.rawValue)),
        map((event) => {
          event.rawValue = this.mergeMultiSelectValuesWithConfigValues(
            event.rawValue
          );
          return event;
        })
      )
      .subscribe(async (event: FormEvent) => {
        if (event.type === 'create') {
          const newProgram = event.rawValue as ProgramFormValue;
          this.form.disable();
          this.createProgram(newProgram);
        } else if (event.type === 'cancel') {
          this.location.back();
        }
      });
  }

  private updateOwnerField() {
    const ownerField = this.formConfig.fields.owner as FieldConfig;
    const { fullname, username, user_id } = this.userService.getSession();

    ownerField.value = {
      username,
      id: user_id,
      name: fullname,
      text: fullname,
    };
  }

  private buildForm(): ComponentRef<CustomCreateExosFormComponent> {
    return this.formService.buildForm(
      this.formContainer,
      this.formConfig,
      CustomCreateExosFormComponent
    );
  }

  public createProgram(program: ProgramCreateReqBody) {
    this.programService.createProgram(program).subscribe(
      async (response) => {
        await this.router.navigate([`/programs/${response.id}`]);
      },
      (error) => {
        this.handleFormsErrors(error);
      }
    );
  }

  private handleFormsErrors(errors: any) {
    this.form.enable();
    if (errors?.error?.errors?.fields) {
      this.formService.setFieldErrors(errors.error);
    }
  }

  // TODO extract and beautify logic in service
  private filterFormValue(program: ProgramCreateReqBody): boolean {
    const configKeys = Object.keys(this.formConfig.fields);

    const valueHasAutocompleteStringValues = !!configKeys.find((configKey) => {
      const currentFieldConfig = this.formConfig.fields[
        configKey
      ] as FieldConfig;
      const isUserModelField = ['multiselect', 'autocomplete'].includes(
        currentFieldConfig.type
      );
      const valueIsString = typeof program[configKey] === 'string';

      return isUserModelField ? valueIsString : false;
    });

    return !valueHasAutocompleteStringValues;
  }

  // TODO extract and beautify logic in service
  private setFormEmptyValuesToNull(value: any): any {
    const formValueKeys = Object.keys(value);

    formValueKeys.forEach((val) => {
      const currentFieldConfig = this.formConfig.fields[val] as FieldConfig;

      if (
        ['multiselect', 'autocomplete'].includes(currentFieldConfig.type) &&
        value[val] === ''
      ) {
        value[val] = null;
      }
    });

    return value;
  }

  // TODO extract and beautify logic in service
  private mergeMultiSelectValuesWithConfigValues(formValue: any) {
    const entries = Object.entries(formValue);

    return entries.reduce((acc, entry) => {
      const [key, value] = entry;
      const currentFieldConfig = this.formConfig.fields[
        key
      ] as MultiSelectConfig;

      if (currentFieldConfig.type === 'multiselect') {
        acc[key] = currentFieldConfig.selectedItems;
      } else {
        acc[key] = value;
      }

      return acc;
    }, {});
  }
}
