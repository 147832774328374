export interface UserRights {
  canUpdate: boolean;
  canDelete: boolean;
}

export interface ProjectUserRights extends UserRights {
  canUpdateOwner: boolean;
  canCancelProject: boolean;
  canSendToApprove: boolean;
  canApproveInitiate: boolean;
  canApprovePlanAndExecute: boolean;
  canApproveReview: boolean;
  canApproveClose: boolean;
  canRejectDivisionGateApproval: boolean;
  canRejectVminusOneGateApproval: boolean;
  canRejectClientGateApproval: boolean;
  canRejectInitiate: boolean;
  canRejectReview: boolean;
  canRejectPlanAndExecute: boolean;
  canApproveProgress: boolean;
  canRejectProgress: boolean;
  canUpdateConfidential: boolean;
  canUpdateProjectStatus: boolean;
  canReopenProject: boolean;
}

// tslint:disable-next-line:no-empty-interface
export interface ProgramUserRights extends UserRights {
  canUpdateOwner: boolean;
}

export interface UserRightsSession {
  isOwner: boolean;
  isClient: boolean;
  isRepresentative: boolean;
  isAdmin: boolean;
}

export interface ProjectUserRightsSession extends UserRightsSession {
  isApprover: boolean;
  isMainTeamMember: boolean;
  isContributor: boolean;
  isVMinusOne: boolean;
  isBoardMember: boolean;
}

export abstract class UserRightsAbstract {
  abstract isOwner<T>(d: any): boolean;

  abstract isOwner<T>(d: any): boolean;

  abstract isClient<T>(d: any): boolean;

  abstract isBoardMember<T>(d: any): boolean;

  abstract isRepresentative<T>(d: any): boolean;

  abstract getUserSessionStatus<T>(d: any): UserRightsSession;

  abstract getUserRights(d: any): any;

  isMainTeamMember?<T>(d: any): boolean;
}
