<ng-container *ngIf="programLinks$ | async">
  <div class="table__toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <h2 exosHeadline type="sub">Links</h2>
      </div>

      <div>
        <button
          *ngIf="canUpdateProgramDocumentLinks$ | async"
          (click)="addDocumentLink()"
          exosButton
          semantic="primary"
        >
          Add link
        </button>

        <button exosButton *ngIf="programFileId" (click)="openGDriveFolder()">
          View GDrive Folder
        </button>
      </div>
    </div>
  </div>

  <dash-table
    [dataSource]="programLinksTableDatasource"
    [cols]="programLinksTableCols"
    noDataMessage="No links added"
  >
    <dash-table-column id="link">
      <div *dashTableHeaderTemplate="let col">
        <span>{{ col.name }}</span>
      </div>

      <div *dashTableCellTemplate="let row; let value; let index">
        <a exosLink visual="external-action" href="{{ value }}" target="_blank">
          {{ value }}
        </a>
      </div>
    </dash-table-column>
  </dash-table>
</ng-container>
