export interface AppTabs {
  ionos: {
    [key: string]: string;
  };
  ['united-internet']: {
    [key: string]: string;
  };
}

export const TABS: AppTabs = {
  ionos: {
    'Project One Pager': 'details',
    'Project Administration': 'edit',
    'Project Brief': 'brief',
    'Project plan': 'project-plan',
    'Functional plan': 'functional-plan',
    'Project Review': 'review',
    'Documents & Links': 'documents-links',
    'Project History': 'project-history',
  },
  'united-internet': {
    'Project One Pager': 'details',
    'Project Administration': 'edit',
    'Functional plan': 'functional-plan',
    'Documents & Links': 'documents-links',
    'Project History': 'project-history',
  },
};
