import { Pipe, PipeTransform } from '@angular/core';
import { ProjectStatusItem } from 'src/app/shared/models/project-status';
import {
  ProjectView,
  ProjectViewUi,
} from '../../../shared/models/project.model';
import { StatusItemImpl, StatusNameType } from '../../../shared/models/status';

@Pipe({
  name: 'project',
})
export class ProjectPipe implements PipeTransform {
  transform(value: ProjectView, ...args: unknown[]): ProjectViewUi {
    const {
      name,
      phase,
      owner,
      client,
      risks,
      lastModified,
      lastApprovalDate,
      html_description,
      projectedLaunchDate,
      projectedStartDate,
      status,
      milestones,
    } = value;

    const projectStatus = Object.keys(status).map((statusName: string) => {
      const currentStatus = status[statusName];
      return new ProjectStatusItem(
        currentStatus.value,
        currentStatus.comment,
        StatusNameType[statusName.toUpperCase()]
      );
    });

    return {
      name,
      phase,
      risks,
      lastModified,
      lastApprovalDate,
      status: Object.keys(projectStatus).length ? projectStatus : null,
      owner: owner ? owner.name : 'Not set',
      client: client ? client.name : 'Not set',
      htmlDescription: html_description || 'Note set',
      projectedLaunchDate: projectedLaunchDate
        ? projectedLaunchDate[projectedLaunchDate.length - 1]
        : null,
      projectedStartDate: projectedStartDate
        ? projectedStartDate[projectedStartDate.length - 1]
        : null,
      milestones,
    };
  }
}
