import {
  addStakeholderDialogConfig,
  editStakeholderDialogConfig,
} from '../../../../shared/config/dialogs/stakeholder-dialog.config';
import {
  adddependencyDialogConfig,
  editdependencyDialogConfig,
} from '../../../../shared/config/dialogs/dependency-dialog.config';
import {
  addRiskDialogConfig,
  editRiskDialogConfig,
} from '../../../../shared/config/dialogs/risk-dialog.config';
import {
  addrequirementDialogConfig,
  editrequirementDialogConfig,
} from '../../../../shared/config/dialogs/requirement-dialog.config';
import { addprojectedLaunchDateDialogConfig } from '../../../../shared/config/dialogs/projected-launch-date-dialog.config';
import { stakeholderTableCols } from '../../../../shared/config/table/stakeholder-table.config';
import { dependencyTableCols } from '../../../../shared/config/table/dependency-table.config';
import { projectedLaunchDateTableCols } from '../../../../shared/config/table/projected-launch-date-table.config';
import { requirementTableCols } from '../../../../shared/config/table/requirement-table.config';
import { riskTableCols } from '../../../../shared/config/table/risk-table.config';
import { DashTableColumnDefinition } from '@dashboard/table';
import { ExosDialogConfig } from '@dashboard/exos-dialog';
import infopoints from '../../../../shared/locale/infopoints.json';
import { InfoPointConfig } from '@dashboard/exos-form';
import { ProjectStakeholdersHelpDialogComponent } from 'src/app/shared/components/dialogs/project-stakeholders-help/project-stakeholders-help-dialog.component';
import { HelpConfig } from 'src/app/shared/models/help-config';
import { addprojectedStartDateDialogConfig } from 'src/app/shared/config/dialogs/project-start-date-dialog.config';
import { projectedStartDateTableCols } from 'src/app/shared/config/table/projected-start-date-table.config';

export interface EditableTableRouteConfig {
  [name: string]: {
    modal?: {
      add: ExosDialogConfig;
      edit?: ExosDialogConfig;
      delete?: ExosDialogConfig;
    };
    table: {
      cols: DashTableColumnDefinition[];
      noResultsFoundMsg: string;
      info?: InfoPointConfig;
      help?: HelpConfig;
    };
  };
}

export const briefRouteConfig: EditableTableRouteConfig = {
  stakeholder: {
    modal: {
      add: addStakeholderDialogConfig,
      edit: editStakeholderDialogConfig,
      delete: {
        data: {
          title: 'Delete stakeholder',
          message: 'Are you sure ?',
          action: 'stakeholders',
          text: 'Delete',
        },
      },
    },
    table: {
      cols: stakeholderTableCols,
      noResultsFoundMsg: 'No stakeholders found.',
      info: {
        hasInfoPoint: false,
      },
      help: {
        hasHelp: true,
        config: ProjectStakeholdersHelpDialogComponent,
      },
    },
  },
  dependency: {
    modal: {
      add: adddependencyDialogConfig,
      edit: editdependencyDialogConfig,
      delete: {
        data: {
          title: 'Delete dependency',
          message: 'Are you sure ?',
          action: 'dependencies',
          text: 'Delete',
        },
      },
    },
    table: {
      cols: dependencyTableCols,
      noResultsFoundMsg: 'No dependencies found.',
      info: {
        hasInfoPoint: true,
        text: infopoints.DEPENDENCIES,
      },
    },
  },
  projectedLaunchDate: {
    modal: {
      add: addprojectedLaunchDateDialogConfig,
    },
    table: {
      cols: projectedLaunchDateTableCols,
      noResultsFoundMsg: 'No projected end dates found.',
    },
  },

  projectedStartDate: {
    modal: {
      add: addprojectedStartDateDialogConfig,
    },
    table: {
      cols: projectedStartDateTableCols,
      noResultsFoundMsg: 'No projected start dates found.',
    },
  },
  requirement: {
    modal: {
      add: addrequirementDialogConfig,
      edit: editrequirementDialogConfig,
      delete: {
        data: {
          title: 'Delete requirement',
          message: 'Are you sure ?',
          action: 'requirements',
          text: 'Delete',
        },
      },
    },
    table: {
      cols: requirementTableCols,
      noResultsFoundMsg: 'No requirements found.',
      info: {
        hasInfoPoint: true,
        text: infopoints.REQUIREMENTS,
      },
    },
  },
  risk: {
    modal: {
      add: addRiskDialogConfig,
      edit: editRiskDialogConfig,
      delete: {
        data: {
          title: 'Delete risk',
          message: 'Are you sure ?',
          action: 'risks',
          text: 'Delete',
        },
      },
    },
    table: {
      cols: riskTableCols,
      noResultsFoundMsg: 'No risks found.',
      info: {
        hasInfoPoint: true,
        text: infopoints.RISKS,
      },
    },
  },
};
