import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  ProjectDocumentFileInfo,
  ProjectDocumentSection,
} from 'src/app/shared/models/google-doc.model';
import { DashNotificationService } from '@dashboard/core';
import { DocumentService } from 'src/app/shared/service/document/document.service';
import { ActivatedRoute } from '@angular/router';
import { pluck, startWith, switchMap, take, filter, map } from 'rxjs/operators';
import { combineLatest, Observable, interval, Subscription } from 'rxjs';
import { ProjectModel } from '../../../../shared/models/project.model';
import { ProjectRightsService } from '../../../../shared/service/user-rights/project-rights/project-rights.service';

@Component({
  selector: 'app-project-review',
  templateUrl: './project-review-route.component.html',
  styleUrls: ['./project-review-route.component.scss'],
})
export class ProjectReviewRouteComponent implements OnInit, OnDestroy {
  public fileId: string;
  public project: ProjectModel;
  public reviewFileSections: ProjectDocumentSection[];

  public disableGenerateButton = false;
  public canGenerateFile = false;

  projectReviewFile: ProjectDocumentFileInfo;
  private routeSubs: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private notificationService: DashNotificationService,
    private documentsService: DocumentService,
    private userRightsService: ProjectRightsService
  ) {}

  ngOnInit() {
    const project$: Observable<ProjectModel> = this.route.parent.data.pipe(
      pluck('project')
    );

    const projectReviewRouteData$: Observable<ProjectDocumentFileInfo> =
      this.route.data.pipe(pluck('projectReviewRouteData'));

    this.routeSubs.push(
      combineLatest([project$, projectReviewRouteData$])
        .pipe(
          map(([project, projectReviewRouteData]) => ({
            project,
            projectReviewRouteData,
          }))
        )
        .subscribe(({ project, projectReviewRouteData }) => {
          const {
            isClient,
            isOwner,
            isMainTeamMember,
            isRepresentative,
            isAdmin,
          } = this.userRightsService.getUserSessionStatus(project);

          const projectCanBeUpdated =
            project.phase !== 'close' && project.phase !== 'canceled';
          const userHasRightsToUpdateProject =
            isClient ||
            isOwner ||
            isMainTeamMember ||
            isRepresentative ||
            isAdmin;

          this.project = project;
          this.fileId = projectReviewRouteData.fileId;
          this.reviewFileSections = projectReviewRouteData.sections;
          this.canGenerateFile =
            projectCanBeUpdated && userHasRightsToUpdateProject;

          if (project.generatingProjectReviewInProgress) {
            this.disableGenerateButton = true;
            this.notificationService.warning('Project Review file generating.');
            interval(3000)
              .pipe(
                startWith(0),
                switchMap(() =>
                  this.documentsService.getProjectDocumentFileInfo(
                    project.folderId,
                    'Project Review'
                  )
                ),
                filter((response) => response.fileId !== null),
                take(1)
              )
              .subscribe((res: ProjectDocumentFileInfo) => {
                this.fileId = res.fileId;
                this.reviewFileSections = res.sections;
                this.notificationService.success(
                  'Project Review file was generated.'
                );
              });
          }
        })
    );
  }

  generateReviewFile(projectId: number) {
    this.notificationService.warning('Project Review file is generating.');
    this.disableGenerateButton = true;

    this.documentsService.generateReviewFile(projectId).subscribe(
      (response) => {
        this.fileId = response.fileId;
        this.reviewFileSections = response.sections;
        this.disableGenerateButton = false;
        this.notificationService.success(
          'Project Review file was successfully generated.'
        );
      },
      (error) => {
        this.notificationService.error(
          'Project Review file cannot be generated.'
        );
      }
    );
  }

  openFileInNewTab(fileId, headingId) {
    if (fileId != null) {
      window.open(
        `https://docs.google.com/document/d/${fileId}/edit#heading=${headingId}`
      );
    }
  }

  ngOnDestroy() {
    this.routeSubs.forEach((sub) => (sub ? sub.unsubscribe() : null));
  }
}
