//@ts-nocheck
import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, EMPTY } from 'rxjs';
import { catchError, find, switchMap, map } from 'rxjs/operators';
import {
  UserPreference,
  UserPreferencesService,
} from '@dashboard/user-preferences';
import { isArray } from 'rxjs/internal-compatibility';

@Injectable({
  providedIn: 'root',
})
export class UserPreferencesResolver implements Resolve<UserPreference[]> {
  constructor(
    private userPreferencesService: UserPreferencesService,
    private router: Router,
    private store: Store<any>
  ) {}

  resolve(
    route: ActivatedRouteSnapshot
  ): Observable<UserPreference | UserPreference[]> {
    const selectDashCore = (state: any) => state.dash_core;
    const section = route.data?.section;

    return this.store.select(selectDashCore).pipe(
      find((core) => core.user?.user_id),
      switchMap((core) => {
        if (!section || !core.user?.user_id) {
          this.router.navigate(['/home']);
          return EMPTY;
        }

        if (isArray(section) && section.length > 0) {
          return this.userPreferencesService
            .getMultipleSectionsByUserId(section, core.user.user_id)
            .pipe(
              map((res) => res),
              catchError(() => {
                this.router.navigate(['/home']);
                return EMPTY;
              })
            );
        } else {
          return this.userPreferencesService
            .getBySectionAndUserId(section, core.user.user_id)
            .pipe(
              map((res) => [res]),
              catchError(() => {
                this.router.navigate(['/home']);
                return EMPTY;
              })
            );
        }
      })
    );
  }
}
