import { ExosDialogConfig } from '@dashboard/exos-dialog';

const ganttDialogFields = {
  text: {
    name: 'text',
    label: 'Name',
    placeholder: 'Name',
    type: 'input',
    fxFlex: '100',
    value: null,
  },
  type: {
    name: 'type',
    label: 'Type',
    type: 'select',
    fxFlex: '33',
    value: null,
    options: [
      { text: 'Not selected', value: null },
      { text: 'Task', value: 'task' },
      { text: 'Milestone', value: 'milestone' },
    ],
  },
  parent: {
    name: 'parent',
    label: 'Parent Item',
    type: 'select',
    options: [{ text: 'Not selected', value: '0' }],
    value: 0,
    fxFlex: '33',
  },
  progress: {
    name: 'progress',
    label: 'Task progress',
    type: 'select',
    placeholder: 'Not selected',
    value: '0',
    options: [
      { text: 'Not started', value: '0' },
      { text: '10%', value: '0.1' },
      { text: '20%', value: '0.2' },
      { text: '30%', value: '0.3' },
      { text: '40%', value: '0.4' },
      { text: '50%', value: '0.5' },
      { text: '60%', value: '0.6' },
      { text: '70%', value: '0.7' },
      { text: '80%', value: '0.8' },
      { text: '90%', value: '0.9' },
      { text: 'Complete', value: '1' },
    ],
    fxFlex: '33',
  },
  assignee: {
    name: 'assignee',
    label: 'Assign to',
    placeholder: 'Not selected',
    type: 'autocomplete',
    value: null,
    items: null,
    icon: 'exos-icon-nav-user-16',
    service: {
      name: 'usersService',
      action: 'findByName',
    },
    noResultsMessage: 'No user found.',
    fxFlex: '100',
  },
  note: {
    name: 'note',
    label: 'Note',
    placeholder: 'No note',
    type: 'textarea',
    fxFlex: '50',
    value: null,
  },
  link: {
    name: 'link',
    label: 'Implementation Link',
    placeholder: 'No link',
    type: 'textarea',
    fxFlex: '50',
    value: null,
  },
  start_date: {
    name: 'start_date',
    label: 'Start Date',
    placeholder: 'Start Date',
    type: 'datepicker',
    fxFlex: '33',
    value: null,
  },
  end_date: {
    name: 'end_date',
    label: 'End Date',
    placeholder: 'End Date',
    type: 'datepicker',
    fxFlex: '33',
    value: null,
  },
  dependencies: {
    name: 'dependencies',
    label: 'Dependent on',
    placeholder: 'Search dependencies',
    type: 'selectMultiselect',
    items: null,
    selectedItems: [],
    fxFlex: '60',
    value: null,
    options: [
      { text: 'option1', value: 'option1-val' },
      { text: 'option2', value: 'option2-val' },
      { text: 'option3', value: 'option3-val' },
      { text: 'option4', value: 'option4-val' },
    ],
    noResultsMessage: 'No tasks found.',
    valueKey: 'name',
  },
};

const ganttProgramDialogFields = {
  text: {
    name: 'text',
    label: 'Name',
    placeholder: 'Name',
    type: 'input',
    fxFlex: '100',
    value: null,
  },
  type: {
    name: 'type',
    label: 'Type',
    type: 'select',
    fxFlex: '33',
    value: null,
    options: [
      { text: 'Not selected', value: null },
      { text: 'Task', value: 'task' },
      { text: 'Milestone', value: 'milestone' },
    ],
  },
  parent: {
    name: 'parent',
    label: 'Parent Item',
    type: 'select',
    options: [{ text: 'Not selected', value: '0' }],
    value: 0,
    fxFlex: '33',
  },
  progress: {
    name: 'progress',
    label: 'Task progress',
    type: 'select',
    placeholder: 'Not selected',
    value: '0',
    options: [
      { text: 'Not started', value: '0' },
      { text: '10%', value: '0.1' },
      { text: '20%', value: '0.2' },
      { text: '30%', value: '0.3' },
      { text: '40%', value: '0.4' },
      { text: '50%', value: '0.5' },
      { text: '60%', value: '0.6' },
      { text: '70%', value: '0.7' },
      { text: '80%', value: '0.8' },
      { text: '90%', value: '0.9' },
      { text: 'Complete', value: '1' },
    ],
    fxFlex: '33',
  },
  start_date: {
    name: 'start_date',
    label: 'Start Date',
    placeholder: 'Start Date',
    type: 'datepicker',
    fxFlex: '33',
    value: null,
  },
  end_date: {
    name: 'end_date',
    label: 'End Date',
    placeholder: 'End Date',
    type: 'datepicker',
    fxFlex: '33',
    value: null,
  },
};

const createDialogButtons = [
  {
    label: 'Save',
    type: 'button',
    action: 'submit',
    hasLoader: true,
    isPrimary: true,
  },
  { label: 'Close', type: 'button', action: 'close', hasLoader: false },
];

const editDialogButtons = [
  ...createDialogButtons,
  {
    label: 'Delete',
    type: 'button',
    action: 'delete',
    hasLoader: false,
    isCritical: true,
  },
];

export const createNewGanttTaskDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Add new task',
    formConfig: {
      fields: ganttDialogFields,
      buttons: createDialogButtons,
    },
  },
};

export const editNewGanttTaskDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Edit task',
    formConfig: {
      fields: ganttDialogFields,
      buttons: editDialogButtons,
    },
  },
};

export const createNewGanttTaskProgramDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Add new task',
    formConfig: {
      fields: ganttProgramDialogFields,
      buttons: createDialogButtons,
    },
  },
};

export const editNewGanttTaskProgramDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Edit task',
    formConfig: {
      fields: ganttProgramDialogFields,
      buttons: editDialogButtons,
    },
  },
};
