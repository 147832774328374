import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// guards
import { AuthGuard, RoleGuard } from '@dashboard/core';

// resolvers
import { ProjectResolver } from '../shared/service/project/project.resolver';
import { ProjectBriefFileResolver } from './containers/project-route/project-brief-route/project-brief.resolver';
import { ProjectPlanResolver } from './containers/project-route/project-plan-route/project-plan.resolver';
import { ProjectFunctionalPlanResolver } from './containers/project-route/project-functional-plan-route/project-functional-plan.resolver';

// route components
import { ProjectRouteComponent } from './containers/project-route/project-route.component';
import { ProjectOverviewRouteComponent } from './containers/project-route/project-overview-route/project-overview-route.component';
import { ProjectBriefRouteComponent } from './containers/project-route/project-brief-route/project-brief-route.component';
import { ProjectPlanRouteComponent } from './containers/project-route/project-plan-route/project-plan-route.component';
import { ProjectReviewRouteComponent } from './containers/project-route/project-review-route/project-review-route.component';
import { ProjectLinksRouteComponent } from './containers/project-route/project-links-route/project-links-route.component';
import { ProjectCreateRouteComponent } from './containers/project-create-route/project-create-route.component';
import { ProjectFunctionalPlanRouteComponent } from './containers/project-route/project-functional-plan-route/project-functional-plan-route.component';
import { ProjectReviewResolver } from './containers/project-route/project-review-route/project-review.resolver';
import { ProjectAuditRouteComponent } from './containers/project-route/project-audit-route/project-audit-route.component';
import { ChangeRequestViewRouteComponent } from './containers/project-change-request/change-request-view/change-request-view-route.component';
import { ProjectLinksResolver } from './containers/project-route/project-links-route/project-links.resolver';
import { ProjectViewComponent } from './containers/project-route/project-view/project-view.component';
import { ProjectViewResolver } from './containers/project-route/project-view/project-view.resolver';
import { DeprecatedLinksRedirectComponent } from '../shared/components/deprecated-links-redirect/deprecated-links-redirect.component';
import { FilterViewType } from '../shared/models/filter-view-type.model';
import ganttAllColumns from '../gantt/gantt-all.table.config';
import { UserPreferencesResolver } from '../shared/service/user-preferences/user-preferences.resolver';
import { ObjectiveResolver } from '../shared/service/objective/objective.resolver';

const routes: Routes = [
  //////// Old links redirect /////////
  {
    path: 'projects/all',
    component: DeprecatedLinksRedirectComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      type: FilterViewType.projects,
    },
  },
  {
    path: 'projects/ideea',
    component: DeprecatedLinksRedirectComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      type: FilterViewType.projects,
    },
  },
  {
    path: 'projects/running',
    component: DeprecatedLinksRedirectComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      type: FilterViewType.projects,
    },
  },
  {
    path: 'projects/closed',
    component: DeprecatedLinksRedirectComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      type: FilterViewType.projects,
    },
  },
  {
    path: 'projects/canceled',
    component: DeprecatedLinksRedirectComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      type: FilterViewType.projects,
    },
  },
  {
    path: 'projects/my-approvals',
    component: DeprecatedLinksRedirectComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      type: FilterViewType.projects,
    },
  },
  {
    path: 'projects/my-projects',
    component: DeprecatedLinksRedirectComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      type: FilterViewType.projects,
    },
  },
  ////////////////////////////////////
  {
    path: 'projects/create',
    component: ProjectCreateRouteComponent,
    canActivate: [AuthGuard, RoleGuard],
    resolve: {
      objectiveSets: ObjectiveResolver,
    },
  },
  {
    path: 'projects/:id',
    component: ProjectRouteComponent,
    runGuardsAndResolvers: 'always',
    resolve: {
      project: ProjectResolver,
    },
    canActivate: [AuthGuard, RoleGuard],

    children: [
      {
        path: '',
        redirectTo: 'details',
        pathMatch: 'full',
      },
      {
        path: 'details',
        component: ProjectViewComponent,
        resolve: {
          projectView: ProjectViewResolver,
        },
      },
      {
        path: 'edit',
        component: ProjectOverviewRouteComponent,
      },
      {
        path: 'brief',
        component: ProjectBriefRouteComponent,
        resolve: {
          projectBriefFile: ProjectBriefFileResolver,
        },
      },
      {
        path: 'project-plan',
        component: ProjectPlanRouteComponent,
        resolve: {
          ganttChartData: ProjectPlanResolver,
          userPreferences: UserPreferencesResolver,
        },
        data: {
          section: 'gantt',
          page: {
            tableCols: ganttAllColumns,
          },
        },
      },
      {
        path: 'functional-plan',
        component: ProjectFunctionalPlanRouteComponent,
        resolve: {
          projectPlanSections: ProjectFunctionalPlanResolver,
        },
      },
      {
        path: 'review',
        component: ProjectReviewRouteComponent,
        resolve: {
          projectReviewRouteData: ProjectReviewResolver,
        },
      },
      {
        path: 'documents-links',
        component: ProjectLinksRouteComponent,
        resolve: {
          projectLinks: ProjectLinksResolver,
        },
      },
      {
        path: 'project-history',
        component: ProjectAuditRouteComponent,
      },
      {
        path: 'change-requests/:id',
        component: ChangeRequestViewRouteComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectRoutingModule {}
