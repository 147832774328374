import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  convertToParamMap,
  Params,
  Router,
} from '@angular/router';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pluck,
  skip,
  switchMap,
  tap,
} from 'rxjs/operators';

import {
  ApiService,
  DashNotificationService,
  DiscoveryService,
  UserService,
} from '@dashboard/core';
import { ConfirmationComponent } from '../../../shared/components/dialogs/confirmation/confirmation.component';
import {
  DashTableColumnDefinition,
  DashTableComponent,
  PageContext,
  SortDirection,
  UrlDataSource,
} from '@dashboard/table';

import { ProjectModel } from '../../../shared/models/project.model';
import { JqlInputComponent } from '../../../exos/jql-input-search/components/jql-input/jql-input.component';
import { JqlTokenBuilderService } from '../../../exos/jql-input-search/services/jql-token-builder/jql-token-builder.service';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { RouteProjectColumnsService } from '../../../shared/service/route-projects-columns/route-project-columns.service';
import { Location } from '@angular/common';
import { DocumentService } from '../../../shared/service/document/document.service';
import { ListingRouteDataConfig } from '../../../shared/models/listing';
import { JqlTokenSuggestionService } from '../../../exos/jql-input-search/services/jql-token-suggestion/jql-token-suggestion.service';
import { JqlTokenValidatorService } from '../../../exos/jql-input-search/services/jql-token-validator/jql-token-validator.service';
import { JQL_CONFIG } from '../../../exos/jql-input-search/config/config';
import { UserPreferencesService as UserPreferencesServiceLocal } from '../../../shared/service/user-preferences/user-preferences.service';
import { ObjectiveService } from '../../../shared/service/objective/objective.service';
import {
  Objective,
  ObjectiveSet,
  PartialObjective,
  PartialObjectiveSet,
} from '../../../shared/models/objective.model';
import {
  AutocompleteOption,
  FormEvent,
  FormService,
} from '@dashboard/exos-form';
import { FormControl, FormGroup } from '@angular/forms';
import { filterFormConfig } from './filter.form.config';
import { DataTableExtractorService } from 'src/app/shared/service/data-table-extractor/data-table-extractor.service';
import { Program } from 'src/app/shared/models/program.model';
import { JQL_CONFIG_MERGED } from 'src/app/shared/config/jql/jql.config';
import { User } from 'src/app/shared/models/user';
import { UsersService } from 'src/app/shared/service/users/users.service';
import { DivisionService } from '../../../shared/service/division/division.service';
import { DashDialogService, ExosDialogConfig } from '@dashboard/exos-dialog';
import {
  filterSaveModalConfig,
  filterEditModalConfig,
} from 'src/app/shared/config/dialogs/save-new-filter.dialog.config';
import { deleteDialogConfig } from './delete-dialog.config';
import { AddFilterDialogComponent } from 'src/app/shared/components/dialogs/add-filter-dialog/add-filter-dialog.component';
import {
  UserFilter,
  UserFiltersService,
} from 'src/app/shared/service/user-filters/user-filters.service';
import { ExosMultiselectComponent } from 'src/app/shared/components/form/exos-multiselect/exos-multiselect.component';
import {
  FILTER_VIEW_TYPE,
  FilterViewType,
} from 'src/app/shared/models/filter-view-type.model';
import { UserFilterApiService } from 'src/app/shared/service/user-filters/user-filters-api.service';
import {
  UserPreference,
  UserPreferencesService,
} from '@dashboard/user-preferences';
import { ViewOkrDialogComponent } from 'src/app/shared/components/dialogs/view-okr-dialog/view-okr-dialog.component';
import { projectObjectiveFormConfig } from 'src/app/project/containers/project-route/project-overview-route/project-overview-form.config';
import { SECTIONS } from 'src/app/shared/config/sections/sections.config';

@Component({
  selector: 'app-listing',
  templateUrl: './listing-route.component.html',
  styleUrls: ['./listing-route.component.scss'],
  providers: [
    JqlTokenSuggestionService,
    JqlTokenValidatorService,
    {
      provide: JQL_CONFIG,
      useValue: JQL_CONFIG_MERGED,
    },
  ],
})
export class ListingRouteComponent implements OnInit, OnDestroy, AfterViewInit {
  public toggleDisplay = false;

  public routeData$: Observable<ListingRouteDataConfig>;
  public tableData: UrlDataSource<ProjectModel | Program>;

  public readonly perPageValues: Array<number> = [25, 50, 100];

  public readonly pmtHost: string = `${this.apiService.getBaseUrl('pmt')}`;

  public readonly tableNotFoundMsgProjects = 'No projects found.';
  public readonly tableNotFoundMsgPrograms = 'No programs found.';

  public tableCols: DashTableColumnDefinition[] = [];
  public dataTypeColumns: {
    [key: string]: DashTableColumnDefinition[];
  } = {};

  public pageContext: PageContext = new PageContext();

  public showStatusColumnTemplate = false;

  public searchIsBasic = false;

  public searchQuery = '';

  public dataType: FILTER_VIEW_TYPE = FilterViewType.projects;

  public jqlLocales = {
    projects: {
      button: ['Search', 'Filter'],
      placeholder: ['Search projects', 'Filter projects'],
    },
    programs: {
      button: ['Search', 'Filter'],
      placeholder: ['Search programs', 'Filter programs'],
    },
  };

  public displayLoader = true;

  public objectiveSets: {
    company: PartialObjectiveSet[];
    division: PartialObjectiveSet[];
  };
  public objectives: PartialObjective[];

  public objectiveSets$: Observable<PartialObjective[]> = this.route.data.pipe(
    tap((response) => {
      if (response.objectiveSets && response.objectiveSets.length > 0) {
        this.objectiveSets = {
          company: response.objectiveSets.filter(
            (set: PartialObjectiveSet) => !set.division?.id
          ),
          division: response.objectiveSets.filter(
            (set: PartialObjectiveSet) => !!set.division?.id
          ),
        };
        const objectives: PartialObjective[] = [];
        response.objectiveSets.forEach((set) => {
          objectives.push(...(set.objectives || []));
        });
        this.objectives = objectives as PartialObjective[];
      }
    }),
    map(({ objectiveSets }) => objectiveSets)
  );

  public userPreferences: UserPreference[];

  public routeData: ListingRouteDataConfig;

  public formConfig = filterFormConfig;
  public form: FormGroup = new FormGroup({
    type: new FormControl(),
    projectOwner: new FormControl(),
    division: new FormControl(),
    programOwner: new FormControl(),
  });

  public projectOwner$: Observable<User[]>;
  public division$: Observable<User[]>;
  public programOwner$: Observable<User[]>;

  @ViewChild(JqlInputComponent)
  public jqlInputComponent: JqlInputComponent;

  @ViewChild(DashTableComponent)
  private table: DashTableComponent;

  @ViewChild(ExosMultiselectComponent)
  private exosMultiselect: ExosMultiselectComponent;

  private queryParams;
  private $queryParamsSub: Subscription;

  public existingFile;
  public existingProjectId;
  public existingProgramId;
  public okrBaseUrl: string;

  private userId: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private apiService: ApiService,
    private documentService: DocumentService,
    private changeDetectorRef: ChangeDetectorRef,
    private userSessionService: UserService,
    private tableColumnsService: RouteProjectColumnsService,
    private jqlTokenBuilderService: JqlTokenBuilderService,
    private objectiveService: ObjectiveService,
    private exosFormService: FormService,
    private dataTableExtractor: DataTableExtractorService,
    private usersService: UsersService,
    private divisionService: DivisionService,
    private dashDialogService: DashDialogService,
    private userPreferencesService: UserPreferencesService,
    private userPreferencesServiceLocal: UserPreferencesServiceLocal,
    private userFiltersService: UserFiltersService,
    private userFilterApiService: UserFilterApiService,
    private notificationService: DashNotificationService,
    private discoveryService: DiscoveryService
  ) {}

  ngOnInit() {
    this.discoveryService.getFrontendUrls('okr').subscribe((result) => {
      this.okrBaseUrl = result.okr?.replace(/\/$/, '');
    });
    this.routeData$ = this.route.data.pipe(
      pluck('page'),
      tap((routeData: ListingRouteDataConfig) => {
        this.routeData = routeData;
        this.dataType = this.routeData.type;
        this.searchIsBasic = this.route.snapshot.queryParamMap.has('basic')
          ? this.route.snapshot.queryParamMap.get('basic') === 'true'
          : this.routeData.mainPage || false;

        // user preference
        this.getUserPreferences();

        const fullname = this.userSessionService.getSession().fullname;

        if (this.dataType === FilterViewType.projects) {
          if (
            routeData.title === 'My Approvals' &&
            routeData.projects.searchQuery === '"current approver" = '
          ) {
            routeData.projects.searchQuery += `"${fullname}"`;
          }

          if (
            routeData.title === 'My Projects' &&
            routeData.projects.searchQuery === 'owner = '
          ) {
            routeData.projects.searchQuery += `"${fullname}" OR representative = "${fullname}"`;
          }
        }

        if (
          this.dataType === FilterViewType.programs &&
          routeData.title === 'My Programs' &&
          routeData.programs.searchQuery === 'owner = '
        ) {
          routeData.programs.searchQuery += `"${fullname}" OR representative = "${fullname}"`;
        }

        if (this.dataType === FilterViewType.projects) {
          this.existingFile = this.tableCols
            .filter((col) => col.id === 'file')
            .find((col) => col.display === true);
          this.existingProjectId = this.tableCols
            .filter((col) => col.id === 'id')
            .find((col) => col.display === true);
        } else {
          this.existingProgramId = this.tableCols
            .filter((col) => col.id === 'id')
            .find((col) => col.display === true);
        }
        this.searchQuery =
          routeData[this.dataType].searchQuery || this.searchQuery;
        this.showStatusColumnTemplate = !!routeData[
          this.dataType
        ].tableCols.filter((col) => col.id === 'status').length;

        this.tableData = new UrlDataSource(
          `${this.pmtHost}/${this.dataType}/list/`,
          {
            pageContext: this.pageContext,
            dataExtractor: (response) => {
              return this.dataTableExtractor.extract(
                this.dataType,
                response,
                this.objectives
              );
            },
            totalElementsExtractor: (response) => response.meta.count,
            httpVerb: 'POST',
          }
        );
        this.tableData.onError(() => (this.displayLoader = false));
        this.tableData.onSuccess(() => (this.displayLoader = false));
      })
    );

    this.migrateFiltersFromLocalStorage();
  }

  ngAfterViewInit() {
    this.form.patchValue({ type: this.dataType });

    this.$queryParamsSub = this.route.queryParams
      .pipe(filter((params) => params !== undefined))
      .subscribe((params: Params) => {
        this.queryParams = params;
        const paramMap = convertToParamMap(this.queryParams);
        this.searchQuery = paramMap.has('search')
          ? paramMap.get('search')
          : this.searchQuery;

        this.fetchProjectsByQuery();
      });

    this.handleInputsSub();
    this.changeDetectorRef.detectChanges();
  }

  private getUserPreferences() {
    this.route.data
      .pipe(
        tap((response) => {
          this.userPreferences = response.userPreferences as UserPreference[];
        }),
        map(({ userPreferences }) => userPreferences)
      )
      .subscribe((pref) => {
        this.userPreferences = [...(pref as UserPreference[])];

        let userPrefData: UserPreference = null;
        if (this.userPreferences.length > 0) {
          this.userPreferences
            .filter((x) => !!x)
            .forEach((pref) => {
              this.dataTypeColumns[pref.section] =
                pref.userPreferences?.columns;
            });
          userPrefData = this.userPreferencesService.getPrefBySection(
            this.userPreferences,
            this.dataType
          );
        }
        if (
          userPrefData &&
          userPrefData.version ===
            SECTIONS[FilterViewType[this.dataType]].version
        ) {
          this.tableCols = userPrefData.userPreferences.columns;
        } else {
          const userPreferencesLocalStorage =
            this.userPreferencesServiceLocal.getAllUserPreferences();
          if (userPreferencesLocalStorage?.[this.dataType]?.columns) {
            const columns = this.routeData[this.dataType].tableCols;
            columns.forEach((col) => {
              const foundCol = userPreferencesLocalStorage[
                this.dataType
              ].columns.find((c) => c.id === col.id);
              if (foundCol) {
                col.display = foundCol.display;
              }
            });
            this.tableCols = columns;
          } else {
            this.tableCols = this.routeData[this.dataType].tableCols;
          }

          this.pushToUpdatePrefsQueue(
            null,
            SECTIONS[FilterViewType[this.dataType]].version
          );
        }
      });
  }

  public goToOverviewById(item: ProjectModel | Program) {
    localStorage.setItem('lastVisitedFilter', this.location.path());

    if (this.dataType === FilterViewType.projects) {
      this.goToProjectOverview(item as ProjectModel);
    } else {
      this.goToProgramOverview(item as Program);
    }
  }

  public generateOkrLink(project: ProjectModel) {
    let url = null;
    if (this.okrBaseUrl) {
      let set: ObjectiveSet = null;
      if (project?.objectiveId) {
        ['company', 'division'].forEach((type) => {
          const foundSet = this.objectiveSets[type].find((set) => {
            return (set.objectives || []).some((objective: Objective) => {
              return objective.id === project.objectiveId;
            });
          });
          if (foundSet) {
            set = foundSet;
          }
        });
      }
      url = set?.division
        ? set.division.isCompany
          ? `${this.okrBaseUrl}/company-okr?company_id=${set.division.id}&set_id=${set.id}&objective_id=${project?.objectiveId}`
          : `${this.okrBaseUrl}/division?division_id=${set.division.id}&set_id=${set.id}&objective_id=${project?.objectiveId}`
        : `${this.okrBaseUrl}`;
    }
    return url;
  }

  public openRoadMap(id) {
    var currentAbsoluteUrl = window.location.href;

    var currentRelativeUrl = this.router.url;
    var index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
    var baseUrl = currentAbsoluteUrl.substring(0, index);

    window.open(`${baseUrl}/projects/${id}/project-plan`);
  }

  public fetchProjectsByQuery() {
    const queryParams = JSON.parse(JSON.stringify(this.queryParams));

    if (!queryParams.sort || queryParams.sort.includes('null')) {
      queryParams.sort =
        this.userPreferencesService.getPrefBySection(
          this.userPreferences,
          this.dataType
        )?.userPreferences.sort || 'name,asc';
    }

    queryParams.basic = this.searchIsBasic;
    const isEmptySearch = (this.searchQuery?.trim() || '') === '';
    queryParams.search =
      this.searchIsBasic && isEmptySearch ? '' : this.searchQuery;
    queryParams.type = this.dataType;

    this.tableData.setQueryParams(queryParams);

    if (isEmptySearch) {
      this.tableData.setRequestBody([]);
    } else {
      const jqlQuery = this.jqlTokenBuilderService.buildQuery(
        this.searchQuery?.trim() || ''
      );
      this.tableData.setRequestBody(this.searchIsBasic ? [] : jqlQuery);
    }

    if (this.tableData && this.tableData.isRunning()) {
      this.tableData.generate();
    } else {
      this.tableData.start();
    }
  }

  public onSaveButton(ev: FormEvent) {
    if (this.routeData.edit) {
      this.editUserFilter(ev);
    } else {
      this.openSaveNewFilterModal(ev);
    }
  }

  public openBriefFile(folderId) {
    this.table.loading = true;
    this.documentService
      .getProjectDocumentFileInfo(folderId, 'Project Brief')
      .subscribe(({ fileId }) => {
        this.table.loading = false;
        window.open(`https://docs.google.com/document/d/${fileId}/edit`);
      });
  }

  public goToProgramOverview(program: Program) {
    this.table.loading = true;
    localStorage.setItem('lastProgramVisitedFilter', this.location.path());

    this.router.navigate([`/programs/${program.id}/edit`]).catch((error) => {
      console.error(error);
    });
  }

  public toggleColumns({ columnName, visible }) {
    if (this.dataType === FilterViewType.projects) {
      this.existingFile = this.tableCols
        .filter((col) => col.id === 'file')
        .find((col) => col.display === true);
      this.existingProjectId = this.tableCols
        .filter((col) => col.id === 'id')
        .find((col) => col.display === true);
    } else {
      this.existingProgramId = this.tableCols
        .filter((col) => col.id === 'id')
        .find((col) => col.display === true);
    }

    this.tableCols = this.tableCols.map((col) => ({
      ...col,
      display: col.name === columnName ? visible : col.display,
    }));
    this.dataTypeColumns[this.dataType] = this.tableCols;

    this.pushToUpdatePrefsQueue();
  }

  public pushToUpdatePrefsQueue(event?: any, version?: string) {
    const prefId =
      this.userPreferences.find((pref) => pref?.section === this.dataType)
        ?.id || null;
    let user_id = null;
    if (!prefId) {
      user_id = this.userSessionService.getSession().user_id;
    }

    this.userPreferencesService.next({
      id: prefId,
      user_id,
      section: this.dataType,
      version,
      userPreferences: {
        sort: this.tableData?.sortValue,
        pageSize: this.pageContext?.size,
        columns: this.tableCols,
      },
    });
  }

  public displayTypeFn(option: AutocompleteOption) {
    return option.text;
  }

  public compareTypeFn(option: AutocompleteOption, value: string) {
    return option.value === value;
  }

  public onSearchInputChanged(): void {
    if (this.form.value.projectOwner) {
      this.form.controls['projectOwner'].reset();
    }
    if (this.form.controls.division) {
      this.form.controls['division'].reset();
      if (this.exosMultiselect) {
        this.exosMultiselect.removeItems();
      }
    }
    if (this.form.value.programOwner) {
      this.form.controls['programOwner'].reset();
    }
  }

  public handleSelectedItem(event, inputType: string): void {
    this.searchIsBasic = false;

    if (event) {
      this.searchQuery = this.buildQuery();
      this.changeRouteQueryParams(null);
    }
  }

  public handleOpenDeleteModal() {
    if (this.routeData.edit) {
      const dialog = this.dashDialogService.open(
        ConfirmationComponent,
        deleteDialogConfig
      );

      dialog.afterSubmit.subscribe(() => {
        this.userFilterApiService
          .deleteFilter(this.routeData.filterKey)
          .subscribe(
            (result) => {
              dialog.close();
              this.router.navigate(['/listing/all']);
              window.location.reload();
            },
            (error) => {
              console.error(`Filter deletion error: ${error}`);
              dialog.close();
              this.notificationService.error('Error deleting filter');
            }
          );
      });
    }
  }

  public onSearchTypeChange(isBasic) {
    this.searchIsBasic = isBasic;
  }

  public changeRouteQueryParams(event: {
    query: string;
    basicSearch: boolean;
  }) {
    const queryParams: Params = {
      search: typeof event?.query === 'string' ? event.query : this.searchQuery,
      type: this.dataType,
      basic: event?.basicSearch ? event.basicSearch : this.searchIsBasic,
    };

    this.router.routeReuseStrategy.shouldReuseRoute = () => true;
    this.router
      .navigate([], {
        queryParamsHandling: 'merge',
        queryParams,
        replaceUrl: true,
        relativeTo: this.route,
      })
      .finally(() => {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      });
  }

  public openOkrModal(ev: FormEvent) {
    const modalConfig: ExosDialogConfig = {
      data: {
        formConfig: JSON.parse(JSON.stringify(projectObjectiveFormConfig)),
      },
    };
    modalConfig.data.project = ev;
    modalConfig.data.objectiveSets = this.objectiveSets;

    const dialog = this.dashDialogService.open(
      ViewOkrDialogComponent,
      modalConfig
    );

    dialog.afterSubmit.subscribe((result) => {
      this.notificationService.success('Project updated successfully');
      this.tableData.generate();
    });
  }

  public disableJqlButtons() {
    return (
      (this.routeData.edit &&
        this.routeData.ufid !==
          this.userSessionService.getSession()?.user_id) ||
      false
    );
  }

  private buildQuery(): string {
    const { projectOwner, programOwner, division } = this.form.value;

    if (this.dataType === FilterViewType.projects) {
      let query = '';
      if (projectOwner?.name) {
        query = `owner = "${projectOwner.name}"`;
      }
      return this.buildDivisionQuery(division, query);
    }

    if (this.dataType === FilterViewType.programs) {
      return programOwner?.name ? `owner = "${programOwner.name}"` : '';
    }

    return '';
  }

  private buildDivisionQuery(divisions: any[], ownerQuery?: string): string {
    if (!(divisions?.length > 0)) {
      return ownerQuery;
    }
    if (ownerQuery) {
      return divisions
        .map((division) => `${ownerQuery} AND division = "${division.item}"`)
        .join(' OR ');
    }

    return divisions
      .map((division) => `division = "${division.item}"`)
      .join(' OR ');
  }

  private editUserFilter(ev: FormEvent) {
    const modalConfig: ExosDialogConfig = JSON.parse(
      JSON.stringify(filterEditModalConfig)
    );
    modalConfig.data.formConfig.fields.filterName.value = this.routeData.title;
    const dialog = this.dashDialogService.open(
      AddFilterDialogComponent,
      modalConfig
    );

    dialog.afterSubmit.subscribe(
      (formData: { filterName: string }) => {
        this.userFilterApiService
          .editFilter({
            id: this.routeData.filterKey,
            title: formData.filterName,
            type: this.dataType,
            searchQuery: this.searchQuery,
          })
          .subscribe(
            (result) => {
              dialog.close();
              window.location.reload();
            },
            (error) => {
              console.error(error);

              dialog.close();
              this.notificationService.error('Error editing filter');
            }
          );
      },
      ({ error }) => {
        dialog.errors(error);
      }
    );
  }

  private openSaveNewFilterModal(ev: FormEvent) {
    const modalConfig: ExosDialogConfig = JSON.parse(
      JSON.stringify(filterSaveModalConfig)
    );
    const dialog = this.dashDialogService.open(
      AddFilterDialogComponent,
      modalConfig
    );

    dialog.afterSubmit.subscribe(
      (formData: { filterName: string }) => {
        const uid = this.userSessionService.getSession()?.user_id;
        if (!uid) {
          return;
        }
        this.userFilterApiService
          .addFilterForUser({
            uid,
            title: formData.filterName,
            type: this.dataType,
            searchQuery: this.searchQuery,
          })
          .subscribe(
            (result) => {
              if (result) {
                this.router
                  .navigate([`listing/my-filter/${result.id}`])
                  .then(() => {
                    dialog.close();
                    window.location.reload();
                  });
              }
            },
            (error) => {
              console.error(error);
              dialog.close();
              this.notificationService.error('Error adding filter');
            }
          );
      },
      ({ error }) => {
        dialog.errors(error);
      }
    );
  }

  private goToProjectOverview(project: ProjectModel) {
    if (this.tableColumnsService.canViewProjectActions(project)) {
      this.table.loading = true;
      this.router.navigate(['projects', project.id, 'details']);
    }
  }

  private checkAndMigrateUserPreferences() {
    const userPrefData = this.userPreferencesService.getPrefBySection(
      this.userPreferences,
      this.dataType
    );

    if (!userPrefData && this.dataTypeColumns[this.dataType]?.length) {
      this.pushToUpdatePrefsQueue();
    }
  }

  private handleInputsSub() {
    this.form.controls.type.valueChanges.subscribe((data) => {
      this.dataType = data.value;
      this.pageContext.page = 1;
      this.searchQuery = null;
      this.displayLoader = true;

      this.onSearchInputChanged();

      this.generateTableCols(this.dataType);

      this.tableData.setUrlNoGenerate(`${this.pmtHost}/${data.value}/list/`);

      this.changeRouteQueryParams(null);

      this.checkAndMigrateUserPreferences();
    });

    this.projectOwner$ = this.form.controls.projectOwner.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((value) => !!value && typeof value === 'string'),
      filter((value) => value.trim().length > 2),
      switchMap((value): any[] => {
        const projectOwner = filterFormConfig.fields['projectOwner'] as any;

        return this[projectOwner.service.name]
          [projectOwner.service.action](value)
          .pipe(
            map((data: any[]) => {
              return data.map((item) => ({
                ...item,
                value: item.id,
                text: item.value,
              }));
            }),
            catchError(() => {
              return of(null);
            })
          );
      })
    );

    this.division$ = this.form.controls.division.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((value) => !!value && typeof value === 'string'),
      filter((value) => value.trim().length > 2),
      switchMap((value): any[] => {
        const division = filterFormConfig.fields['division'] as any;

        this.exosMultiselect.isLoading = true;
        this.exosMultiselect.displayNoResultsMsg = false;

        return this[division.service.name][division.service.action](value).pipe(
          map((data: any[]) => {
            this.exosMultiselect.isLoading = false;
            this.exosMultiselect.displayNoResultsMsg = data.length === 0;
            return data.map((item) => ({
              ...item,
              value: item.id,
              text: item.item,
            }));
          }),
          catchError(() => {
            this.exosMultiselect.isLoading = false;
            return of(null);
          })
        );
      })
    );

    this.programOwner$ = this.form.controls.programOwner.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((value) => !!value && typeof value === 'string'),
      filter((value) => value.trim().length > 2),
      switchMap((value): any[] => {
        const programOwner = filterFormConfig.fields['programOwner'] as any;

        return this[programOwner.service.name]
          [programOwner.service.action](value)
          .pipe(
            map((data: any[]) => {
              return data.map((item) => ({
                ...item,
                value: item.id,
                text: item.value,
              }));
            }),
            catchError(() => {
              return of(null);
            })
          );
      })
    );
  }

  ngOnDestroy() {
    this.$queryParamsSub.unsubscribe();
  }

  private generateTableCols(dataType: string): void {
    const userPreferencesLocalStorage =
      this.userPreferencesServiceLocal.getAllUserPreferences();

    if (this.dataTypeColumns?.[dataType]?.length > 0) {
      this.tableCols = this.dataTypeColumns[dataType];
    } else if (userPreferencesLocalStorage?.[dataType]?.columns) {
      this.dataTypeColumns[this.dataType] =
        userPreferencesLocalStorage[dataType].columns;
      this.tableCols = userPreferencesLocalStorage[dataType].columns;
    } else {
      this.tableCols = this.routeData[dataType].tableCols;
    }
  }

  private migrateFiltersFromLocalStorage() {
    this.userId = this.userSessionService.getId().toString();
    this.userFilterApiService
      .getFiltersForUser(this.userId)
      .subscribe((filters) => {
        if (filters.length === 0) {
          const filtersLocalStorage = this.userFiltersService.getUserFilters();
          if (filtersLocalStorage) {
            let arrayFilters = Object.keys(filtersLocalStorage).map((key) => {
              filtersLocalStorage[key].uid = this.userId;
              return filtersLocalStorage[key];
            });
            if (arrayFilters.length > 0) {
              this.userFilterApiService
                .addAllFiltersForUser(arrayFilters)
                .subscribe((filters) => {});
            }
          }
        }
      });
  }
}
