import { Component, OnInit } from '@angular/core';
import { ExosFormComponent } from '@dashboard/exos-form';

@Component({
  selector: 'app-custom-exos-form',
  templateUrl: './custom-exos-form.component.html',
  styleUrls: ['./custom-exos-form.component.scss'],
})
export class CustomExosFormComponent
  extends ExosFormComponent
  implements OnInit
{
  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
