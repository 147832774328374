import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GanttChartData } from '../../../../shared/models/gantt.model';
import { ProgramGanttProcessorService } from '../../../../shared/service/program-gantt-processor/program-gantt-processor.service';
import { ProgramRightsService } from '../../../../shared/service/user-rights/program-rigths/program-rights.service';
import { DhtmlxGanttService } from '../../../../shared/service/dhtmlx-gantt-service/dhtmlx-gantt.service';
import { ListingRouteDataConfig } from 'src/app/shared/models/listing';
import { gantt } from 'src/app/shared/service/dhtmlx-gantt-service/dhtmlx-gantt';
import { DashTableColumnDefinition } from '@dashboard/table';

@Component({
  selector: 'app-program-plan-route',
  templateUrl: './program-plan-route.component.html',
  styleUrls: ['./program-plan-route.component.scss'],
})
export class ProgramPlanRouteComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public fetchError$: Observable<boolean> = this.route.data.pipe(
    pluck('ganttData', 'fetchError')
  );

  public ganttData$: Observable<GanttChartData> = this.route.data.pipe(
    pluck('ganttData', 'data')
  );

  public routeData: ListingRouteDataConfig;

  public tableCols: DashTableColumnDefinition[];

  constructor(
    private route: ActivatedRoute,
    private userRights: ProgramRightsService,
    private programGanttProcessor: ProgramGanttProcessorService,
    private dhtmlxGanttService: DhtmlxGanttService
  ) {}

  ngOnInit(): void {
    const programId = parseInt(
      this.route.snapshot.parent.paramMap.get('id'),
      10
    );

    this.route.data.pipe(pluck('page')).subscribe((routeData) => {
      this.routeData = routeData;
      this.tableCols = routeData.tableCols;
      this.programGanttProcessor.initDataProcessor(programId);
    });

    const program = this.route.parent.snapshot.data.program;

    this.dhtmlxGanttService.setCanUpdate(
      !this.userRights.getUserRights(program).canUpdate
    );
  }

  ngOnDestroy() {
    this.programGanttProcessor.flushProcessor();
  }

  ngAfterViewInit(): void {
    this.convertToGanttCols();
  }

  private convertToGanttCols() {
    gantt.config.columns.forEach((elem) => {
      const col = this.tableCols.find((col) => col.value === elem.name);
      if (!col) {
        elem.hide = true;
      } else {
        elem.hide = false;
      }
    });
  }
}
