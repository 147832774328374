import { Directive, Input } from '@angular/core';
import { ExosBaseSemanticType } from '../../exos.model';

export type ParagraphType = 'leading';

@Directive({
  selector: '[exosParagraph]',
  host: {
    class: 'paragraph',
    // type
    '[class.paragraph--leading]': "type === 'leading'",
    // layout
    '[class.paragraph--last]': 'isLast',
    '[class.paragraph--narrow]': 'isNarrow',
    '[class.paragraph--ellipsized]': 'isEllipsized',
    // style
    '[class.paragraph--minor]': 'isMinor',
    '[class.paragraph--bold]': 'isBold',
    '[class.paragraph--large]': 'isLarge',
    '[class.paragraph--small]': 'isSmall',
    '[class.paragraph--uppercase]': 'isUppercase',
    '[class.paragraph--bright]': 'isBright',
    '[class.paragraph--dark]': 'isDark',
    // semantic
    '[class.paragraph--activating]': "semantic === 'activating'",
    '[class.paragraph--success]': "semantic === 'success'",
    '[class.paragraph--warning]': "semantic === 'warning'",
    '[class.paragraph--critical]': "semantic === 'critical'",
    '[class.paragraph--neutral]': "semantic === 'neutral'",
  },
})
export class ParagraphDirective {
  @Input() type: ParagraphType;
  @Input() semantic: ExosBaseSemanticType;
  @Input() isBright: boolean;
  @Input() isDark: boolean;
  @Input() isLast: boolean;
  @Input() isNarrow: boolean;
  @Input() isEllipsized: boolean;
  @Input() isUppercase: boolean;
  @Input() isMinor: boolean;
  @Input() isBold: boolean;
  @Input() isLarge: boolean;
  @Input() isSmall: boolean;
}
