import { Component } from '@angular/core';
import { EnvironmentType, MenuType } from '@dashboard/core';
import { environment } from '../environments/environment';
import { menuData } from './menu';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  env: EnvironmentType = environment;
  menu: MenuType = menuData;
}
