<div class="mb-4">
  <div class="table__toolbar">
    <div fxLayout="row" fxLayoutAlign="end center">
      <button *ngIf="canGenerateFile" exosButton (click)="addNewFile($event)">
        Add new functional stream
      </button>
      <a exosLink visual="helpandlearn" (click)="openHelpLink()">Help</a>
    </div>
  </div>

  <dash-table
    [dataSource]="projectDocuments"
    [cols]="cols[project.workflow]"
    noDataMessage="No streams yet"
  >
    <dash-table-row-details [if]="handleExpandRow">
      <div
        *dashTableRowDetailsTemplate="let row; let index"
        class="row-details"
      >
        <div fxLayout="row wrap" style="border-top: 1px solid #e6e6e6">
          <ng-container *ngFor="let section of row.sections">
            <div fxFlex="25" style="padding: 2rem">
              <h4 exosHeadline type="paragraph">
                {{ section.heading }}
              </h4>

              <p exosParagraph>
                {{ section.description }}
              </p>

              <p exosParagraph *ngIf="row.fileDriveId" class="mt-1">
                <a
                  exosLink
                  visual="external-action"
                  target="_blank"
                  href="https://docs.google.com/document/d/{{
                    row.fileDriveId
                  }}/edit"
                >
                  View file
                </a>
              </p>
            </div>
          </ng-container>
        </div>
      </div>
    </dash-table-row-details>
  </dash-table>
</div>
