<form [formGroup]="form">
  <div fxLayout="row wrap" fxLayoutGap="32px grid">
    <div fxFlex="50">
      <div exosSheet>
        <div exosSheetSection>
          <div exosSheetBlock>
            <h3 exosHeadline type="sub">Details</h3>

            <div fxLayout="row warp" fxLayoutGap="16px grid">
              <ng-container *ngFor="let field of fields">
                <div
                  [fxFlex]="field.fxFlex"
                  *ngIf="field.section === 'project-details-section'"
                >
                  <ng-container
                    dynamicFormField
                    [config]="field"
                    [group]="form"
                  >
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex="50" fxLayout="column">
      <div exosSheet>
        <div exosSheetSection>
          <div exosSheetBlock>
            <h3 exosHeadline type="sub">Objective</h3>

            <div fxLayout="row warp" fxLayoutGap="16px grid">
              <ng-container *ngFor="let field of fields">
                <div
                  [fxFlex]="field.fxFlex"
                  *ngIf="field.section === 'section-objective'"
                >
                  <ng-container
                    dynamicFormField
                    [config]="field"
                    [group]="form"
                  >
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div exosSheet>
        <div exosSheetSection>
          <div exosSheetBlock>
            <h3 exosHeadline type="sub">Project Team</h3>
            <div fxLayout="row warp" fxLayoutGap="16px grid">
              <ng-container *ngFor="let field of fields">
                <div
                  [fxFlex]="field.fxFlex"
                  *ngIf="field.section === 'project-team-section'"
                >
                  <ng-container
                    dynamicFormField
                    [config]="field"
                    [group]="form"
                  >
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    exosSheet
    *ngIf="config.buttons.length"
    class="row-buttons row-buttons--sticky"
  >
    <div exosSheetSection semantic="neutral">
      <div exosSheetBlock>
        <ng-container *ngFor="let button of config.buttons">
          <span class="button--container">
            <ng-container
              dynamicFormField
              (handleFormAction)="onFormAction($event)"
              [config]="button"
              [group]="form"
            >
            </ng-container>
          </span>
        </ng-container>
      </div>
    </div>
  </div>
</form>

<ng-template #loading>
  <div class="loading-circle loading-circle--small loading-circle--secondary">
    <span class="loading-circle__circle"></span>
    <span class="loading-circle__circle"></span>
    <span class="loading-circle__circle"></span>
  </div>
</ng-template>
