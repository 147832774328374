import { Component, OnInit } from '@angular/core';
import { ProjectDocumentFileInfo } from 'src/app/shared/models/google-doc.model';
import { DocumentService } from 'src/app/shared/service/document/document.service';
import { ActivatedRoute } from '@angular/router';
import { map, pluck } from 'rxjs/operators';
import { DashNotificationService } from '@dashboard/core';
import {
  DashTableColumnDefinition,
  ArrayDataSource,
  DashTableColumnActions,
} from '@dashboard/table';
import { DashDialogService, ExosDialogConfig } from '@dashboard/exos-dialog';
import { AddItemDialogComponent } from '../../../../shared/components/dialogs/add-item-dialog/add-item-dialog.component';
import { functionalPlanRouteConfig } from './project-functionl-plan-route.config';
import { ProjectModel } from 'src/app/shared/models/project.model';
import { EditableTableRouteConfig } from '../project-brief-route/project-brief-route.config';
import { combineLatest, Observable } from 'rxjs';
import { ProjectRightsService } from '../../../../shared/service/user-rights/project-rights/project-rights.service';
import { addFunctionalPlanDialogConfig } from '../../../../shared/config/dialogs/functional-plan-dialog.config';
import { ConfirmationComponent } from '../../../../shared/components/dialogs/confirmation/confirmation.component';
import { ProjectFunctionalHelpDialogComponent } from 'src/app/shared/components/dialogs/project-functional-plan-help/project-functional-help-dialog.component';

@Component({
  selector: 'app-functional-plan',
  templateUrl: './project-functional-plan-route.component.html',
  styleUrls: ['./project-functional-plan-route.component.scss'],
})
export class ProjectFunctionalPlanRouteComponent implements OnInit {
  routeConfig: EditableTableRouteConfig = functionalPlanRouteConfig;
  projectDocuments: ArrayDataSource<ProjectDocumentFileInfo>;
  project: ProjectModel;
  cols: { [key: string]: DashTableColumnDefinition[] } = {
    ionos: [
      {
        id: 'streamName',
        name: 'Stream Name',
        value: 'streamName',
        sortable: false,
        display: true,
      },
      {
        id: 'fileName',
        name: 'File Name',
        value: 'fileName',
        sortable: false,
        display: true,
      },
      {
        id: 'responsible',
        name: 'Responsible',
        value: (id: string, row: any) => {
          return !!row.responsible ? row.responsible.name : '';
        },
        sortable: false,
        display: true,
      },
      {
        id: 'actions',
        name: 'Actions',
        value: null,
        display: true,
        actions: (row: any) => {
          const defaultActions: DashTableColumnActions = [
            {
              name: 'View File',
              // tslint:disable-next-line:no-shadowed-variable
              action: (row) => {
                if (row.fileDriveId) {
                  window.open(
                    `https://docs.google.com/document/d/${row.fileDriveId}/edit`
                  );
                }
              },
            },
            {
              name: 'Add/Edit responsible',
              action: (row: ProjectDocumentFileInfo) => {
                this.handleEditModal(row);
              },
            },
          ];

          if (row.custom) {
            defaultActions.push({
              name: 'Delete',
              action: (row: ProjectDocumentFileInfo) => {
                this.handleDeleteFile(row);
              },
            });
          }

          return this.canGenerateFile ? defaultActions : [];
        },
      },
    ],
    'united-internet': [
      {
        id: 'title',
        name: 'File Name',
        value: 'title',
        sortable: false,
        display: true,
      },
      {
        id: 'responsible',
        name: 'Responsible',
        value: (id: string, row: any) => {
          return !!row.responsible ? row.responsible.name : '';
        },
        sortable: false,
        display: true,
      },
      {
        id: 'actions',
        name: 'Actions',
        value: null,
        display: true,
        actions: (row: any) => {
          const defaultActions: DashTableColumnActions = [
            {
              name: 'View File',
              // tslint:disable-next-line:no-shadowed-variable
              action: (row) => {
                if (row.fileDriveId) {
                  window.open(
                    `https://drive.google.com/open?id=${row.fileDriveId}`
                  );
                }
              },
            },
            {
              name: 'Add/Edit responsible',
              action: (row: ProjectDocumentFileInfo) => {
                this.handleEditModal(row);
              },
            },
            {
              name: 'Delete',
              action: (row: ProjectDocumentFileInfo) => {
                this.handleDeleteFile(row);
              },
            },
          ];

          return this.canGenerateFile ? defaultActions : [];
        },
      },
    ],
  };
  canGenerateFile = false;

  constructor(
    private route: ActivatedRoute,
    private documentsService: DocumentService,
    private notificationService: DashNotificationService,
    private dialogService: DashDialogService,
    private documentService: DocumentService,
    private userRightsService: ProjectRightsService
  ) {}

  ngOnInit() {
    const project$: Observable<ProjectModel> = this.route.parent.data.pipe(
      pluck('project')
    );

    const projectFunctionalPlanDocuments$: Observable<
      ProjectDocumentFileInfo[]
    > = this.route.data.pipe(pluck('projectPlanSections'));

    combineLatest(project$, projectFunctionalPlanDocuments$)
      .pipe(
        map(([project, projectFunctionalPlanDocuments]) => ({
          project,
          projectFunctionalPlanDocuments,
        }))
      )
      .subscribe(({ project, projectFunctionalPlanDocuments }) => {
        this.projectDocuments = new ArrayDataSource(
          projectFunctionalPlanDocuments
        );
        this.projectDocuments.start();
        this.project = project;

        const userSessionStatus =
          this.userRightsService.getUserSessionStatus(project);
        const projectIsInCancelOrCompletePhase =
          project.phase === 'close' || project.phase === 'canceled';
        const {
          isClient,
          isOwner,
          isMainTeamMember,
          isRepresentative,
          isAdmin,
        } = userSessionStatus;
        this.canGenerateFile =
          (isOwner || isRepresentative || isAdmin) &&
          !projectIsInCancelOrCompletePhase;
      });
  }

  public fetchFunctionalPlanFiles(projectId: number) {
    this.documentService.getProjectPlanSections(projectId).subscribe((data) => {
      this.projectDocuments.setData(data);
    });
  }

  public generatePlanFile(projectPlanFileId: number, fileName: string) {
    if (!this.canGenerateFile) {
      this.notificationService.warning(
        `File is not generated and you do not have permission to perform this action.`
      );
      return;
    }

    this.notificationService.warning(`${fileName} file is generating.`);

    this.documentsService
      .generatePlanFile(this.project.id, projectPlanFileId)
      .subscribe(() => {
        this.documentService
          .getProjectPlanSections(this.project.id)
          .subscribe((response) => {
            this.projectDocuments.setData(response);
          });
        this.notificationService.success(
          'Project plan file has been generated.'
        );
      });
  }

  private handleEditModal(row: ProjectDocumentFileInfo) {
    if (!this.canGenerateFile) {
      this.notificationService.warning(
        `File is not generated and you do not have permission to perform this action.`
      );
      return;
    }

    const dialogConfig: ExosDialogConfig =
      this.routeConfig.responsible.modal.edit;
    const dialog = this.dialogService.open(
      AddItemDialogComponent,
      dialogConfig
    );

    dialog.afterSubmit.subscribe((formData) => {
      this.documentsService
        .saveResponsible(this.project.id, row.fileId, formData.responsible)
        .subscribe(
          () => {
            this.documentService
              .getProjectPlanSections(this.project.id)
              .subscribe((response) => {
                this.projectDocuments.setData(response);
                dialog.close();
              });
            this.notificationService.success(
              'Successfully updated responsible'
            );
          },
          ({ error }) => {
            this.notificationService.error('Responsible update failed.');
            dialog.errors(error);
          }
        );
    });
  }

  public handleExpandRow(row) {
    return !!(row.sections && row.sections.length);
  }

  public addNewFile(event) {
    const dialog = this.dialogService.open(
      AddItemDialogComponent,
      addFunctionalPlanDialogConfig[this.project.workflow]
    );

    dialog.afterSubmit.subscribe((formData) => {
      if (
        formData.streamName !== null &&
        formData.fileName !== null &&
        formData.template !== null
      ) {
        this.notificationService.warning('File is generating.');
      }

      let generateFileAction: Observable<ProjectModel> =
        this.documentsService.addNewFunctionalPlanStream(
          this.project.id,
          formData
        );
      if (this.project.workflow === 'united-internet') {
        generateFileAction = this.documentsService.addNewUnitedInternetPlanFile(
          this.project.id,
          formData
        );
      }
      generateFileAction.subscribe(
        () => {
          this.documentService
            .getProjectPlanSections(this.project.id)
            .subscribe((response) => {
              this.projectDocuments.setData(response);
              dialog.close();
            });
          this.notificationService.success('Successfully generated new stream');
        },
        ({ error }) => {
          if (error.errors && error.errors.fields) {
            dialog.errors(error);
          } else {
            this.notificationService.error('Stream generation failed.');
            dialog.close();
          }
        }
      );
    });
  }

  handleDeleteFile(row) {
    const deleteModalConfig: ExosDialogConfig = {
      data: {
        title: 'Delete file',
        message: 'Are you sure ?',
        text: 'Delete',
      },
    };

    const dialog = this.dialogService.open(
      ConfirmationComponent,
      deleteModalConfig
    );
    const projectId = this.project.id;

    dialog.afterSubmit.subscribe(() => {
      this.notificationService.warning('Deleting file in progress');
      this.documentService
        .deleteFunctionalPlanStream(projectId, row.fileId)
        .subscribe((project) => {
          this.notificationService.success('Successfully updated files');
          dialog.close();
          this.fetchFunctionalPlanFiles(projectId);
          // this.projectDocuments.setData(project.projectPlanFiles);
        });
    });
  }

  openHelpLink() {
    return this.dialogService.open(ProjectFunctionalHelpDialogComponent, {
      data: { title: '' },
    });
  }
}
