<div exos-sheet>
  <div exosSheetSection type="secondary">
    <div exos-sheet-block>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h2 class="mb-0" exosHeadline type="sub">
          {{ heading }}

          <i
            class="exos-icon exos-icon-help-18 ml-05"
            style="color: #0b9dcc; font-size: 16px"
            exosTooltip="The end date list contains the latest projected end date (highlighted in bold blue on the very top of the list) as well as prior end dates defined and set by the respective project owner."
            exosTooltipPosition="above"
          >
          </i>
        </h2>

        <a *ngIf="canUpdate && data.length" exosButton (click)="onUpdate()">
          Edit
        </a>
      </div>
    </div>
  </div>

  <table class="table table--responsive">
    <ng-container *ngIf="!data.length">
      <tr class="table__row">
        <td class="table__cell">
          <div style="padding: 1rem">Not set.</div>
        </td>

        <td class="table__cell align-right">
          <div style="padding: 1rem">
            <button
              *ngIf="canUpdate"
              exosLink
              visual="action"
              (click)="onUpdate()"
            >
              Add end date
            </button>
          </div>
        </td>
      </tr>
    </ng-container>

    <ng-container *ngIf="data.length">
      <tr
        *ngFor="let projectedDate of data"
        class="table__row"
        [class.table__row--activating]="projectedDate.isCurrent"
      >
        <td class="table__cell">
          <span
            class="paragraph"
            [class.paragraph--bold]="projectedDate.isCurrent"
          >
            {{ projectedDate.date | date: 'EEEE, MMMM d, y' }}
          </span>
        </td>
        <td class="table__cell table__cell--align-right">
          <i
            *ngIf="projectedDate.isCurrent"
            class="exos-icon exos-icon-crown-20 color--primary"
          ></i>
        </td>
      </tr>
    </ng-container>
  </table>
</div>
