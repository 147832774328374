import { FieldConfigType, FormConfig } from '@dashboard/exos-form';

const programDetailsFormConfig: {
  [key: string]: FieldConfigType | FieldConfigType[];
} = {
  name: {
    name: 'name',
    label: 'Program Name',
    placeholder: 'Program Name',
    type: 'input',
    fxFlex: '100',
    value: null,
  },
  description: {
    name: 'description',
    label: 'Program Description',
    type: 'textarea',
    fxFlex: '100',
    rows: '5',
    cols: '30',
    value: null,
    resizable: 'vertical',
  },
};

const programTeamFormConfig: {
  [key: string]: FieldConfigType | FieldConfigType[];
} = {
  client: {
    name: 'client',
    label: 'Program Client',
    placeholder: 'Program Client',
    type: 'autocomplete',
    fxFlex: '100',
    value: null,
    items: null,
    icon: 'exos-icon-nav-user-16',
    service: {
      name: 'usersService',
      action: 'findByName',
    },
    noResultsMessage: 'No user found.',
  },
  owner: {
    name: 'owner',
    label: 'Program Owner',
    placeholder: 'Program Owner',
    type: 'autocomplete',
    items: null,
    fxFlex: '100',
    value: null,
    icon: 'exos-icon-nav-user-16',
    disabled: false,
    service: {
      name: 'usersService',
      action: 'findByName',
    },
    noResultsMessage: 'No user found.',
  },
  representative: {
    name: 'representative',
    label: 'Program Representative',
    placeholder: 'Program Representative',
    type: 'multiselect',
    items: null,
    selectedItems: [],
    fxFlex: '100',
    value: null,
    section: 'section4',
    icon: 'exos-icon-nav-user-16',
    service: {
      name: 'usersService',
      action: 'findByName',
    },
    noResultsMessage: 'No user found.',
  },
};

const programProjectsFormConfig: {
  [key: string]: FieldConfigType | FieldConfigType[];
} = {
  projects: {
    name: 'projects',
    label: 'Projects',
    placeholder: 'Projects',
    type: 'multiselect',
    fxFlex: '100',
    items: null,
    selectedItems: [],
    value: null,
    service: {
      name: 'projectService',
      action: 'findProjectByName',
    },
    noResultsMessage: 'No project found.',
  },
};

export const programCreateFormConfig: FormConfig = {
  fields: {
    ...programDetailsFormConfig,
    ...programTeamFormConfig,
    ...programProjectsFormConfig,
  },
  buttons: [
    {
      action: 'create',
      type: 'button',
      isPrimary: true,
      label: 'Create Program',
    },
    {
      action: 'cancel',
      type: 'button',
      label: 'Cancel',
    },
  ],
};

export const programEditFormConfig: FormConfig = {
  fields: {
    ...programDetailsFormConfig,
    ...programTeamFormConfig,
    ...programProjectsFormConfig,
  },
  buttons: [],
};
