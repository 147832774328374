import {
  ContentChild,
  Directive,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import {
  CdkPortalOutlet,
  ComponentPortal,
  PortalOutlet,
} from '@angular/cdk/portal';
import { InlineEditFormComponent } from './inline-edit-form/inline-edit-form.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface InlineEditConfig {
  controlName: string | null;
  controlValue: string | null;
}

@Directive({
  selector: '[inlineEdit]',
  host: {
    class: 'inline-edit-box',
    '[class.inline-edit-box--enabled]': '!disabled',
    '[class.inline-edit-box--form-is-visible]': 'formIsVisible',
  },
})
export class InlineEditDirective implements OnDestroy {
  private readonly destroyed$ = new Subject<void>();

  private formInstance: InlineEditFormComponent;

  public formIsVisible: boolean;

  // tslint:disable-next-line:no-input-rename
  @Input('inlineEditConfig') formConfig: InlineEditConfig;

  @Input('inlineEditDisabled') disabled: boolean;

  @Output() inlineEditValue: EventEmitter<any> = new EventEmitter<any>();

  @ContentChild(CdkPortalOutlet) formPortalOutlet: PortalOutlet;

  @HostListener('click') showInlineForm() {
    if (this.disabled) {
      return;
    }

    if (!this.formIsVisible) {
      this.formInstance = this._buildFormInstance();
      this.formIsVisible = true;
    }
  }

  constructor() {}

  public close(): void {
    this.formPortalOutlet.detach();
    this.formIsVisible = false;
  }

  public setFormError(error: any) {
    this.formInstance.setError(error);
  }

  private _buildFormInstance(): InlineEditFormComponent {
    const portal = new ComponentPortal(InlineEditFormComponent);

    const instance = this.formPortalOutlet.attach(portal)
      .instance as InlineEditFormComponent;

    instance.config = this.formConfig;

    instance.onSubmit$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((editValue) => this.handleEditSubmit(editValue));

    instance.onCancel$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.handleEditCancel());

    return instance;
  }

  private handleEditSubmit(value) {
    this.inlineEditValue.next(value);
  }

  private handleEditCancel() {
    this.formPortalOutlet.detach();
    this.formIsVisible = false;
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
