<div *ngIf="isLoading">
  <div class="loading-spin loading-spin--large"></div>
</div>

<form
  [hidden]="isLoading"
  [formGroup]="form"
  fxLayout="row wrap"
  fxLayoutGap="32px grid"
>
  <!--  Project details, Project description, Project okr -->
  <div fxFlex="50" fxFlex.lt-lg="100">
    <div exosSheet>
      <div exosSheetSection type="secondary">
        <div exosSheetBlock>
          <h3 exosHeadline type="sub">
            {{ formConfig.details.heading }}
          </h3>
        </div>
      </div>

      <div exosSheetSection>
        <div exosSheetBlock>
          <div fxLayout="row wrap" fxLayoutGap="1rem grid">
            <exos-input
              [group]="form"
              fxFlex="100"
              [name]="formConfig.details.fields?.name.name"
              [label]="formConfig.details.fields?.name.label"
              [placeholder]="formConfig.details.fields?.name.placeholder"
            >
            </exos-input>

            <exos-select
              [group]="form"
              fxFlex="100"
              [name]="formConfig.details.fields?.effort.name"
              [label]="formConfig.details.fields?.effort.label"
              [placeholder]="formConfig.details.fields?.effort.placeholder"
              [options]="formConfig.details.fields?.effort.options"
              [infoPoint]="formConfig.details.fields?.effort.infoPoint.text"
            >
            </exos-select>

            <exos-select
              [group]="form"
              fxFlex="100"
              [name]="formConfig.details.fields?.prio.name"
              [label]="formConfig.details.fields?.prio.label"
              [placeholder]="formConfig.details.fields?.prio.placeholder"
              [options]="formConfig.details.fields?.prio.options"
              [infoPoint]="formConfig.details.fields?.prio.infoPoint.text"
            >
            </exos-select>

            <exos-select
              [group]="form"
              fxFlex="100"
              [name]="formConfig.details.fields?.workflow.name"
              [label]="formConfig.details.fields?.workflow.label"
              [placeholder]="formConfig.details.fields?.workflow.placeholder"
              [options]="formConfig.details.fields?.workflow.options"
              [displayFn]="displayWorkflowFn"
              [compareFn]="compareWorkflowFn"
            >
            </exos-select>

            <exos-autocomplete
              [group]="form"
              fxFlex="100"
              [name]="formConfig.details.fields?.program.name"
              [label]="formConfig.details.fields?.program.label"
              [placeholder]="formConfig.details.fields?.program.placeholder"
              [noResultsMessage]="
                formConfig.details.fields?.program.noResultsMessage
              "
              [items]="programs$"
            >
            </exos-autocomplete>

            <exos-multiselect
              icon="exos-icon-marker-16"
              [group]="form"
              fxFlex="100"
              [name]="formConfig.details.fields?.tags.name"
              [label]="formConfig.details.fields?.tags.label"
              [placeholder]="formConfig.details.fields?.tags.placeholder"
              [results]="tag$"
            >
            </exos-multiselect>
          </div>
        </div>
      </div>
    </div>

    <div exosSheet>
      <div exosSheetSection type="secondary">
        <div exosSheetBlock>
          <h3 exosHeadline type="sub">
            {{ formConfig.description.heading }}
          </h3>
        </div>
      </div>

      <div exosSheetSection>
        <div exosSheetBlock>
          <div fxLayout="row wrap" fxLayoutGap="1rem grid">
            <exos-wysiwyg
              [group]="form"
              fxFlex="100"
              (event)="openEditDescriptionModal($event)"
              [config]="formConfig.description.fields?.html_description"
              [name]="formConfig.description.fields?.html_description.name"
              [label]="formConfig.description.fields?.html_description.label"
              [placeholder]="
                formConfig.description.fields?.html_description.placeholder
              "
            >
            </exos-wysiwyg>
          </div>
        </div>
      </div>
    </div>

    <div exosSheet>
      <div exosSheetSection type="secondary">
        <div exosSheetBlock>
          <h3 exosHeadline type="sub">
            {{ formConfig.roadmap.heading }}
          </h3>
        </div>
      </div>

      <div exosSheetSection>
        <div exosSheetBlock>
          <div fxLayout="row wrap" fxLayoutGap="1rem grid">
            <exos-checkbox
              [group]="form"
              fxFlex="100"
              [name]="formConfig.roadmap.fields?.show_on_roadmap.name"
              [label]="formConfig.roadmap.fields?.show_on_roadmap.label"
            >
            </exos-checkbox>

            <exos-autocomplete
              [items]="productSpace$"
              [group]="form"
              fxFlex="100"
              [name]="formConfig.roadmap.fields?.product_space.name"
              [label]="formConfig.roadmap.fields?.product_space.label"
              [placeholder]="
                formConfig.roadmap.fields?.product_space.placeholder
              "
              [noResultsMessage]="
                formConfig.roadmap.fields?.product_space.noResultsMessage
              "
            >
            </exos-autocomplete>

            <exos-select
              [group]="form"
              fxFlex="100"
              [name]="formConfig.roadmap.fields?.product.name"
              [label]="formConfig.roadmap.fields?.product.label"
              [placeholder]="formConfig.roadmap.fields?.product.placeholder"
              [options]="products"
              [infoPoint]="formConfig.roadmap.fields?.product.infoPoint.text"
              [displayFn]="displayProductSpaceFn"
              [compareFn]="compareProductSpaceFn"
            >
            </exos-select>
            <exos-select
              [group]="form"
              fxFlex="100"
              [name]="formConfig.roadmap.fields?.category.name"
              [label]="formConfig.roadmap.fields?.category.label"
              [placeholder]="formConfig.roadmap.fields?.category.placeholder"
              [options]="categories"
              [infoPoint]="formConfig.roadmap.fields?.category.infoPoint.text"
              [displayFn]="displayProductSpaceFn"
              [compareFn]="compareProductSpaceFn"
            >
            </exos-select>
          </div>
        </div>
      </div>
    </div>

    <div exosSheet>
      <div exosSheetSection type="secondary">
        <div exosSheetBlock>
          <h3 exosHeadline type="sub">
            {{ formConfig.okr.heading }}
          </h3>
        </div>
      </div>

      <div exosSheetSection>
        <div exosSheetBlock>
          <div fxLayout="row wrap" fxLayoutGap="1rem grid">
            <!-- <exos-select
              [group]="form"
              fxFlex="100"
              [name]="formConfig.okr.fields?.objectiveType.name"
              [label]="formConfig.okr.fields?.objectiveType.label"
              [placeholder]="formConfig.okr.fields?.objectiveType.placeholder"
              [options]="objectiveTypes"
              [displayFn]="displayObjectiveSetTypeFn"
            >
            </exos-select> -->

            <exos-select
              [group]="form"
              fxFlex="100"
              [name]="formConfig.okr.fields?.objectiveYear.name"
              [label]="formConfig.okr.fields?.objectiveYear.label"
              [placeholder]="formConfig.okr.fields?.objectiveYear.placeholder"
              [options]="years"
            >
            </exos-select>

            <exos-select
              *ngIf="objectiveType === 'division'"
              [group]="form"
              fxFlex="100"
              [name]="formConfig.okr.fields?.objectiveQuarter.name"
              [label]="formConfig.okr.fields?.objectiveQuarter.label"
              [placeholder]="
                formConfig.okr.fields?.objectiveQuarter.placeholder
              "
              [options]="quarters"
              [displayFn]="displayObjectiveQuarterFn"
            >
            </exos-select>

            <exos-select
              *ngIf="objectiveType === 'division'"
              [group]="form"
              fxFlex="100"
              [name]="formConfig.okr.fields?.objectiveDivision.name"
              [label]="formConfig.okr.fields?.objectiveDivision.label"
              [placeholder]="
                formConfig.okr.fields?.objectiveDivision.placeholder
              "
              [options]="objectiveDivisions"
              [displayFn]="displayObjectiveDivisionFn"
            >
            </exos-select>

            <exos-select
              [group]="form"
              fxFlex="100"
              [name]="formConfig.okr.fields?.objectiveId.name"
              [label]="formConfig.okr.fields?.objectiveId.label"
              [placeholder]="formConfig.okr.fields?.objectiveId.placeholder"
              [options]="objectives"
              [displayFn]="displayObjectiveFn"
              [compareFn]="compareObjectiveFn"
            >
            </exos-select>

            <exos-select
              [group]="form"
              fxFlex="100"
              [name]="formConfig.okr.fields?.keyResultId.name"
              [label]="formConfig.okr.fields?.keyResultId.label"
              [placeholder]="formConfig.okr.fields?.keyResultId.placeholder"
              [options]="keyResults"
              [displayFn]="displayKeyResultFn"
              [compareFn]="compareFnKeyResultFn"
            >
            </exos-select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Project End Date (Launch Date), Project visibility, Project Status, Project team -->
  <div fxFlex="50" fxFlex.lt-lg="100">
    <widget-projected-launch-date
      *ngIf="projectWorkflow === 'ionos'"
      heading="Project end date"
      [data]="projectedLaunchDateTable"
      [canUpdate]="canUpdate"
      (update)="updateLaunchDate($event)"
    >
    </widget-projected-launch-date>
    <widget-projected-start-date
      *ngIf="projectWorkflow === 'ionos'"
      heading="Project start date"
      [data]="projectedStartDateTable"
      [canUpdate]="canUpdate"
      (update)="updateStartDate($event)"
    >
    </widget-projected-start-date>

    <div exosSheet *ngIf="canUpdateConfidential">
      <div exosSheetSection type="secondary">
        <div exosSheetBlock>
          <h3 exosHeadline type="sub">
            {{ formConfig.visibility.heading }}
          </h3>
        </div>
      </div>

      <div exosSheetSection>
        <div exosSheetBlock>
          <div
            [formGroup]="confidentialForm"
            fxLayout="row wrap"
            fxLayoutGap="32px grid"
          >
            <exos-checkbox
              [group]="confidentialForm"
              fxFlex="100"
              [name]="formConfig.visibility.fields?.confidential.name"
              [label]="formConfig.visibility.fields?.confidential.label"
              [placeholder]="
                formConfig.visibility.fields?.confidential.placeholder
              "
            >
            </exos-checkbox>
          </div>
        </div>
      </div>
    </div>

    <card-status
      [hasHelp]="true"
      [data]="projectStatus"
      [canUpdate]="canUpdateStatus"
      [cardTitle]="'Project status'"
      (openHelp)="openProjectStatusHelpModal()"
      (updateStatusItem)="updateStatus($event)"
    >
    </card-status>

    <div exosSheet>
      <div exosSheetSection type="secondary">
        <div exosSheetBlock>
          <h3 exosHeadline type="sub">
            {{ formConfig.team.heading }}
          </h3>
        </div>
      </div>

      <div exosSheetSection>
        <div exosSheetBlock>
          <div fxLayout="row wrap" fxLayoutGap="1rem grid">
            <exos-autocomplete
              icon="exos-icon-nav-user-16"
              [items]="client$"
              [group]="form"
              fxFlex="100"
              [name]="formConfig.team.fields?.client.name"
              [label]="formConfig.team.fields?.client.label"
              [placeholder]="formConfig.team.fields?.client.placeholder"
              [noResultsMessage]="
                formConfig.team.fields?.client.noResultsMessage
              "
            >
            </exos-autocomplete>

            <exos-autocomplete
              icon="exos-icon-nav-user-16"
              [group]="form"
              fxFlex="100"
              [name]="formConfig.team.fields?.owner.name"
              [label]="formConfig.team.fields?.owner.label"
              [placeholder]="formConfig.team.fields?.owner.placeholder"
              [items]="owner$"
              [infoPoint]="formConfig.team.fields?.owner.infoPoint.text"
              [noResultsMessage]="
                formConfig.team.fields?.owner.noResultsMessage
              "
            >
            </exos-autocomplete>

            <exos-multiselect
              icon="exos-icon-nav-user-16"
              [group]="form"
              fxFlex="100"
              [results]="representative$"
              [name]="formConfig.team.fields?.representative.name"
              [label]="formConfig.team.fields?.representative.label"
              [placeholder]="formConfig.team.fields?.representative.placeholder"
              [infoPoint]="
                formConfig.team.fields?.representative.infoPoint.text
              "
              [noResultsMessage]="
                formConfig.team.fields?.representative.noResultsMessage
              "
            >
            </exos-multiselect>

            <exos-multiselect
              icon="exos-icon-community-64"
              [group]="form"
              fxFlex="100"
              [results]="division$"
              [name]="formConfig.team.fields?.division.name"
              [label]="formConfig.team.fields?.division.label"
              [placeholder]="formConfig.team.fields?.division.placeholder"
              [infoPoint]="formConfig.team.fields?.division.infoPoint.text"
              [noResultsMessage]="
                formConfig.team.fields?.division.noResultsMessage
              "
            >
            </exos-multiselect>

            <exos-multiselect
              icon="exos-icon-nav-user-16"
              [group]="form"
              fxFlex="100"
              [results]="mainTeamMember$"
              [name]="formConfig.team.fields?.mainTeamMembers.name"
              [label]="formConfig.team.fields?.mainTeamMembers.label"
              [placeholder]="
                formConfig.team.fields?.mainTeamMembers.placeholder
              "
              [infoPoint]="
                formConfig.team.fields?.mainTeamMembers.infoPoint.text
              "
            >
            </exos-multiselect>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<project-actions
  [projectActionsButtons$]="projectActionsButtons$"
  (update)="updateProject($event)"
  [updateInProgress$]="updateInProgress$"
>
</project-actions>
