import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { AuthService, DashNotificationService } from '@dashboard/core';
import { DashDialogService, ExosDialogRef } from '@dashboard/exos-dialog';

import { GanttChartData, GanttLink, GanttTask } from '../../models/gantt.model';
import { GanttService } from '../../service/gantt-service/gantt.service';
import { GanttTaskDialogComponent } from '../dialogs/gantt-task-dialog/gantt-task-dialog.component';
import {
  createNewGanttTaskDialogConfig,
  editNewGanttTaskDialogConfig,
} from '../dialogs/gantt-task-dialog/gantt-task.dialog-config';
import { ConfirmationComponent } from '../dialogs/confirmation/confirmation.component';

import {
  gantt,
  GanttInitializationConfig,
} from '../../service/dhtmlx-gantt-service/gantt/dhtmlxgantt';
import {
  GanttScaleLevel,
  UserGanttSettingsService,
} from '../../service/user-gantt-settings/user-gantt-settings.service';
import { map } from 'rxjs/operators';

const GANTT_INIT_ZOOM_CONFIG = {
  levels: [
    {
      name: 'day',
      scale_height: 100,
      min_column_width: 80,
      scales: [{ unit: 'day', step: 1, format: '%d %M' }],
    },
    {
      name: 'week',
      scale_height: 100,
      min_column_width: 50,
      scales: [
        {
          unit: 'week',
          step: 1,
          format: (date) => {
            const dateToStr = gantt.date.date_to_str('%d %M');
            const endDate = gantt.date.add(date, 6, 'day');
            const weekNum = gantt.date.date_to_str('%W')(date);
            return (
              '#' +
              weekNum +
              ', ' +
              dateToStr(date) +
              ' - ' +
              dateToStr(endDate)
            );
          },
        },
        { unit: 'day', step: 1, format: '%j %D' },
      ],
    },
    {
      name: 'month',
      scale_height: 100,
      min_column_width: 120,
      scales: [
        { unit: 'month', step: 1, format: '%F, %Y' },
        { unit: 'week', step: 1, format: 'Week #%W' },
      ],
    },
    {
      name: 'quarter',
      height: 50,
      min_column_width: 90,
      scales: [
        { unit: 'month', step: 1, format: '%M' },
        {
          unit: 'quarter',
          step: 1,
          format: (date) => {
            const dateToStr = gantt.date.date_to_str('%M');
            const endDate = gantt.date.add(
              gantt.date.add(date, 3, 'month'),
              -1,
              'day'
            );
            return dateToStr(date) + ' - ' + dateToStr(endDate);
          },
        },
      ],
    },
    {
      name: 'year',
      scale_height: 100,
      min_column_width: 30,
      scales: [{ unit: 'year', step: 1, format: '%Y' }],
    },
  ],
  element: null,
};

const GANTT_INIT_CONFIG: GanttInitializationConfig = {
  config: {
    readonly: true,
    date_format: '%Y-%m-%d %H:%i',
    show_progress: true,
    min_column_width: 60,
    duration_unit: 'day',
    work_time: true,
    scale_height: 100,
    row_height: 40,
    min_duration: 24 * 60 * 60 * 1000,
    auto_scheduling: true,
    // auto_scheduling_strict: true,
    auto_scheduling_compatibility: true,

    scales: [
      { unit: 'day', step: 1, format: '%j, %D' },
      { unit: 'week', step: 1, format: 'Week #%W' },
      { unit: 'month', step: 1, format: '%F, %Y' },
      {
        unit: 'quarter',
        step: 1,
        format: (date) => {
          const dateToStr = gantt.date.date_to_str('%M');
          const endDate = gantt.date.add(
            gantt.date.add(date, 3, 'month'),
            -1,
            'day'
          );
          return dateToStr(date) + ' - ' + dateToStr(endDate);
        },
      },
      { unit: 'year', step: 1, format: 'Calendar year %Y' },
    ],
  },
  plugins: {
    marker: true,
    tooltip: true,
    drag_timeline: true,
    undo: true,
    auto_scheduling: true,
    critical_path: true,
    fullscreen: true,
    // keyboard_navigation: true,
  },
  locale: 'en',
};

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'chart-gantt',
  templateUrl: './chart-gantt.component.html',
  styleUrls: ['./chart-gantt.component.scss'],
})
export class ChartGanttComponent implements AfterViewInit, OnDestroy {
  @Input()
  public datasource: GanttChartData;

  @Input()
  public projectId: number;

  @Input()
  public canUpdate: boolean;

  @ViewChild('gantt_container')
  public ganttContainer: ElementRef;

  public criticalPathToggle: FormControl = new FormControl(null);

  public zoomForm = new FormGroup({
    zoom: new FormControl(null),
  });

  public zoomLevelItems = ['day', 'week', 'month', 'quarter', 'year'];

  private dialogCreateOrEdit: ExosDialogRef;

  private dialogConfirmation: ExosDialogRef;

  private zoomService: any;

  private ganttEvents: any[] = [];

  constructor(
    private authService: AuthService,
    private ganttService: GanttService,
    private notifyService: DashNotificationService,
    private dialogService: DashDialogService,
    private userGanttSettingsService: UserGanttSettingsService,
    private cdr: ChangeDetectorRef
  ) {
    this.zoomService = gantt.ext.zoom;
  }

  ngAfterViewInit(): void {
    this.initGanttChart(this.datasource, this.projectId);
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    gantt.clearAll();
    // gantt.destructor();
    this.ganttEvents.forEach((e) => {
      gantt.detachEvent(e);
    });
  }

  public handleScaleChange() {
    this.zoomForm.valueChanges
      .pipe(map(({ zoom }) => zoom))
      .subscribe((value) => {
        this.zoomService.setLevel(value);
        this.cacheZoomScale(value);
      });
  }

  private handleCreateTask(task: GanttTask, projectId: number) {
    this.ganttService
      .createTask(task, projectId)
      .then((updatedTask: GanttTask) => {
        // @ts-ignore
        gantt.getTask(updatedTask.id).progressColor =
          updatedTask.progress === 1 ? '#38CC59' : '';
        gantt.getTask(updatedTask.id).$new = false;
        this.dialogCreateOrEdit.close();
        this.notifyService.success(`Task successfully created.`);
      })
      .catch(() => {
        this.notifyService.warning(`Task could not be created.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleUpdateTask(task: GanttTask, projectId: number) {
    this.ganttService
      .updateTask(task, projectId)
      .then((updatedTask: GanttTask) => {
        // @ts-ignore
        gantt.getTask(updatedTask.id).progressColor =
          updatedTask.progress === 1 ? '#38CC59' : '';

        if (this.dialogCreateOrEdit) {
          this.dialogCreateOrEdit.close();
        }
        this.notifyService.success(`Task successfully updated.`);
      })
      .catch(() => {
        this.notifyService.warning(`Task could not be updated.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleDeleteTask(taskId: string, projectId: number) {
    this.ganttService
      .deleteTask(taskId, projectId)
      .then(() => {
        this.dialogConfirmation.close();
        this.dialogCreateOrEdit.close();
        this.notifyService.success(`Task was deleted.`);
      })
      .catch(() => {
        this.dialogConfirmation.close();
        this.notifyService.warning(`Task could not be deleted.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleCreateLink(link: GanttLink, projectId: number) {
    this.ganttService
      .createLink(link, projectId)
      .then(() => {
        this.notifyService.success(`Link created.`);
      })
      .catch(() => {
        this.notifyService.warning(`Link could not be created.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleUpdateLink(link: GanttLink, projectId: number) {
    this.ganttService
      .updateLink(link, projectId)
      .then(() => {
        this.notifyService.success(`Link successfully updated.`);
      })
      .catch(() => {
        this.notifyService.warning(`Link could not be updated.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleDeleteLink(linkId: string, projectId: number) {
    this.ganttService
      .deleteLink(linkId, projectId)
      .then(() => {
        this.notifyService.success(`Link was deleted.`);
      })
      .catch(() => {
        this.notifyService.warning(`Link could not be deleted.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private addTooltipToLinks() {
    const tooltips = gantt.ext.tooltips;
    const linkTypeToString = (linkType) => {
      switch (linkType) {
        case gantt.config.links.start_to_start:
          return 'Start to start';
        case gantt.config.links.start_to_finish:
          return 'Start to finish';
        case gantt.config.links.finish_to_start:
          return 'Finish to start';
        case gantt.config.links.finish_to_finish:
          return 'Finish to finish';
        default:
          return '';
      }
    };

    gantt.templates.tooltip_text = (start, end, task) => {
      end = gantt.date.add(end as Date, -1, 'day');

      return (
        '<b>Task:</b> ' +
        task.text +
        '<br/>' +
        '<b>Start date:</b> ' +
        gantt.templates.tooltip_date_format(start) +
        '<br/><b>End date:</b> ' +
        gantt.templates.tooltip_date_format(end)
      );
    };

    tooltips.tooltipFor({
      selector: '.gantt_task_link',
      html: (event, node) => {
        const linkId = node.getAttribute(gantt.config.link_attribute);

        if (linkId) {
          const link = gantt.getLink(linkId);
          const from = gantt.getTask(link.source);
          const to = gantt.getTask(link.target);

          return [
            '<b>Link:</b> ' + linkTypeToString(link.type),
            '<b>From: </b> ' + from.text,
            '<b>To: </b> ' + to.text,
          ].join('<br>');
        }
      },
    });
  }

  private ganttInitConfig() {
    const config = GANTT_INIT_CONFIG.config;
    const plugins = GANTT_INIT_CONFIG.plugins;

    this.zoomForm.patchValue({
      zoom: this.userGanttSettingsService.getZoomLevel(),
    });

    const cp = this.userGanttSettingsService.getDisplayCriticalPath();

    this.criticalPathToggle.patchValue(cp, { emitEvent: false });

    gantt.plugins(plugins);

    Object.keys(config).forEach((key) => {
      gantt.config[key] = config[key];
    });

    gantt.config.readonly = !this.canUpdate;

    gantt.config.highlight_critical_path = cp;
  }

  private initGanttChart(ganttData: GanttChartData, projectId: number) {
    this.ganttInitConfig();
    this.initZoomModule();

    gantt.init(this.ganttContainer.nativeElement);
    gantt.parse(ganttData);
    gantt.sort('start_date', false);

    gantt.templates.scale_cell_class = (date) => {
      if (!gantt.isWorkTime(date)) {
        return 'weekend';
      }
    };
    gantt.templates.timeline_cell_class = (item, date) => {
      if (!gantt.isWorkTime({ date, task: item })) {
        return 'weekend';
      }
    };
    gantt.templates.rightside_text = (start, end, task) => {
      return task.type === gantt.config.types.milestone ? task.text : '';
    };

    this.ganttCreateDataProcessor(projectId);
    this.ganttMarkers();
    this.addTooltipToLinks();
    this.handleCriticalPath();
    this.handleScaleChange();

    gantt.eachTask((t) => (t.$open = true));
    gantt.render();

    // this.highlightTasksOnSelect();
  }

  // TODO
  // private highlightTasksOnSelect() {
  //   let highlightTasks = [];
  //   let highlightSearch = {};
  //
  //   const reset = (value?) => {
  //     if (value) {
  //       if (value.join() === highlightTasks.join()) {
  //         return;
  //       }
  //       highlightTasks = value;
  //       highlightSearch = {};
  //       highlightTasks.forEach((id) => {
  //         highlightSearch[id] = true;
  //       });
  //       gantt.render();
  //     } else if (highlightTasks.length) {
  //       highlightTasks = [];
  //       highlightSearch = {};
  //       gantt.render();
  //     }
  //   };
  //
  //   gantt.templates.task_class = (start, end, task) => {
  //     return highlightSearch[task.id] ? 'task_groups' : '';
  //   };
  //
  //   gantt.attachEvent(
  //     'onTaskClick',
  //     (id) => {
  //       const group = gantt.getConnectedGroup(id);
  //
  //       if (!group.tasks.length) {
  //         reset();
  //       } else {
  //         reset(group.tasks);
  //       }
  //
  //       return true;
  //     },
  //     null
  //   );
  //
  //   gantt.attachEvent(
  //     'onEmptyClick',
  //     () => {
  //       reset();
  //       return true;
  //     },
  //     null
  //   );
  // }

  private initZoomModule() {
    GANTT_INIT_ZOOM_CONFIG.element = this.ganttContainer.nativeElement;
    this.zoomService.init(GANTT_INIT_ZOOM_CONFIG);

    this.zoomForm.patchValue({
      zoom: this.userGanttSettingsService.getZoomLevel(),
    });

    this.zoomService.setLevel(this.userGanttSettingsService.getZoomLevel());
  }

  private cacheZoomScale(scale: GanttScaleLevel) {
    this.userGanttSettingsService.saveZoomScale(scale);
  }

  public zoomIn() {
    const currentLevel = this.zoomService.getCurrentLevel();

    if (currentLevel === 0) {
      return;
    }

    const prevLevelName = this.zoomService.getLevels()[currentLevel - 1].name;
    this.zoomForm.patchValue({ zoom: prevLevelName });
  }

  public zoomOut() {
    const currentLevel = this.zoomService.getCurrentLevel();

    if (currentLevel === 4) {
      return;
    }

    const nextLevelName = this.zoomService.getLevels()[currentLevel + 1].name;
    this.zoomForm.patchValue({ zoom: nextLevelName });
  }

  protected ganttMarkers() {
    const dateToStr = gantt.date.date_to_str(gantt.config.task_date);
    const today = new Date();

    gantt.addMarker({
      start_date: today,
      css: 'today',
      text: 'Today',
      title: 'Today: ' + dateToStr(today),
    });
  }

  private calculateSummaryProgress(task) {
    if (task.type != gantt.config.types.project) return task.progress;
    var totalToDo = 0;
    var totalDone = 0;
    gantt.eachTask(function (child) {
      if (child.type != gantt.config.types.project) {
        totalToDo += child.duration;
        totalDone += (child.progress || 0) * child.duration;
      }
    }, task.id);
    if (!totalToDo) return 0;
    else return totalDone / totalToDo;
  }

  private refreshSummaryProgress(id, submit) {
    if (!gantt.isTaskExists(id)) return;

    var task = gantt.getTask(id);
    var newProgress = this.calculateSummaryProgress(task);

    if (newProgress !== task.progress) {
      task.progress = newProgress;

      if (!submit) {
        gantt.refreshTask(id);
      } else {
        gantt.updateTask(id, task);
      }
    }

    if (!submit && gantt.getParent(id) !== gantt.config.root_id) {
      this.refreshSummaryProgress(gantt.getParent(id), submit);
    }
  }

  private ganttCreateDataProcessor(projectId: number) {
    const onAfterTaskUpdate = gantt.attachEvent(
      'onAfterTaskUpdate',
      (id) => {
        this.refreshSummaryProgress(gantt.getParent(id), true);
      },
      {}
    );

    const onTaskDrag = gantt.attachEvent(
      'onTaskDrag',
      (id) => {
        this.refreshSummaryProgress(gantt.getParent(id), false);
      },
      {}
    );
    const onAFterTaskAdd = gantt.attachEvent(
      'onAfterTaskAdd',
      (id) => {
        this.refreshSummaryProgress(gantt.getParent(id), true);
      },
      {}
    );
    const onParse = gantt.attachEvent(
      'onParse',
      () => {
        gantt.eachTask((task) => {
          task.progress = this.calculateSummaryProgress(task);
        });
      },
      {}
    );

    const onBeforeTaskAdd = gantt.attachEvent(
      'onBeforeTaskAdd',
      (id, task: GanttTask) => {
        this.handleCreateTask(task, projectId);
      },
      {}
    );

    const onBeforeTaskUpdate = gantt.attachEvent(
      'onBeforeTaskUpdate',
      (id, task: GanttTask) => {
        if (task.start_date.toString() === task.end_date.toString()) {
          task.end_date = gantt.date.add(new Date(task.start_date), 1, 'day');
        }
        this.handleUpdateTask(task, projectId);
      },
      {}
    );

    const onBeforeTaskDelete = gantt.attachEvent(
      'onBeforeTaskDelete',
      (taskId) => {
        const taskIsNew = !!gantt.getTask(taskId).$new;

        if (!taskIsNew) {
          this.handleDeleteTask(taskId, projectId);
        }
      },
      {}
    );

    const onAfterLinkAdd = gantt.attachEvent(
      'onAfterLinkAdd',
      (id, link: GanttLink) => {
        this.handleCreateLink(link, projectId);
      },
      {}
    );

    const onAfterTaLinkUpdate = gantt.attachEvent(
      'onAfterLinkUpdate',
      (id, link: GanttLink) => {
        this.handleUpdateLink(link, projectId);
      },
      {}
    );

    const onAfterTaLinkDelete = gantt.attachEvent(
      'onAfterLinkDelete',
      (linkId) => {
        this.handleDeleteLink(linkId, projectId);
      },
      {}
    );

    const onBeforeLightbox = gantt.attachEvent(
      'onBeforeLightbox',
      (id: string | number) => {
        const task = gantt.getTask(id);
        if (task.$new) {
          const today = new Date();
          const startDay = today.setHours(0, 0, 0, 0);

          task.start_date = new Date(startDay);
          task.end_date = gantt.date.add(new Date(startDay), 1, 'day');
        }

        this.dialogCreateOrEdit = this.openCreateOrEditDialog(task);
        this.handleCreateOrEditDialogActions(task);
        return false;
      },
      {}
    );

    this.ganttEvents.push(
      onTaskDrag,
      onAFterTaskAdd,
      onAfterTaskUpdate,
      onParse,
      onBeforeLightbox,
      onBeforeTaskAdd,
      onBeforeTaskUpdate,
      onBeforeTaskDelete,
      onAfterLinkAdd,
      onAfterTaLinkUpdate,
      onAfterTaLinkDelete
    );
  }

  private handleCreateOrEditDialogActions(task: GanttTask) {
    this.dialogCreateOrEdit.afterSubmit.subscribe((action) => {
      if (action.type === 'CLOSE') {
        if (task.$new) {
          gantt.deleteTask(task.id);
        }
      } else if (action.type === 'CREATE') {
        const newTask = Object.assign(task, action.payload);
        gantt.addTask(newTask);
      } else if (action.type === 'UPDATE') {
        const newTask = Object.assign(task, action.payload);
        gantt.updateTask(newTask.id, newTask);
      } else if (action.type === 'DELETE') {
        this.handleDeleteDialogActions(task);
      }
    });
  }

  private handleDeleteDialogActions(task: GanttTask) {
    this.dialogCreateOrEdit.close();
    const deleteDialog = this.openDialogConfirmation();

    this.dialogConfirmation = deleteDialog;

    deleteDialog.afterSubmit.subscribe(() => {
      gantt.deleteTask(task.id);
    });

    deleteDialog.afterClosed.subscribe(() => {
      this.dialogCreateOrEdit = this.openCreateOrEditDialog(task);
      this.handleCreateOrEditDialogActions(task);
    });
  }

  private openCreateOrEditDialog(task: GanttTask): ExosDialogRef {
    // @ts-ignore
    const config = JSON.parse(
      JSON.stringify(
        task.$new
          ? createNewGanttTaskDialogConfig
          : editNewGanttTaskDialogConfig
      )
    );
    const configFormFields = Object.keys(config.data.formConfig.fields);
    config.data.task = task;

    // @ts-ignore
    const currentAllTasks = Object.keys(
      // @ts-ignore
      gantt.$data.tasksStore.pull
    )
      .map((taskId) => ({
        text: gantt.getTask(taskId).text,
        value: taskId,
      }))
      .filter((option) => {
        const optionId = parseInt(option.value, 10);
        const taskId = parseInt(task.id, 10);
        const parentId = parseInt(gantt.getTask(optionId).parent, 10);

        const isSameTask = optionId !== taskId;
        const isChildTask = parentId === taskId;

        return isSameTask && !isChildTask;
      });

    configFormFields.forEach((fieldName) => {
      config.data.formConfig.fields[fieldName].value = task[fieldName];
    });

    config.data.formConfig.fields.parent.options = [
      ...config.data.formConfig.fields.parent.options,
      ...currentAllTasks,
    ];

    return this.dialogService.open(GanttTaskDialogComponent, config);
  }

  private openDialogConfirmation() {
    return this.dialogService.open(ConfirmationComponent, {
      data: {
        title: 'Delete task',
        text: 'Are you sure?',
      },
    });
  }

  public handleCriticalPath() {
    this.criticalPathToggle.valueChanges.subscribe((value) => {
      gantt.config.highlight_critical_path = value;

      this.userGanttSettingsService.saveCriticalPath(value);

      gantt.render();
    });
  }
}
