import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { GanttChartData } from '../../models/gantt.model';
import { FormControl, FormGroup } from '@angular/forms';
import { filter } from 'rxjs/operators';
import { DhtmlxGanttService } from '../../service/dhtmlx-gantt-service/dhtmlx-gantt.service';
import { Observable } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { gantt } from '../../service/dhtmlx-gantt-service/gantt/dhtmlxgantt';

@Component({
  selector: 'dhtmlx-gantt',
  templateUrl: './dhtmlx-gantt.component.html',
  styleUrls: ['./dhtmlx-gantt.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DhtmlxGanttComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('gantt_container')
  public ganttContainer: ElementRef;

  @Input()
  public datasource: GanttChartData;

  @Input()
  public canUpdate: boolean;

  public zoomForm = new FormGroup({
    zoom: new FormControl('day'),
  });

  public criticalPathToggle: FormControl = new FormControl(false);

  public zoomLevels: string[] = this.dhtmlxGanttService.zoomLevels;

  public zoomLevel$: Observable<string> =
    this.dhtmlxGanttService.currentZoomLevel$;

  public criticalPathVisibility$: Observable<boolean> =
    this.dhtmlxGanttService.criticalPathVisibility$;

  constructor(
    private dhtmlxGanttService: DhtmlxGanttService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.handleScaleChange();
    this.handleCriticalPath();

    this.dhtmlxGanttService.initGanttChart(
      this.datasource,
      this.ganttContainer,
      this.canUpdate
    );

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    gantt.clearAll();
  }

  private handleScaleChange() {
    this.zoomLevel$.subscribe((zoom) =>
      this.zoomForm.patchValue({ zoom }, { emitEvent: false })
    );

    this.zoomForm.valueChanges.subscribe(({ zoom }) =>
      this.dhtmlxGanttService.setZoomLevel(zoom)
    );
  }

  private handleCriticalPath() {
    this.criticalPathVisibility$
      .pipe(filter((value) => value !== null))
      .subscribe((visible) =>
        this.criticalPathToggle.patchValue(visible, { emitEvent: false })
      );
  }

  public setFullScreen() {
    gantt.expand();
  }

  public toggleCriticalPathVisibility(ev: MatSlideToggleChange) {
    this.dhtmlxGanttService.setCriticalPathVisibility(ev.checked);
  }

  public showCurrentDate() {
    this.dhtmlxGanttService.showCurrentDate();
  }
}
