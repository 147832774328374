import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';

const linkFormConfig: FormConfig = {
  action: 'links',

  fields: {
    name: {
      name: 'name',
      label: 'Name',
      placeholder: 'Name',
      type: 'input',
      fxFlex: '100',
      value: null,
    },
    link: {
      name: 'link',
      label: 'Link',
      placeholder: 'link',
      type: 'input',
      fxFlex: '100',
      value: null,
    },
  },

  buttons: [
    {
      label: 'Save',
      type: 'button',
      action: 'submit',
      hasLoader: true,
      isPrimary: true,
    },
    {
      label: 'Close',
      type: 'button',
      action: 'cancel',
      hasLoader: false,
    },
  ],
};

export const addLinkDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Add new link',
    formConfig: linkFormConfig,
  },
};

export const editLinkDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Edit link',
    formConfig: linkFormConfig,
  },
};
