import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeModule } from './components/badge/badge.module';
import { ButtonModule } from './components/button/button.module';
import { CardModule } from './components/card/card.module';
import { HeadlineModule } from './components/headline/headline.module';
import { LinkModule } from './components/link/link.module';
import { ListModule } from './components/list/list.module';
import { PageHeaderModule } from './components/page-header/page-header.module';
import { PageSectionModule } from './components/page-section/page-section.module';
import { ParagraphModule } from './components/paragraph/paragraph.module';
import { SheetModule } from './components/sheet/sheet.module';
import { StripeModule } from './components/stripe/stripe.module';
import { InlineEditModule } from './inline-edit/inline-edit.module';
import {
  MatCheckboxModule,
  MatIconModule,
  MatMenuModule,
} from '@angular/material';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    BadgeModule,
    ButtonModule,
    CardModule,
    HeadlineModule,
    LinkModule,
    ListModule,
    PageHeaderModule,
    PageSectionModule,
    ParagraphModule,
    SheetModule,
    StripeModule,
    InlineEditModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
  ],
  exports: [
    BadgeModule,
    ButtonModule,
    CardModule,
    HeadlineModule,
    LinkModule,
    ListModule,
    PageHeaderModule,
    PageSectionModule,
    ParagraphModule,
    SheetModule,
    StripeModule,
    InlineEditModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ExosModule {}
