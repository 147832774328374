import { BrowserModule, DomSanitizer } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { DashCoreModule } from '@dashboard/core';
import { RoutingModule } from './routing.module';
import { metaReducers, reducers } from './store/reducers';
import { AppEffects } from './app.effects';
import { AppComponent } from './app.component';
import { ProjectModule } from './project/project.module';
import { ListingModule } from './listing/listing.module';
import { MatIconModule, MatIconRegistry } from '@angular/material';
import { StaticModule } from './static/static.module';
import { ProgramModule } from './program/program.module';
import { NotAuthorizedInterceptor } from './shared/http/error.interceptor';
import { EXOS_DASHBOARD_FORM_CONFIG } from '@dashboard/exos-form';
import { UsersService } from './shared/service/users/users.service';
import { DivisionService } from './shared/service/division/division.service';
import { ProjectService } from './shared/service/project/project.service';
import { ProgramService } from './shared/service/program/program.service';
import { TagService } from './shared/service/tag/tag.service';
import { GanttService } from './shared/service/gantt-service/gantt.service';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    DashCoreModule,
    ProjectModule,
    ProgramModule,
    ListingModule,
    StaticModule,
    RoutingModule,
    MatIconModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([AppEffects]),
    BrowserAnimationsModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NotAuthorizedInterceptor,
      multi: true,
    },
    {
      provide: EXOS_DASHBOARD_FORM_CONFIG,
      useValue: {
        debounceTime: 500,
        services: {
          usersService: UsersService,
          divisionService: DivisionService,
          projectService: ProjectService,
          programService: ProgramService,
          tagService: TagService,
          ganttService: GanttService,
        },
      },
    },
  ],
})
export class AppModule {
  constructor(matIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer) {
    matIconRegistry.addSvgIconSet(
      domSanitizer.bypassSecurityTrustResourceUrl('./assets/mdi.svg')
    );
  }
}
