import { Component, OnInit } from '@angular/core';
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterEvent,
} from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-program-route',
  templateUrl: './program-route.component.html',
  styleUrls: ['./program-route.component.scss'],
})
export class ProgramRouteComponent implements OnInit {
  public tabs = {
    Overview: 'edit',
    'Documents & Links': 'documents-links',
    Projects: 'projects',
    'Program plan': 'program-plan',
  };

  public routeIsLoading$: Observable<boolean>;

  public programName$: Observable<string>;

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.routeIsLoading$ = this.router.events.pipe(
      distinctUntilChanged(),
      filter((ev: RouterEvent) => {
        return (
          ev instanceof NavigationStart ||
          ev instanceof NavigationEnd ||
          ev instanceof NavigationCancel ||
          ev instanceof NavigationError
        );
      }),
      map((ev: RouterEvent) => ev instanceof NavigationStart)
    );

    this.programName$ = this.route.data.pipe(pluck('program', 'name'));
  }

  public async backToLastFilter() {
    await this.router.navigateByUrl(
      localStorage.getItem('lastProgramVisitedFilter')
    );
  }
}
