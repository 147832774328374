import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, EMPTY, combineLatest } from 'rxjs';
import { mergeMap, catchError, tap } from 'rxjs/operators';
import {
  ObjectiveSet,
  PartialObjectiveSet,
} from '../../models/objective.model';
import { ObjectiveService } from './objective.service';

@Injectable({
  providedIn: 'root',
})
export class ObjectiveResolver implements Resolve<PartialObjectiveSet[]> {
  constructor(
    private objectiveService: ObjectiveService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PartialObjectiveSet[]> {
    const divisionObjectiveYears$: Observable<PartialObjectiveSet[]> =
      this.objectiveService.getAllDivisionObjectivesSets();

    return divisionObjectiveYears$.pipe(
      mergeMap((divisionObjectiveSets) => {
        divisionObjectiveSets.forEach((set) => {
          if (!set.objectives) {
            set.objectives = [];
          }
        });
        return of(divisionObjectiveSets);
      }),
      catchError(() => {
        this.router.navigate(['/home']);
        return EMPTY;
      })
    );
  }
}
