import { DashTableColumnDefinition } from '@dashboard/table';

const projectAllColumns: DashTableColumnDefinition[] = [
  {
    id: 'file',
    name: 'File',
    sortable: false,
    value: 'file',
    display: true,
    fixed: true,
    colWidth: '50px',
  },
  {
    id: 'id',
    name: 'Project Id',
    sortable: true,
    value: 'id',
    display: true,
    fixed: true,
    colWidth: '115px',
  },
  {
    id: 'name',
    name: 'Project Name',
    sortable: true,
    value: 'name',
    display: true,
    fixed: true,
    colWidth: '300px',
  },
  {
    id: 'objective',
    name: 'Objective',
    sortable: false,
    value: '_objective',
    display: false,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'dependencies',
    name: 'Dependencies',
    sortable: false,
    value: 'dependencies_name',
    display: false,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'dependencies_description',
    name: 'Dependencies description',
    sortable: false,
    value: 'dependencies_description',
    display: false,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'division',
    name: 'Division',
    sortable: false,
    value: 'division',
    display: false,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'effort',
    name: 'Effort',
    sortable: false,
    value: 'effort',
    display: false,
    fixed: false,
    colWidth: '80px',
  },
  {
    id: 'keyResult',
    name: 'Key Result',
    sortable: false,
    value: 'keyResult',
    display: false,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'lastModified',
    name: 'Last Update',
    sortable: false,
    value: 'lastModified',
    display: false,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'projectedLaunchDate',
    name: 'End Date',
    sortable: false,
    value: '_projectedLaunchDate',
    display: false,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'gantt',
    name: 'Milestone',
    sortable: false,
    value: 'gantt',
    display: false,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'phase',
    name: 'Phase',
    sortable: true,
    value: 'phase',
    display: false,
    fixed: false,
    colWidth: '150px',
  },
  {
    id: 'prio',
    name: 'Prio',
    sortable: false,
    value: 'prio',
    display: false,
    fixed: false,
    colWidth: '80px',
  },
  {
    id: 'program',
    name: 'Program Name',
    sortable: false,
    value: '_program',
    display: true,
    fixed: false,
    colWidth: '300px',
  },
  {
    id: 'client',
    name: 'Project Client',
    sortable: true,
    value: 'client',
    display: false,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'owner',
    name: 'Project Owner',
    sortable: true,
    value: 'owner',
    display: false,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'tags',
    name: 'Project Tags',
    sortable: false,
    value: '_tags',
    display: false,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'risks',
    name: 'Risks',
    sortable: false,
    value: '_risks',
    display: false,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'projectedStartDate',
    name: 'Start Date',
    sortable: false,
    value: '_projectedStartDate',
    display: false,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'status',
    name: 'Status',
    sortable: true,
    value: 'status',
    display: true,
    fixed: false,
    colWidth: '200px',
  },
  {
    id: 'status_comment',
    name: 'Status Comment',
    sortable: false,
    value: 'status_comment',
    display: true,
    fixed: false,
    colWidth: '200px',
  },
];

export default projectAllColumns;
