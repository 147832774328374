<header exosPageHeader [isOverlapping]="true">
  <div exosPageHeaderBlock>
    <a (click)="location.back()" exosPageHeaderBackButton></a>

    <h1 exosPageHeaderHeadline>Create program</h1>
  </div>
</header>

<div exosPageSection [isOverlapping]="true" size="narrow">
  <div exosPageSectionBlock>
    <div exosSheet>
      <div exosSheetSection type="secondary">
        <div exosSheetBlock>
          <h3 exosHeadline type="sub">Program</h3>
        </div>
      </div>

      <ng-template libFormContainer> </ng-template>
    </div>
  </div>
</div>
