<header exosPageHeader [isOverlapping]="true">
    <div exosPageHeaderBlock>
        <h1 exosPageHeaderHeadline>IONOS Projects</h1>
        <h2 exosPageHeaderHeadline>User Roles</h2>
    </div>
</header>

<div class="mb-4-i" exosPageSection [isOverlapping]="true">
    <div exosPageSectionBlock>
        <div exosSheet>
            <div exosSheetSection>
                <div exosSheetBlock>
                    <p exosParagraph>
                        The following tables describe the user roles and privileges on Projects and Programs.
                    </p>
                </div>
            </div>
        </div>

        <table class="table table--responsive">
            <thead>
                <tr class="table__header">
                    <td class="table__cell" data-caption="Project Section / Role">
                        Project Section / Role
                    </td>
                    <td class="table__cell" data-caption="Project One Pager">
                        Project One Pager
                    </td>
                    <td class="table__cell" data-caption="Administration">
                        Administration
                    </td>
                    <td class="table__cell" data-caption="Project Brief">
                        Project Brief
                    </td>
                    <td class="table__cell" data-caption="Project Plan">
                        Project Plan
                    </td>
                    <td class="table__cell" data-caption="Functional Plan">
                        Functional Plan
                    </td>
                    <td class="table__cell" data-caption="Project Review">
                        Project Review
                    </td>
                    <td class="table__cell" data-caption="Documents & Links">
                        Documents & Links
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr class="table__row">
                    <td class="table__cell">USER (no specific role on the project)</td>
                    <td class="table__cell">export pdf</td>
                    <td class="table__cell">view</td>
                    <td class="table__cell">
                        <ul>
                            <li>- view Project Brief file</li>
                            <li>- view project change requests</li>
                        </ul>
                    </td>
                    <td class="table__cell">
                        <ul>
                            <li>- view & export</li>
                            <li>- cannot create tasks or import from Jira</li>
                        </ul>
                    </td>
                    <td class="table__cell">view</td>
                    <td class="table__cell">view Review File</td>
                    <td class="table__cell">view GDrive Folder</td>
                </tr>
                <tr class="table__row">
                    <td class="table__cell">CLIENT</td>
                    <td class="table__cell">export pdf</td>
                    <td class="table__cell">
                        <ul>
                            <li>- edit "Project Owner" field</li>
                            <li>- can approve project phase</li>
                            <li>- can reject project phase</li>
                        </ul>
                    </td>
                    <td class="table__cell">
                        <ul>
                            <li>- generate and view Project Brief file</li>
                            <li>- view/approve/reject project change requests</li>
                        </ul>
                    </td>
                    <td class="table__cell">
                        <ul>
                            <li>- view & export</li>
                            <li>- cannot create tasks or import from Jira</li>
                        </ul>
                    </td>
                    <td class="table__cell">view</td>
                    <td class="table__cell">generate/view Review File</td>
                    <td class="table__cell">
                        <ul>
                            <li>- add link</li>
                            <li>- view GDrive Folder</li>
                        </ul>
                    </td>
                </tr>
                <tr class="table__row">
                    <td class="table__cell">OWNER</td>
                    <td class="table__cell">export pdf</td>
                    <ul>
                        <li>- edit all fields except workflow</li>
                        <li>- can cancel project</li>
                        <li>- can delete project</li>
                    </ul>
                    <td class="table__cell">
                        <ul>
                            <li>- generate and edit Project Brief file</li>
                            <li>- create/update/delete Market, Dependencies, Requirements, Risks and Stakeholders
                            </li>
                            <li>- create/edit/cancel change requests if the project is in <b>Plan and execute
                                    phase</b>
                            </li>
                        </ul>
                    </td>
                    <td class="table__cell">no restricts</td>
                    <td class="table__cell">add new functional stream</td>
                    <td class="table__cell">generate/edit Review File</td>
                    <td class="table__cell">
                        <ul>
                            <li>- add link</li>
                            <li>- view GDrive Folder</li>
                        </ul>
                    </td>
                </tr>
                <tr class="table__row">
                    <td class="table__cell">REPRESENTATIVE</td>
                    <td class="table__cell">export pdf</td>
                    <ul>
                        <li>- edit all fields except workflow and Project Owner</li>
                        <li>- can cancel project</li>
                        <li>- can change project phase</li>
                        <li>- can delete project</li>
                    </ul>
                    <td class="table__cell">
                        <ul>
                            <li>- generate and edit Project Brief file</li>
                            <li>- create/update/delete Market, Dependencies, Requirements, Risks and Stakeholders
                            </li>
                            <li>- create/edit/cancel change requests if the project is in <b>Plan and execute
                                    phase</b>
                            </li>
                        </ul>
                    </td>
                    <td class="table__cell">no restricts</td>
                    <td class="table__cell">add new functional stream</td>
                    <td class="table__cell">generate/edit Review File</td>
                    <td class="table__cell">
                        <ul>
                            <li>- add link</li>
                            <li>- view GDrive Folder</li>
                        </ul>
                    </td>
                </tr>
                <tr class="table__row ">
                    <td class="table__cell">MAIN TEAM MEMBER</td>
                    <td class="table__cell">export pdf</td>
                    <td class="table__cell">view</td>
                    <td class="table__cell">
                        <ul>
                            <li>- generate and view Project Brief file</li>
                            <li>- view project change requests</li>
                        </ul>
                    </td>
                    <td class="table__cell">
                        <ul>
                            <li>- view & export</li>
                            <li>- cannot create tasks or import from Jira</li>
                        </ul>
                    </td>
                    <td class="table__cell">view</td>
                    <td class="table__cell">generate/view Review File</td>
                    <td class="table__cell">
                        <ul>
                            <li>- add link</li>
                            <li>- view GDrive Folder</li>
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table>
        <br><br>
        <table class="table table--responsive">
            <thead>
                <tr class="table__header">
                    <td class="table__cell" data-caption="Program Section / Role">
                        Program Section / Role
                    </td>
                    <td class="table__cell" data-caption="Overview">
                        Project One Pager
                    </td>
                    <td class="table__cell" data-caption="Documents & Links">
                        Documents & Links
                    </td>
                    <td class="table__cell" data-caption="Projects">
                        Projects
                    </td>
                    <td class="table__cell" data-caption="Program Plan">
                        Program Plan
                    </td>
                </tr>
            </thead>
            <tbody>
                <tr class="table__row">
                    <td class="table__cell">USER (no specific role on the program)</td>
                    <td class="table__cell">view</td>
                    <td class="table__cell">view GDrive Folder</td>
                    <td class="table__cell">view</td>
                    <td class="table__cell">view</td>
                </tr>
                <tr class="table__row">
                    <td class="table__cell">CLIENT</td>
                    <td class="table__cell">view</td>
                    <td class="table__cell">View GDrive Folder</td>
                    <td class="table__cell">view</td>
                    <td class="table__cell">view</td>
                </tr>
                <tr class="table__row">
                    <td class="table__cell">OWNER</td>
                    <td class="table__cell">can edit all fields</td>
                    <td class="table__cell">
                        <ul>
                            <li>- add link</li>
                            <li>- view GDrive Folder</li>
                        </ul>
                    </td>
                    <td class="table__cell">add project</td>
                    <td class="table__cell">no restricts</td>
                </tr>
                <tr class="table__row">
                    <td class="table__cell">REPRESENTATIVE</td>
                    <td class="table__cell">can edit all fields except Program Owner</td>
                    <td class="table__cell">
                        <ul>
                            <li>- add link</li>
                            <li>- view GDrive Folder</li>
                        </ul>
                    </td>
                    <td class="table__cell">add project</td>
                    <td class="table__cell">no restricts</td>
                </tr>
            </tbody>
        </table>

    </div>
</div>