import { FormConfig } from '@dashboard/exos-form';
import infopoints from '../../../shared/locale/infopoints.json';
import { ProjectWorkflowHelpDialogComponent } from 'src/app/shared/components/dialogs/project-workflow-help/project-workflow-help-dialog.component.js';

export const projectCreateFormConfig: FormConfig = {
  fields: {
    name: {
      name: 'name',
      label: 'Project Name',
      placeholder: 'Project Name',
      type: 'input',
      fxFlex: '100',
      value: null,
      section: 'project-details-section',
    },
    effort: {
      name: 'effort',
      label: 'Effort',
      type: 'select',
      placeholder: 'Not selected',
      options: [
        { text: 'S', value: 'S' },
        { text: 'M', value: 'M' },
        { text: 'L', value: 'L' },
        { text: 'XL', value: 'XL' },
      ],
      value: null,
      fxFlex: '100',
      section: 'project-details-section',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.EFFORT,
      },
    },
    objectiveYear: {
      name: 'objectiveYear',
      label: 'Year',
      type: 'select',
      options: [],
      value: null,
      fxFlex: '100',
      section: 'section-objective',
    },
    objectiveQuarter: {
      name: 'objectiveQuarter',
      label: 'Quarter',
      type: 'select',
      options: [],
      value: null,
      fxFlex: '100',
      section: 'section-objective',
    },
    objectiveDivision: {
      name: 'objectiveDivision',
      label: 'Company/Division',
      type: 'select',
      options: [],
      value: null,
      fxFlex: '100',
      section: 'section-objective',
    },
    objectiveId: {
      name: 'objectiveId',
      label: 'Objective',
      type: 'select',
      placeholder: 'Not selected',
      options: [],
      value: null,
      fxFlex: '100',
      section: 'section-objective',
    },
    keyResultId: {
      name: 'keyResultId',
      label: 'Key Result',
      type: 'select',
      placeholder: 'Not selected',
      options: [],
      value: null,
      fxFlex: '100',
      section: 'section-objective',
    },
    prio: {
      name: 'prio',
      label: 'Prio',
      type: 'select',
      placeholder: 'Not selected',
      options: [
        { text: 'A', value: 'A' },
        { text: 'B', value: 'B' },
        { text: 'C', value: 'C' },
      ],
      value: null,
      fxFlex: '100',
      section: 'project-details-section',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.PRIO,
      },
    },
    workflow: {
      name: 'workflow',
      label: 'Workflow',
      type: 'select',
      placeholder: 'Not selected',
      options: [
        { text: 'IONOS', value: 'ionos' },
        { text: 'United Internet', value: 'united-internet' },
      ],
      value: 'ionos',
      fxFlex: '100',
      section: 'project-details-section',
      infoPoint: {
        hasInfoPoint: false,
      },
      help: {
        hasHelp: true,
        config: ProjectWorkflowHelpDialogComponent.name,
      },
    },
    program: {
      name: 'program',
      label: 'Program',
      placeholder: 'Search program',
      type: 'autocomplete',
      fxFlex: '100',
      items: null,
      value: null,
      section: 'project-details-section',
      service: {
        name: 'programService',
        action: 'findByName',
      },
      noResultsMessage: 'No program found.',
    },
    tags: {
      name: 'tags',
      label: 'Project Tags',
      type: 'multiselect',
      placeholder: 'Search tags',
      items: null,
      selectedItems: [],
      value: null,
      section: 'project-details-section',
      fxFlex: '100',
      icon: 'exos-icon-marker-16',
      service: {
        name: 'tagService',
        action: 'findByNameLikePattern',
      },
      // canAddOnEnter: true,
      valueKey: 'name',
    },
    client: {
      name: 'client',
      label: 'Project Client',
      placeholder: 'Project Client',
      type: 'autocomplete',
      fxFlex: '100',
      value: null,
      items: null,
      section: 'project-team-section',
      icon: 'exos-icon-nav-user-16',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.CLIENT,
      },
      service: {
        name: 'usersService',
        action: 'findByName',
      },
      noResultsMessage: 'No user found.',
    },
    owner: {
      name: 'owner',
      disabled: true,
      label: 'Project Owner',
      placeholder: 'Project Owner',
      type: 'autocomplete',
      items: null,
      fxFlex: '100',
      value: null,
      section: 'project-team-section',
      icon: 'exos-icon-nav-user-16',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.OWNER,
      },
      service: {
        name: 'usersService',
        action: 'findByName',
      },
      noResultsMessage: 'No user found.',
    },
    representative: {
      name: 'representative',
      label: 'Project Representative',
      placeholder: 'Project Representative',
      type: 'multiselect',
      items: null,
      selectedItems: [],
      fxFlex: '100',
      value: null,
      section: 'project-team-section',
      icon: 'exos-icon-nav-user-16',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.REPRESENTATIVE,
      },
      service: {
        name: 'usersService',
        action: 'findByName',
      },
      noResultsMessage: 'No user found.',
    },
    division: {
      name: 'division',
      label: 'Divisions',
      type: 'multiselect',
      placeholder: 'Divisions',
      items: null,
      selectedItems: [],
      value: null,
      fxFlex: '100',
      section: 'project-team-section',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.DIVISION,
      },
      service: {
        name: 'divisionService',
        action: 'findByName',
      },
      icon: 'exos-icon-community-64',
      noResultsMessage: 'No division found.',
    },
    mainTeamMembers: {
      name: 'mainTeamMembers',
      label: 'Main Team Members',
      placeholder: 'Main Team Members',
      type: 'multiselect',
      items: null,
      selectedItems: [],
      fxFlex: '100',
      value: null,
      section: 'project-team-section',
      icon: 'exos-icon-nav-user-16',
      infoPoint: {
        hasInfoPoint: true,
        text: infopoints.MAIN_TEAM_MEMBERS,
      },
      service: {
        name: 'usersService',
        action: 'findByName',
      },
      noResultsMessage: 'No user found.',
    },
  },

  buttons: [
    {
      label: 'Create project',
      type: 'button',
      action: 'submit',
      hasLoader: true,
      isPrimary: true,
    },
    {
      label: 'Cancel',
      type: 'button',
      action: 'cancel',
    },
  ],
};
