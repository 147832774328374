export const auditDetailsTableCols = [
  {
    id: 'key',
    name: 'Field',
    sortable: false,
    value: 'key',
    display: true,
  },
  {
    id: 'value',
    name: 'Value',
    sortable: false,
    value: 'value',
    display: true,
  },
];
