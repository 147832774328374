import {
  Directive,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { map, skip } from 'rxjs/operators';

@Directive({
  selector: '[ngModelTyped]',
})
export class NgModelTypedDirective implements OnInit, OnDestroy {
  @Input()
  ngModelKey: string;

  @Output()
  ngModelTypedChange = new EventEmitter<any>();

  private subscription: Subscription;

  constructor(private ngModel: NgModel) {}

  ngOnInit(): void {
    this.subscription = this.ngModel.control.valueChanges
      .pipe(
        skip(1),
        map((value) => ({ [this.ngModelKey || 'model']: value }))
      )
      .subscribe((value) => this.ngModelTypedChange.emit(value));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
