import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GanttChartData } from '../../../../shared/models/gantt.model';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, pluck, tap } from 'rxjs/operators';
import { ProjectRightsService } from '../../../../shared/service/user-rights/project-rights/project-rights.service';
import { ProjectModel } from '../../../../shared/models/project.model';
import { ProjectStepperService } from 'src/app/shared/service/project-stepper-service/project-stepper.service';
import { ChangeRequestFacadeService } from 'src/app/shared/facade/change-request-facade/change-request-facade.service';
import { ProjectGanttProcessorService } from '../../../../shared/service/project-gantt-processor/project-gantt-processor.service';
import { DhtmlxGanttService } from '../../../../shared/service/dhtmlx-gantt-service/dhtmlx-gantt.service';
import { ProjectService } from 'src/app/shared/service/project/project.service';
import { DashDialogService, ExosDialogConfig } from '@dashboard/exos-dialog';
import jiraIssuesConfig from '../../../../shared/config/dialogs/jira-issues.config';
import { JiraIssuesDialogComponent } from 'src/app/shared/components/dialogs/jira-issues-dialog/jira-issues-dialog.component';
import { DhtmlxGanttComponent } from 'src/app/shared/components/dhtmlx-gantt/dhtmlx-gantt.component';
import { ListingRouteDataConfig } from 'src/app/shared/models/listing';
import { gantt } from 'src/app/shared/service/dhtmlx-gantt-service/dhtmlx-gantt';
import { DashTableColumnDefinition } from '@dashboard/table';
import {
  UserPreferencesService,
  UserPreference,
} from '@dashboard/user-preferences';
import { UserService } from '@dashboard/core';

@Component({
  selector: 'app-project-plan',
  templateUrl: './project-plan-route.component.html',
  styleUrls: ['./project-plan-route.component.scss'],
})
export class ProjectPlanRouteComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild(DhtmlxGanttComponent)
  public dhtmlxComponent: DhtmlxGanttComponent;

  projectId: number;

  ganttChartData: GanttChartData;

  jqlDialogConfig: ExosDialogConfig = {
    ...jiraIssuesConfig,
  };

  project$: Observable<ProjectModel>;

  projectName: string;

  public routeData: ListingRouteDataConfig;

  public tableCols: DashTableColumnDefinition[] = [];

  public toggle = false;

  public isChartSelected = true;

  public userPreferences: UserPreference;

  only_chart = {
    css: 'gantt_container',
    cols: [
      {
        rows: [
          { view: 'grid', scrollX: 'scrollHor', scrollY: 'scrollVer' },
          { view: 'scrollbar', id: 'scrollHor', group: 'horizontal' },
        ],
      },
      { view: 'scrollbar', id: 'scrollVer' },
    ],
  };

  grid_and_chart = {
    css: 'gantt_container',
    cols: [
      {
        rows: [{ view: 'grid' }],
      },
      { resizer: true, width: 1 },
      {
        rows: [
          { view: 'timeline', scrollX: 'scrollHor', scrollY: 'scrollVer' },
          { view: 'scrollbar', id: 'scrollHor', group: 'horizontal' },
        ],
      },
      { view: 'scrollbar', id: 'scrollVer' },
    ],
  };

  constructor(
    private route: ActivatedRoute,
    private userRightsService: ProjectRightsService,
    private changeRequestFacadeService: ChangeRequestFacadeService,
    private projectStepperService: ProjectStepperService,
    private projectGanttProcessorService: ProjectGanttProcessorService,
    private router: Router,
    private dhtmlxGanttService: DhtmlxGanttService,
    private projectService: ProjectService,
    private dialogService: DashDialogService,
    private userPreferencesService: UserPreferencesService,
    private userService: UserService
  ) {}

  ngOnInit() {
    const projectId = parseInt(
      this.route.snapshot.parent.paramMap.get('id'),
      10
    );

    this.route.data.pipe(pluck('page')).subscribe((routeData) => {
      this.routeData = routeData;

      this.getUserPreferences(projectId);
    });

    const ganttChartData$: Observable<GanttChartData> = this.route.data.pipe(
      pluck('ganttChartData')
    );

    this.project$ = this.route.parent.data.pipe(pluck('project'));

    combineLatest([this.project$, ganttChartData$])
      .pipe(
        map(([project, ganttChartData]) => {
          return { project, ganttChartData };
        })
      )
      .subscribe(({ project, ganttChartData }) => {
        this.projectName = project.name;
        this._updateRoute(project, ganttChartData);
      });

    this.changeRequestFacadeService.project$.subscribe(
      (project: ProjectModel) => {
        this._updateRoute(project, null, true);
      }
    );
  }

  private getUserPreferences(projectId: number) {
    this.route.data
      .pipe(
        tap((response) => {
          this.userPreferences = response
            .userPreferences?.[0] as UserPreference;
        }),
        map(({ userPreferences }) => userPreferences?.[0])
      )
      .subscribe((userPreferences) => {
        if (userPreferences) {
          this.tableCols = userPreferences.userPreferences.columns;
        } else {
          this.tableCols = this.routeData.tableCols;
          this.pushToUpdatePrefsQueue();
        }
        this.projectGanttProcessorService.initDataProcessor(projectId);
      });
  }

  ngAfterViewInit(): void {
    this.convertToGanttCols();
  }

  ngOnDestroy() {
    this.projectGanttProcessorService.flushProcessor();
  }

  private _updateRoute(
    project: ProjectModel,
    ganttChartData?: GanttChartData,
    updateStepper?: boolean
  ) {
    if (ganttChartData) {
      this.ganttChartData = ganttChartData;
      this.projectId = project.id;
    }

    if (updateStepper) {
      this.projectStepperService.update(project);
    }

    this.dhtmlxGanttService.setCanUpdate(
      !this.userRightsService.getUserRights(project).canUpdate
    );
  }

  openJiraModal() {
    this.projectService.findById(this.projectId).subscribe((project) => {
      this.jqlDialogConfig.data.formConfig.fields.jql.value = project.jql;
      this.jqlDialogConfig.data['projectId'] = this.projectId;
      const dialog = this.dialogService.open(JiraIssuesDialogComponent, {
        ...JSON.parse(JSON.stringify(this.jqlDialogConfig)),
      });
      dialog.afterClosed.subscribe((result) => {
        if (result as GanttChartData) {
          (this.ganttChartData.data = [...result.data]),
            (this.ganttChartData.links = [...result.links]);
          this.dhtmlxGanttService.initGanttChart(
            this.ganttChartData,
            this.dhtmlxComponent.ganttContainer,
            this.dhtmlxComponent.canUpdate
          );
        }
      });
    });
  }

  exportPDF() {
    this.dhtmlxGanttService.exportPDF(this.projectName);
  }

  public toggle_grid() {
    if (this.toggle) {
      gantt.config.layout = this.grid_and_chart;
      this.isChartSelected = true;
    } else {
      gantt.config.layout = this.only_chart;
      this.isChartSelected = false;
    }
    this.toggle = !this.toggle;
    gantt.init(this.dhtmlxComponent.ganttContainer.nativeElement);
    gantt.parse(this.ganttChartData);
    gantt.sort('orderIndex');
  }

  public toggleColumns(columnName: string) {
    this.tableCols = this.tableCols.map((col) => ({
      ...col,
      display: col.name === columnName ? !col.display : col.display,
    }));

    this.convertToGanttCols();
    gantt.init(this.dhtmlxComponent.ganttContainer.nativeElement);
    gantt.parse(this.ganttChartData);
    gantt.sort('orderIndex');

    this.pushToUpdatePrefsQueue();
  }

  public pushToUpdatePrefsQueue(event?: any) {
    const prefId = this.userPreferences?.id || null;
    let user_id = null;
    if (!prefId) {
      user_id = this.userService.getSession().user_id;
    }

    this.userPreferencesService.next({
      id: prefId,
      user_id,
      section: 'gantt',
      userPreferences: {
        columns: this.tableCols,
      },
    });
  }

  private convertToGanttCols() {
    this.tableCols.forEach((elem) => {
      let col = gantt.config.columns.find((col) => col.name === elem.value);
      if (col) {
        col.hide = !elem.display;
      }
    });
  }

  exportExcel() {
    this.dhtmlxGanttService.exportExcel(this.projectName);
  }
}
