import { Pipe, PipeTransform } from '@angular/core';
import { DashTableColumnDefinition } from '@dashboard/table';

@Pipe({
  name: 'tableColumnFixed'
})
export class TableColumnFixedPipe implements PipeTransform {

  transform(value: DashTableColumnDefinition[], ...args: unknown[]): DashTableColumnDefinition[] {
    const filter = value.filter((column) => !column.fixed);
    return filter;
  }
}
