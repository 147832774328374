import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ProjectModel } from '../../models/project.model';
import {
  StepperItem,
  StepperItemBuilder,
} from '../../components/project-stepper/stepper-item';

@Injectable({
  providedIn: 'root',
})
export class ProjectStepperService {
  private projectStepperConfig: { [key: string]: StepperItem[] } = {
    ionos: [
      {
        name: 'idea',
        uiName: 'Idea',
        icon: 'lightbulb',
        status: 'PASSED',
        iconSvg: null,
        link: 'details',
      },
      {
        name: 'divisionGateApproval',
        uiName: 'Approval gate',
        icon: 'report',
        status: null,
        iconSvg: null,
        link: 'details',
      },
      {
        name: 'initiate',
        uiName: 'Initiate',
        icon: null,
        iconSvg: 'rocket-outline',
        status: null,
        link: 'brief',
      },
      {
        name: 'vminusOneGateApproval',
        uiName: 'Approval gate',
        icon: 'report',
        status: null,
        iconSvg: null,
        link: 'details',
      },
      {
        name: 'planAndExecute',
        uiName: 'Plan and Execute',
        icon: 'build',
        status: null,
        iconSvg: null,
        link: 'project-plan',
      },
      {
        name: 'review',
        uiName: 'Review',
        icon: 'rate_review',
        status: null,
        iconSvg: null,
        link: 'review',
      },
      {
        name: 'clientGateApproval',
        uiName: 'Approval gate',
        icon: 'report',
        status: null,
        iconSvg: null,
        link: 'details',
      },
      {
        name: 'close',
        uiName: 'Close',
        iconSvg: 'medal-outline',
        status: null,
        icon: null,
        link: 'details',
      },
    ],
    'united-internet': [
      {
        name: 'idea',
        uiName: 'Idea',
        icon: 'lightbulb',
        status: 'PASSED',
        iconSvg: null,
        link: 'details',
      },
      {
        name: 'clientGateApproval',
        uiName: 'Approval gate',
        icon: 'report',
        status: null,
        iconSvg: null,
        link: 'details',
      },
      {
        name: 'inProgress',
        uiName: 'In Progress',
        icon: null,
        iconSvg: 'rocket-outline',
        status: null,
        link: 'functional-plan',
      },
      {
        name: 'close',
        uiName: 'Close',
        iconSvg: 'medal-outline',
        status: null,
        icon: null,
        link: 'details',
      },
    ],
  };

  private projectSteps: BehaviorSubject<StepperItem[]> = new BehaviorSubject<
    StepperItem[]
  >(null);

  public currentProjectSteps = this.projectSteps.asObservable();

  constructor(private stepperItemBuilder: StepperItemBuilder) {}

  private buildStepper(project: ProjectModel): StepperItem[] {
    const projectStepperItems = JSON.parse(
      JSON.stringify(this.projectStepperConfig[project.workflow])
    );
    const projectPassedSteps = [
      ...(project.lastApprovedGates || []),
      ...(project.lastCheckedPhases || []),
    ];

    projectPassedSteps.forEach((checkedPhase) => {
      const currentStep = projectStepperItems.find(
        (step) => step.name === checkedPhase
      );
      currentStep.status = 'PASSED';
    });

    if (project.readyToApprove && project.lastPendingGate) {
      const currentGate = projectStepperItems.find(
        (step) => step.name === project.lastPendingGate
      );
      currentGate.status = 'PENDING';
    }

    if (project.phase === 'canceled') {
      projectStepperItems.forEach((step) => {
        step.status = step.status !== null ? 'PASSED' : 'UNFINISHED';
      });
    }

    return projectStepperItems.map((step) =>
      this.stepperItemBuilder
        .setName(step.name)
        .setIcon(step.icon)
        .setIconSvg(step.iconSvg)
        .setUiName(step.uiName)
        .setStatus(step.status)
        .setLink(step.link)
        .build()
    );
  }

  update(project: ProjectModel) {
    const steps: StepperItem[] = this.buildStepper(project);
    this.projectSteps.next(steps);
  }
}
