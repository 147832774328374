<div exosSheetSection>
  <div exosSheetBlock>
    <div class="mb-3" fxLayout="row" fxLayoutAlign="space-between center">
      <h3 exosHeadline type="sub" class="mb-0">
        {{ config.data.title }}
      </h3>

      <button class="link static-overlay__close" (click)="close()">
        <i class="exos-icon exos-icon-closecard-24"></i>
      </button>
    </div>

    <ng-template libFormContainer></ng-template>
  </div>
</div>
