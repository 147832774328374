<section exosSheetSection class="w-05">
  <div exosSheetBlock>
    <p exosHeadline type="sub">
      {{ config.data.title }}
    </p>

    <ng-template libFormContainer></ng-template>

    <div *ngIf="loading">
      <div class="loading-spin loading-spin--form"></div>
    </div>
  </div>
</section>
