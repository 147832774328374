import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  FieldConfig,
  FieldConfigType,
  FormConfig,
  FormContainerDirective,
  FormService,
  MultiSelectConfig,
} from '@dashboard/exos-form';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProgramEditFacadeService } from '../../../../shared/facade/program-edit-facade/program-edit-facade.service';
import { ProgramStatusItem } from '../../../../shared/models/program-status';
import { Observable, Subscription } from 'rxjs';
import { programEditFormConfig } from '../../program-create-route/program-create.form-config';
import {
  Program,
  ProgramFormValue,
} from '../../../../shared/models/program.model';
import { debounceTime, filter, map, tap } from 'rxjs/operators';
import { ConfirmationComponent } from '../../../../shared/components/dialogs/confirmation/confirmation.component';
import { DashDialogService, ExosDialogConfig } from '@dashboard/exos-dialog';
import { ProjectStatusItem } from '../../../../shared/models/project-status';
import { StatusChangeEvent } from '../../../../shared/models/status';
import { programUpdateStatusModalConfig } from '../../../../shared/config/dialogs/program-status-dialog.config';
import { ProjectUpdateStatusDialogComponent } from '../../../../shared/components/dialogs/project-update-status-dialog/project-update-status-dialog.component';
import { DashNotificationService } from '@dashboard/core';

@Component({
  selector: 'app-program-edit-route',
  templateUrl: './program-edit-route.component.html',
  styleUrls: ['./program-edit-route.component.scss'],
})
export class ProgramEditRouteComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public formConfig: FormConfig = JSON.parse(
    JSON.stringify(programEditFormConfig)
  );

  private routeSubs: Subscription[] = [];

  private dialogConfig = {
    editStatusProgram: JSON.parse(
      JSON.stringify(programUpdateStatusModalConfig)
    ),
    deleteProgram: {
      data: {
        message: 'Are you sure?',
        title: 'Delete program',
        text: 'Delete',
      },
    },
  };

  private form: FormGroup;

  public programStatus$: Observable<ProgramStatusItem[]>;

  public canUpdate$: Observable<boolean>;

  public canDelete$: Observable<boolean>;

  public canUpdateOwner$: Observable<boolean>;

  public updateInProgress$: Observable<boolean>;

  public programId: string;

  @ViewChild(FormContainerDirective, { read: ViewContainerRef })
  private formContainer: ViewContainerRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private exosFormService: FormService,
    private dialogService: DashDialogService,
    private facade: ProgramEditFacadeService,
    private changeDetectorRef: ChangeDetectorRef,
    private notificationService: DashNotificationService
  ) {}

  ngOnInit(): void {
    this.canUpdate$ = this.facade.canUpdateProgram$;
    this.canDelete$ = this.facade.canDeleteProgram$;
    this.canUpdateOwner$ = this.facade.canUpdateOwner$;
    this.programStatus$ = this.facade.programStatus$;
    this.updateInProgress$ = this.facade.updateInProgress$;
    this.programId = this.route.parent.snapshot.paramMap.get('id');

    const errorsSub = this.facade.programUpdateErrors$.subscribe(
      (errors: any) => {
        if (errors) {
          this.handleFormError(errors);
        }
      }
    );

    this.routeSubs.push(errorsSub);
  }

  ngAfterViewInit() {
    const program = this.route.parent.snapshot.data.program as Program;

    this.patchFormConfigValues(program);
    this.facade.dispatchLoadProgram(program);

    this.form = this.buildForm();
    this.handleFormSubmit();

    this.canUpdate$ = this.facade.canUpdateProgram$.pipe(
      tap((value) => {
        this.toggleFormState(value);
      })
    );

    this.canUpdateOwner$ = this.facade.canUpdateOwner$.pipe(
      tap((value) => {
        value
          ? this.form.controls.owner.enable({ emitEvent: false })
          : this.form.controls.owner.disable({ emitEvent: false });
      })
    );
    this.changeDetectorRef.detectChanges();

    this.routeSubs.push(this.canUpdateOwner$.subscribe());

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.routeSubs.forEach((sub) => sub.unsubscribe());
  }

  public async deleteProgram() {
    const dialog = this.presentConfirmationModal(
      this.dialogConfig.deleteProgram
    );

    dialog.afterSubmit.subscribe(async () => {
      const result = await this.facade.dispatchDeleteProgram();

      if (result.updated) {
        await this.router.navigate(['/programs']);
        dialog.close();
      } else {
        this.notificationService.warning(result.error.errors.generic);
      }
    });
  }

  public updateProgramStatus(
    selectedStatus: ProgramStatusItem,
    programStatus: ProgramStatusItem[]
  ) {
    const dialog = this.presentUpdateProgramStatusModal(
      selectedStatus,
      programStatus
    );

    dialog.afterSubmit.subscribe(async (data) => {
      const result = await this.facade.dispatchUpdateProgramStatus(
        this.programId,
        data.resourceName,
        data.formValue
      );

      if (result.updated) {
        dialog.close();
      } else {
        dialog.errors(result.error);
      }
    });
  }

  private presentUpdateProgramStatusModal(
    selectedStatus: ProgramStatusItem,
    programStatus: ProgramStatusItem[]
  ) {
    const config = this.buildUpdateStatusModalConfig(
      programStatus,
      selectedStatus
    );
    return this.dialogService.open(ProjectUpdateStatusDialogComponent, config);
  }

  private presentConfirmationModal(dialogConfig) {
    return this.dialogService.open(ConfirmationComponent, dialogConfig);
  }

  private buildUpdateStatusModalConfig(
    programStatus: ProjectStatusItem[],
    status: StatusChangeEvent
  ): ExosDialogConfig {
    const { name, value, comment } = status;
    const dialogConfig: ExosDialogConfig = JSON.parse(
      JSON.stringify(this.dialogConfig.editStatusProgram)
    );
    const overallStatus = this.findOverallStatus(programStatus);

    const projectStatusValueOptions =
      dialogConfig.data.formConfig.fields.value.options.filter((item) => {
        if (name !== 'overall') {
          return item.value !== 'ON_HOLD';
        } else {
          return item.value === 'ON_HOLD' || item.value === overallStatus;
        }
      });

    dialogConfig.data.title = `Edit program ${name} status`;
    dialogConfig.data.formConfig.fields.comment.value = comment;
    dialogConfig.data.formConfig.fields.value.value = value;
    dialogConfig.data.formConfig.fields.value.options =
      projectStatusValueOptions;
    dialogConfig.data.resourceName = name;

    return dialogConfig;
  }

  private buildForm(): FormGroup {
    return this.exosFormService.buildForm(this.formContainer, this.formConfig)
      .instance.form;
  }

  private handleFormSubmit() {
    this.form.valueChanges
      .pipe(
        debounceTime(1000),
        map(() => this.form.getRawValue()),
        map((value) => this.setFormEmptyValuesToNull(value)),
        filter((value: ProgramFormValue) => this.filterFormValue(value)),
        map((value) => this.mergeMultiSelectValuesWithConfigValues(value))
      )
      .subscribe(async (value: Program) => {
        const result = await this.facade.dispatchUpdateProgram(value);

        if (result.updated) {
          this.notificationService.success('Program successfully updated.');
        }
      });
  }

  private handleFormError(errors: any) {
    this.exosFormService.setFieldErrors(errors.error);
  }

  private toggleFormState(enabled: boolean) {
    if (enabled) {
      this.form.enable({ emitEvent: false });
    } else {
      this.form.disable({ emitEvent: false });
    }
  }

  // TODO extract and beautify logic in service
  private patchFormConfigValues(program: Program) {
    const fields = Object.keys(this.formConfig.fields);

    fields.forEach((fieldName) => {
      const currentField = this.formConfig.fields[fieldName] as FieldConfigType;

      if (currentField.type === 'multiselect') {
        if ('selectedItems' in currentField && program[fieldName] !== null) {
          currentField.selectedItems = program[fieldName];
        }
      } else {
        currentField.value = program[fieldName];
      }
    });
  }

  // TODO extract and beautify logic in service
  private filterFormValue(program: ProgramFormValue): boolean {
    const configKeys = Object.keys(this.formConfig.fields);

    const valueHasAutocompleteStringValues = !!configKeys.find((configKey) => {
      const currentFieldConfig = this.formConfig.fields[
        configKey
      ] as FieldConfig;
      const isUserModelField = ['multiselect', 'autocomplete'].includes(
        currentFieldConfig.type
      );
      const valueIsString = typeof program[configKey] === 'string';

      return isUserModelField ? valueIsString : false;
    });

    return !valueHasAutocompleteStringValues;
  }

  // TODO extract and beautify logic in service
  private setFormEmptyValuesToNull(value: any): any {
    const formValueKeys = Object.keys(value);

    formValueKeys.forEach((val) => {
      const currentFieldConfig = this.formConfig.fields[val] as FieldConfig;

      if (
        ['multiselect', 'autocomplete'].includes(currentFieldConfig.type) &&
        value[val] === ''
      ) {
        value[val] = null;
      }
    });

    return value;
  }

  // TODO
  private mergeMultiSelectValuesWithConfigValues(formValue: any) {
    const entries = Object.entries(formValue);

    return entries.reduce((acc, entry) => {
      const [key, value] = entry;
      const currentFieldConfig = this.formConfig.fields[
        key
      ] as MultiSelectConfig;

      if (currentFieldConfig.type === 'multiselect') {
        acc[key] = currentFieldConfig.selectedItems;
      } else {
        acc[key] = value;
      }

      return acc;
    }, {});
  }

  // TODO extract status utilities in service
  private findOverallStatus(projectStatus: ProjectStatusItem[]) {
    const statuses: string[] = projectStatus.map((item) => item.value);

    if (statuses.includes('RISK_OR_ROADBLOCK')) {
      return 'RISK_OR_ROADBLOCK';
    } else if (statuses.includes('POTENTIAL_RISKS')) {
      return 'POTENTIAL_RISKS';
    } else if (statuses.includes('ON_TRACK')) {
      return 'ON_TRACK';
    } else {
      return 'NOT_STARTED';
    }
  }
}
