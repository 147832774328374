import { DefaultEnvDev, EnvironmentType } from '@dashboard/core';
import { patch } from './patcher';
import { customEnvVars } from './custom_env_vars';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const env: EnvironmentType = {
  ...DefaultEnvDev,
  production: false,
  services: {
    pmt: {
      base_url: 'http://localhost:8405',
      audit: {
        audit_enable: false,
        audit_default_message: 'Are you sure?',
      },
    },
    okr: {
      base_url: 'http://localhost:8406',
      audit: {
        audit_enable: false,
        audit_default_message: 'Are you sure?',
      },
    },
    preferences: {
      base_url: 'http://localhost:8407',
      audit: {
        audit_enable: false,
        audit_default_message: 'Are you sure?',
      },
    },
    roadmap: {
      base_url: 'http://localhost:8408',
      audit: {
        audit_enable: false,
        audit_default_message: 'Are you sure?',
      },
    },
  },
};

/* patch environment with values from environment variables */
export const environment = patch(env, customEnvVars);

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
