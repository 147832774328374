import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FILTER_VIEW_TYPE } from '../../models/filter-view-type.model';

@Component({
  selector: 'deprecated-links-redirect',
  template: '',
  styleUrls: [],
})
export class DeprecatedLinksRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.route.data.subscribe((data: { type: FILTER_VIEW_TYPE }) => {
      this.router.navigate(['listing/all'], {
        queryParamsHandling: 'merge',
        queryParams: { type: data.type },
      });
    });
  }
}
