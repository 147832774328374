import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ChangeRequest,
  ChangeRequestAction,
} from 'src/app/shared/models/change-request';
import { ChangeRequestUpdatePayload } from '../../facade/change-request-facade/change-request-facade.service';

@Component({
  selector: 'card-change-request',
  templateUrl: './card-change-request.component.html',
  styleUrls: ['./card-change-request.component.scss'],
})
export class CardChangeRequestComponent {
  @Input()
  changeRequests: ChangeRequest[];

  @Input()
  canCreateChangeRequest: boolean;

  @Input()
  canViewChangeRequests: boolean;

  @Input()
  viewRoutePath: string;

  @Input()
  updateInProgress: boolean;

  @Output()
  updateChangeRequest: EventEmitter<ChangeRequestUpdatePayload> = new EventEmitter();

  @Output()
  createChangeRequest: EventEmitter<any> = new EventEmitter();

  constructor() {}

  onUpdateChangeRequest(
    ev: Event,
    changeRequest: ChangeRequest,
    action: ChangeRequestAction
  ) {
    ev.stopPropagation();

    this.updateChangeRequest.emit({
      actionName: action.name,
      data: changeRequest,
      state: action.value,
    });
  }

  onCreateChangeRequest() {
    this.createChangeRequest.emit();
  }
}
