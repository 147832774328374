import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckListDirective } from './check-list.directive';

@NgModule({
  declarations: [CheckListDirective],
  imports: [CommonModule],
  exports: [CheckListDirective],
})
export class ListModule {}
