import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SheetDirective,
  SheetSectionDirective,
  SheetBlockDirective,
  SheetFooterDirective,
  SheetVisualDirective,
  SheetCloseDirective,
  SheetSeparatorDirective,
} from './sheet.directive';

@NgModule({
  declarations: [
    SheetDirective,
    SheetSectionDirective,
    SheetBlockDirective,
    SheetFooterDirective,
    SheetVisualDirective,
    SheetCloseDirective,
    SheetSeparatorDirective,
  ],
  imports: [CommonModule],
  exports: [
    SheetDirective,
    SheetSectionDirective,
    SheetBlockDirective,
    SheetFooterDirective,
    SheetVisualDirective,
    SheetCloseDirective,
    SheetSeparatorDirective,
  ],
})
export class SheetModule {}
