import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DashNotificationService, UserService } from '@dashboard/core';
import { DashDialogService } from '@dashboard/exos-dialog';
import {
  FieldConfig,
  FormConfig,
  FormContainerDirective,
  FormEvent,
  FormService,
  SelectInputConfig,
} from '@dashboard/exos-form';
import { Subscription } from 'rxjs';
import { ComponentLookupRegistry } from 'src/app/shared/decorators/component-lookup.config';
import {
  generateDivisionSetsDisableMap,
  generateDivisionSetsMap,
} from 'src/app/utils/division-sets-map';
import { CustomExosFormComponent } from '../../../shared/components/custom-exos-form/custom-exos-form.component';
import {
  PartialObjectiveSet,
  PartialObjectiveSetMap,
} from '../../../shared/models/objective.model';
import { ProjectModel } from '../../../shared/models/project.model';
import { DivisionService } from '../../../shared/service/division/division.service';
import { ObjectiveService } from '../../../shared/service/objective/objective.service';
import { ProjectService } from '../../../shared/service/project/project.service';
import { UsersService } from '../../../shared/service/users/users.service';
import { projectCreateFormConfig } from './project-create-form.config';

@Component({
  selector: 'project-create',
  templateUrl: './project-create-route.component.html',
  styleUrls: ['./project-create-route.component.scss'],
})
export class ProjectCreateRouteComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  public projectCreateFormConfig: FormConfig = JSON.parse(
    JSON.stringify(projectCreateFormConfig)
  );

  @ViewChild(FormContainerDirective)
  private formContainer: FormContainerDirective;

  private projectCreateForm: FormGroup;

  private objectiveSets: PartialObjectiveSet[];
  private divisionSetsMap: PartialObjectiveSetMap = {};
  private divisionSetsDisableMap: {
    [year: number]: {
      [quarter: string]: boolean;
    };
  } = {};
  private currentSet: PartialObjectiveSet;

  subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private projectService: ProjectService,
    private userService: UserService,
    private userLdapService: UsersService,
    private router: Router,
    private exosFormService: FormService,
    public location: Location,
    private divisionService: DivisionService,
    private notificationService: DashNotificationService,
    private objectiveService: ObjectiveService,
    private dialogService: DashDialogService
  ) {}

  ngOnInit() {
    this.subscription = this.exosFormService.openModal.subscribe((config) => {
      this.dialogService.open(ComponentLookupRegistry.get(config), {
        data: { title: '' },
      });
    });
  }

  ngAfterViewInit() {
    this.objectiveSets = this.route.snapshot.data.objectiveSets.filter(
      (set) => !!set.division
    );

    this.divisionSetsMap = generateDivisionSetsMap(this.objectiveSets);
    this.divisionSetsDisableMap = generateDivisionSetsDisableMap(
      this.divisionSetsMap
    );

    this.currentSet = this.findObjectivesSet(this.objectiveSets);
    const objectiveYearField = this.projectCreateFormConfig.fields
      .objectiveYear as SelectInputConfig;
    objectiveYearField.options = Object.keys(this.divisionSetsMap).map(
      (year) => ({
        value: year,
        text: year,
      })
    );
    objectiveYearField.value = this.currentSet.year;

    const objectiveQuarterFields = this.projectCreateFormConfig.fields
      .objectiveQuarter as SelectInputConfig;
    objectiveQuarterFields.options = this.getQuarters(
      objectiveYearField.value,
      this.divisionSetsMap,
      this.divisionSetsDisableMap
    );

    const objectiveDivisionFields = this.projectCreateFormConfig.fields
      .objectiveDivision as SelectInputConfig;
    objectiveDivisionFields.options = this.divisionSetsMap[
      this.currentSet?.year
    ]?.[this.currentSet?.quarter]
      .filter((set: PartialObjectiveSet) => !set?.division.disabled)
      .map((set: PartialObjectiveSet) => ({
        value: `${set.division?.id}`,
        text: `${set.division?.text}${
          set.division?.isCompany ? ' (Company)' : ''
        }`,
      }));

    const objectiveField = this.projectCreateFormConfig.fields
      .objectiveId as SelectInputConfig;
    const objectives = this.currentSet.objectives;
    objectiveField.options = objectives.map((objective) => ({
      value: JSON.stringify(objective),
      text: objective.title,
    }));

    const keyResultField = this.projectCreateFormConfig.fields
      .keyResultId as SelectInputConfig;
    keyResultField.options = [];

    const {
      user_id: id,
      fullname: name,
      username,
    } = this.userService.getSession();

    const owner: FieldConfig = this.projectCreateFormConfig.fields
      .owner as FieldConfig;

    owner.value = { id, name, username, text: name };

    const createForm = this.exosFormService.buildForm(
      this.formContainer.viewContainerRef,
      this.projectCreateFormConfig,
      CustomExosFormComponent
    ).instance;

    this.projectCreateForm = createForm.form;

    setTimeout(() => {
      this.projectCreateForm.patchValue(
        {
          objectiveYear: this.currentSet?.year,
          objectiveQuarter: this.currentSet?.quarter,
          objectiveDivision: `${this.currentSet?.division?.id}`,
          objectiveId: null,
          keyResultId: null,
        },
        { emitEvent: false }
      );
    }, 0);
    this.projectCreateForm.controls.keyResultId.disable();

    createForm.formEvents.subscribe((event: FormEvent) => {
      if (event.type === 'submit') {
        this.createNewProject(event);
      } else if (event.type === 'cancel') {
        this.location.back();
      }
    });

    this.projectCreateForm.controls.objectiveYear.valueChanges.subscribe(
      (selectedYear) => {
        this.currentSet = this.objectiveSets.find(
          (set) => set.year === selectedYear && !set.division.disabled
        );
        const objectiveQuarterFields = this.projectCreateFormConfig.fields
          .objectiveQuarter as SelectInputConfig;
        objectiveQuarterFields.options = this.getQuarters(
          selectedYear,
          this.divisionSetsMap,
          this.divisionSetsDisableMap
        );

        const objectiveDivisionFields = this.projectCreateFormConfig.fields
          .objectiveDivision as SelectInputConfig;
        objectiveDivisionFields.options = this.divisionSetsMap[
          this.currentSet?.year
        ]?.[this.currentSet?.quarter]
          .filter((set: PartialObjectiveSet) => !set?.division.disabled)
          .map((set: PartialObjectiveSet) => ({
            value: `${set.division?.id}`,
            text: `${set.division?.text}${
              set.division?.isCompany ? ' (Company)' : ''
            }`,
          }));

        const objectiveField = this.projectCreateFormConfig.fields
          .objectiveId as SelectInputConfig;
        const objectives = this.currentSet.objectives;
        objectiveField.options = objectives.map((objective) => ({
          value: JSON.stringify(objective),
          text: objective.title,
        }));

        const keyResultField = this.projectCreateFormConfig.fields
          .keyResultId as SelectInputConfig;
        keyResultField.options = [];

        this.projectCreateForm.patchValue(
          {
            objectiveQuarter: this.currentSet?.quarter,
            objectiveDivision: `${this.currentSet.division?.id}`,
            objectiveId: null,
            keyResultId: null,
          },
          { emitEvent: false }
        );
      }
    );

    this.projectCreateForm.controls.objectiveQuarter.valueChanges.subscribe(
      (selectedQuarter) => {
        this.currentSet = this.divisionSetsMap[this.currentSet?.year]?.[
          selectedQuarter
        ]?.find((set) => !set.division.disabled);

        const objectiveDivisionFields = this.projectCreateFormConfig.fields
          .objectiveDivision as SelectInputConfig;
        objectiveDivisionFields.options = this.divisionSetsMap[
          this.currentSet?.year
        ]?.[this.currentSet?.quarter]
          .filter((set: PartialObjectiveSet) => !set?.division.disabled)
          .map((set: PartialObjectiveSet) => ({
            value: `${set.division?.id}`,
            text: `${set.division?.text}${
              set.division?.isCompany ? ' (Company)' : ''
            }`,
          }));
        objectiveDivisionFields.value = this.currentSet.division?.value;

        const objectiveField = this.projectCreateFormConfig.fields
          .objectiveId as SelectInputConfig;
        const objectives = this.currentSet.objectives;
        objectiveField.options = objectives.map((objective) => ({
          value: JSON.stringify(objective),
          text: objective.title,
        }));

        const keyResultField = this.projectCreateFormConfig.fields
          .keyResultId as SelectInputConfig;
        keyResultField.options = [];

        this.projectCreateForm.patchValue(
          {
            objectiveDivision: `${this.currentSet.division?.id}`,
            objectiveId: null,
            keyResultId: null,
          },
          { emitEvent: false }
        );
      }
    );

    this.projectCreateForm.controls.objectiveDivision.valueChanges.subscribe(
      (selectedDivision) => {
        this.currentSet = this.divisionSetsMap[this.currentSet?.year]?.[
          this.currentSet?.quarter
        ]?.find((set) => `${set.division?.id}` == selectedDivision);

        const objectiveField = this.projectCreateFormConfig.fields
          .objectiveId as SelectInputConfig;
        const objectives = this.currentSet.objectives;
        objectiveField.options = objectives.map((objective) => ({
          value: JSON.stringify(objective),
          text: objective.title,
        }));
        const keyResultField = this.projectCreateFormConfig.fields
          .keyResultId as SelectInputConfig;
        keyResultField.options = [];
        this.projectCreateForm.patchValue(
          {
            objectiveId: null,
            keyResultId: null,
          },
          { emitEvent: false }
        );
      }
    );

    this.projectCreateForm.controls.objectiveId.valueChanges.subscribe(
      (selectedObjective) => {
        const keyResultField = this.projectCreateFormConfig.fields
          .keyResultId as SelectInputConfig;

        if (!selectedObjective) {
          keyResultField.options = [];
          this.projectCreateForm.controls.keyResultId.disable();
        } else {
          selectedObjective = JSON.parse(selectedObjective);

          keyResultField.options = selectedObjective.keyResults.map(
            (keyResult) => ({
              value: JSON.stringify(keyResult),
              text: keyResult.name,
            })
          );
          this.projectCreateForm.controls.keyResultId.enable();
        }

        this.projectCreateForm.patchValue(
          {
            keyResultId: null,
          },
          { emitEvent: false }
        );

        this.projectCreateForm.controls.keyResultId.setErrors({
          bkError: null,
        });
        this.projectCreateForm.controls.keyResultId.updateValueAndValidity();
      }
    );

    this.changeDetectorRef.detectChanges();
  }

  private findObjectivesSet(sets: PartialObjectiveSet[]): PartialObjectiveSet {
    let set: PartialObjectiveSet = null;
    if (!set) {
      const enabledDivisions = sets.filter((set) => !set.division?.disabled);
      set =
        enabledDivisions.find((set) => set.isCurrentSet) || enabledDivisions[0];
    }

    return set || sets?.[0];
  }

  private getQuarters(year: string, divisionSetsMap, divisionSetsDisableMap) {
    return Object.keys(divisionSetsMap[year]).map((quarter) => {
      return {
        value: quarter,
        text: quarter,
        disabled: !!divisionSetsDisableMap[year]?.[quarter],
      };
    });
  }

  private createNewProject(event: FormEvent) {
    if (event.formIsValid) {
      this.projectCreateForm.markAsPending();

      const payload = {
        ...event.rawValue,
        objectiveId: event.rawValue.objectiveId
          ? JSON.parse(event.rawValue.objectiveId).id
          : null,
        keyResultId: event.rawValue.keyResultId
          ? JSON.parse(event.rawValue.keyResultId).id
          : null,
      };

      this.projectService.create(payload).subscribe(
        (response) => this.handleSubmitSuccess(response),
        ({ error }) => this.handleSubmitError(error)
      );
    }
  }

  private handleSubmitSuccess(project: ProjectModel) {
    this.notificationService.success('Successfully created new project.');
    this.router.navigate(['projects', project.id, 'details']);
  }

  private handleSubmitError(errors) {
    this.exosFormService.setFieldErrors(errors);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
