import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@dashboard/core';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ObjectiveSet,
  PartialObjective,
  PartialObjectiveSet,
} from '../../models/objective.model';
import { Division } from '../division/division.service';

@Injectable({
  providedIn: 'root',
})
export class ObjectiveService {
  constructor(private apiService: ApiService, private http: HttpClient) {}

  public getObjectivesSets(): Observable<any[]> {
    return this.http
      .get<ObjectiveSet[]>(`${this.apiService.getBaseUrl('okr')}/partialSets/`)
      .pipe(map((response) => response));
  }

  public getAllDivisionObjectivesSets(): Observable<PartialObjectiveSet[]> {
    const divisions$ = this.http.get<Division[]>(
      `${this.apiService.getBaseUrl('okr')}/divisions/`
    );
    const objectives$ = this.http.get<PartialObjective[]>(
      `${this.apiService.getBaseUrl('okr')}/partialObjectives/`
    );

    return combineLatest([divisions$, objectives$]).pipe(
      map(([divisions, objectives]) => {
        const sets = [];
        for (const division of divisions) {
          const divisionObjectives = objectives.filter(
            (objective) => objective.divisionId == division.id
          );
          if (!!division.okrDivisionSet && !!divisionObjectives) {
            sets.push({
              division: {
                ...division,
                value: division.item,
                text: division.item,
                disabled: !divisionObjectives?.some(
                  (objective) => objective.keyResults?.length > 0
                ),
              },
              id: division.okrDivisionSet.id,
              objectives: divisionObjectives,
              year: division.okrDivisionSet.year,
              quarter: division.okrDivisionSet.quarter,
              isCurrentSet: division.okrDivisionSet.isCurrentSet,
            });
          }
        }

        return sets;
      })
    );
  }
}
