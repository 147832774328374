<ng-container *ngIf="routeData$ | async as routeData">
  <header exosPageHeader>
    <div exosPageHeaderBlock>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <h1 exosPageHeaderHeadline>
          {{ routeData.title }}
        </h1>

        <ng-container *ngIf="routeData?.button">
          <button
            exosButton
            semantic="primary"
            [routerLink]="routeData.button.link"
            *ngIf="canCreateProgram"
          >
            {{ routeData.button.label }}
          </button>
        </ng-container>
      </div>
    </div>
  </header>
  <div exosPageSection>
    <div exosPageSectionBlock>
      <jql-input
        [searchQuery]="searchQuery"
        [searchIsBasic]="searchIsBasic"
        [tableColumns]="tableCols"
        [locales]="jqlLocales"
        (submitSearch)="fetchProjectsByQuery($event)"
        (searchTypeChanged)="handleSearchType($event)"
        (columnChecked)="toggleColumns($event)"
        filterType="programs"
      >
      </jql-input>
      <div class="programs-table-container">
        <dash-table
          #table
          [dataSource]="programs"
          (rowClicked)="goToEditById($event)"
          [cols]="tableCols | tableColumnFilter"
          [noDataMessage]="tableNotFoundMsg"
        >
          <td dashTableColumn id="name">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
              <ng-container *ngIf="col.sortable">
                <i
                  *ngIf="
                    col.sortDirection === 'asc' || col.sortDirection == null
                  "
                  class="material-icons"
                >
                  arrow_upward</i
                >
                <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                  arrow_downward</i
                >
              </ng-container>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <i
                class="exos-icon exos-icon-lock-48 exos-icon-locked"
                exosTooltip="CONFIDENTIAL"
                exosTooltipPosition="above"
                *ngIf="row.confidential"
              ></i>
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>

          <dash-table
            #table
            [dataSource]="programs"
            (rowClicked)="goToEditById($event)"
            [cols]="tableCols | tableColumnFilter"
            [noDataMessage]="tableNotFoundMsg"
          >
            <td dashTableColumn id="name">
              <ng-container *dashTableHeaderTemplate="let col">
                <span>{{ col.name }}</span>
                <ng-container *ngIf="col.sortable">
                  <i
                    *ngIf="
                      col.sortDirection === 'asc' || col.sortDirection == null
                    "
                    class="material-icons"
                  >
                    arrow_upward</i
                  >
                  <i
                    *ngIf="col.sortDirection === 'desc'"
                    class="material-icons"
                  >
                    arrow_downward</i
                  >
                </ng-container>
              </ng-container>
              <ng-container
                *dashTableCellTemplate="
                  let row = row;
                  let value = value;
                  let index = index
                "
              >
                <i
                  class="exos-icon exos-icon-lock-48 exos-icon-locked"
                  exosTooltip="CONFIDENTIAL"
                  exosTooltipPosition="above"
                  *ngIf="row.confidential"
                ></i>
                <span [exosTooltip]="value" exosTooltipPosition="above">{{
                  value
                }}</span>
              </ng-container>
            </td>

            <td dashTableColumn id="projects">
              <ng-container *dashTableHeaderTemplate="let col">
                <span>{{ col.name }}</span>
              </ng-container>
            </td>

            <ng-container>
              <td dashTableColumn id="status">
                <ng-container *dashTableHeaderTemplate="let col">
                  <span>{{ col.name }}</span>
                  <ng-container *ngIf="col.sortable">
                    <i
                      *ngIf="
                        col.sortDirection === 'asc' || col.sortDirection == null
                      "
                      class="material-icons"
                    >
                      arrow_upward</i
                    >
                    <i
                      *ngIf="col.sortDirection === 'desc'"
                      class="material-icons"
                    >
                      arrow_downward</i
                    >
                  </ng-container>
                </ng-container>
                <ng-container
                  *dashTableCellTemplate="
                    let row = row;
                    let value = value;
                    let index = index
                  "
                >
                  <span exosBadge [semantic]="row.statusItem.semanticType">
                    {{ row.statusItem.valueLocalized }}
                  </span>
                </ng-container>
              </td>
            </ng-container>

            <td dashTableColumn id="status_comment">
              <ng-container *dashTableHeaderTemplate="let col">
                <span>{{ col.name }}</span>
                <ng-container *ngIf="col.sortable">
                  <i
                    *ngIf="
                      col.sortDirection === 'asc' || col.sortDirection == null
                    "
                    class="material-icons"
                  >
                    arrow_upward</i
                  >
                  <i
                    *ngIf="col.sortDirection === 'desc'"
                    class="material-icons"
                  >
                    arrow_downward</i
                  >
                </ng-container>
              </ng-container>
              <ng-container
                *dashTableCellTemplate="
                  let row = row;
                  let value = value;
                  let index = index
                "
              >
                <span [exosTooltip]="value" exosTooltipPosition="above">{{
                  value
                }}</span>
              </ng-container>
            </td>
          </dash-table>

          <td dashTableColumn id="status_comment">
            <ng-container *dashTableHeaderTemplate="let col">
              <span>{{ col.name }}</span>
              <ng-container *ngIf="col.sortable">
                <i
                  *ngIf="
                    col.sortDirection === 'asc' || col.sortDirection == null
                  "
                  class="material-icons"
                >
                  arrow_upward</i
                >
                <i *ngIf="col.sortDirection === 'desc'" class="material-icons">
                  arrow_downward</i
                >
              </ng-container>
            </ng-container>
            <ng-container
              *dashTableCellTemplate="
                let row = row;
                let value = value;
                let index = index
              "
            >
              <span [exosTooltip]="value" exosTooltipPosition="above">{{
                value
              }}</span>
            </ng-container>
          </td>
        </dash-table>
      </div>
      <dash-table-paginator
        class="mt-2"
        [pageContext]="pageContext"
        [perPageValues]="perPageValues"
      >
      </dash-table-paginator>
    </div>
  </div>
</ng-container>
