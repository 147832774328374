export const addProjectToProgramDialogConfig = {
  data: {
    formConfig: {
      action: 'programs',

      fields: {
        project: {
          name: 'project',
          label: 'Project Name',
          placeholder: 'Project Name',
          type: 'autocomplete',
          fxFlex: '100',
          items: null,
          value: null,
          service: {
            name: 'projectService',
            action: 'findProjectByName',
          },
          noResultsMessage: 'No project found.',
        },
      },

      buttons: [
        {
          label: 'Add project',
          type: 'button',
          action: 'submit',
          hasLoader: true,
          isPrimary: true,
        },
        {
          label: 'Close',
          type: 'button',
          action: 'cancel',
          hasLoader: false,
        },
      ],
    },
    title: 'Add Project',
  },
};
