import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';

export const filterSaveFormConfig: FormConfig = {
  fields: {
    filterName: {
      name: 'filterName',
      placeholder: 'Filter name',
      type: 'input',
      fxFlex: '100',
      value: null,
    },
  },

  buttons: [
    {
      label: 'Save',
      type: 'button',
      action: 'save',
      hasLoader: true,
      isPrimary: true,
    },
    {
      label: 'Close',
      type: 'button',
      action: 'cancel',
      hasLoader: false,
    },
  ],
};

export const filterSaveModalConfig: ExosDialogConfig = {
  data: {
    title: 'Save Filter',
    formConfig: filterSaveFormConfig,
  },
};

export const filterEditModalConfig: ExosDialogConfig = {
  data: {
    title: 'Edit Filter',
    formConfig: filterSaveFormConfig,
    edit: true,
  },
};
