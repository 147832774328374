import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  ContentChild,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
} from '@angular/core';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { Subject } from 'rxjs';

@Directive({
  selector: '[editTableCellContent]',
})
export class EditTableCellContentDirective {
  private isVisible = true;

  private isVisible$ = new Subject();

  constructor(private elementRef: ElementRef) {}

  display() {
    this.elementRef.nativeElement.style.display = 'block';
  }

  hide() {
    this.elementRef.nativeElement.style.display = 'none';
  }
}

@Directive({
  selector: '[editTableCellInline]',
})
export class EditTableCellInlineDirective {
  private inputPortalOutlet: DomPortalOutlet;

  private readonly inputCmpPortal: ComponentPortal<TableCellInputComponent>;

  private _isLoading = false;

  private inputCmpPortalInstance: ComponentRef<TableCellInputComponent>;

  @ContentChild(EditTableCellContentDirective)
  private contentChild: EditTableCellContentDirective;

  constructor(
    private elementRef: ElementRef,
    private applicationRef: ApplicationRef,
    private injector: Injector,
    private cmpFactoryResolver: ComponentFactoryResolver
  ) {
    this.inputPortalOutlet = new DomPortalOutlet(
      this.elementRef.nativeElement,
      this.cmpFactoryResolver,
      this.applicationRef,
      this.injector
    );

    this.inputCmpPortal = new ComponentPortal<TableCellInputComponent>(
      TableCellInputComponent
    );
  }

  @HostListener('click') clickHandler() {
    if (!this._isLoading && !this.inputCmpPortalInstance) {
      this.inputCmpPortalInstance =
        this.inputPortalOutlet.attachComponentPortal(this.inputCmpPortal);
      this.contentChild.hide();
    } else {
      // this.inputPortalOutlet.detach();
      // this.inputCmpPortalInstance = null;
    }
  }
}

@Component({
  selector: 'edit-inline-input',
  host: {
    class: '',
  },
  template: ` <input class="input-text" type="text" autofocus /> `,
})
export class TableCellInputComponent {
  constructor() {}
}
