import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'exos-error',
  templateUrl: './exos-error.component.html',
  styleUrls: ['./exos-error.component.scss'],
})
export class ExosErrorComponent implements OnInit {
  @Input()
  public group: FormGroup;

  @Input()
  public name: string;

  constructor() {}

  ngOnInit(): void {}
}
