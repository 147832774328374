import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  convertToParamMap,
  Params,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { ProjectModel } from '../../../../shared/models/project.model';
import { filter, map, pluck, tap } from 'rxjs/operators';
import { JqlTokenSuggestionService } from '../../../../exos/jql-input-search/services/jql-token-suggestion/jql-token-suggestion.service';
import { JqlTokenValidatorService } from '../../../../exos/jql-input-search/services/jql-token-validator/jql-token-validator.service';
import { JQL_CONFIG } from '../../../../exos/jql-input-search/config/config';
import { JQL_PROJECT_CONFIG } from '../../../../project/config/jql.config';
import { ListingRouteDataConfig } from '../../../../shared/models/listing';
import {
  DashTableComponent,
  PageContext,
  SortDirection,
  UrlDataSource,
} from '@dashboard/table';
import { JqlInputComponent } from '../../../../exos/jql-input-search/components/jql-input/jql-input.component';
import {
  ApiService,
  DashNotificationService,
  DiscoveryService,
  UserService,
} from '@dashboard/core';
import { JqlTokenBuilderService } from '../../../../exos/jql-input-search/services/jql-token-builder/jql-token-builder.service';
import { RouteProjectColumnsService } from '../../../../shared/service/route-projects-columns/route-project-columns.service';
import { Location } from '@angular/common';
import { DocumentService } from '../../../../shared/service/document/document.service';
import { ProjectStatusItem } from '../../../../shared/models/project-status';
import { StatusNameType } from '../../../../shared/models/status';
import { AddItemDialogComponent } from '../../../../shared/components/dialogs/add-item-dialog/add-item-dialog.component';
import { DashDialogService, ExosDialogConfig } from '@dashboard/exos-dialog';
import { ProgramEditFacadeService } from '../../../../shared/facade/program-edit-facade/program-edit-facade.service';
import { Program } from '../../../../shared/models/program.model';
import { addProjectToProgramDialogConfig } from '../../../../shared/config/dialogs/add-project-to-program-dialog.config';
import {
  Objective,
  ObjectiveSet,
  PartialObjective,
  PartialObjectiveSet,
} from 'src/app/shared/models/objective.model';
import { ObjectiveService } from 'src/app/shared/service/objective/objective.service';
import {
  UserPreferencesService,
  UserPreference,
} from '@dashboard/user-preferences';
import { FilterViewType } from 'src/app/shared/models/filter-view-type.model';
import { FormEvent } from '@dashboard/exos-form';
import { ViewOkrDialogComponent } from 'src/app/shared/components/dialogs/view-okr-dialog/view-okr-dialog.component';
import { projectObjectiveFormConfig } from 'src/app/project/containers/project-route/project-overview-route/project-overview-form.config';
import { SECTIONS } from 'src/app/shared/config/sections/sections.config';
import { UserPreferencesService as UserPreferencesServiceLocal } from '../../../../shared/service/user-preferences/user-preferences.service';

@Component({
  selector: 'app-program-projects',
  templateUrl: './program-projects.component.html',
  styleUrls: ['./program-projects.component.scss'],
  providers: [
    JqlTokenSuggestionService,
    JqlTokenValidatorService,
    {
      provide: JQL_CONFIG,
      useValue: { projects: JQL_PROJECT_CONFIG },
    },
  ],
})
export class ProgramProjectsComponent implements OnInit, AfterViewInit {
  public program$: Observable<Program>;

  public canUpdate$: Observable<boolean>;

  public routeData$: Observable<ListingRouteDataConfig>;

  public updateInProgress$: Observable<boolean>;

  public projects: UrlDataSource<ProjectModel>;

  public readonly tableNotFoundMsg = 'No projects found.';
  public readonly perPageValues: Array<number> = [25, 50, 100];
  public readonly pmtHost: string = `${this.apiService.getBaseUrl(
    'pmt'
  )}/projects/list/`;

  public pageContext: PageContext = new PageContext();
  public searchIsBasic = false;
  public searchQuery = '';

  public program: Program;

  public tableCols: any[];

  public displayObjectivesLoader = false;

  public existingFile;
  public existingProjectId;

  public objectiveSets: {
    company: PartialObjectiveSet[];
    division: PartialObjectiveSet[];
  };
  public objectives: PartialObjective[];

  public objectiveSets$: Observable<PartialObjective[]> = this.route.data.pipe(
    tap((response) => {
      if (response.objectiveSets && response.objectiveSets.length > 0) {
        this.objectiveSets = {
          company: response.objectiveSets.filter(
            (set: PartialObjectiveSet) => !set.division?.id
          ),
          division: response.objectiveSets.filter(
            (set: PartialObjectiveSet) => !!set.division?.id
          ),
        };
        const objectives: PartialObjective[] = [];
        response.objectiveSets.forEach((set) => {
          objectives.push(...(set.objectives || []));
        });
        this.objectives = objectives as PartialObjective[];
      }
    }),
    map(({ objectiveSets }) => objectiveSets)
  );

  public userPreferences: UserPreference;
  public dataType = FilterViewType.projects;

  public userPreferences$: Observable<UserPreference> = this.route.data.pipe(
    tap((response) => {
      this.userPreferences = response.userPreferences as UserPreference;
    }),
    map(({ userPreferences }) => userPreferences)
  );

  public jqlLocales = {
    button: ['Search', 'Filter'],
    placeholder: ['Search projects', 'Filter projects'],
  };

  public loading = true;
  public disabledBtn = false;

  private addProjectToProgramDialogConfig = addProjectToProgramDialogConfig;
  public okrBaseUrl: string;

  @ViewChild(JqlInputComponent)
  public jqlInputComponent: JqlInputComponent;

  @ViewChild(DashTableComponent)
  public table: DashTableComponent;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private userSessionService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private jqlTokenBuilderService: JqlTokenBuilderService,
    private tableColumnsService: RouteProjectColumnsService,
    private location: Location,
    private documentService: DocumentService,
    private dialogService: DashDialogService,
    private programFacade: ProgramEditFacadeService,
    private notificationService: DashNotificationService,
    private userPreferencesService: UserPreferencesService,
    private userPreferencesServiceLocal: UserPreferencesServiceLocal,
    private objectiveService: ObjectiveService,
    private discoveryService: DiscoveryService
  ) {}

  ngOnInit() {
    this.discoveryService
      .getFrontendUrls('okr')
      .subscribe((result) => (this.okrBaseUrl = result.okr));

    this.program$ = this.route.parent.data.pipe(
      pluck('program'),
      tap((program: Program) => {
        this.program = program;
        this.programFacade.dispatchLoadProgram(program);
        this.searchQuery = `program = '${program.name}'`;
      })
    );

    this.routeData$ = this.route.data.pipe(
      pluck('page'),
      tap((data) => {
        this.tableCols = data.tableCols;

        this.getUserPreferences(data);

        this.existingFile = this.tableCols
          .filter((col) => col.id === 'file')
          .find((col) => col.display === true);
        this.existingProjectId = this.tableCols
          .filter((col) => col.id === 'id')
          .find((col) => col.display === true);

        this.projects = new UrlDataSource(this.pmtHost, {
          pageContext: this.pageContext,
          dataExtractor: (response) => this.dataTableExtractor(response),
          totalElementsExtractor: (response) => response.meta.count,
          httpVerb: 'POST',
        });
        this.projects.onError(() => (this.loading = false));
        this.projects.onSuccess(() => (this.loading = false));
      })
    );

    this.canUpdate$ = this.programFacade.canUpdateProgram$;
    this.updateInProgress$ = this.programFacade.updateInProgress$;
    this.changeDetectorRef.detectChanges();
  }

  private getUserPreferences(data: any) {
    this.route.data
      .pipe(
        tap((response) => {
          this.userPreferences = response
            .userPreferences?.[0] as UserPreference;
        }),
        map(({ userPreferences }) => userPreferences?.[0])
      )
      .subscribe((userPrefData: UserPreference) => {
        if (
          userPrefData &&
          userPrefData.version ===
            SECTIONS[FilterViewType[this.dataType]].version
        ) {
          this.tableCols = userPrefData.userPreferences.columns;
        } else {
          const userPreferencesLocalStorage =
            this.userPreferencesServiceLocal.getAllUserPreferences();
          if (userPreferencesLocalStorage?.[this.dataType]?.columns) {
            const columns = data[this.dataType].tableCols;
            columns.forEach((col) => {
              const foundCol = userPreferencesLocalStorage[
                this.dataType
              ].columns.find((c) => c.id === col.id);
              if (foundCol) {
                col.display = foundCol.display;
              }
            });
            this.tableCols = columns;
          } else {
            this.tableCols = data[this.dataType].tableCols;
          }

          this.pushToUpdatePrefsQueue(
            null,
            SECTIONS[FilterViewType[this.dataType]].version
          );
        }
      });
  }

  ngAfterViewInit() {
    this.route.queryParams
      .pipe(filter((params) => params !== undefined))
      .subscribe((params: Params) => {
        const queryParams = JSON.parse(JSON.stringify(params));
        const paramMap = convertToParamMap(params);

        this.projects.setRequestBody(this.jqlInputComponent.searchQueryTokens);
        this.projects.setQueryParams(queryParams);

        this.searchQuery = paramMap.has('search')
          ? paramMap.get('search')
          : this.searchQuery;
        this.searchIsBasic = paramMap.get('basic') === 'true';

        if (this.searchQuery.trim() === '') {
          this.projects.setRequestBody([]);
        } else {
          const jqlQuery = this.jqlTokenBuilderService.buildQuery(
            this.searchQuery.trim()
          );
          this.projects.setRequestBody(this.searchIsBasic ? [] : jqlQuery);
        }

        if (this.projects && this.projects.isRunning()) {
          this.projects.generate();
        } else {
          this.projects.start();
        }
      });

    this.changeDetectorRef.detectChanges();
  }

  public goToOverviewById(project: ProjectModel) {
    localStorage.setItem('lastVisitedFilter', this.location.path());

    if (this.tableColumnsService.canViewProjectActions(project)) {
      this.disabledBtn = true;
      this.router.navigate([this.dataType, project.id, 'details']);
    }
  }

  public generateOkrLink(project: ProjectModel) {
    let url = null;
    if (this.okrBaseUrl) {
      let set: ObjectiveSet = null;
      if (project?.objectiveId) {
        ['company', 'division'].forEach((type) => {
          const foundSet = this.objectiveSets[type].find((set) => {
            return (set.objectives || []).some((objective: Objective) => {
              return objective.id === project.objectiveId;
            });
          });
          if (foundSet) {
            set = foundSet;
          }
        });
      }
      url = set?.division
        ? set.division.isCompany
          ? `${this.okrBaseUrl}/company-okr?company_id=${set.division.id}&set_id=${set.id}&objective_id=${project?.objectiveId}`
          : `${this.okrBaseUrl}/division?division_id=${set.division.id}&set_id=${set.id}&objective_id=${project?.objectiveId}`
        : `${this.okrBaseUrl}`;
    }
    return url;
  }

  public fetchProjectsByQuery(event) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        search: event.query,
        basic: event.basicSearch,
        page: 1,
      },
      queryParamsHandling: 'merge',
    });
  }

  public openBriefFile(folderId) {
    this.disabledBtn = true;
    this.documentService
      .getProjectDocumentFileInfo(folderId, 'Project Brief')
      .subscribe(({ fileId }) => {
        this.disabledBtn = false;
        window.open(`https://docs.google.com/document/d/${fileId}/edit`);
      });
  }

  public openOkrModal(ev: FormEvent) {
    const modalConfig: ExosDialogConfig = {
      data: {
        formConfig: JSON.parse(JSON.stringify(projectObjectiveFormConfig)),
      },
    };
    modalConfig.data.project = ev;
    modalConfig.data.objectiveSets = this.objectiveSets;
    const dialog = this.dialogService.open(ViewOkrDialogComponent, modalConfig);
    dialog.afterSubmit.subscribe((result) => {
      dialog.afterSubmit.subscribe((result) => {
        this.notificationService.success('Project updated successfully');
        this.projects.generate();
      });
    });
  }

  public openRoadMap(id) {
    var currentAbsoluteUrl = window.location.href;
    var currentRelativeUrl = this.router.url;
    var index = currentAbsoluteUrl.indexOf(currentRelativeUrl);
    var baseUrl = currentAbsoluteUrl.substring(0, index);

    window.open(`${baseUrl}/projects/${id}/project-plan`);
  }

  public handleSearchType(value: boolean) {
    this.searchIsBasic = value;
  }

  private dataTableExtractor(response) {
    return response.data.map((project) => {
      if ('phase' in project) {
        project._phase = project.phase;
        project.phase = project.phase
          ? this.camelCaseToSentenceCase(project.phase)
          : '-';
      }

      if ('status' in project) {
        const { value, comment } = project.status.overall;
        project.statusItem = new ProjectStatusItem(
          value,
          comment,
          StatusNameType.OVERALL
        );
        project.status_comment = comment ? comment : '-';
      }

      if ('program' in project) {
        project._program = project.program ? project.program.name : '-';
      }

      if ('client' in project) {
        project._client = project.client;
        project.client =
          project.client && project.client.name ? project.client.name : '-';
      }

      if ('owner' in project) {
        project._owner = project.owner;
        project.owner = project.owner.name;
      }

      if ('division' in project) {
        project.division =
          project.division.length > 0
            ? project.division.map((d) => d.item).join()
            : '-';
      }

      if ('gantt' in project) {
        project._gantt = project.gantt;
        project.gantt = project.gantt.filter(
          (task) => task.type === 'milestone'
        );
        project.gantt =
          project.gantt.length > 0
            ? project.gantt.map((milestone) => milestone.text).join()
            : '-';
      } else {
        project.gantt = '-';
      }

      if ('tags' in project) {
        project._tags =
          project.tags.length > 0
            ? project.tags.map((tag) => tag.name).join()
            : '-';
      }

      if ('lastModified' in project) {
        project.lastModified = project.lastModified
          ? project.lastModified
          : '-';
      }

      if ('projectedLaunchDate' in project) {
        project._projectedLaunchDate = project.projectedLaunchDate
          ? project.projectedLaunchDate[project.projectedLaunchDate.length - 1]
          : '-';
      }

      if ('projectedStartDate' in project) {
        project._projectedStartDate = project.projectedStartDate
          ? project.projectedStartDate[project.projectedStartDate.length - 1]
              .date
          : '-';
      }

      if ('risks' in project) {
        project._risks =
          project.risks.length > 0
            ? project.risks.map((risk) => risk.description).join()
            : '-';
      }

      if ('dependencies' in project) {
        project.dependencies_name =
          project.dependencies.length > 0
            ? project.dependencies.map((dependency) => dependency.name).join()
            : '-';
        project.dependencies_description =
          project.dependencies.length > 0
            ? project.dependencies
                .map((dependency) => dependency.description)
                .join()
            : '-';
      }

      if ('objectiveId' in project) {
        const { objectiveName, keyResultName } =
          this.findObjectiveAndKeyResultName(
            this.objectives,
            project.objectiveId,
            project.keyResultId
          );

        project._objective = project.objectiveId ? objectiveName : '-';
        project.keyResult = project.keyResultId ? keyResultName : '-';
      } else {
        project._objective = '-';
        project.keyResult = '-';
      }

      return project;
    });
  }

  private findObjectiveAndKeyResultName(
    objectives: PartialObjective[],
    objectiveId: number,
    keyResultId: number
  ): { objectiveName: string; keyResultName: string } {
    const foundObjective = objectives?.find(
      (objective) => objective.id === objectiveId
    );
    const foundKeyResult = foundObjective?.keyResults?.find(
      (keyResult) => keyResult.id === keyResultId
    );

    return {
      objectiveName: foundObjective?.title,
      keyResultName: foundKeyResult?.name,
    };
  }

  private camelCaseToSentenceCase(value: string) {
    if (value === undefined) {
      return value;
    }
    const result = value.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  public addProject() {
    const dialog = this.presentAddProjectModal();

    dialog.afterSubmit.subscribe(
      async (formValue: { project: ProjectModel }) => {
        const project = formValue.project;
        const programProjects = [
          ...this.programFacade.program.projects,
          project,
        ];
        const reqBody = Object.assign({}, this.programFacade.program);
        reqBody.projects = programProjects;

        const result = await this.programFacade.dispatchUpdateProgram(reqBody);

        if (result.updated) {
          this.notificationService.success(
            `${project.name} was successfully added to program.`
          );
          dialog.close();
          this.projects.generate();
        }
      }
    );
  }

  private presentAddProjectModal() {
    const dialogConfig = JSON.parse(
      JSON.stringify(this.addProjectToProgramDialogConfig)
    );
    return this.dialogService.open(AddItemDialogComponent, dialogConfig);
  }

  public toggleColumns({ columnName, visible }) {
    this.existingFile = this.tableCols
      .filter((col) => col.id === 'file')
      .find((col) => col.display === true);
    this.existingProjectId = this.tableCols
      .filter((col) => col.id === 'id')
      .find((col) => col.display === true);

    this.tableCols = this.tableCols.map((col) => ({
      ...col,
      display: col.name === columnName ? visible : col.display,
    }));
    this.pushToUpdatePrefsQueue();
  }

  public pushToUpdatePrefsQueue(event?: any, version?: string) {
    const prefId = this.userPreferences?.id || null;
    let user_id = null;
    if (!prefId) {
      user_id = this.userSessionService.getSession().user_id;
    }

    this.userPreferencesService.next({
      id: prefId,
      user_id,
      version,
      section: this.dataType,
      userPreferences: {
        sort: this.projects.sortValue,
        pageSize: this.pageContext.size,
        columns: this.tableCols,
      },
    });
  }
}
