<div exosSheetSection>
  <div exosSheetBlock>
    <div class="mb-3" fxLayout="row" fxLayoutAlign="space-between center">
      <h3 exosHeadline type="sub" class="mb-0">FUNCTIONAL PLAN CHEAT SHEET</h3>

      <button class="link static-overlay__close" (click)="close()">
        <i class="exos-icon exos-icon-closecard-24"></i>
      </button>
    </div>

    <div *ngFor="let item of contentText.functionalPlanDefinition">
      <h4 exosHeadline type="sub">
        {{ item.title }}
      </h4>
      <p exosParagraph>
        {{ item.bodyText }}
      </p>
    </div>

    <footer exosSheetFooter>
      <button exosButton (click)="close()">Close</button>
    </footer>
  </div>
</div>
