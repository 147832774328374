import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  ExosFormComponent,
  FormContainerDirective,
  FormEvent,
  FormService,
} from '@dashboard/exos-form';
import { ExosDialogConfig, ExosDialogRef } from '@dashboard/exos-dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-change-request',
  templateUrl: './change-request-dialog.component.html',
  styleUrls: ['./change-request-dialog.component.scss'],
})
export class ChangeRequestDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild(FormContainerDirective)
  changeRequestFormContainer: FormContainerDirective;

  private modalSubs: Subscription[] = [];

  constructor(
    public config: ExosDialogConfig,
    public dialog: ExosDialogRef,
    private changeDetectorRef: ChangeDetectorRef,
    private exosFormService: FormService
  ) {}

  ngAfterViewInit() {
    const changeRequestForm = this.buildFormComponent();

    this.modalSubs.push(
      changeRequestForm.formEvents.subscribe((event: FormEvent) => {
        switch (event.type) {
          case 'cancel':
            this.dialog.close();
            break;
          case 'submit':
            changeRequestForm.form.markAsPending();
            this.dialog.submit(event.rawValue);
            break;
        }
      })
    );

    this.modalSubs.push(
      this.dialog.afterErrors.subscribe((errors: any) => {
        if (errors) {
          this.exosFormService.setFieldErrors(errors);
        } else {
          changeRequestForm.form.setValue(changeRequestForm.form.getRawValue());
        }
      })
    );

    this.changeDetectorRef.detectChanges();
  }

  private buildFormComponent(): ExosFormComponent {
    return this.exosFormService.buildForm(
      this.changeRequestFormContainer.viewContainerRef,
      this.config.data.formConfig
    ).instance;
  }

  close() {
    this.dialog.close();
  }

  ngOnDestroy() {
    this.modalSubs.forEach((sub) => sub.unsubscribe());
  }
}
