import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AutocompleteMapperService {
  constructor() {}

  public map<T>(data, keyToMap: string, additionalKeyToMap?: string): T {
    return data.map((item) => {
      const addKey = !!additionalKeyToMap
        ? ` - ${item[additionalKeyToMap]}`
        : '';
      item.text = `${item[keyToMap]}${addKey}`;
      return item;
    });
  }
}
