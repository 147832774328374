import { Component } from '@angular/core';
import { ExosDialogConfig, ExosDialogRef } from '@dashboard/exos-dialog';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  public isLoading = false;

  constructor(public config: ExosDialogConfig, public dialog: ExosDialogRef) {}

  onClose(event: Event) {
    event.stopPropagation();
    this.dialog.close();
  }

  onSubmit(event: Event) {
    event.stopPropagation();
    this.isLoading = true;
    this.dialog.submit();
  }
}
