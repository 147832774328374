import {
  addResponsibleDialogConfig,
  editResponsibleDialogConfig,
} from '../../../../shared/config/dialogs/responsible-dialog.config';
import { EditableTableRouteConfig } from '../project-brief-route/project-brief-route.config';

export const functionalPlanRouteConfig: EditableTableRouteConfig = {
  responsible: {
    modal: {
      add: addResponsibleDialogConfig,
      edit: editResponsibleDialogConfig,
    },
    table: {
      cols: null,
      noResultsFoundMsg: null,
    },
  },
};
