import { FilterViewType } from '../../models/filter-view-type.model';

export const SECTIONS = {
  [FilterViewType.projects]: {
    version: '1.2',
  },
  [FilterViewType.programs]: {
    version: '1.1',
  },
};
