import {
  Component,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
  ViewEncapsulation,
} from '@angular/core';
import {
  ChangeRequest,
  ChangeRequestState,
} from '../../../../shared/models/change-request';
import { ActivatedRoute, Router } from '@angular/router';
import { pluck, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProjectRightsService } from '../../../../shared/service/user-rights/project-rights/project-rights.service';
import { ProjectModel } from '../../../../shared/models/project.model';
import { ChangeRequestService } from '../../../../shared/service/change-request/change-request.service';
import { InlineEditDirective } from '../../../../exos/inline-edit/inline-edit.directive';
import { InlineEditFormConfig } from '../../../../exos/inline-edit/inline-edit-form/inline-edit-form.component';

export interface ChangeRequestEditSection {
  key: string;
  name: string;
  formConfig: InlineEditFormConfig;
  info: string;
}

@Component({
  selector: 'app-change-request-view',
  templateUrl: './change-request-view-route.component.html',
  styleUrls: ['./change-request-view-route.component.scss'],
})
export class ChangeRequestViewRouteComponent implements OnInit, OnDestroy {
  @ViewChildren(InlineEditDirective)
  inlineForms: QueryList<InlineEditDirective>;

  public isUpdating = false;
  public changeRequest: ChangeRequest;
  public changeRequestSections: ChangeRequestEditSection[] = [
    {
      key: 'changeDescription',
      name: 'Change description',
      info: 'Brief description of what the change is about',
      formConfig: { controlName: 'changeDescription', controlValue: '' },
    },
    {
      key: 'changeReason',
      name: 'Change Reason',
      info: 'Brief explanation of why the change is being introduced',
      formConfig: { controlName: 'changeReason', controlValue: '' },
    },
    {
      key: 'changeOutcome',
      name: 'Change Outcome',
      info: 'e.g. BENEFITS: What happens if we make the change? <br> e.g. RISKS: What happens if we don’t make the change?',
      formConfig: { controlName: 'changeOutcome', controlValue: '' },
    },
    {
      key: 'impactOfChange',
      name: 'Impact of change',
      info: 'Impact on e.g. budget / resources / scope / timeline',
      formConfig: { controlName: 'impactOfChange', controlValue: '' },
    },
    {
      key: 'proposedAction',
      name: 'Proposed action',
      info: 'Outline of required steps to address the change',
      formConfig: { controlName: 'proposedAction', controlValue: '' },
    },
    {
      key: 'fileLink',
      name: 'File link',
      info: 'Further documentation that might be helpful',
      formConfig: { controlName: 'fileLink', controlValue: '' },
    },
  ];

  private projectId: number;
  private changeRequestId: number;
  private destroyed: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private userRightsService: ProjectRightsService,
    private changeRequestService: ChangeRequestService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.parent.data
      .pipe(pluck('project'), takeUntil(this.destroyed))
      .subscribe((project) => {
        this.changeRequest = this._parse(project);
      });
  }

  private _parse(project: ProjectModel): ChangeRequest {
    const userStatus = this.userRightsService.getUserSessionStatus(project);
    const changeRequestId = parseInt(this.route.snapshot.params.id, 10);
    const changeRequest = project.changeRequests.filter(
      ({ id }) => id === changeRequestId
    )[0];

    this.projectId = project.id;
    this.changeRequestId = changeRequestId;

    this.changeRequestSections.forEach((section) => {
      if (changeRequest[section.key]) {
        section.formConfig.controlValue = changeRequest[section.key];
      }
    });

    const cr: ChangeRequest = new ChangeRequest(
      changeRequest,
      userStatus,
      project.phase
    );
    cr.actions = cr.actions.filter(
      (action) => action.name !== 'VIEW' && action.name !== 'EDIT'
    );

    return cr;
  }

  public handleEditChangeRequestState(state: ChangeRequestState) {
    if (this.isUpdating) {
      return false;
    }

    const body = { ...this.changeRequest, ...{ state } };

    this.changeRequestService
      .updateChangeRequest(body, this.projectId, this.changeRequestId)
      .subscribe((response) => {
        this.router.navigate(['../../brief'], {
          relativeTo: this.route,
        });
      });
  }

  public handleEditChangeRequest(value: any, cmpIndex: number) {
    const body = { ...this.changeRequest, ...value };

    this.changeRequestService
      .updateChangeRequest(body, this.projectId, this.changeRequestId)
      .subscribe(
        (response) => {
          this.changeRequest = this._parse(response);
          this.inlineForms.find((item, index) => index === cmpIndex).close();
        },
        (error) => {
          const fieldError = error.error.errors.fields;

          this.inlineForms
            .find((item, index) => index === cmpIndex)
            .setFormError(fieldError);
        }
      );
  }

  ngOnDestroy(): void {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
