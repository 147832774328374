import { Directive, Input } from '@angular/core';
import { StepperItemStatus } from './stepper-item';

@Directive({
  selector: '[stepperItem]',
  host: {
    class: 'stepper-item',
    '[class.stepper-item-status--unfinished]': "status === 'UNFINISHED'",
    '[class.stepper-item-status--passed]': "status === 'PASSED'",
    '[class.stepper-item-status--pending]': "status === 'PENDING'",
  },
})
export class StepperItemDirective {
  @Input() status: StepperItemStatus;

  constructor() {}
}
