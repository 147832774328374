import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DashNotificationService, UserService } from '@dashboard/core';
import { ExosDialogConfig, ExosDialogRef } from '@dashboard/exos-dialog';
import { FormService } from '@dashboard/exos-form';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { ExosCardFormConfig } from 'src/app/shared/models/card-form-config';
import {
  KeyResult,
  Objective,
  PartialKeyResult,
  PartialObjective,
  PartialObjectiveSet,
  PartialObjectiveSetMap,
} from 'src/app/shared/models/objective.model';
import { ProjectModel } from 'src/app/shared/models/project.model';
import {
  Division,
  DivisionService,
} from 'src/app/shared/service/division/division.service';
import { ProjectService } from 'src/app/shared/service/project/project.service';
import { projectObjectiveValidator } from 'src/app/shared/validators/project-objective.validator';
import {
  generateDivisionSetsDisableMap,
  generateDivisionSetsMap,
} from 'src/app/utils/division-sets-map';

@Component({
  selector: 'app-view-okr-dialog',
  templateUrl: './view-okr-dialog.component.html',
  styleUrls: ['./view-okr-dialog.component.scss'],
})
export class ViewOkrDialogComponent implements AfterViewInit, OnDestroy {
  public formConfig: ExosCardFormConfig;

  public form: FormGroup = new FormGroup(
    {
      objectiveType: new FormControl(null),
      objectiveDivision: new FormControl(null),
      objectiveYear: new FormControl(null),
      objectiveQuarter: new FormControl(null),
      objectiveId: new FormControl(null),
      keyResultId: new FormControl(null),
    },
    { validators: [projectObjectiveValidator] }
  );

  private allSets: PartialObjectiveSet[] = [];
  private sets: PartialObjectiveSet[] = [];
  private companySets: PartialObjectiveSet[] = [];
  private divisionSets: PartialObjectiveSet[] = [];
  private divisionSetsMap: PartialObjectiveSetMap = {};
  private divisionSetsDisableMap: {
    [year: number]: {
      [quarter: string]: boolean;
    };
  } = {};
  private set: PartialObjectiveSet;
  private objectiveYear: string;

  public objectiveTypes = [
    { type: 'company', text: 'Company OKR' },
    { type: 'division', text: 'Division OKR' },
  ];

  public objectiveType: 'company' | 'division' = 'division';

  public changeDivision = false;
  public loading = true;
  public objectiveDivisions: Division[] = [];
  public years: string[] = [];
  public quarters: { text: string; disabled?: boolean }[] = [];
  public objectives: PartialObjective[] = [];
  public keyResults: PartialKeyResult[] = [];
  public projectId: number;
  public pendingRequest: boolean;
  public project: ProjectModel;

  private subs: Subscription[] = [];
  private divisions$ = this.divisionService.findByName('');

  constructor(
    public config: ExosDialogConfig,
    public dialog: ExosDialogRef,
    private exosFormService: FormService,
    private changeDetectorRef: ChangeDetectorRef,
    private projectService: ProjectService,
    private notificationService: DashNotificationService,
    private divisionService: DivisionService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.formConfig = this.config.data?.formConfig;
  }

  ngAfterViewInit(): void {
    this.divisions$.subscribe((divisions) => {
      this.loading = false;

      const project = this.config.data?.project;
      const companySets: PartialObjectiveSet[] =
        this.config.data?.objectiveSets.company;
      const divisionSets: PartialObjectiveSet[] =
        this.config.data?.objectiveSets.division;

      // Map divisions ids which have objectives to full division objects and determine user division
      const objectiveDivisions = [];

      this.companySets = companySets;
      this.divisionSets = divisionSets;

      this.divisionSetsMap = generateDivisionSetsMap(divisionSets);
      this.divisionSetsDisableMap = generateDivisionSetsDisableMap(
        this.divisionSetsMap
      );

      this.allSets = [...companySets, ...divisionSets];
      this.set = this.findObjectivesSet(project);

      this.objectiveType = this.set?.division?.id ? 'division' : 'company';
      this.sets =
        this.objectiveType === 'division' ? divisionSets : companySets;

      this.years =
        this.objectiveType === 'division'
          ? Object.keys(this.divisionSetsMap)?.sort()
          : companySets.map((set) => set.year)?.sort();

      project.objectiveYear = this.set?.year;
      this.objectiveYear = this.set?.year;

      this.quarters =
        this.objectiveType === 'division'
          ? this.getQuarters(this.set?.year)
          : [];
      this.objectiveDivisions =
        this.objectiveType === 'division'
          ? this.divisionSetsMap[this.set?.year]?.[this.set?.quarter].map(
              (set: PartialObjectiveSet) => set.division
            )
          : [];
      this.objectives = this.set?.objectives;

      this.project = project;

      this.populateForm();

      this.handleOkrFieldChanges();
      // this.handleFormEvents();
      this.handleDialogErrors();

      this.changeDetectorRef.detectChanges();
    });
  }

  private populateForm() {
    setTimeout(() => {
      this.form.patchValue(
        {
          objectiveType: this.objectiveTypes.find(
            (objective) => objective.type === this.objectiveType
          ),
          objectiveYear: this.project.objectiveYear,
          objectiveQuarter:
            this.objectiveType === 'division'
              ? this.findQuarter(this.set?.quarter)
              : null,
          objectiveDivision: this.set?.division,

          objectiveId: this.project.objectiveId,
          keyResultId: this.project.keyResultId,
        },
        { emitEvent: false }
      );
    }, 0);

    if (!this.project?.objectiveId) {
      this.form.controls.keyResultId.disable({ emitEvent: false });
    }
  }

  private handleOkrFieldChanges(): void {
    this.form.controls.objectiveType.valueChanges.subscribe((type) => {
      this.objectiveType = type?.type;
      this.sets =
        this.objectiveType === 'division'
          ? this.divisionSets
          : this.companySets;
      this.set = this.findObjectivesSet(null);
      this.years =
        this.objectiveType === 'division'
          ? Object.keys(this.divisionSetsMap)?.sort()
          : this.sets.map((set) => set.year)?.sort();
      this.objectiveYear = this.set?.year;
      this.quarters =
        this.objectiveType === 'division'
          ? this.getQuarters(this.objectiveYear)
          : [];

      const quarter =
        this.objectiveType === 'division'
          ? this.findQuarter(this.set?.quarter)
          : null;
      this.objectiveDivisions =
        this.objectiveType === 'division'
          ? this.divisionSetsMap[this.set?.year]?.[this.set?.quarter].map(
              (set: PartialObjectiveSet) => set.division
            )
          : [];

      this.objectives = this.set?.objectives;
      this.keyResults = [];

      this.form.patchValue(
        {
          objectiveYear: this.objectiveYear,
          objectiveQuarter: quarter,
          objectiveDivision: this.set?.division,
          objectiveId: null,
          keyResultId: null,
        },
        { emitEvent: false }
      );
      this.form.controls.keyResultId.disable({ emitEvent: false });
    });

    this.form.controls.objectiveYear.valueChanges.subscribe((year) => {
      this.objectiveYear = year;
      this.quarters =
        this.objectiveType === 'division' ? this.getQuarters(year) : [];
      this.set = this.sets.find(
        (set) =>
          set.year === year &&
          (this.objectiveType === 'division' ? !set.division.disabled : true)
      );
      const quarter =
        this.objectiveType === 'division'
          ? this.findQuarter(this.set?.quarter)
          : null;
      this.objectiveDivisions =
        this.objectiveType === 'division'
          ? this.divisionSetsMap[this.set?.year]?.[this.set?.quarter]?.map(
              (set: PartialObjectiveSet) => set.division
            )
          : [];
      this.objectives = this.set?.objectives;
      this.keyResults = [];

      this.form.patchValue(
        {
          objectiveQuarter: quarter,
          objectiveDivision: this.set?.division,
          objectiveId: null,
          keyResultId: null,
        },
        { emitEvent: false }
      );
      this.form.controls.keyResultId.disable({ emitEvent: false });
    });

    this.form.controls.objectiveQuarter.valueChanges.subscribe((quarter) => {
      this.set =
        this.sets.find(
          (set) =>
            set.year === this.objectiveYear &&
            set.quarter === quarter.text &&
            !set.division.disabled
        ) || this.sets[0];
      this.objectiveDivisions = this.divisionSetsMap[this.set?.year]?.[
        quarter.text
      ]?.map((set: PartialObjectiveSet) => set.division);
      this.objectives = this.set?.objectives;
      this.keyResults = [];

      this.form.patchValue(
        {
          objectiveDivision: this.set?.division,
          objectiveId: null,
          keyResultId: null,
        },
        { emitEvent: false }
      );
      this.form.controls.keyResultId.disable({ emitEvent: false });
    });

    this.form.controls.objectiveDivision.valueChanges.subscribe((division) => {
      this.set = this.divisionSets.find(
        (div) => div.division?.id === division.id
      );
      this.objectives = this.set?.objectives;
      this.keyResults = [];

      this.form.patchValue(
        {
          objectiveId: null,
          keyResultId: null,
        },
        { emitEvent: false }
      );
      this.form.controls.keyResultId.disable({ emitEvent: false });
    });

    this.form.controls.objectiveId.valueChanges
      .pipe(
        map((value) => {
          return value
            ? this.objectives.find((objective) => objective.id === value.id)
                .keyResults
            : [];
        })
      )
      .subscribe((keyResults) => {
        this.keyResults = keyResults;
        this.form.controls.keyResultId.patchValue(null);
        if (!keyResults.length) {
          this.form.controls.keyResultId.disable({ emitEvent: false });
        } else {
          this.form.controls.keyResultId.enable({ emitEvent: false });
        }
      });
  }

  private getQuarters(year: string) {
    return Object.keys(this.divisionSetsMap[year])
      ?.sort()
      .map((quarter) => {
        return {
          text: quarter,
          disabled: !!this.divisionSetsDisableMap[year]?.[quarter],
        };
      });
  }

  private findQuarter(quarter) {
    return quarter ? this.quarters.find((q) => q.text === quarter) : null;
  }

  private findObjectivesSet(project: ProjectModel): PartialObjectiveSet {
    let set: PartialObjectiveSet = null;
    if (project?.objectiveId) {
      set = this.allSets.find((set) => {
        return set.objectives.some((objective) => {
          return objective.id === project.objectiveId;
        });
      });
    }
    if (!set) {
      if (this.objectiveType === 'company') {
        set = this.companySets.find((set) => set.isCurrentSet);
      } else if (this.objectiveType === 'division') {
        const enabledDivisions = this.divisionSets.filter(
          (set) => !set.division?.disabled
        );
        set =
          enabledDivisions.find((set) => set.isCurrentSet) ||
          enabledDivisions[0];
      }
    }

    return (
      set ||
      (this.objectiveType === 'division'
        ? this.divisionSets?.[0]
        : this.companySets?.[0])
    );
  }

  private handleDialogErrors() {
    this.subs.push(
      this.dialog.afterErrors.subscribe((errors: any) => {
        if (errors) {
          this.exosFormService.setFieldErrors(errors);
        } else {
          this.form.setValue(this.form.getRawValue());
        }
      })
    );
  }

  private processFormValue(value: any) {
    value.objectiveId = value.objectiveId?.id
      ? value.objectiveId.id
      : value.objectiveId || null;
    value.keyResultId = value.objectiveId
      ? value.keyResultId?.id
        ? value.keyResultId.id
        : value.keyResultId
      : null;
  }

  public submit(event) {
    this.pendingRequest = true;

    if (this.form.valid) {
      this.form.disable({ emitEvent: false });

      const payload = Object.assign({}, this.form.value);

      this.processFormValue(payload);

      this.projectService
        .save({
          payload,
          //@ts-ignore
          projectId: this.project.id,
        })
        .subscribe(
          (result) => {
            this.pendingRequest = false;
            this.notifyLocalStorage(result.id);
            this.dialog.submit(result);
            this.dialog.close();
          },
          (error) => {
            this.pendingRequest = false;
            this.notificationService.warning('Project could not be updated');
            this.form.enable({ emitEvent: false });
          }
        );
    } else {
      const errors = this.form.errors || {};
      const mapErrors = Object.keys(errors).map((k) => [k, errors[k]]);

      mapErrors.forEach(([field, err]) => {
        if (this.form.controls[field] && err) {
          this.form.controls[field].setErrors({
            error: err,
          });
        }
      });
      this.pendingRequest = false;
    }
  }

  public displayObjectiveSetTypeFn(option: { type: string; text: string }) {
    return option.text;
  }

  public displayObjectiveFn(option: Objective) {
    return option.title;
  }

  public compareObjectiveFn(option: Objective, value: any) {
    if (value) {
      if (value.id) {
        return option.id.toString() === value.id.toString();
      } else {
        return option.id.toString() === value.toString();
      }
    }
    return null;
  }

  public displayKeyResultFn(option: KeyResult) {
    return option.name;
  }

  public compareFnKeyResultFn(option: KeyResult, value: any) {
    if (value) {
      if (value.id) {
        return option.id.toString() === value.id.toString();
      } else {
        return option.id.toString() === value.toString();
      }
    }
    return null;
  }

  public displayObjectiveDivisionFn(option: Division) {
    return option.text;
  }

  public displayObjectiveQuarterFn(option: Division) {
    return option.text;
  }

  public closeDialog() {
    this.dialog.close();
  }

  private notifyLocalStorage(projectId: number) {
    localStorage.setItem(
      'projectChanges',
      `${
        parseInt(
          localStorage.getItem('projectChanges')
            ? localStorage.getItem('projectChanges')
            : '0',
          10
        ) + 1
      }`
    );
  }

  ngOnDestroy() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
