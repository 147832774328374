import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { DocumentLink } from '../../../../shared/models/project-link.model';
import { ProjectService } from 'src/app/shared/service/project/project.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectLinksResolver implements Resolve<DocumentLink[]> {
  constructor(private projectService: ProjectService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<DocumentLink[]> {
    const projectId = route.parent.data.project.id;

    return this.projectService.fetchProjectResource(projectId, 'links').pipe(
      mergeMap((projectLinks) => of(projectLinks)),
      catchError(() => {
        return of([]);
      })
    );
  }
}
