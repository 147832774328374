import { Directive, Input } from '@angular/core';
import { ExosSemanticType } from '../../exos.model';

export type ExosCardAlignType = 'center';

@Directive({
  selector: '[exosCard]',
  host: {
    class: 'card',
    '[class.card--page-context]': 'inheritBackground',
  },
})
export class ExosCardDirective {
  @Input() inheritBackground: boolean;

  constructor() {}
}

@Directive({
  selector: '[exosCardContent]',
  host: {
    class: 'card__content',
  },
})
export class ExosCardContentDirective {
  constructor() {}
}

@Directive({
  selector: '[exosCardSection]',
  host: {
    class: 'card__section',
    '[class.card__section--stripes]': 'hasStripe',
    '[class.card__section--align-center]': "align === 'center'",
    '[class.card__section--activating]': "semantic === 'activating'",
    '[class.card__section--success]': "semantic === 'success'",
    '[class.card__section--warning]': "semantic === 'warning'",
    '[class.card__section--critical]': "semantic === 'critical'",
    '[class.card__section--neutral]': "semantic === 'neutral'",
    '[class.card__section--advertising]': "semantic === 'advertising'",
  },
})
export class ExosCardSectionDirective {
  @Input() align: ExosCardAlignType;
  @Input() semantic: ExosSemanticType;
  @Input() hasStripe: boolean;

  constructor() {}
}

@Directive({
  selector: '[exosCardPreHeadline]',
  host: {
    class: 'card__preheadline',
  },
})
export class ExosCardPreHeadlineDirective {
  constructor() {}
}

@Directive({
  selector: '[exosCardHeadline]',
  host: {
    class: 'card__headline',
    '[class.card__headline--ellipsized]': 'isEllipsized',
    '[class.card__headline--activating]': "semantic === 'activating'",
    '[class.card__headline--success]': "semantic === 'success'",
    '[class.card__headline--warning]': "semantic === 'warning'",
    '[class.card__headline--critical]': "semantic === 'critical'",
    '[class.card__headline--neutral]': "semantic === 'neutral'",
  },
})
export class ExosCardHeadlineDirective {
  @Input() isEllipsized: boolean;
  @Input() semantic: ExosSemanticType;

  constructor() {}
}

@Directive({
  selector: '[exosCardSubHeadline]',
  host: {
    class: 'card__subheadline',
  },
})
export class ExosCardSubHeadlineDirective {
  constructor() {}
}

@Directive({
  selector: '[exosCardHeader]',
  host: {
    class: 'card__header',
  },
})
export class ExosCardHeaderDirective {
  constructor() {}
}

@Directive({
  selector: '[exosCardFooter]',
  host: {
    class: 'card__footer',
    '[class.card__footer--align-center]': "align === 'center'",
    '[class.card__footer--distinct]': 'isDistinct',
  },
})
export class ExosCardFooterDirective {
  @Input() align: ExosCardAlignType;
  @Input() isDistinct: boolean;

  constructor() {}
}

@Directive({
  selector: '[exosCardVisual]',
  host: {
    class: 'card__visual',
  },
})
export class ExosCardVisualDirective {
  constructor() {}
}

@Directive({
  selector: '[exosCardSerparator]',
  host: {
    class: 'card__separator',
  },
})
export class ExosCardSeparatorDirective {
  constructor() {}
}

@Directive({
  selector: '[exosCardRibbon]',
  host: {
    class: 'card__ribbon',
    '[class.card__ribbon--activating]': "semantic === 'activating'",
    '[class.card__ribbon--success]': "semantic === 'success'",
    '[class.card__ribbon--warning]': "semantic === 'warning'",
    '[class.card__ribbon--critical]': "semantic === 'critical'",
    '[class.card__ribbon--neutral]': "semantic === 'neutral'",
  },
})
export class ExosCardRibbonDirective {
  @Input() semantic: ExosSemanticType;

  constructor() {}
}
