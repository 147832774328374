<ng-container *ngIf="ganttChartData">
  <dhtmlx-gantt [datasource]="ganttChartData">
    <div>
      <button exosButton semantic="primary" (click)="exportExcel()">
        Export Excel
      </button>
      <button exosButton semantic="primary" (click)="exportPDF()">
        Export PDF
      </button>
      <button exosButton semantic="primary" (click)="openJiraModal()">
        Jira import
      </button>

      <ng-container>
        <button
          mat-icon-button
          id="cols_menu_chart"
          aria-label="Example icon-button with a menu"
          (click)="toggle_grid()"
        >
          <ng-container *ngIf="!isChartSelected">
            <label class="change-icon">Chart</label>
          </ng-container>

          <ng-container *ngIf="isChartSelected">
            <label class="change-icon">List</label>
          </ng-container>
        </button>
      </ng-container>

      <ng-container>
        <button
          mat-icon-button
          id="cols_menu"
          [matMenuTriggerFor]="principalMenu"
          class="menu"
          aria-label="Example icon-button with a menu"
        >
          <mat-icon class="view-column-menu">view_column</mat-icon>
        </button>

        <mat-menu #principalMenu="matMenu">
          <ng-container *ngFor="let column of tableCols | tableColumnFixed">
            <mat-checkbox
              class="mat-menu-item"
              [checked]="column.display"
              (click)="$event.stopPropagation()"
              (change)="toggleColumns(column.name)"
              style="color: black"
            >
              {{ column.name }}
            </mat-checkbox>
          </ng-container>
        </mat-menu>
      </ng-container>
    </div>
  </dhtmlx-gantt>
</ng-container>
