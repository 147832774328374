import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashCoreModule } from '@dashboard/core';
import { DashTableModule } from '@dashboard/table';
import { ExosFormModule } from '@dashboard/exos-form';
import { ExosDialogModule } from '@dashboard/exos-dialog';

import { ProjectRoutingModule } from './project-routing.module';
import { ProjectsRouteComponent } from './containers/projects-route/projects-route.component';
import { ProjectRouteComponent } from './containers/project-route/project-route.component';
import { ProjectOverviewRouteComponent } from './containers/project-route/project-overview-route/project-overview-route.component';
import { ProjectBriefRouteComponent } from './containers/project-route/project-brief-route/project-brief-route.component';
import { ProjectPlanRouteComponent } from './containers/project-route/project-plan-route/project-plan-route.component';
import { ProjectReviewRouteComponent } from './containers/project-route/project-review-route/project-review-route.component';
import { ProjectLinksRouteComponent } from './containers/project-route/project-links-route/project-links-route.component';
import { ProjectCreateRouteComponent } from './containers/project-create-route/project-create-route.component';
import { ProjectFunctionalPlanRouteComponent } from './containers/project-route/project-functional-plan-route/project-functional-plan-route.component';
import { ProjectAuditRouteComponent } from './containers/project-route/project-audit-route/project-audit-route.component';
import { ChangeRequestViewRouteComponent } from './containers/project-change-request/change-request-view/change-request-view-route.component';
import { ExosTooltipModule } from '@dashboard/exos-tooltip';
import { PortalModule } from '@angular/cdk/portal';
import { SharedModule } from '../shared/shared.module';
import { JqlInputSearchModule } from '../exos/jql-input-search/jql-input-search.module';
import { JQL_PROJECT_CONFIG } from './config/jql.config';
import { ExosModule } from '../exos/exos.module';
import { ProjectActionsComponent } from './components/project-actions/project-actions.component';
import { ProjectViewComponent } from './containers/project-route/project-view/project-view.component';
import { QuillModule } from 'ngx-quill';
import { ProjectPipe } from './pipes/project/project.pipe';

@NgModule({
  declarations: [
    ProjectsRouteComponent,
    ProjectRouteComponent,
    ProjectOverviewRouteComponent,
    ProjectBriefRouteComponent,
    ProjectPlanRouteComponent,
    ProjectReviewRouteComponent,
    ProjectLinksRouteComponent,
    ProjectAuditRouteComponent,
    ProjectFunctionalPlanRouteComponent,
    ProjectCreateRouteComponent,
    ChangeRequestViewRouteComponent,
    ProjectActionsComponent,
    ProjectViewComponent,
    ProjectPipe,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PortalModule,
    DashCoreModule,
    DashTableModule,
    ExosFormModule,
    ExosDialogModule,
    ExosTooltipModule,
    ExosModule,
    JqlInputSearchModule.forChild(JQL_PROJECT_CONFIG),
    ProjectRoutingModule,
    QuillModule,
  ],
})
export class ProjectModule {}
