export const auditTableCols = [
  {
    id: 'actionName',
    name: 'Action',
    sortable: true,
    value: 'actionName',
    display: true,
  },
  { id: 'date', name: 'Date', sortable: true, value: 'date', display: true },
  {
    id: 'username',
    name: 'User',
    sortable: true,
    value: 'username',
    display: true,
  },
  {
    id: 'payload',
    name: 'Details',
    sortable: false,
    value: 'payload',
    display: true,
  },
];
