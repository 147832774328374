import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DashCoreModule } from '@dashboard/core';
import { ExosDialogModule } from '@dashboard/exos-dialog';
import { ExosFormModule } from '@dashboard/exos-form';
import { ExosTooltipModule } from '@dashboard/exos-tooltip';
import { DashTableModule } from '@dashboard/table';
import { QuillModule } from 'ngx-quill';
import { ExosModule } from '../exos/exos.module';
import { JqlInputSearchModule } from '../exos/jql-input-search/jql-input-search.module';
import { JQL_CONFIG_MERGED } from '../shared/config/jql/jql.config';
import { SharedModule } from '../shared/shared.module';
import { ListingRouteComponent } from './containers/listing-route/listing-route.component';
import { ListingRoutingModule } from './listing-routing.module';

@NgModule({
  declarations: [ListingRouteComponent],
  imports: [
    CommonModule,
    SharedModule,
    PortalModule,
    DashCoreModule,
    DashTableModule,
    ExosFormModule,
    ExosDialogModule,
    ExosTooltipModule,
    ExosModule,
    JqlInputSearchModule.forChild(JQL_CONFIG_MERGED.projects),
    ListingRoutingModule,
    QuillModule,
  ],
})
export class ListingModule {}
