export const LOCALE_STATUS = {
  label_status_name_scope: 'Scope',
  label_status_name_budget: 'Budget',
  label_status_name_schedule: 'Schedule',
  label_status_name_resources: 'Resources',
  label_status_name_overall: 'Overall',
  label_status_value_risk_or_roadblock: 'Risk or roadblock',
  label_status_value_potential_risks: 'Potential risks',
  label_status_value_on_track: 'On track',
  label_status_value_not_started: 'Not started',
  label_status_value_on_hold: 'On hold',
  label_status_value_canceled: 'Canceled',
  label_status_value_closed: 'Closed',
};
