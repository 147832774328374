import { Injectable } from '@angular/core';
import { GanttLink, GanttTask } from '../../models/gantt.model';
import { gantt } from '../dhtmlx-gantt-service/gantt/dhtmlxgantt';
import { ProgramGanttService } from '../program-gantt-service/program-gantt.service';
import { DashNotificationService } from '@dashboard/core';
import { DashDialogService, ExosDialogRef } from '@dashboard/exos-dialog';
import {
  createNewGanttTaskProgramDialogConfig,
  editNewGanttTaskProgramDialogConfig,
} from '../../components/dialogs/gantt-task-dialog/gantt-task.dialog-config';
import { GanttTaskDialogComponent } from '../../components/dialogs/gantt-task-dialog/gantt-task-dialog.component';
import { ConfirmationComponent } from '../../components/dialogs/confirmation/confirmation.component';

@Injectable({
  providedIn: 'root',
})
export class ProgramGanttProcessorService {
  private dialogCreateOrEdit: ExosDialogRef;

  private dialogConfirmation: ExosDialogRef;

  private ganttEvents = [];

  constructor(
    private programGanttService: ProgramGanttService,
    private notifyService: DashNotificationService,
    private dialogService: DashDialogService
  ) {}

  private handleCreateTask(task: GanttTask, programId: number) {
    this.programGanttService
      .createTask(task, programId)
      .then((updatedTask: GanttTask) => {
        // @ts-ignore
        const currentTask: GanttTask = gantt.getTask(updatedTask.id);

        currentTask.progressColor = updatedTask.progress === 1 ? '#38CC59' : '';
        currentTask.$new = false;
        currentTask.orderIndex = updatedTask.orderIndex;

        this.dialogCreateOrEdit.close();
        this.notifyService.success(`Task successfully created.`);
      })
      .catch(() => {
        this.notifyService.warning(`Task could not be created.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleUpdateTask(task: GanttTask, programId: number) {
    this.programGanttService
      .updateTask(task, programId)
      .then((updatedTask: GanttTask) => {
        // @ts-ignore
        gantt.getTask(updatedTask.id).progressColor =
          updatedTask.progress === 1 ? '#38CC59' : '';

        if (this.dialogCreateOrEdit) {
          this.dialogCreateOrEdit.close();
        }
        this.notifyService.success(`Task successfully updated.`);
      })
      .catch(() => {
        this.notifyService.warning(`Task could not be updated.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleDeleteTask(taskId: string, programId: number) {
    this.programGanttService
      .deleteTask(taskId, programId)
      .then(() => {
        this.dialogConfirmation.close();
        this.dialogCreateOrEdit.close();
        this.notifyService.success(`Task was deleted.`);
      })
      .catch(() => {
        this.dialogConfirmation.close();
        this.notifyService.warning(`Task could not be deleted.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleCreateLink(link: GanttLink, programId: number) {
    this.programGanttService
      .createLink(link, programId)
      .then(() => {
        this.notifyService.success(`Link created.`);
      })
      .catch(() => {
        this.notifyService.warning(`Link could not be created.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleUpdateLink(link: GanttLink, programId: number) {
    this.programGanttService
      .updateLink(link, programId)
      .then(() => {
        this.notifyService.success(`Link successfully updated.`);
      })
      .catch(() => {
        this.notifyService.warning(`Link could not be updated.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleDeleteLink(linkId: string, programId: number) {
    this.programGanttService
      .deleteLink(linkId, programId)
      .then(() => {
        this.notifyService.success(`Link was deleted.`);
      })
      .catch(() => {
        this.notifyService.warning(`Link could not be deleted.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  private handleUpdateTaskOrder(
    taskId: number,
    projectId: number,
    orderIndex: number
  ) {
    this.programGanttService
      .updateTaskOrder(taskId, projectId, orderIndex)
      .then(() => {
        this.notifyService.success(`Task order was updated.`);
      })
      .catch(() => {
        this.notifyService.warning(`Task cannot be moved.`);
        gantt.undo();
      })
      .finally(() => {
        gantt.render();
      });
  }

  // data processor
  public initDataProcessor(programId: number) {
    const onBeforeTaskAdd = gantt.attachEvent(
      'onBeforeTaskAdd',
      (id, task: GanttTask) => {
        if (task.start_date.toString() === task.end_date.toString()) {
          task.end_date = gantt.date.add(new Date(task.start_date), 1, 'day');
          task.duration = 1;
        }
        this.handleCreateTask(task, programId);
      },
      {}
    );

    const onBeforeTaskUpdate = gantt.attachEvent(
      'onBeforeTaskUpdate',
      (id, task: GanttTask) => {
        if (task.start_date.toString() === task.end_date.toString()) {
          task.end_date = gantt.date.add(new Date(task.start_date), 1, 'day');
          task.duration = 1;
        }
        this.handleUpdateTask(task, programId);
      },
      {}
    );

    const onBeforeTaskDelete = gantt.attachEvent(
      'onBeforeTaskDelete',
      (taskId) => {
        const taskIsNew = !!gantt.getTask(taskId).$new;

        if (!taskIsNew) {
          this.handleDeleteTask(taskId, programId);
        }
      },
      {}
    );

    const onAfterLinkAdd = gantt.attachEvent(
      'onAfterLinkAdd',
      (id, link: GanttLink) => {
        this.handleCreateLink(link, programId);
      },
      {}
    );

    const onAfterTaLinkUpdate = gantt.attachEvent(
      'onAfterLinkUpdate',
      (id, link: GanttLink) => {
        this.handleUpdateLink(link, programId);
      },
      {}
    );

    const onAfterTaLinkDelete = gantt.attachEvent(
      'onAfterLinkDelete',
      (linkId) => {
        this.handleDeleteLink(linkId, programId);
      },
      {}
    );

    const onBeforeLightbox = gantt.attachEvent(
      'onBeforeLightbox',
      (id: string | number) => {
        const task = gantt.getTask(id);
        if (task.$new) {
          const today = new Date();
          const startDay = today.setHours(0, 0, 0, 0);

          task.start_date = new Date(startDay);
          task.end_date = gantt.date.add(new Date(startDay), 1, 'day');
        }
        if (task.start_date.toString() === task.end_date.toString()) {
          task.end_date = gantt.date.add(new Date(task.start_date), 1, 'day');
          task.duration = 1;
        }

        this.dialogCreateOrEdit = this.openCreateOrEditDialog(task);
        this.handleCreateOrEditDialogActions(task);
        return false;
      },
      {}
    );

    const onAfterTaskMove = gantt.attachEvent(
      'onAfterTaskMove',
      (id, parent, taskIndex) => {
        const ganttTask: GanttTask = gantt.getTask(id);
        ganttTask.orderIndex = taskIndex;
      },
      null
    );

    const onBeforeRowDragEnd = gantt.attachEvent(
      'onBeforeRowDragEnd',
      (id, parent, orderIndex) => {
        const ganttTask: GanttTask = gantt.getTask(id);

        if (ganttTask.orderIndex !== orderIndex) {
          this.handleUpdateTaskOrder(
            parseInt(ganttTask.id, 10),
            programId,
            ganttTask.orderIndex
          );
        }
      },
      {}
    );

    this.ganttEvents.push(
      onBeforeLightbox,
      onBeforeTaskAdd,
      onBeforeTaskUpdate,
      onBeforeTaskDelete,
      onAfterLinkAdd,
      onAfterTaLinkUpdate,
      onAfterTaLinkDelete,
      onAfterTaskMove,
      onBeforeRowDragEnd
    );
  }

  public flushProcessor() {
    this.ganttEvents.forEach((ev) => gantt.detachEvent(ev));
  }

  // dialogs
  public handleCreateOrEditDialogActions(task: GanttTask) {
    this.dialogCreateOrEdit.afterSubmit.subscribe((action) => {
      if (action.type === 'CLOSE') {
        if (task.$new) {
          gantt.deleteTask(task.id);
        }
      } else if (action.type === 'CREATE') {
        const newTask = Object.assign(task, action.payload);
        gantt.addTask(newTask);
      } else if (action.type === 'UPDATE') {
        const newTask = Object.assign(task, action.payload);
        gantt.updateTask(newTask.id, newTask);
      } else if (action.type === 'DELETE') {
        this.handleDeleteDialogActions(task);
      }
    });
  }

  private handleDeleteDialogActions(task: GanttTask) {
    this.dialogCreateOrEdit.close();
    const deleteDialog = this.openDialogConfirmation();

    this.dialogConfirmation = deleteDialog;

    deleteDialog.afterSubmit.subscribe(() => {
      gantt.deleteTask(task.id);
    });

    deleteDialog.afterClosed.subscribe(() => {
      this.dialogCreateOrEdit = this.openCreateOrEditDialog(task);
      this.handleCreateOrEditDialogActions(task);
    });
  }

  private openCreateOrEditDialog(task: GanttTask): ExosDialogRef {
    // @ts-ignore
    const config = JSON.parse(
      JSON.stringify(
        task.$new
          ? createNewGanttTaskProgramDialogConfig
          : editNewGanttTaskProgramDialogConfig
      )
    );

    config.data.formConfig.fields.type.options.push({
      text: 'Project',
      value: 'project',
    });

    const configFormFields = Object.keys(config.data.formConfig.fields);
    config.data.task = task;

    // @ts-ignore
    const currentAllTasks = Object.keys(
      // @ts-ignore
      gantt.$data.tasksStore.pull
    )
      .map((taskId) => ({
        text: gantt.getTask(taskId).text,
        value: taskId,
      }))
      .filter((option) => {
        const optionId = parseInt(option.value, 10);
        const taskId = parseInt(task.id, 10);
        const parentId = parseInt(gantt.getTask(optionId).parent, 10);

        const isSameTask = optionId !== taskId;
        const isChildTask = parentId === taskId;

        return isSameTask && !isChildTask;
      });

    configFormFields.forEach((fieldName) => {
      if (task.$new && (fieldName === 'text' || fieldName === 'type')) {
        return;
      }
      config.data.formConfig.fields[fieldName].value = task[fieldName];
    });

    config.data.formConfig.fields.parent.options = [
      ...config.data.formConfig.fields.parent.options,
      ...currentAllTasks,
    ];

    return this.dialogService.open(GanttTaskDialogComponent, config);
  }

  private openDialogConfirmation() {
    return this.dialogService.open(ConfirmationComponent, {
      data: {
        title: 'Delete task',
        text: 'Are you sure?',
      },
    });
  }
}
