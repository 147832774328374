import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';
import { GanttChartData } from '../../../../shared/models/gantt.model';
import { GanttService } from '../../../../shared/service/gantt-service/gantt.service';
import { ProjectModel } from 'src/app/shared/models/project.model';

@Injectable({
  providedIn: 'root',
})
export class ProjectPlanResolver implements Resolve<GanttChartData> {
  constructor(private ganttService: GanttService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<GanttChartData> {
    const project: ProjectModel = route.parent.data.project;
    if (project.workflow === 'united-internet') {
      this.router.navigate([`/projects/${project.id}/details`]);
    }

    return this.ganttService.fetchGanttData(project.id).pipe(
      mergeMap((projectPlanSections) => of(projectPlanSections)),
      catchError(() => {
        return of({ data: [], links: [] });
      })
    );
  }
}
