import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  map,
  pluck,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { interval } from 'rxjs/internal/observable/interval';

import { DashNotificationService } from '@dashboard/core';
import { DashTableColumnActions } from '@dashboard/table';
import { DashDialogService, ExosDialogConfig } from '@dashboard/exos-dialog';

import { AddItemDialogComponent } from '../../../../shared/components/dialogs/add-item-dialog/add-item-dialog.component';
import { ConfirmationComponent } from '../../../../shared/components/dialogs/confirmation/confirmation.component';
import { ProjectService } from 'src/app/shared/service/project/project.service';
import { DocumentService } from '../../../../shared/service/document/document.service';
import { ProjectRightsService } from '../../../../shared/service/user-rights/project-rights/project-rights.service';
import { ProjectDocumentFileInfo } from 'src/app/shared/models/google-doc.model';
import {
  ProjectModel,
  ProjectStartDates,
} from 'src/app/shared/models/project.model';
import {
  briefRouteConfig,
  EditableTableRouteConfig,
} from './project-brief-route.config';
import { CheckboxFormComponent } from '../../../../shared/components/checkbox-form/checkbox-form.component';
import {
  CheckboxFormConfig,
  MarketFormService,
} from '../../../../shared/service/market-form/market-form.service';
import { DivisionService } from 'src/app/shared/service/division/division.service';
import { ProjectOverviewFacadeService } from '../../../../shared/facade/project-overview-facade/project-overview-facade.service';
import { ChangeRequest } from 'src/app/shared/models/change-request';
import {
  ChangeRequestFacadeService,
  ChangeRequestUpdatePayload,
} from 'src/app/shared/facade/change-request-facade/change-request-facade.service';
import { ProjectStepperService } from 'src/app/shared/service/project-stepper-service/project-stepper.service';

@Component({
  selector: 'app-project-brief',
  templateUrl: './project-brief-route.component.html',
  styleUrls: ['./project-brief-route.component.scss'],
})
export class ProjectBriefRouteComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  routeConfig: EditableTableRouteConfig = briefRouteConfig;
  canUpdateResources = false;
  canGenerateFile = false;
  disableGenerateButton = false;

  projectBriefFile: ProjectDocumentFileInfo;
  project: ProjectModel;
  projectId: number;

  projectChangeRequests: ChangeRequest[];
  canCreateChangeRequest: boolean;
  canViewChangeRequests: boolean;
  changeRequestUpdateInProgress: boolean;

  @ViewChild(CheckboxFormComponent)
  marketForm: CheckboxFormComponent;

  marketFormConfig: CheckboxFormConfig;
  defaultSort: { [key: string]: boolean } = {
    requirements: false,
    dependencies: false,
    risks: false,
  };

  private routeSubs: Subscription[] = [];
  private projectDocumentFileInfoSubscription: Subscription;
  private projectFolderInfoSubscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private dialogService: DashDialogService,
    private changeRequestFacadeService: ChangeRequestFacadeService,
    private projectStepperService: ProjectStepperService,
    private projectService: ProjectService,
    private notificationService: DashNotificationService,
    private changeDetectorRef: ChangeDetectorRef,
    private documentsService: DocumentService,
    private userRightsService: ProjectRightsService,
    private router: Router,
    private marketFormService: MarketFormService,
    private divisionService: DivisionService,
    private projectOverviewFacadeService: ProjectOverviewFacadeService
  ) {}

  ngOnInit() {
    const briefFileInfo$: Observable<ProjectDocumentFileInfo> =
      this.route.data.pipe(pluck('projectBriefFile'));

    const project$: Observable<ProjectModel> = this.route.parent.data.pipe(
      pluck('project')
      // map((project: ProjectModel) => {
      //   if (project.stakeholders.length) {
      //     const stakeholderIndex = project.stakeholders.findIndex(
      //       (stakeholder) => stakeholder.division === 'Others (free text)'
      //     );
      //     const foundStakeholder = project.stakeholders[stakeholderIndex];
      //
      //     project.stakeholders.splice(stakeholderIndex, 1);
      //     project.stakeholders.unshift(foundStakeholder);
      //   }
      //
      //   return project;
      // })
    );

    this.routeSubs.push(
      combineLatest(project$, briefFileInfo$)
        .pipe(
          map(([project, projectBriefFile]) => ({ project, projectBriefFile }))
        )
        .subscribe(({ project, projectBriefFile }) => {
          const {
            isClient,
            isOwner,
            isMainTeamMember,
            isRepresentative,
            isAdmin,
          } = this.userRightsService.getUserSessionStatus(project);

          this.project = project;
          this._updateRoute(project);

          this.projectBriefFile = projectBriefFile;
          this.canUpdateResources =
            this.userRightsService.getUserRights(project).canUpdate;
          this.updateMarketFormConfig(project.market || []);
          this.canGenerateFile =
            isClient ||
            isOwner ||
            isMainTeamMember ||
            isRepresentative ||
            isAdmin;
          this.updateTablesColsWithActions();

          if (!project?.folderId) {
            this.notificationService.warning('Project folder was not created.');
          }
          if (project.generatingProjectBriefInProgress && !!project?.folderId) {
            this.disableGenerateButton = true;
            this.notificationService.warning('Project Brief file generating.');
            this.projectDocumentFileInfoSubscription = interval(3000)
              .pipe(
                startWith(0),
                switchMap(() =>
                  this.documentsService.getProjectDocumentFileInfo(
                    project.folderId,
                    'Project Brief'
                  )
                ),
                filter((response) => response.fileId !== null),
                take(1)
              )
              .subscribe((res: ProjectDocumentFileInfo) => {
                this.projectBriefFile = res;
                this.notificationService.success(
                  'Project Brief file was generated.'
                );
              });
          }
        })
    );

    this.changeRequestFacadeService.project$.subscribe(
      (project: ProjectModel) => {
        this._updateRoute(project, true);
      }
    );

    this.projectId = parseInt(
      this.route.snapshot.parent.paramMap.get('id'),
      10
    );
  }

  ngAfterViewInit() {
    this.divisionService.findByName('').subscribe((response) => {
      const divisionFieldEditModal = this.routeConfig.stakeholder.modal.edit
        .data.formConfig.fields.division as any;
      divisionFieldEditModal.options = response;
    });
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.routeSubs.forEach((sub) => (sub ? sub.unsubscribe() : null));
    if (this.projectDocumentFileInfoSubscription) {
      this.projectDocumentFileInfoSubscription.unsubscribe();
    }
    if (this.projectFolderInfoSubscription) {
      this.projectFolderInfoSubscription.unsubscribe();
    }
  }

  public openBriefFile(fileId, headingId) {
    if (fileId != null) {
      let link = `https://docs.google.com/document/d/${fileId}/edit`;
      if (headingId !== null) {
        link = link + `#heading=${headingId}`;
      }
      window.open(link);
    }
  }

  public generateProjectBriefFile() {
    this.notificationService.warning('Project Brief file generating.');
    this.disableGenerateButton = true;

    this.documentsService
      .generateProjectBriefFile(this.project.id)
      .pipe(
        catchError(() => {
          this.disableGenerateButton = false;
          this.notificationService.warning(
            'Project Brief file cannot be generated.'
          );
          return of(null);
        })
      )
      .subscribe((response: ProjectModel) => {
        if (response) {
          const folderId = this.project?.folderId || response.folderId || null;
          if (!folderId) {
            this.projectFolderInfoSubscription = interval(3000)
              .pipe(
                startWith(0),
                switchMap(() =>
                  this.documentsService.getProjectFolderId(
                    this.project.id || response.id
                  )
                ),
                filter((response) => response?.fileId !== null),
                take(1)
              )
              .subscribe((folderResponse: ProjectDocumentFileInfo) => {
                if (folderResponse.fileId !== null) {
                  this.project.folderId = folderResponse.fileId;
                  this.projectDocumentFileInfoSubscription =
                    this.projectDocumentPolling(folderResponse.fileId);
                }
              });
          } else {
            this.projectDocumentFileInfoSubscription =
              this.projectDocumentPolling(response.folderId);
          }
        }
      });
  }

  private projectDocumentPolling(folderId: string) {
    return interval(3000)
      .pipe(
        startWith(0),
        switchMap(() =>
          this.documentsService.getProjectDocumentFileInfo(
            folderId,
            'Project Brief'
          )
        ),
        filter((response) => response.fileId !== null),
        take(1)
      )
      .subscribe((res: ProjectDocumentFileInfo) => {
        this.disableGenerateButton = false;
        this.projectBriefFile = res;
        this.notificationService.success('Project Brief file was generated.');
      });
  }

  public handleOpenAddModal(dialogConfig: ExosDialogConfig) {
    const dialog = this.dialogService.open(
      AddItemDialogComponent,
      dialogConfig
    );
    const { action: resource, fields: formFields } =
      dialogConfig.data.formConfig;

    for (const field in formFields) {
      if (formFields.hasOwnProperty(field)) {
        formFields[field].value = '';

        if (formFields[field].selectedItems) {
          formFields[field].items.next([]);
          formFields[field].selectedItems = [];
        }
      }
    }

    dialog.afterSubmit.subscribe((formData: ProjectModel) => {
      if (
        resource !== 'projectedLaunchDates' &&
        resource !== 'projectedStartDates'
      ) {
        this.handleEditProjectItem(formData, resource, dialog);
      }
    });
  }

  public onMarketCheck(payload) {
    const updatePayload = { payload, projectId: this.project.id };

    this.marketForm.form.disable({ emitEvent: false });

    this.projectService
      .save(updatePayload)
      .pipe(
        finalize(() => {
          this.marketForm.form.enable({ emitEvent: false });
        })
      )
      .subscribe(
        () => {
          this.notificationService.success('Project updated.');
        },
        () => {
          this.notificationService.error('Project cannot be updated');
        }
      );
  }

  public updateChangeRequest(payload: ChangeRequestUpdatePayload) {
    if (payload.actionName === 'VIEW' || payload.actionName === 'EDIT') {
      this.router.navigate(['../change-requests', payload.data.id], {
        relativeTo: this.route,
      });
    } else {
      this.changeRequestUpdateInProgress = true;
      payload.projectId = this.projectId;

      this.changeRequestFacadeService
        .handleChangeRequestAction(payload)
        .pipe(tap(() => (this.changeRequestUpdateInProgress = false)))
        .subscribe((response: ProjectModel) => {
          this._updateRoute(response, true);
        });
    }
  }

  private _updateRoute(project: ProjectModel, updateStepper?: boolean) {
    const userSessionStatus =
      this.userRightsService.getUserSessionStatus(project);

    if (updateStepper) {
      this.projectStepperService.update(project);
    }

    this.projectChangeRequests =
      this.changeRequestFacadeService.parseChangeRequests(
        project,
        userSessionStatus
      );

    this.canCreateChangeRequest =
      this.changeRequestFacadeService.canAddProjectChangeRequest(
        userSessionStatus,
        project.phase
      );

    this.canViewChangeRequests =
      this.changeRequestFacadeService.canViewProjectChangeRequests(
        project.phase
      );
  }

  public createChangeRequest() {
    this.changeRequestFacadeService.openChangeRequestModal(
      null,
      this.projectId
    );
  }

  private updateMarketFormConfig(markets: string[]) {
    this.marketFormConfig = this.marketFormService.generateConfig(
      'market',
      'All markets'
    );

    markets.forEach((market) => {
      this.marketFormConfig.fields[market].value = true;
      this.marketFormConfig.fields[market].rowWidth = '25';
    });
  }

  private handleEditProjectItem(reqBody: any, resource: string, dialog: any) {
    this.projectService.addItem(this.project.id, reqBody, resource).subscribe(
      () => {
        this.notificationService.success('Successfully updated ' + resource);
        dialog.close();
        this.fetchResource(this.project.id, resource);
        this.defaultSort[resource] = true;
      },
      ({ error }) => {
        dialog.errors(error);
      }
    );
  }

  private fetchResource(projectId: number, resource: string) {
    this.projectService
      .fetchProjectResource(projectId, resource)
      .pipe(
        map((response) => {
          if (resource === 'stakeholders') {
            response = response.sort((s) => (s.predefined ? 0 : -1));
          }
          return response;
        })
      )
      .subscribe((data) => {
        this.project[resource] = data;
      });
  }

  private updateTablesColsWithActions() {
    Object.keys(this.routeConfig).forEach((config) => {
      if (config === 'projectedLaunchDate' || config === 'projectedStartDate') {
        return;
      }

      if (this.canUpdateResources) {
        const editModal = this.routeConfig[config].modal.edit;
        const deleteModal = this.routeConfig[config].modal.delete;
        const hasEditModal = !!editModal;
        const hasDeleteModal = !!deleteModal;

        const rowActions = {
          id: 'actions',
          name: '',
          sortable: false,
          value: null,
          display: true,
          actions: (row: any) => {
            if (config === 'stakeholder' && !row.updatable) {
              return [];
            }

            const defaultActions: DashTableColumnActions = [
              {
                name: 'Edit',
                // tslint:disable-next-line:no-shadowed-variable
                action: (row) => {
                  this.handleOpenEditModal(editModal, row);
                },
              },
              {
                name: 'Delete',
                // tslint:disable-next-line:no-shadowed-variable
                action: (row) => {
                  this.handleOpenDeleteModal(deleteModal, row);
                },
              },
            ];

            if (row.predefined || (!row.predefined && row.userInfo === null)) {
              return defaultActions.filter((item) => {
                if (typeof item !== 'string') {
                  return item.name === 'Add';
                }

                return false;
              });
            }

            return hasEditModal && hasDeleteModal ? defaultActions : [];
          },
        };
        this.routeConfig[config].table.cols.push(rowActions);
      } else {
        this.routeConfig[config].table.cols = this.routeConfig[
          config
        ].table.cols.filter((col) => col.id !== 'actions');
      }
    });
  }

  private handleOpenEditModal(dialogConfig: ExosDialogConfig, item: any) {
    const dialog = this.dialogService.open(
      AddItemDialogComponent,
      dialogConfig
    );
    const projectId = this.project.id;
    const resource = dialogConfig.data.formConfig.action;
    const { fields: formFields } = dialogConfig.data.formConfig;

    for (const field in formFields) {
      if (formFields.hasOwnProperty(field)) {
        formFields[field].disabled = false;
        if (formFields[field]) {
          const currentField = formFields[field];
          currentField.value = item[field];

          if (currentField.selectedItems) {
            currentField.selectedItems = item[field];
          }
        }

        if (
          resource === 'stakeholders' &&
          item.predefined &&
          field !== 'userInfo'
        ) {
          formFields[field].disabled = true;
        }
      }
    }

    dialog.afterSubmit.subscribe((formData: ProjectModel) => {
      if (resource !== 'projectedLaunchDate') {
        this.projectService
          .editItem(projectId, item.id, formData, resource)
          .subscribe(
            () => {
              this.notificationService.success(
                'Successfully updated ' + resource
              );
              dialog.close();
              this.fetchResource(this.project.id, resource);
            },
            ({ error }) => {
              dialog.errors(error);
            }
          );
      }
    });
  }

  private handleOpenDeleteModal(dialogConfig: ExosDialogConfig, item: any) {
    const dialog = this.dialogService.open(ConfirmationComponent, dialogConfig);
    const projectId = this.project.id;
    const resource = dialogConfig.data.action;

    dialog.afterSubmit.subscribe(() => {
      this.projectService
        .deleteItem(projectId, item.id, resource)
        .subscribe(() => {
          this.notificationService.success('Successfully updated ' + resource);
          dialog.close();
          this.fetchResource(projectId, resource);
        });
    });
  }
}
