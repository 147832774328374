import { ExosCardFormConfig } from "src/app/shared/models/card-form-config";

export const productNewsFormConfig: ExosCardFormConfig = {
    heading: 'Product News',
    disabled: true,
    name: 'product_news',
    fields: {
        html_product_news: {
        name: 'html_product_news',
        type: 'wysiwig',
        fxFlex: '100',
        rows: '20',
        cols: '30',
        value: null,
        resizable: 'vertical',
        readOnly: true,
        emptyMsg: 'No product news.',
        buttons: [{ label: 'View', action: 'SHOW_NEWS', type: 'button', hideOnDisabled: true },{ label: 'Edit', action: 'EDIT_NEWS', type: 'button', hideOnDisabled: true }],
      },
    },
    buttons: [],
  };

  export const releaseNotesFormConfig: ExosCardFormConfig = {
    heading: 'Release Notes',
    disabled: true,
    name: 'release_notes',
    fields: {
        html_release_notes: {
        name: 'html_release_notes',
        type: 'wysiwig',
        fxFlex: '100',
        rows: '20',
        cols: '30',
        value: null,
        resizable: 'vertical',
        readOnly: true,
        emptyMsg: 'No release notes.',
        buttons: [{ label: 'View', action: 'SHOW_NOTES', type: 'button', hideOnDisabled: true },{ label: 'Edit', action: 'EDIT_NOTES', type: 'button', hideOnDisabled: true }],
      },
    },
    buttons: [],
  };

  export const newsHomeFormsConfig: {
    [key: string]: ExosCardFormConfig;
  } = {
    product_news: productNewsFormConfig,
    release_notes: releaseNotesFormConfig,
  };
  