import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DashNotificationService, UserService } from '@dashboard/core';
import {
  DashDialogService,
  ExosDialogConfig,
  ExosDialogRef,
} from '@dashboard/exos-dialog';
import {
  AutocompleteOption,
  FormEvent,
  WysiwigConfig,
} from '@dashboard/exos-form';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  pairwise,
  pluck,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  ProjectLaunchDates,
  ProjectModel,
  ProjectStartDates,
  StartDate,
} from 'src/app/shared/models/project.model';
import {
  Division,
  DivisionService,
} from 'src/app/shared/service/division/division.service';
import { ExosFormButtonsService } from 'src/app/shared/service/exos-form-buttons/exos-form-buttons.service';
import { ProjectStatusService } from 'src/app/shared/service/project-status/project-status.service';
import { ProjectService } from 'src/app/shared/service/project/project.service';
import { ProjectRightsService } from 'src/app/shared/service/user-rights/project-rights/project-rights.service';
import { UsersService } from 'src/app/shared/service/users/users.service';
import { projectOverviewFormsConfig } from './project-overview-form.config';
import { projectUpdateDescriptionModalConfig } from '../../../../shared/config/dialogs/project-description.dialog.config';
import { ProjectOverviewService } from './project-overview.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProjectStatusItem } from '../../../../shared/models/project-status';
import {
  ExosButtonConfig,
  ExosCardFormConfig,
} from '../../../../shared/models/card-form-config';
import { ProjectOverviewFacadeService } from '../../../../shared/facade/project-overview-facade/project-overview-facade.service';
import { ObjectiveService } from '../../../../shared/service/objective/objective.service';
import {
  KeyResult,
  Objective,
  PartialKeyResult,
  PartialObjective,
  PartialObjectiveSetMap,
} from '../../../../shared/models/objective.model';
import { WysiwygDialogComponent } from '../../../../shared/components/dialogs/wysiwyg-dialog/wysiwyg-dialog.component';
import { ProgramService } from '../../../../shared/service/program/program.service';
import { Program } from '../../../../shared/models/program.model';
import { User } from '../../../../shared/models/user';
import { ExosMultiselectComponent } from '../../../../shared/components/form/exos-multiselect/exos-multiselect.component';
import { Tag } from '../../../../shared/models/tag.model';
import { TagService } from '../../../../shared/service/tag/tag.service';
import { projectObjectiveValidator } from '../../../../shared/validators/project-objective.validator';
import { ExosAutocompleteComponent } from '../../../../shared/components/form/exos-autocomplete/exos-autocomplete.component';
import { AutocompleteMapperService } from 'src/app/shared/service/autocomplete-mapper/autocomplete-mapper.service';
import { PartialObjectiveSet } from 'src/app/shared/models/objective.model';
import { RoadmapService } from 'src/app/shared/service/roadmap/roadmap.service';
import {
  Category,
  Product,
  ProductSpaceDTO,
} from 'src/app/shared/models/product-space.model';
import { projectRoadmapValidator } from 'src/app/shared/validators/project-roadmap.validator';
import {
  generateDivisionSetsDisableMap,
  generateDivisionSetsMap,
} from 'src/app/utils/division-sets-map';

const OBJECT_FIELDS = [
  'client',
  'owner',
  'representative',
  'division',
  'mainTeamMembers',
  'program',
  'tags',
  'productSpaceId',
];

const SUBS_FIELDS = [
  ['programs$', 'program', 'programService', 'findByName'],
  ['owner$', 'owner', 'userLdapService', 'findByName'],
  ['client$', 'client', 'userLdapService', 'findByName'],
  ['representative$', 'representative', 'userLdapService', 'findByName'],
  ['mainTeamMember$', 'mainTeamMembers', 'userLdapService', 'findByName'],
  ['tag$', 'tags', 'tagService', 'findByNameLikePattern'],
  ['division$', 'division', 'divisionService', 'findByName', 'id', 'item'],
  ['productSpace$', 'productSpaceId', 'roadmapService', 'findByName'],
];

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview-route.component.html',
  styleUrls: ['./project-overview-route.component.scss'],
})
export class ProjectOverviewRouteComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @ViewChildren(ExosMultiselectComponent)
  public multiselectInputs: ExosMultiselectComponent[];

  @ViewChildren(ExosAutocompleteComponent)
  public autocompleteInputs: ExosAutocompleteComponent[];

  private subs: Subscription[] = [];

  public formConfig: {
    [key: string]: ExosCardFormConfig;
  } = JSON.parse(JSON.stringify(projectOverviewFormsConfig));

  private subsFields = [...SUBS_FIELDS];

  private initialForm;
  private sets: PartialObjectiveSet[] = [];
  private set: PartialObjectiveSet;
  private objectiveYear: string;
  private productSpace: any;
  private product: any;
  private category: any;

  public projectDescriptionModalConfig: ExosDialogConfig =
    projectUpdateDescriptionModalConfig;

  public canUpdate: boolean;
  private canUpdateOwner: boolean;

  public canUpdateStatus: boolean;

  public canUpdateConfidential: boolean;

  public projectActionsButtons$: Observable<ExosButtonConfig[]> =
    this.exosFormButtonsService.currentButtons$;

  public projectWorkflow: string;

  public projectStatus: ProjectStatusItem[];
  public projectedLaunchDateTable: ProjectLaunchDates[];
  public projectedStartDateTable: ProjectStartDates[];

  public form: FormGroup = new FormGroup(
    {
      // detail card
      name: new FormControl(null, []),
      effort: new FormControl(null, [Validators.required]),
      prio: new FormControl(null, [Validators.required]),
      workflow: new FormControl(null, [Validators.required]),
      program: new FormControl(null),
      tags: new FormControl(null),

      // description card
      html_description: new FormControl(null),

      // roadmap card
      showOnRoadmap: new FormControl(null),
      productSpaceId: new FormControl(null),
      productId: new FormControl(null),
      categoryId: new FormControl(null),

      // team card
      client: new FormControl(null),
      owner: new FormControl(null, [Validators.required]),
      representative: new FormControl(null),
      division: new FormControl(null),
      mainTeamMembers: new FormControl(null),

      // okr card
      objectiveType: new FormControl(null),
      objectiveYear: new FormControl(null),
      objectiveQuarter: new FormControl(null),
      objectiveDivision: new FormControl(null),
      objectiveId: new FormControl(null),
      keyResultId: new FormControl(null),
    },
    { validators: [projectObjectiveValidator, projectRoadmapValidator] }
  );

  public confidentialForm: FormGroup = new FormGroup({
    confidential: new FormControl(null),
  });

  // inputs
  public programs$: Observable<Program[]>;

  public owner$: Observable<User[]>;

  public client$: Observable<User[]>;

  public representative$: Observable<User[]>;

  public mainTeamMember$: Observable<User[]>;

  public division$: Observable<Division[]>;

  public tag$: Observable<Tag[]>;

  public productSpace$: Observable<ProductSpaceDTO[]>;

  public productSpaces: ProductSpaceDTO[];

  public products: { id: number; name: string; categories: any[] }[];

  public categories: { id: number; name: string }[];

  public objectiveTypes = [
    { type: 'company', text: 'Company OKR' },
    { type: 'division', text: 'Division OKR' },
  ];

  public objectiveType: 'company' | 'division' = 'division';

  public years: string[] = [];

  public objectiveDivisions: Division[] = [];

  public divisionSets: PartialObjectiveSet[] = [];
  private divisionSetsMap: PartialObjectiveSetMap = {};
  private divisionSetsDisableMap: {
    [year: number]: {
      [quarter: string]: boolean;
    };
  } = {};

  public quarters: { text: string; disabled?: boolean }[] = [];

  public objectives: PartialObjective[] = [];

  public keyResults: PartialKeyResult[] = [];

  public projectId: number;

  public updateInProgress$: Observable<boolean>;
  public isLoading = true;

  public project: ProjectModel;

  private cacheFormValue: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService,
    private userLdapService: UsersService,
    private divisionService: DivisionService,
    private notificationService: DashNotificationService,
    private exosFormButtonsService: ExosFormButtonsService,
    private projectOverviewService: ProjectOverviewService,
    private userRights: ProjectRightsService,
    private statusService: ProjectStatusService,
    private projectOverviewFacade: ProjectOverviewFacadeService,
    private objectiveService: ObjectiveService,
    private userService: UserService,
    private autocompleteService: AutocompleteMapperService,
    private dashDialogService: DashDialogService,
    protected programService: ProgramService,
    protected tagService: TagService,
    protected roadmapService: RoadmapService
  ) {}

  @HostListener('window:storage')
  onStorageChange() {
    const prop = localStorage.getItem('projectChanges');
    console.log('projectsChanges:', prop);

    window.location.reload();
  }

  ngOnInit(): void {
    this.form.disable({ emitEvent: false });
  }

  ngAfterViewInit(): void {
    this.handleFirstProjectFetch();
    this.handleInputsSubs();
    this.handleFormValueChanges();
    this.handleFormDisabledState();
    this.handleProjectVisibility();
    this.handleProjectUpdateError();

    this.subs.push(
      this.projectOverviewService.currentFormValue.subscribe((project) => {
        this.project = project;
        this.handleUserRights(project);
        this.cacheFormValue = this.form.value;
      })
    );

    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subs.forEach((s) => s.unsubscribe());
  }

  private handleProjectVisibility() {
    this.confidentialForm.controls.confidential.valueChanges
      .pipe(map((value) => ({ flag: value ? 'enable' : 'disable', value })))
      .subscribe(({ flag, value }) => {
        this.form.disable({ emitEvent: false });

        this.projectService
          .updateProjectVisibility(this.projectId.toString(), flag)
          .subscribe(
            (response) => {
              this.handleUserRights(response);
              this.notificationService.success(
                'Project visibility successfully updated.'
              );
            },
            () => {
              this.confidentialForm.controls.confidential.patchValue(!value, {
                emitEvent: false,
              });
              this.handleUserRights(this.project);
            }
          );
      });
  }

  private handleFormDisabledState() {
    this.updateInProgress$ = this.projectOverviewService.actionInProgress$.pipe(
      tap((value) => {
        if (value) {
          this.form.disable({ emitEvent: false });
        }
      })
    );
  }

  private handleInputSub(
    inputName: string,
    serviceName: string,
    serviceAction: string,
    mapValue: string = 'id',
    mapText: string = 'text'
  ): Observable<any[]> {
    return this.form.controls[inputName].valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      filter((value) => !!value && typeof value === 'string'),
      filter((value) => value.trim().length > 2),
      switchMap((value): any[] => {
        const currentInput =
          this.autocompleteInputs.find((input) => input.name === inputName) ||
          this.multiselectInputs.find((input) => input.name === inputName);

        currentInput.isLoading = true;
        currentInput.displayNoResultsMsg = false;

        return this[serviceName][serviceAction](value).pipe(
          map((data: any[]) => {
            currentInput.isLoading = false;
            currentInput.displayNoResultsMsg = data.length === 0;
            return data.map((item) => ({
              ...item,
              value: item[mapValue],
              text: item[mapText],
            }));
          }),
          catchError(() => {
            currentInput.isLoading = false;
            return of(null);
          })
        );
      })
    );
  }

  private handleInputsSubs() {
    this.subsFields.forEach(
      ([prop, inputName, serviceName, serviceAction, mapValue, mapText]) => {
        this[prop] = this.handleInputSub(
          inputName,
          serviceName,
          serviceAction,
          mapValue || undefined,
          mapText || undefined
        );
      }
    );

    this.handleOkrFieldChanges();
    this.handleRoadmapFieldChanges();
  }

  private handleOkrFieldChanges(): void {
    this.form.controls.objectiveType.valueChanges.subscribe((type) => {
      this.objectiveType = type?.type;
      this.sets = this.divisionSets;
      this.set = this.findObjectivesSet(null);
      this.years = Object.keys(this.divisionSetsMap);
      this.objectiveYear = this.set?.year;
      this.quarters =
        this.objectiveType === 'division'
          ? this.getQuarters(this.objectiveYear)
          : [];

      const quarter = this.findQuarter(this.set?.quarter);
      this.objectiveDivisions = this.divisionSetsMap[this.set?.year]?.[
        this.set?.quarter
      ].map((set: PartialObjectiveSet) => set.division);

      this.objectives = this.set?.objectives;
      this.keyResults = [];

      this.form.patchValue(
        {
          objectiveYear: this.objectiveYear,
          objectiveQuarter: quarter,
          objectiveDivision: this.set?.division,
          objectiveId: null,
          keyResultId: null,
        },
        { emitEvent: false }
      );
      this.form.controls.keyResultId.disable({ emitEvent: false });
    });

    this.form.controls.objectiveYear.valueChanges.subscribe((year) => {
      this.objectiveYear = year;
      this.quarters = this.getQuarters(year);
      this.set = this.sets.find(
        (set) =>
          set.year === year &&
          (this.objectiveType === 'division' ? !set.division.disabled : true)
      );
      const quarter = this.findQuarter(this.set?.quarter);
      this.objectiveDivisions = this.divisionSetsMap[this.set?.year]?.[
        this.set?.quarter
      ]?.map((set: PartialObjectiveSet) => set.division);
      this.objectives = this.set?.objectives;
      this.keyResults = [];

      this.form.patchValue(
        {
          objectiveQuarter: quarter,
          objectiveDivision: this.set?.division,
          objectiveId: null,
          keyResultId: null,
        },
        { emitEvent: false }
      );
      this.form.controls.keyResultId.disable({ emitEvent: false });
    });

    this.form.controls.objectiveQuarter.valueChanges.subscribe((quarter) => {
      this.set =
        this.sets.find(
          (set) =>
            set.year === this.objectiveYear &&
            set.quarter === quarter.text &&
            !set.division.disabled
        ) || this.sets[0];
      this.objectiveDivisions = this.divisionSetsMap[this.set?.year]?.[
        quarter.text
      ]?.map((set: PartialObjectiveSet) => set.division);
      this.objectives = this.set?.objectives;
      this.keyResults = [];

      this.form.patchValue(
        {
          objectiveDivision: this.set?.division,
          objectiveId: null,
          keyResultId: null,
        },
        { emitEvent: false }
      );
      this.form.controls.keyResultId.disable({ emitEvent: false });
    });

    this.form.controls.objectiveDivision.valueChanges.subscribe((division) => {
      this.set = this.divisionSets.find(
        (div) => div.division?.id === division.id
      );
      this.objectives = this.set?.objectives;
      this.keyResults = [];

      this.form.patchValue(
        {
          objectiveId: null,
          keyResultId: null,
        },
        { emitEvent: false }
      );
      this.form.controls.keyResultId.disable({ emitEvent: false });
    });

    this.form.controls.objectiveId.valueChanges
      .pipe(
        map((value) => {
          return value
            ? this.objectives.find((objective) => objective.id === value.id)
                .keyResults
            : [];
        })
      )
      .subscribe((keyResults) => {
        this.keyResults = keyResults;
        this.form.controls.keyResultId.patchValue(null);
        if (!keyResults.length) {
          this.form.controls.keyResultId.disable({ emitEvent: false });
        } else {
          this.form.controls.keyResultId.enable({ emitEvent: false });
        }
      });
  }

  /**
   * Register processing for whenever certain roadmap fields change;
   * product space: reset product and category;
   * product: reset category.
   */
  private handleRoadmapFieldChanges(): void {
    this.form.controls.productSpaceId.valueChanges.subscribe(
      (productSpace: ProductSpaceDTO) => {
        if (!productSpace) {
          this.form.controls.productId.disable({ emitEvent: false });
          this.form.controls.categoryId.disable({ emitEvent: false });
          this.form.controls.showOnRoadmap.disable({ emitEvent: false });
        }
        this.products = [];
        this.categories = [];
        this.form.patchValue(
          {
            productId: null,
            categoryId: null,
            showOnRoadmap: false,
          },
          { emitEvent: false }
        );

        if (productSpace?.products?.length > 0) {
          this.productSpace = productSpace;
          this.products = productSpace.products.map((product) => {
            return { ...product, text: product.name };
          });
          this.form.controls.productId.enable({ emitEvent: false });
        } else {
          if (productSpace) {
            this.form.controls.productSpaceId.setErrors({
              bkError: 'No products found for the selected Product Space',
            });
          }
          this.form.controls.productId.disable({ emitEvent: false });
          this.form.controls.categoryId.disable({ emitEvent: false });
        }
      }
    );

    this.form.controls.productId.valueChanges.subscribe((product) => {
      if (!product) {
        this.form.controls.categoryId.disable({ emitEvent: false });
        this.form.controls.showOnRoadmap.disable({ emitEvent: false });
      }
      this.categories = [];
      this.form.patchValue(
        {
          categoryId: null,
          showOnRoadmap: false,
        },
        { emitEvent: false }
      );

      this.categories = product?.categories || [];

      if (this.categories.length > 0) {
        this.categories = this.categories.map((category) => {
          return { ...category, text: category.name };
        });
        this.form.controls.categoryId.enable({ emitEvent: false });
      } else {
        if (product) {
          this.form.controls.productId.setErrors({
            bkError: 'No categories found for the selected Product',
          });
        }
        this.form.controls.categoryId.disable({ emitEvent: false });
      }
    });

    this.form.controls.categoryId.valueChanges.subscribe((category) => {
      if (!category) {
        this.form.controls.showOnRoadmap.disable({ emitEvent: false });

        this.form.patchValue(
          {
            showOnRoadmap: false,
          },
          { emitEvent: false }
        );
      } else {
        this.form.patchValue(
          {
            showOnRoadmap: true,
          },
          { emitEvent: false }
        );
      }
    });
  }

  private handleFirstProjectFetch() {
    const project$ = this.route.parent.data.pipe(pluck('project'));
    const divisionSets$ = this.objectiveService.getAllDivisionObjectivesSets();

    const productSpaces$ = this.roadmapService.productSpaceFetchAll();

    this.subs.push(
      combineLatest([project$, divisionSets$, productSpaces$]).subscribe(
        ([project, divisionSets, productSpaces]) => {
          this.divisionSets = divisionSets;

          this.divisionSetsMap = generateDivisionSetsMap(divisionSets);
          this.divisionSetsDisableMap = generateDivisionSetsDisableMap(
            this.divisionSetsMap
          );

          this.set = this.findObjectivesSet(project);

          this.sets = divisionSets;

          this.years = Object.keys(this.divisionSetsMap);

          project.objectiveYear = this.set?.year;
          this.objectiveYear = this.set?.year;

          this.quarters =
            this.objectiveType === 'division'
              ? this.getQuarters(this.set?.year)
              : [];
          this.objectiveDivisions =
            this.objectiveType === 'division'
              ? this.divisionSetsMap[this.set?.year]?.[this.set?.quarter].map(
                  (set: PartialObjectiveSet) => set.division
                )
              : [];
          this.objectives = this.set?.objectives;
          this.productSpaces = productSpaces;

          this.project = project;
          setTimeout(() => {
            this.handleProjectUpdate(project);
          }, 0);

          this.handleProjectObjectives(this.set?.objectives, project);
          this.handleProjectRoadmap(productSpaces, project);
          this.handleUserRights(project);
          this.isLoading = false;

          this.cacheFormValue = this.form.value;
        }
      )
    );
  }

  private handleProjectObjectives(
    objectives: PartialObjective[],
    project: ProjectModel
  ) {
    this.objectives = objectives.filter((o) => o.keyResults.length);

    if (project.objectiveId) {
      this.keyResults =
        this.objectives.find(
          (objective) => objective?.id === project?.objectiveId
        )?.keyResults || [];
    }
  }

  private handleProjectRoadmap(
    productSpaces: ProductSpaceDTO[],
    project: ProjectModel
  ) {
    if (project.productSpaceId) {
      this.products =
        productSpaces.find(
          (productSpace) => productSpace?.id === project.productSpaceId
        )?.products || [];
    }
    if (project.productId) {
      this.categories =
        this.products.find((product) => product?.id === project.productId)
          ?.categories || [];
    }
  }

  private handleUserRights(project: ProjectModel) {
    const {
      canUpdate,
      canUpdateOwner,
      canUpdateConfidential,
      canUpdateProjectStatus,
    } = this.userRights.getUserRights(project);

    this.canUpdate = canUpdate;
    this.canUpdateOwner = canUpdateOwner;

    const descriptionFieldConfig = this.formConfig.description.fields
      .html_description as WysiwigConfig;

    this.canUpdateStatus = canUpdateProjectStatus;
    this.canUpdateConfidential = canUpdateConfidential;

    if (canUpdate) {
      this.form.enable({ emitEvent: false });
      if (!this.project.objectiveId) {
        this.form.get('keyResultId').disable({ emitEvent: false });
      }
      if (
        !this.productSpaces?.length ||
        !this.products?.length ||
        !this.categories?.length
      ) {
        this.form.get('showOnRoadmap').disable({ emitEvent: false });
      }
      if (!this.products?.length) {
        this.form.get('productId').disable({ emitEvent: false });
      }

      if (!this.categories?.length) {
        this.form.get('categoryId').disable({ emitEvent: false });
      }

      descriptionFieldConfig.buttons = [
        descriptionFieldConfig.buttons[0],
        {
          label: 'Edit',
          action: 'EDIT_DESCRIPTION',
          type: 'button',
        },
      ];
    } else {
      this.form.disable({ emitEvent: false });
      descriptionFieldConfig.buttons = [descriptionFieldConfig.buttons[0]];
    }

    if (!canUpdateOwner) {
      this.form.controls.owner.disable({ emitEvent: false });
    } else {
      this.form.controls.owner.enable({ emitEvent: false });
    }

    if (this.canUpdateConfidential) {
      this.confidentialForm.controls.confidential.enable({ emitEvent: false });
    }

    this.form.controls.workflow.disable({ emitEvent: false });
    this.exosFormButtonsService.update(project);
  }

  private handleProjectUpdate(project: ProjectModel) {
    const productSpace = this.productSpaces.find(
      (ps) => ps.id === project.productSpaceId
    );

    this.form.patchValue(
      {
        name: project.name,
        html_description: project.html_description,
        effort: project.effort,
        prio: project.prio,
        workflow: project.workflow,
        program: project.program,
        client: project.client,
        owner: project.owner,
        objectiveType: this.objectiveTypes.find(
          (objective) => objective.type === this.objectiveType
        ),
        objectiveYear: project.objectiveYear,
        objectiveQuarter:
          this.objectiveType === 'division'
            ? this.findQuarter(this.set?.quarter)
            : null,
        objectiveDivision: this.set?.division,
        objectiveId: project.objectiveId,
        keyResultId: project.keyResultId,
        showOnRoadmap: project.showOnRoadmap,
        productSpaceId: productSpace
          ? { ...productSpace, text: productSpace?.name }
          : null,
        productId: project.productId,
        categoryId: project.categoryId,
      },
      { emitEvent: false }
    );
    this.initialForm = this.form.getRawValue();

    this.projectStatus = this.projectOverviewFacade.parseProjectStatus(
      project.status
    );
    this.parseLaunchDates(project);
    this.parseStartDates(project);
    this.projectedLaunchDateTable = this.project.projectedLaunchDateTable;
    this.projectedStartDateTable = this.project.projectedStartDateTable;
    this.projectWorkflow = project.workflow;
    this.projectId = project.id;

    this.multiselectInputs.forEach((input) => {
      if (project[input.name] !== null) {
        if (input.name !== 'division' && input.name !== 'tags') {
          input.items = this.autocompleteService.map(
            project[input.name],
            'name',
            'username'
          );
        } else {
          input.items = project[input.name];
        }
      }
    });

    this.confidentialForm.controls.confidential.patchValue(
      project.confidential,
      { emitEvent: false }
    );
  }

  private parseLaunchDates(project: ProjectModel) {
    if (!project.projectedLaunchDate) {
      project.projectedLaunchDate = [];
      this.project.projectedLaunchDateTable = [];
    } else {
      this.project.projectedLaunchDateTable = project.projectedLaunchDate.map(
        (date, index) => ({
          date,
          isCurrent: index === project.projectedLaunchDate.length - 1,
        })
      );

      this.project.projectedLaunchDateTable = [
        this.project.projectedLaunchDateTable.pop(),
        ...this.project.projectedLaunchDateTable,
      ];
    }
  }

  private parseStartDates(project: ProjectModel) {
    if (!project.projectedStartDate) {
      project.projectedStartDate = [];
      this.project.projectedStartDateTable = [];
    } else {
      this.project.projectedStartDateTable = project.projectedStartDate.map(
        ({ date, comment }, index) => ({
          date,
          comment,
          isCurrent: index === project.projectedStartDate.length - 1,
        })
      );

      this.project.projectedStartDateTable = [
        this.project.projectedStartDateTable.pop(),
        ...this.project.projectedStartDateTable,
      ];
    }
  }

  private getQuarters(year: string) {
    return Object.keys(this.divisionSetsMap[year]).map((quarter) => {
      return {
        text: quarter,
        disabled: !!this.divisionSetsDisableMap[year]?.[quarter],
      };
    });
  }

  private findQuarter(quarter) {
    return quarter ? this.quarters.find((q) => q.text === quarter) : null;
  }

  public displayWorkflowFn(option: AutocompleteOption) {
    return option.text;
  }

  public compareWorkflowFn(option: AutocompleteOption, value: string) {
    return option.value === value;
  }

  public displayProductSpaceFn(option: { id: number; name: string }) {
    return option.name;
  }

  public displayObjectiveDivisionFn(option: Division) {
    return option.text + (option?.isCompany ? ' (Company)' : '');
  }

  public displayObjectiveQuarterFn(option: Division) {
    return option.text;
  }

  public compareProductSpaceFn(
    option: { id: number; name: string },
    value: any
  ) {
    if (value) {
      if (value.id) {
        return option.id.toString() === value.id.toString();
      } else {
        return option.id.toString() === value.toString();
      }
    }
    return null;
  }

  public displayPartialObjectiveSetTypeFn(option: {
    type: string;
    text: string;
  }) {
    return option.text;
  }

  public displayObjectiveFn(option: Objective) {
    return option.title;
  }

  public compareObjectiveFn(option: Objective, value: any) {
    if (value) {
      if (value.id) {
        return option.id.toString() === value.id.toString();
      } else {
        return option.id.toString() === value.toString();
      }
    }
    return null;
  }

  public displayKeyResultFn(option: KeyResult) {
    return option.name;
  }

  public compareFnKeyResultFn(option: KeyResult, value: any) {
    if (value) {
      if (value.id) {
        return option.id.toString() === value.id.toString();
      } else {
        return option.id.toString() === value.toString();
      }
    }
    return null;
  }

  private findObjectivesSet(project: ProjectModel): PartialObjectiveSet {
    let set: PartialObjectiveSet = null;
    if (project?.objectiveId) {
      //  Replace to allSets after company/division split
      set = this.divisionSets.find((set) => {
        return set.objectives.some((objective) => {
          return objective.id === project.objectiveId;
        });
      });
    }
    if (!set) {
      const enabledDivisions = this.divisionSets.filter(
        (set) => !set.division?.disabled
      );
      set =
        enabledDivisions.find((set) => set.isCurrentSet) || enabledDivisions[0];
    }

    return set || this.divisionSets?.[0];
  }

  private handleFormValueChanges() {
    this.form.valueChanges
      .pipe(
        startWith(null as any),
        debounceTime(1000),
        pairwise(),
        filter(() => {
          return this.form.valid;
        }),
        filter(([prev, value]) => {
          if (
            (!!prev &&
              prev?.objectiveDivision?.id !== value.objectiveDivision?.id) ||
            (!prev &&
              this.initialForm?.objectiveDivision?.id !==
                value.objectiveDivision?.id)
          ) {
            return false;
          }

          if (
            (!!prev &&
              prev?.objectiveType?.type !== value.objectiveType?.type) ||
            (!prev &&
              this.initialForm?.objectiveType?.type !==
                value.objectiveType?.type)
          ) {
            return false;
          }

          if (
            (!!prev && prev?.objectiveQuarter !== value.objectiveQuarter) ||
            (!prev &&
              this.initialForm?.objectiveQuarter !== value.objectiveQuarter)
          ) {
            return false;
          }

          if (
            (!!prev && prev?.objectiveYear !== value.objectiveYear) ||
            (!prev && this.initialForm?.objectiveYear !== value.objectiveYear)
          ) {
            return false;
          }
          return true;
        }),
        filter(([prev, value]) => {
          return Object.keys(value).length >= 12 || this.canUpdateOwner;
        }),
        filter(([prev, formValue]) => {
          return OBJECT_FIELDS.map((field) => {
            return this.form.controls[field].value;
          }).every(
            (value) =>
              typeof value === 'object' || value === '' || value === null
          );
        }),
        filter(([prev, value]) => {
          return (
            Object.keys(value).find((k) => k !== 'confidential') &&
            JSON.stringify(value) !== this.cacheFormValue
          );
        })
      )
      .subscribe(([prev, value]) => {
        this.processFormValue(value);

        const event = {
          type: 'submit',
          value,
          rawValue: value,
          formIsValid: true,
        };

        this.projectOverviewService.sendNextEvent({
          projectId: this.projectId,
          event,
        });
      });
  }

  processFormValue(value) {
    if (this.objectiveType === 'company') {
      value.objectiveQuarter = null;
      value.objectiveDivision = null;
    }
    value.objectiveId = value.objectiveId?.id
      ? value.objectiveId.id
      : value.objectiveId;
    value.keyResultId = value.objectiveId
      ? value.keyResultId?.id
        ? value.keyResultId.id
        : value.keyResultId
      : null;

    value.productSpaceId =
      value.productSpaceId?.id || value.productSpaceId || null;
    value.productId = value.productSpaceId
      ? value.productId?.id
        ? value.productId.id
        : value.productId
      : null;
    value.categoryId = value.productId
      ? value.categoryId?.id
        ? value.categoryId.id
        : value.categoryId
      : null;
    value.showOnRoadmap = value.productSpaceId ? value.showOnRoadmap : false;
  }

  public openEditDescriptionModal(ev: FormEvent) {
    const modalConfig: ExosDialogConfig = JSON.parse(
      JSON.stringify(this.projectDescriptionModalConfig)
    );

    const readOnly = ev.type === 'SHOW_DESCRIPTION';

    modalConfig.data.formConfig.fields.html_description.value =
      this.form.controls.html_description.value;
    modalConfig.data.formConfig.fields.html_description.readOnly = readOnly;

    modalConfig.data.formConfig.buttons =
      modalConfig.data.formConfig.buttons.filter((button) =>
        readOnly ? button.action === 'CLOSE' : true
      );

    modalConfig.data.title = readOnly
      ? 'Project description'
      : 'Edit project description';

    const modal = this.dashDialogService.open(
      WysiwygDialogComponent,
      modalConfig
    );

    modal.afterSubmit.subscribe((payload) => {
      payload.html_description = payload.html_description || '';

      payload.description =
        payload.html_description === null
          ? ''
          : payload.html_description.replace(/(<([^>]+)>)/gi, ' ');

      this.projectService
        .update(payload, this.projectId)
        .subscribe((response) => {
          this.form.controls.html_description.patchValue(
            response.html_description,
            { emitEvent: false }
          );
          modal.close();
        });
    });
  }

  public updateStatus(statusItem: ProjectStatusItem): void {
    const dialog: ExosDialogRef =
      this.projectOverviewFacade.openChangeStatusModal(
        statusItem,
        this.projectStatus
      );

    dialog.afterSubmit.subscribe((payload) => {
      this.statusService
        .updateProjectStatusByType(
          payload.resourceName,
          this.projectId,
          payload.formValue
        )
        .subscribe(
          (response) => {
            this.projectStatus =
              this.projectOverviewFacade.parseProjectStatus(response);
            dialog.close();
          },
          () => {
            dialog.errors(null);
          }
        );
    });
  }

  public updateLaunchDate(launchDate: string) {
    const dialog =
      this.projectOverviewFacade.openAddLaunchDateModal(launchDate);

    dialog.afterSubmit.subscribe((payload) => {
      if (Object.values(payload).filter((item) => item != null).length !== 0) {
        this.projectService
          .addProjectedLaunchDate(this.projectId, payload)
          .subscribe(
            (project) => {
              this.notificationService.success('Successfully updated project');
              this.parseLaunchDates(project);
              this.projectedLaunchDateTable =
                this.project.projectedLaunchDateTable;
              dialog.close();
            },
            ({ error }) => {
              dialog.errors(error);
              this.notificationService.warning(
                'The projected end date could not be updated.'
              );
            }
          );
      } else {
        dialog.close();
      }
    });
  }

  public updateStartDate(projectedStartDate: StartDate) {
    const dialog =
      this.projectOverviewFacade.openAddStartDateModal(projectedStartDate);

    dialog.afterSubmit.subscribe((payload) => {
      payload.projectedStartDate = {
        date: payload.date,
        comment: payload.comment,
      };
      delete payload.date;
      delete payload.comment;
      if (
        Object.values(payload).filter((item) => item['date'] != null).length !==
        0
      ) {
        this.projectService
          .addProjectedStartDate(this.projectId, payload)
          .subscribe(
            (project) => {
              this.notificationService.success('Successfully updated project');
              this.parseStartDates(project);
              this.projectedStartDateTable =
                this.project.projectedStartDateTable;
              dialog.close();
            },
            ({ error }) => {
              dialog.errors(error);
              this.notificationService.warning(
                'The projected start date could not be updated.'
              );
            }
          );
      } else {
        dialog.close();
      }
    });
  }

  public openProjectStatusHelpModal() {
    this.projectOverviewFacade.openStatusHelpModal();
  }

  public updateProject(ev) {
    const value = this.form.value;
    this.processFormValue(value);

    const event = {
      type: ev,
      value,
      rawValue: this.form.getRawValue(),
      formIsValid: true,
    };

    this.projectOverviewService.sendNextEvent({
      projectId: this.projectId,
      event,
    });
  }

  private handleProjectUpdateError() {
    this.subs.push(
      this.projectOverviewService.currentErrors.subscribe((error) => {
        this.handleUserRights(this.project);

        if (error.status >= 400) {
          if (error?.error?.errors?.fields) {
            const errors = error.error.errors.fields;
            const mapErrors = Object.keys(errors).map((k) => [k, errors[k]]);

            mapErrors.forEach(([field, err]) => {
              if (this.form.controls[field]) {
                this.form.controls[field].setErrors({
                  bkError: err,
                });
              }

              if (this.confidentialForm.controls[field]) {
                this.confidentialForm.controls[field].setErrors({
                  bkError: err,
                });
              }
            });
          }

          if (error.error?.errors?.generic) {
            this.notificationService.warning(error.error.errors.generic);
          }
        } else {
          this.notificationService.warning('New error' + error.error);
        }
      })
    );
  }
}
