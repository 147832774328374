import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@dashboard/core';
import { Observable } from 'rxjs';
import { StatusItem } from '../../models/status';
import { Status } from '../../models/status';

@Injectable({
  providedIn: 'root',
})
export class ProjectStatusService {
  private readonly apiBaseUrl: string = `${this.apiService.getBaseUrl(
    'pmt'
  )}/projects`;

  constructor(private http: HttpClient, private apiService: ApiService) {}

  public findProjectStatusById(projectId: number): Observable<Status> {
    return this.http.get<Status>(`${this.apiBaseUrl}/${projectId}/status/`);
  }

  public updateProjectStatusByType(
    resource: string,
    projectId: number,
    body: StatusItem
  ): Observable<Status> {
    return this.http.put<Status>(
      `${this.apiBaseUrl}/${projectId}/status/${resource}`,
      body
    );
  }
}
