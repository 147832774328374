<div [formGroup]="group">
  <exos-label
    *ngIf="label"
    [name]="name"
    [text]="label"
    [infoPointText]="infoPoint"
  ></exos-label>

  <div
    class="input-text-group"
    fxLayout="row warp"
    fxLayoutGap="0 grid"
    fxLayoutAlign="space-between center"
    matAutocompleteOrigin
    #origin="matAutocompleteOrigin"
    [class.input-text-group--disabled]="group.get(name).disabled"
    [class.input-text-group--error]="group.get(name).invalid"
  >
    <div>
      <i class="input-text-group__icon exos-icon {{ icon }}"></i>
    </div>

    <input
      class="input-text"
      #input
      [matAutocomplete]="auto"
      [matAutocompleteConnectedTo]="origin"
      (blur)="onBlur()"
      [class.input-text--error]="group.get(name).invalid"
      [class.input-text--disabled]="group.get(name).disabled"
      [id]="name"
      [name]="name"
      [formControlName]="name"
      [placeholder]="placeholder"
      [attr.disabled]="group.get(name).disabled ? 'disabled' : null"
    />

    <div [class.show]="!isLoading" style="margin: 0 7px">
      <div class="loading-spin loading-spin--small"></div>
    </div>
  </div>

  <exos-error [group]="group" [name]="name"></exos-error>
</div>

<mat-autocomplete
  #auto="matAutocomplete"
  [displayWith]="displayFn"
  (closed)="handleAutocompleteClose()"
  (optionSelected)="onSelectItem($event)"
>
  <ng-container *ngIf="items | async as items">
    <mat-option *ngFor="let item of items" [value]="item">
      <span class="prefix">{{ item?.prefix }}</span> {{ item.text }}
      <small class="suffix">{{ item?.suffix }}</small>
    </mat-option>

    <mat-option *ngIf="noResultsMessage && displayNoResultsMsg" [value]="false">
      <div>
        {{ noResultsMessage }}
      </div>
    </mat-option>
  </ng-container>
</mat-autocomplete>
