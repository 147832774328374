import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ProgramLinksFacadeService } from '../../../../shared/facade/program-links-facade/program-links-facade.service';
import { ArrayDataSource, DashTableColumnDefinition } from '@dashboard/table';
import { tap } from 'rxjs/operators';
import { DocumentLink } from '../../../../shared/models/project-link.model';
import { ConfirmationComponent } from '../../../../shared/components/dialogs/confirmation/confirmation.component';
import { DashDialogService, ExosDialogConfig } from '@dashboard/exos-dialog';
import { DashNotificationService } from '@dashboard/core';
import { AddItemDialogComponent } from '../../../../shared/components/dialogs/add-item-dialog/add-item-dialog.component';
import {
  addLinkDialogConfig,
  editLinkDialogConfig,
} from '../../../../shared/config/dialogs/link-dialog.config';
import { Program } from '../../../../shared/models/program.model';
import { FieldConfig } from '@dashboard/exos-form';

@Component({
  selector: 'app-program-files-route',
  templateUrl: './program-files-route.component.html',
  styleUrls: ['./program-files-route.component.scss'],
})
export class ProgramFilesRouteComponent implements OnInit, AfterViewInit {
  public programId: string;

  public programFileId: string;

  public programLinks$: Observable<any>;

  public updateInProgress$: Observable<boolean>;

  public canUpdateProgramDocumentLinks$: Observable<boolean>;

  public programLinksTableDatasource: ArrayDataSource<DocumentLink>;

  public programLinksTableCols: Array<DashTableColumnDefinition> = [
    { id: 'name', name: 'Name', sortable: true, value: 'name', display: true },
    { id: 'link', name: 'Link', sortable: true, value: 'link', display: true },
  ];

  private dialogConfig = {
    addDocumentLink: JSON.parse(JSON.stringify(addLinkDialogConfig)),
    editDocumentLink: JSON.parse(JSON.stringify(editLinkDialogConfig)),
    deleteDocumentLink: {
      data: {
        message: 'Are you sure?',
        title: 'Delete link',
        text: 'Delete',
      },
    },
  };

  constructor(
    private route: ActivatedRoute,
    private dialogService: DashDialogService,
    private programLinksFacade: ProgramLinksFacadeService,
    private notificationService: DashNotificationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const program: Program = this.route.snapshot.parent.data.program;

    this.programId = program.id.toString();
    this.programFileId = program.folderId;

    this.programLinksTableDatasource = new ArrayDataSource(program.links);
    this.programLinksTableDatasource.start();

    this.updateInProgress$ = this.programLinksFacade.updateInProgress$;
    this.canUpdateProgramDocumentLinks$ =
      this.programLinksFacade.canUpdateProgramDocumentLinks$.pipe(
        tap((value) => {
          if (value) {
            this.addActionsToTableRows();
          } else {
            this.removeActionsFromTableRows();
          }
        })
      );

    this.programLinks$ = this.programLinksFacade.programDocumentLinks$.pipe(
      tap((value) => {
        this.programLinksTableDatasource.setData(value);
      })
    );

    this.programLinksFacade.dispatchLoadProgram(program);

    this.cdr.detectChanges();
  }

  ngAfterViewInit() {}

  public openGDriveFolder() {
    if (this.programFileId != null) {
      window.open(
        `https://drive.google.com/drive/folders/${this.programFileId}`
      );
    }
  }

  public addDocumentLink() {
    const dialog = this.presentAddLinkModal();

    dialog.afterSubmit.subscribe(async (formValue: DocumentLink) => {
      const result =
        await this.programLinksFacade.dispatchAddDocumentLinkToProgram(
          this.programId,
          formValue
        );

      if (result.updated) {
        this.programLinksFacade.dispatchLoadProgram(result.data);
        dialog.close();
      } else {
        dialog.errors(result.error);
      }
    });
  }

  public editDocumentLink(documentLink: DocumentLink) {
    const dialog = this.presentEditLinkModal(documentLink);

    dialog.afterSubmit.subscribe(async (formValue: DocumentLink) => {
      const result =
        await this.programLinksFacade.dispatchEditDocumentLinkToProgram(
          this.programId,
          documentLink.id.toString(),
          formValue
        );

      if (result.updated) {
        this.programLinksFacade.dispatchLoadProgram(result.data);
        dialog.close();
      } else {
        dialog.errors(result.error);
      }
    });
  }

  public deleteDocumentLink(documentLinkId: string) {
    const dialog = this.presentConfirmationModal(
      this.dialogConfig.deleteDocumentLink
    );

    dialog.afterSubmit.subscribe(async () => {
      const result =
        await this.programLinksFacade.dispatchDeleteDocumentLinkFromProject(
          documentLinkId
        );

      if (result.updated) {
        this.programLinksFacade.dispatchLoadProgram(result.data);
        dialog.close();
      } else {
        this.notificationService.warning(result.error.errors.generic);
      }
    });
  }

  private presentConfirmationModal(dialogConfig) {
    return this.dialogService.open(ConfirmationComponent, dialogConfig);
  }

  private presentAddLinkModal() {
    const config = JSON.parse(
      JSON.stringify(this.dialogConfig.addDocumentLink)
    );
    return this.openLinkModal(config);
  }

  private presentEditLinkModal(formValue: DocumentLink) {
    const config = this.patchEditLinkModalFormConfigValues(formValue);

    return this.openLinkModal(config);
  }

  private openLinkModal(modalConfig: ExosDialogConfig) {
    return this.dialogService.open(AddItemDialogComponent, modalConfig);
  }

  private addActionsToTableRows() {
    const rowActions: DashTableColumnDefinition = {
      id: 'actions',
      name: '',
      sortable: false,
      value: null,
      display: true,
      actions: [
        {
          name: 'Edit',
          action: (row: DocumentLink) => {
            this.editDocumentLink(row);
          },
        },
        {
          name: 'Delete',
          action: (row) => {
            this.deleteDocumentLink(row.id);
          },
        },
      ],
    };

    this.programLinksTableCols = [...this.programLinksTableCols, rowActions];
  }

  private removeActionsFromTableRows() {
    return this.programLinksTableCols.filter(
      (row) => typeof row !== 'string' && row.name !== 'actions'
    );
  }

  private patchEditLinkModalFormConfigValues(formValue: DocumentLink) {
    const currentConfig = JSON.parse(
      JSON.stringify(this.dialogConfig.editDocumentLink)
    );
    const formValueEntries = Object.keys(formValue);

    formValueEntries.forEach((key) => {
      const currentFieldConfig = currentConfig.data.formConfig.fields[
        key
      ] as FieldConfig;

      if (currentFieldConfig) {
        currentFieldConfig.value = formValue[key];
      }
    });

    return currentConfig;
  }
}
