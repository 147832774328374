<ng-container>
  <div fxLayout="row wrap" fxLayoutGap="32px grid">
    <div fxFlex="50" fxFlex.lt-lg="100">
      <div class="table__toolbar">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div class="inline">
            <h2 exosHeadline type="sub" class="mb-0">Project Brief File</h2>
          </div>
          <ng-container *ngIf="projectBriefFile.fileId">
            <button
              exosButton
              semantic="primary"
              (click)="openBriefFile(projectBriefFile.fileId, null)"
            >
              View Project Brief
            </button>
          </ng-container>

          <ng-container *ngIf="!projectBriefFile.fileId && canGenerateFile">
            <button
              exosButton
              semantic="primary"
              [withLoader]="true"
              [isDisabled]="disableGenerateButton"
              [disabled]="disableGenerateButton"
              (click)="generateProjectBriefFile()"
            >
              Generate Project Brief
            </button>
          </ng-container>
        </div>
      </div>

      <div exosSheet>
        <ul sheet-stripes>
          <ng-container *ngFor="let section of projectBriefFile.sections">
            <li
              settings-stripe
              [directSelection]="true"
              (click)="
                openBriefFile(projectBriefFile.fileId, section.headingId)
              "
              [hasLink]="!!projectBriefFile?.fileId"
            >
              <div settings-stripe-label>
                <strong>{{ section.heading }}</strong>
              </div>
              <div settings-stripe-value>
                {{ section.description }}
              </div>
              <div settings-stripe-action>
                <span
                  *ngIf="projectBriefFile.fileId"
                  exosLink
                  visual="next"
                  [isStripeTarget]="true"
                ></span>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>

    <div fxFlex="50" fxFlex.lt-lg="100">
      <div class="table__toolbar">
        <h2 exosHeadline type="sub" style="margin: 5.5px 0">Market</h2>
      </div>
      <div exosSheet>
        <div exosSheetBlock>
          <div exosSheetSection>
            <div>
              <app-checkbox-form
                [canUpdate]="canUpdateResources"
                [config]="marketFormConfig"
                (changed)="onMarketCheck($event)"
              >
              </app-checkbox-form>
            </div>
          </div>
        </div>
      </div>

      <app-editable-table
        fxFlex.lt-lg="100"
        [dialogConfig]="routeConfig.dependency.modal.add"
        [data]="project.dependencies"
        [cols]="routeConfig.dependency.table.cols"
        [noResultsFoundMsg]="routeConfig.dependency.table.noResultsFoundMsg"
        tableCaption="Dependencies"
        [hasAction]="canUpdateResources"
        [infopointConfig]="routeConfig.dependency.table.info"
        [defaultSort]="defaultSort.dependencies"
        (openAddModal)="handleOpenAddModal($event)"
      >
      </app-editable-table>
    </div>

    <app-editable-table
      fxFlex="100"
      [dialogConfig]="routeConfig.requirement.modal.add"
      [data]="project.requirements"
      [cols]="routeConfig.requirement.table.cols"
      [noResultsFoundMsg]="routeConfig.requirement.table.noResultsFoundMsg"
      tableCaption="Requirements"
      [hasAction]="canUpdateResources"
      [infopointConfig]="routeConfig.requirement.table.info"
      [defaultSort]="defaultSort.requirements"
      (openAddModal)="handleOpenAddModal($event)"
    >
    </app-editable-table>

    <card-change-request
      fxFlex="100"
      style="padding: 0px"
      [changeRequests]="projectChangeRequests"
      [canCreateChangeRequest]="canCreateChangeRequest"
      [canViewChangeRequests]="canViewChangeRequests"
      [updateInProgress]="changeRequestUpdateInProgress"
      viewRoutePath="/projects/{{ projectId }}"
      (updateChangeRequest)="updateChangeRequest($event)"
      (createChangeRequest)="createChangeRequest()"
    >
    </card-change-request>

    <app-editable-table
      fxFlex="100"
      [dialogConfig]="routeConfig.risk.modal.add"
      [data]="project.risks"
      [cols]="routeConfig.risk.table.cols"
      [noResultsFoundMsg]="routeConfig.risk.table.noResultsFoundMsg"
      tableCaption="Risks"
      [hasAction]="canUpdateResources"
      [infopointConfig]="routeConfig.risk.table.info"
      [defaultSort]="defaultSort.risks"
      (openAddModal)="handleOpenAddModal($event)"
    >
    </app-editable-table>

    <app-editable-table
      fxFlex="100"
      class="mb-6"
      [dialogConfig]="routeConfig.stakeholder.modal.add"
      [data]="project.stakeholders"
      [cols]="routeConfig.stakeholder.table.cols"
      [noResultsFoundMsg]="routeConfig.stakeholder.table.noResultsFoundMsg"
      tableCaption="Stakeholders"
      [hasAction]="canUpdateResources"
      [infopointConfig]="routeConfig.stakeholder.table.info"
      [hasTooltip]="true"
      tooltipColumn="division"
      [helpConfig]="routeConfig.stakeholder.table.help"
      (openAddModal)="handleOpenAddModal(routeConfig.stakeholder.modal.add)"
    >
    </app-editable-table>
  </div>
</ng-container>
