import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';

const responsibleFormConfig: FormConfig = {
  action: 'responsible',

  fields: {
    responsible: {
      name: 'responsible',
      label: 'Responsible Name',
      placeholder: 'Responsible Name',
      type: 'autocomplete',
      items: null,
      fxFlex: '100',
      value: '',
      icon: 'exos-icon-nav-user-16',
      noResultsMessage: 'No user found.',
      service: {
        name: 'usersService',
        action: 'findByName',
      },
    },
  },

  buttons: [
    {
      label: 'Save',
      type: 'button',
      action: 'submit',
      isPrimary: true,
      hasLoader: true,
    },
    {
      label: 'Close',
      type: 'button',
      action: 'cancel',
    },
  ],
};

export const addResponsibleDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Add new responsible',
    formConfig: responsibleFormConfig,
  },
};

export const editResponsibleDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Edit responsible',
    formConfig: responsibleFormConfig,
  },
};
