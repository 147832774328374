import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';

const requirementFormConfig: FormConfig = {
  action: 'requirements',

  fields: {
    name: {
      name: 'name',
      label: 'Name',
      placeholder: 'Name',
      type: 'input',
      fxFlex: '100',
      value: null,
    },
    priority: {
      name: 'priority',
      label: 'Priority',
      type: 'select',
      options: [
        { text: 'Not selected', value: '' },
        { text: '1', value: '1' },
        { text: '2', value: '2' },
        { text: '3', value: '3' },
      ],
      fxFlex: '100',
      value: null,
      infoPoint: {
        hasInfoPoint: true,
        text:
          'Priority 1: MUST red flag if not fulfilled <br> ' +
          'Priority 2: SHOULD highly desired for launch, but no red flag <br> ' +
          'Priority 3: NICE TO HAVE only small efforts causing no impact on timeline',
      },
    },
    description: {
      name: 'description',
      label: 'Description',
      placeholder: 'Description',
      type: 'textarea',
      rows: '7',
      resizable: 'vertical',
      fxFlex: '100',
      value: null,
    },
  },

  buttons: [
    {
      label: 'Save',
      type: 'button',
      action: 'submit',
      hasLoader: true,
      isPrimary: true,
    },
    {
      label: 'Close',
      type: 'button',
      action: 'cancel',
      hasLoader: false,
    },
  ],
};

export const addrequirementDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Add new requirement',
    formConfig: requirementFormConfig,
  },
};

export const editrequirementDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Edit requirement',
    formConfig: requirementFormConfig,
  },
};
