import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  ExosFormComponent,
  FormContainerDirective,
  FormService,
} from '@dashboard/exos-form';
import { Subject, Subscription } from 'rxjs';
import { ExosCardFormConfig } from '../../models/card-form-config';

@Component({
  selector: 'card-form',
  templateUrl: './card-form.component.html',
  styleUrls: ['./card-form.component.scss'],
})
export class CardFormComponent implements AfterViewInit, OnDestroy, OnChanges {
  @ViewChild(FormContainerDirective)
  public formContainer: FormContainerDirective;

  @Input()
  public config: ExosCardFormConfig;

  public formCmp: ExosFormComponent;

  public form: FormGroup;

  private readonly _onValue = new Subject<any>();
  public onValue = this._onValue.asObservable();

  private routeSubs: Subscription[] = [];

  constructor(
    private exosFormService: FormService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    const formContainerRef: ViewContainerRef =
      this.formContainer.viewContainerRef;

    const formCmpInstance = this.exosFormService.buildForm(
      formContainerRef,
      this.config,
      ExosFormComponent
    ).instance;

    this.formCmp = formCmpInstance;
    this.form = formCmpInstance.form;

    this.form.disable();

    this.routeSubs.push(
      this.form.valueChanges.subscribe((value) => {
        this._onValue.next(value);
      })
    );

    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.form && changes?.config?.currentValue) {
      if (changes?.config?.currentValue) {
        if (changes.config.currentValue.disabled) {
          this.form.disable();
        } else {
          this.form.enable();
        }
      }
    }
  }

  ngOnDestroy() {
    this._onValue.complete();
    this.routeSubs.forEach((sub) => sub.unsubscribe());
  }
}
