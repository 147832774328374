<form [formGroup]="form">
  <div style="margin-bottom: 16px">
    <input
      class="input-checkbox"
      id="all"
      formControlName="all"
      type="checkbox"
    />
    <label for="all">{{ config.groupLabel }}</label>
  </div>

  <div [formGroupName]="config.groupName">
    <div fxLayout="row wrap" fxLayoutGap="16px grid">
      <ng-container *ngFor="let field of fields">
        <div [fxFlex]="field.rowWidth">
          <input
            class="input-checkbox"
            [class.input-checkbox--disabled]="form.disabled"
            [id]="field.name"
            [formControlName]="field.name"
            type="checkbox"
          />
          <label [for]="field.name">{{ field.label }}</label>
        </div>
      </ng-container>
    </div>
  </div>
</form>
