import { Directive } from '@angular/core';

@Directive({
  selector: '[exos-check-list], [exosCheckList]',
  host: {
    class: 'check-list',
  },
})
export class CheckListDirective {
  constructor() {}
}
