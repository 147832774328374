import { Directive, Input } from '@angular/core';
import { ExosBaseSemanticType } from '../../exos.model';

export type LinkType =
  | 'lookup'
  | 'action'
  | 'external-action'
  | 'delete'
  | 'upselling'
  | 'secure'
  | 'print'
  | 'download'
  | 'facebook'
  | 'twitter'
  | 'gplus'
  | 'friend'
  | 'helpandlearn'
  | 'pointer'
  | 'back'
  | 'prev'
  | 'next'
  | 'yes'
  | 'no';

@Directive({
  selector: '[exos-link], [exosLink]',
  host: {
    class: 'link',
    '[class.link--lookup]': "visual === 'lookup'",
    '[class.link--action]': "visual === 'action'",
    '[class.link--external-action]': "visual === 'external-action'",
    '[class.link--delete]': "visual === 'delete'",
    '[class.link--upselling]': "visual === 'upselling'",
    '[class.link--secure]': "visual === 'secure'",
    '[class.link--print]': "visual === 'print'",
    '[class.link--download]': "visual === 'download'",
    '[class.link--facebook]': "visual === 'facebook'",
    '[class.link--twitter]': "visual === 'twitter'",
    '[class.link--gplus]': "visual === 'gplus'",
    '[class.link--friend]': "visual === 'friend'",
    '[class.link--helpandlearn]': "visual === 'helpandlearn'",
    '[class.link--pointer]': "visual === 'pointer'",
    '[class.link--back]': "visual === 'back'",
    '[class.link--prev]': "visual === 'prev'",
    '[class.link--next]': "visual === 'next'",
    '[class.link--yes]': "visual === 'yes'",
    '[class.link--no]': "visual === 'no'",
    // semantic
    '[class.link--success]': "semantic === 'success'",
    '[class.link--warning]': "semantic === 'warning'",
    '[class.link--critical]': "semantic === 'critical'",
    '[class.link--neutral]': "semantic === 'neutral'",
    // behavior
    '[class.link--ellipsized]': 'isEllipsized',
    '[class.link--disabled]': 'isDisabled',
    '[class.link--bright]': 'isBright',
    '[class.__direct-selection--target]': 'isStripeTarget',
  },
})
export class LinkDirective {
  @Input() visual: LinkType;
  @Input() semantic: ExosBaseSemanticType;
  @Input() isDisabled: boolean;
  @Input() isBright: boolean;
  @Input() isEllipsized: boolean;
  @Input() isStripeTarget: boolean;
}
