import { Injectable } from '@angular/core';
import { Program } from '../../models/program.model';
import { BehaviorSubject } from 'rxjs';
import { ProgramService } from '../../service/program/program.service';
import { ProgramRightsService } from '../../service/user-rights/program-rigths/program-rights.service';
import { ExosDialogPromiseResult } from '../../config/dialogs/dialog';
import { DocumentLink } from '../../models/project-link.model';

@Injectable({
  providedIn: 'root',
})
export class ProgramLinksFacadeService {
  private program: Program;

  private _programDocumentLinks = new BehaviorSubject<any>([]);
  public programDocumentLinks$ = this._programDocumentLinks.asObservable();

  private _canUpdateProgramDocumentLinks = new BehaviorSubject<boolean>(null);
  public canUpdateProgramDocumentLinks$ =
    this._canUpdateProgramDocumentLinks.asObservable();

  private _updateInProgress = new BehaviorSubject<boolean>(false);
  public updateInProgress$ = this._updateInProgress.asObservable();

  constructor(
    private programRightsService: ProgramRightsService,
    private programService: ProgramService
  ) {}

  public dispatchLoadProgram(program: Program) {
    const canUpdate = this.canUpdateProgram(program);

    this.program = program;
    this._programDocumentLinks.next(program.links);
    this._canUpdateProgramDocumentLinks.next(canUpdate);
  }

  public dispatchAddDocumentLinkToProgram(
    programId: string,
    documentLink: DocumentLink
  ) {
    this._updateInProgress.next(true);

    return this.programService
      .addDocumentProgramLink(programId, documentLink)
      .toPromise()
      .then<ExosDialogPromiseResult>((response) => {
        return {
          data: response,
          updated: true,
        };
      })
      .catch<ExosDialogPromiseResult>((error) => {
        return {
          updated: false,
          error: error.error,
        };
      })
      .finally(() => {
        this._updateInProgress.next(false);
      });
  }

  public dispatchEditDocumentLinkToProgram(
    programId: string,
    documentLinkId: string,
    documentLink: DocumentLink
  ) {
    this._updateInProgress.next(true);

    return this.programService
      .editDocumentProgramLink(programId, documentLinkId, documentLink)
      .toPromise()
      .then<ExosDialogPromiseResult>((response) => {
        return {
          data: response,
          updated: true,
        };
      })
      .catch<ExosDialogPromiseResult>((error) => {
        return {
          updated: false,
          error: error.error,
        };
      })
      .finally(() => {
        this._updateInProgress.next(false);
      });
  }

  public dispatchDeleteDocumentLinkFromProject(documentLinkId: string) {
    this._updateInProgress.next(true);

    return this.programService
      .deleteDocumentProgramLinkById(this.program.id.toString(), documentLinkId)
      .toPromise()
      .then<ExosDialogPromiseResult>((response) => {
        return {
          updated: true,
          data: response,
        };
      })
      .catch<ExosDialogPromiseResult>((error) => {
        return {
          updated: false,
          error: error.error,
        };
      })
      .finally(() => {
        this._updateInProgress.next(false);
      });
  }

  private canUpdateProgram(program: Program): boolean {
    const currentUserRights = this.programRightsService.getUserRights(program);
    return currentUserRights.canUpdate;
  }
}
