<div [formGroup]="group">
  <exos-label
    *ngIf="label"
    [name]="name"
    [text]="label"
    [infoPointText]="infoPoint"
  ></exos-label>

  <quill-editor
    *ngIf="!config.readOnly"
    [id]="name"
    [format]="config.format || 'html'"
    [customToolbarPosition]="config.customToolbarPosition || 'top'"
    [sanitize]="config.sanitize || false"
    [styles]="config.styles"
    [trimOnValidation]="config.trimOnValidation || false"
    [customOptions]="config.customOptions || []"
    [customModules]="config.customModules || []"
    [formControlName]="name"
  >
  </quill-editor>

  <quill-view
    *ngIf="config.readOnly"
    [content]="group.get(name)?.value || config.emptyMsg"
  >
  </quill-view>

  <div style="margin-top: 2rem" *ngIf="config.buttons">
    <ng-container *ngFor="let button of config.buttons">
      <button
        *ngIf="!button.disabled && !button.hideOnDisabled"
        exosButton
        [semantic]="button.semantic"
        (click)="handleEvents($event, button.action)"
        [isDisabled]="button.disabled"
      >
        {{ button.label }}
      </button>
    </ng-container>
  </div>

  <exos-error [group]="group" [name]="name"></exos-error>
</div>
