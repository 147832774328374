import {
  PartialObjectiveSet,
  PartialObjectiveSetMap,
} from '../shared/models/objective.model';

export const generateDivisionSetsMap = (
  divisionSets: PartialObjectiveSet[]
) => {
  const divisionSetsMap = {};
  const sortedSets = divisionSets.sort((a, b) => {
    if (a.year < b.year) {
      return -1;
    }
    if (a.year > b.year) {
      return 1;
    }
    if (a.year == b.year) {
      if (a.quarter < b.quarter) {
        return -1;
      }
      if (a.quarter > b.quarter) {
        return 1;
      }
      // sort after division name for same quarters
      if (a.quarter == b.quarter) {
        if (a.division?.isCompany && !b.division?.isCompany) {
          return -1;
        }
        if (!a.division?.isCompany && b.division?.isCompany) {
          return 1;
        }
        if (a.division?.isCompany == b.division?.isCompany) {
          if (
            a.division?.item?.localeCompare(b.division?.item, undefined, {
              numeric: true,
            })
          ) {
            return 1;
          }
          if (a.division?.item < b.division?.item) {
            return -1;
          }
        }

        return 0;
      }

      return 0;
    }

    return 0;
  });

  for (const set of sortedSets) {
    if (!divisionSetsMap[set.year]) {
      divisionSetsMap[set.year] = {};
    }
    if (!divisionSetsMap[set.year][set.quarter]) {
      divisionSetsMap[set.year][set.quarter] = [];
    }
    divisionSetsMap[set.year][set.quarter].push(set);
  }

  return divisionSetsMap;
};

export const generateDivisionSetsDisableMap = (
  divisionSetsMap: PartialObjectiveSetMap
) => {
  const divisionSetsDisableMap = {};

  Object.keys(divisionSetsMap).forEach((year) => {
    divisionSetsDisableMap[year] = {};
    Object.keys(divisionSetsMap[year]).forEach((quarter) => {
      divisionSetsDisableMap[year][quarter] = !divisionSetsMap[year][
        quarter
      ]?.some((set: PartialObjectiveSet) => set.objectives?.length > 0);
    });
  });

  return divisionSetsDisableMap;
};
