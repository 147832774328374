import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '@dashboard/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Tag } from '../../models/tag.model';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  private readonly apiBaseUrl = `${this.apiService.getBaseUrl('pmt')}/tags`;

  constructor(private httpClient: HttpClient, private apiService: ApiService) {}

  public findByNameLikePattern(key: string): Observable<Tag[]> {
    key = key.trim();
    return this.httpClient
      .get<Tag[]>(this.apiBaseUrl, { params: { key } })
      .pipe(
        map((tags) => {
          const tagIsPresent = tags.findIndex((tag: Tag) => tag.name === key);

          if (key.trim().length !== 0 && tagIsPresent === -1) {
            tags.unshift({ name: key, suffix: '- (New tag)' });
          }

          return tags.map((tag) => ({
            ...tag,
            value: tag.name,
            text: tag.name,
          }));
        })
      );
  }
}
