import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';

const projectedLaunchDateFormConfig: FormConfig = {
  action: 'projectedLaunchDates',

  fields: {
    projectedLaunchDate: {
      name: 'projectedLaunchDate',
      label: 'Projected end date',
      placeholder: 'Projected end date',
      type: 'datepicker',
      fxFlex: '100',
      value: null,
    },
  },

  buttons: [
    {
      label: 'Save',
      type: 'button',
      action: 'submit',
      hasLoader: true,
      isPrimary: true,
    },
    {
      label: 'Close',
      type: 'button',
      action: 'cancel',
      hasLoader: false,
    },
  ],
};

export const addprojectedLaunchDateDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Add new projected end date',
    formConfig: projectedLaunchDateFormConfig,
  },
};
