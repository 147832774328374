import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, of, EMPTY } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { UserFilterApiService } from './user-filters-api.service';
import { UserFilter } from './user-filters.service';

@Injectable({
  providedIn: 'root',
})
export class UserFiltersResolver implements Resolve<UserFilter> {
  constructor(
    private userFiltersApiService: UserFilterApiService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<UserFilter> {
    const id = route.paramMap.get('id');

    return this.userFiltersApiService.getFilter(id).pipe(
      mergeMap((userFilter) => {
        if (userFilter) {
          route.data.page.title = userFilter.title;
          route.data.page.type = userFilter.type;
          route.data.page.filterKey = userFilter.id;
          route.data.page.ufid = userFilter.uid;
          route.data.page[userFilter.type].searchQuery = userFilter.searchQuery;
        }

        return of(userFilter);
      }),
      catchError(() => {
        this.router.navigate(['/listing/all']);
        return EMPTY;
      })
    );
  }
}
