import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { CardChangeRequestComponent } from './components/card-change-request/card-change-request.component';
import { CardFormComponent } from './components/card-form/card-form.component';
import { CardStatusComponent } from './components/card-status/card-status.component';
import { ChartGanttComponent } from './components/chart-gantt/chart-gantt.component';
import { CheckboxFormComponent } from './components/checkbox-form/checkbox-form.component';
import { CustomExosFormComponent } from './components/custom-exos-form/custom-exos-form.component';
import { ProjectStepperComponent } from './components/project-stepper/project-stepper.component';
import { StepperItemDirective } from './components/project-stepper/stepper-item.directive';
import { EditableTableComponent } from './components/editable-table/editable-table.component';
import { ExosFormModule } from '@dashboard/exos-form';
import { AddItemDialogComponent } from './components/dialogs/add-item-dialog/add-item-dialog.component';
import { ChangeRequestDialogComponent } from './components/dialogs/change-request-dialog/change-request-dialog.component';
import { ConfirmationComponent } from './components/dialogs/confirmation/confirmation.component';
import { GanttTaskDialogComponent } from './components/dialogs/gantt-task-dialog/gantt-task-dialog.component';
import { ProjectHistoryDetailsDialogComponent } from './components/dialogs/project-history-details-dialog/project-history-details-dialog.component';
import { ProjectStatusHelpDialogComponent } from './components/dialogs/project-status-help/project-status-help-dialog.component';
import { ProjectUpdateStatusDialogComponent } from './components/dialogs/project-update-status-dialog/project-update-status-dialog.component';
import { ProjectFunctionalHelpDialogComponent } from './components/dialogs/project-functional-plan-help/project-functional-help-dialog.component';
import { JiraIssuesDialogComponent } from './components/dialogs/jira-issues-dialog/jira-issues-dialog.component';
import { DashTableModule } from '@dashboard/table';
import { ExosTooltipModule } from '@dashboard/exos-tooltip';
import { RouterModule } from '@angular/router';
import { ExosModule } from '../exos/exos.module';
import { MatAutocompleteModule, MatIconModule } from '@angular/material';
import { ProjectStakeholdersHelpDialogComponent } from './components/dialogs/project-stakeholders-help/project-stakeholders-help-dialog.component';
import { ProjectWorkflowHelpDialogComponent } from './components/dialogs/project-workflow-help/project-workflow-help-dialog.component';
import { WysiwygDialogComponent } from './components/dialogs/wysiwyg-dialog/wysiwyg-dialog.component';
import { WidgetProjectedLaunchDateComponent } from './components/widgets/widget-projected-launch-date/widget-projected-launch-date.component';
import { ExosInputComponent } from './components/form/exos-input/exos-input.component';
import { ExosSelectComponent } from './components/form/exos-select/exos-select.component';
import { ExosMultiselectComponent } from './components/form/exos-multiselect/exos-multiselect.component';
import { ExosAutocompleteComponent } from './components/form/exos-autocomplete/exos-autocomplete.component';
import { ExosCheckboxComponent } from './components/form/exos-checkbox/exos-checkbox.component';
import { ExosErrorComponent } from './components/form/exos-error/exos-error.component';
import { ExosLabelComponent } from './components/form/exos-label/exos-label.component';
import { ExosWysiwygComponent } from './components/form/exos-wysiwyg/exos-wysiwyg.component';
import { QuillModule } from 'ngx-quill';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DhtmlxGanttComponent } from './components/dhtmlx-gantt/dhtmlx-gantt.component';
import {
  EditTableCellContentDirective,
  EditTableCellInlineDirective,
  TableCellInputComponent,
} from './directives/edit-inline/edit-table-cell-inline.directive';
import { NgModelDebounceChangeDirective } from './directives/ng-model-debounce-change/ng-model-debounce-change.directive';
import { TextAreaAutoResizeDirective } from './directives/text-area-auto-resize/text-area-auto-resize.directive';
import { NgModelTypedDirective } from './directives/ng-model-typed/ng-model-typed.directive';
import { TableColumnFilterPipe } from './pipes/table-column-filter/table-column-filter.pipe';
import { AddFilterDialogComponent } from './components/dialogs/add-filter-dialog/add-filter-dialog.component';
import { WidgetProjectedStartDateComponent } from './components/widgets/widget-projected-start-date/widget-projected-start-date.component';
import { TableColumnFixedPipe } from './pipes/table-column-fixed/table-column-fixed.pipe';
import { ViewOkrDialogComponent } from './components/dialogs/view-okr-dialog/view-okr-dialog.component';

@NgModule({
  declarations: [
    CardChangeRequestComponent,
    CardFormComponent,
    CardStatusComponent,
    ChartGanttComponent,
    CheckboxFormComponent,
    CustomExosFormComponent,
    ProjectStepperComponent,
    EditableTableComponent,
    StepperItemDirective,
    CustomExosFormComponent,
    AddItemDialogComponent,
    ChangeRequestDialogComponent,
    ConfirmationComponent,
    GanttTaskDialogComponent,
    ProjectHistoryDetailsDialogComponent,
    ProjectStatusHelpDialogComponent,
    ProjectUpdateStatusDialogComponent,
    ProjectStakeholdersHelpDialogComponent,
    ProjectFunctionalHelpDialogComponent,
    ProjectWorkflowHelpDialogComponent,
    WysiwygDialogComponent,
    WidgetProjectedLaunchDateComponent,
    ExosInputComponent,
    ExosSelectComponent,
    ExosMultiselectComponent,
    ExosAutocompleteComponent,
    ExosCheckboxComponent,
    ExosErrorComponent,
    ExosLabelComponent,
    ExosWysiwygComponent,
    DhtmlxGanttComponent,
    JiraIssuesDialogComponent,
    EditTableCellInlineDirective,
    EditTableCellContentDirective,
    TableCellInputComponent,
    NgModelDebounceChangeDirective,
    TextAreaAutoResizeDirective,
    NgModelTypedDirective,
    TableColumnFilterPipe,
    AddFilterDialogComponent,
    WidgetProjectedStartDateComponent,
    ViewOkrDialogComponent,
    TableColumnFixedPipe,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    FlexModule,
    DashTableModule,
    MatAutocompleteModule,
    ExosFormModule,
    ExosTooltipModule,
    ExosModule,
    MatIconModule,
    QuillModule,
    MatSlideToggleModule,
  ],
  exports: [
    CardChangeRequestComponent,
    CardFormComponent,
    CardStatusComponent,
    ChartGanttComponent,
    CheckboxFormComponent,
    CustomExosFormComponent,
    ProjectStepperComponent,
    EditableTableComponent,
    StepperItemDirective,
    CustomExosFormComponent,
    AddItemDialogComponent,
    ChangeRequestDialogComponent,
    ConfirmationComponent,
    GanttTaskDialogComponent,
    ProjectHistoryDetailsDialogComponent,
    ProjectStatusHelpDialogComponent,
    ProjectUpdateStatusDialogComponent,
    ProjectStakeholdersHelpDialogComponent,
    ProjectFunctionalHelpDialogComponent,
    ProjectWorkflowHelpDialogComponent,
    WidgetProjectedLaunchDateComponent,
    WidgetProjectedStartDateComponent,
    ExosInputComponent,
    ExosSelectComponent,
    ExosAutocompleteComponent,
    ExosCheckboxComponent,
    ExosMultiselectComponent,
    ExosWysiwygComponent,
    DhtmlxGanttComponent,
    JiraIssuesDialogComponent,
    EditTableCellInlineDirective,
    EditTableCellContentDirective,
    TableCellInputComponent,
    NgModelDebounceChangeDirective,
    TextAreaAutoResizeDirective,
    NgModelTypedDirective,
    TableColumnFilterPipe,
    TableColumnFixedPipe,
  ],
  entryComponents: [
    CustomExosFormComponent,
    AddItemDialogComponent,
    ChangeRequestDialogComponent,
    ConfirmationComponent,
    GanttTaskDialogComponent,
    ProjectHistoryDetailsDialogComponent,
    ProjectStatusHelpDialogComponent,
    ProjectUpdateStatusDialogComponent,
    ProjectStakeholdersHelpDialogComponent,
    ProjectFunctionalHelpDialogComponent,
    ProjectWorkflowHelpDialogComponent,
    JiraIssuesDialogComponent,
    ViewOkrDialogComponent,
  ],
})
export class SharedModule {}
