<div exosSheetSection>
  <div exosSheetBlock>
    <div class="mb-3" fxLayout="row" fxLayoutAlign="space-between center">
      <h3 exosHeadline type="sub" class="mb-0">STAKEHOLDER CHEAT SHEET</h3>

      <button class="link static-overlay__close" (click)="close()">
        <i class="exos-icon exos-icon-closecard-24"></i>
      </button>
    </div>

    <div fxLayout="row wrap" fxLayoutGap="32px grid">
      <div fxFlex="50">
        <div exosSheet>
          <div exosSheetSection type="secondary">
            <div exosSheetBlock>
              <h4 exosHeadline type="sub">STAKEHOLDER DEFINITION</h4>
            </div>
          </div>
          <div exosCard [inheritBackground]="true">
            <div exosCardContent>
              <section exosCardSection>
                <p exosParagraph>
                  {{ contentText.stakeholderDefinition[0].bodyText }}
                </p>
              </section>
            </div>
          </div>
        </div>
        <div exosSheet>
          <div exosSheetSection type="secondary">
            <div exosSheetBlock>
              <h4 exosHeadline type="sub">STAKEHOLDER ROLES</h4>
            </div>
          </div>
          <ul sheetStripes>
            <ng-container *ngFor="let stripe of contentText.stakeholderRoles">
              <li settingsStripe>
                <div settingsStripeLabel *ngIf="stripe.title !== ''">
                  <b exosParagraph [isBold]="true">{{ stripe.title }}</b>
                </div>
                <div settingsStripeValue>{{ stripe.bodyText }} <br /></div>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>

      <div fxFlex="50">
        <div exosSheet>
          <div exosSheetSection type="secondary">
            <div exosSheetBlock>
              <h4 exosHeadline type="sub">STAKEHOLDER DIVISION</h4>
            </div>
          </div>

          <section exosCardSection>
            <p exosParagraph>
              {{ contentText.stakeholderDivisionDescription[0].bodyText }}
            </p>
          </section>

          <ul sheetStripes>
            <ng-container *ngFor="let item of contentText.stakeholderDivisions">
              <ng-container *ngFor="let paragraph of item.bodyText">
                <li settingsStripe [readonly]="true">
                  <div settingsStripeValue>{{ paragraph }} <br /></div>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>

    <footer exosSheetFooter>
      <button exosButton (click)="close()">Close</button>
    </footer>
  </div>
</div>
