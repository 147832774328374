import {
  Component,
  ViewChild,
  AfterViewInit,
  ChangeDetectorRef,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ExosDialogRef } from '@dashboard/exos-dialog';
import { ExosDialogConfig } from '@dashboard/exos-dialog';
import {
  ExosFormComponent,
  FormContainerDirective,
  FormEvent,
  FormService,
} from '@dashboard/exos-form';
import { customColumnsMapping } from 'src/app/shared/config/dialogs/risk-dialog.config';

@Component({
  selector: 'app-add-item-dialog',
  templateUrl: './add-item-dialog.component.html',
  styleUrls: ['./add-item-dialog.component.scss'],
})
export class AddItemDialogComponent
  implements AfterViewInit, OnDestroy, OnInit
{
  @ViewChild(FormContainerDirective)
  public formContainer: FormContainerDirective;

  private modalForm: FormGroup;

  private modalSubs: Subscription[] = [];

  public errors = new Subject();

  private _errors = this.errors.asObservable();

  constructor(
    public config: ExosDialogConfig,
    public dialog: ExosDialogRef,
    private changeDetectorRef: ChangeDetectorRef,
    private exosFormService: FormService
  ) {}

  ngOnInit() {
    const formFields = this.config.data.formConfig.fields;

    Object.keys(formFields).forEach((key: string) => {
      const field = formFields[key];
      if (field.type === 'select' && this.config.data.title === 'Edit risk') {
        if (field.name === 'impact' || field.name === 'probability') {
          field.value = customColumnsMapping[field.value];
        }
      }
    });
  }

  ngAfterViewInit() {
    const modalForm = this.exosFormService.buildForm(
      this.formContainer.viewContainerRef,
      this.config.data.formConfig
    ).instance;

    this.modalForm = modalForm.form;
    this.handleFormEvents(modalForm);
    this.handleDialogErrors();

    this._errors.subscribe((errors) => {
      if (errors) {
        this.exosFormService.setFieldErrors(errors);
      } else {
        this.modalForm.setValue(this.modalForm.getRawValue());
      }
    });

    this.changeDetectorRef.detectChanges();
  }

  private handleFormEvents(form: ExosFormComponent) {
    this.modalSubs.push(
      form.formEvents.subscribe((event: FormEvent) => {
        if (event.type === 'cancel') {
          this.dialog.close();
        } else if (event.type === 'submit') {
          if (event.formIsValid) {
            this.modalForm.markAsPending();
            this.dialog.submit(event.rawValue);
          }
        }
      })
    );
  }

  private handleDialogErrors() {
    this.modalSubs.push(
      this.dialog.afterErrors.subscribe((errors: any) => {
        if (errors) {
          this.exosFormService.setFieldErrors(errors);
        } else {
          this.modalForm.setValue(this.modalForm.getRawValue());
        }
      })
    );
  }

  ngOnDestroy() {
    this.modalSubs.forEach((sub) => sub.unsubscribe());
  }
}
