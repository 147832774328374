import { Inject, Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { JqlTokenBuilderService } from '../jql-token-builder/jql-token-builder.service';
import { JqlToken } from '../../jql-token';
import { JQL_CONFIG, JqlConfig } from '../../config/config';
import { FILTER_VIEW_TYPE } from 'src/app/shared/models/filter-view-type.model';

export interface TokenValidationI {
  valid: boolean;
  message?: string;
}

@Injectable()
export class JqlTokenValidatorService {
  private errorsSource: Subject<string> = new Subject<string>();

  public errors = this.errorsSource.asObservable();

  constructor(
    @Inject(JQL_CONFIG) private jqlConfig: { projects: JqlConfig },
    private jqlTokenBuilderService: JqlTokenBuilderService
  ) {}

  public update(jqlString: string, type: FILTER_VIEW_TYPE): string {
    const error = this.validate(jqlString, type);
    return error ? error : null;
  }

  public check(jqlString: string, type: FILTER_VIEW_TYPE) {
    return this.validate(jqlString, type).length === 0;
  }

  private validate(jqlString: string, type: FILTER_VIEW_TYPE) {
    if (!jqlString?.trim().length) {
      return '';
    }

    const tokens = this.jqlTokenBuilderService.buildQuery(jqlString.trim());

    // tslint:disable-next-line:forin
    for (const token in tokens) {
      const validation = this.validateToken(tokens[token], tokens, type);

      if (!validation.valid) {
        return validation.message;
      }
    }

    return '';
  }

  private validateToken(
    token: JqlToken,
    query: JqlToken[],
    type: FILTER_VIEW_TYPE
  ): TokenValidationI {
    switch (token.type) {
      case 'FIELD':
        return this.validateTokenField(token, query, type);
      case 'OPERATOR':
        return this.validateTokenOperator(token, query, type);
      case 'KEYWORD':
        return this.validateTokenKeyword(token, query, type);
      default:
        return this.validateTokenValue(token, query, type);
    }
  }

  private validateTokenField(
    token: JqlToken,
    query: JqlToken[],
    type: FILTER_VIEW_TYPE
  ): TokenValidationI {
    const value = token.value.toLowerCase().replace(/"/g, '');
    const fieldIsValid = this.jqlConfig[type].fields
      .map((field) => field.name.toLowerCase())
      .includes(value);
    // tslint:disable-next-line:max-line-length
    const errorMsg = `Query error: Field '${token.value}' does not exist or you do not have permission to view it. (line 1, character ${token.errIndex})`;
    const nextTokenValue = query[token.index + 1];

    if (!fieldIsValid) {
      return {
        valid: false,
        message: errorMsg,
      };
    }

    if (
      type === 'projects' &&
      query[0]?.value === 'id' &&
      isNaN(Number(query[2]?.value))
    ) {
      return {
        valid: false,
        message: `Error in the JQL Query: Enter only the number value of the project (e.g.: For PRJ.999 enter only 999).`,
      };
    }

    if (
      type === 'programs' &&
      query[0]?.value === 'id' &&
      isNaN(Number(query[2]?.value))
    ) {
      return {
        valid: false,
        message: `Error in the JQL Query: Enter only the number value of the program (e.g.: For PRG.999 enter only 999).`,
      };
    }

    if (!nextTokenValue || nextTokenValue.value.trim() === '') {
      return {
        valid: false,
        message: `Error in the JQL Query: Expecting operator before the end of the query. The valid operators are '=', '~', '!='.`,
      };
    }

    return {
      valid: true,
      message: null,
    };
  }

  private validateTokenOperator(
    token: JqlToken,
    query: JqlToken[],
    type: FILTER_VIEW_TYPE
  ): TokenValidationI {
    const prevFieldValue = query[token.index - 1].value
      .replace(/"/g, '')
      .toLowerCase();
    const value = token.value.toLowerCase().replace(/"/g, '');
    const nextTokenValue = query[token.index + 1];

    const validOperators = this.jqlConfig[type].fields
      .filter((field) => field.name.toLowerCase() === prevFieldValue)
      .map((field) => field.operators)
      .reduce((acc, val) => acc.concat(val), []);

    const fieldIsValid = validOperators.includes(value);

    if (!fieldIsValid) {
      return {
        valid: false,
        // tslint:disable-next-line:max-line-length
        message: `Query error: Expecting operator but got '${
          token.value
        }'. The valid operators for ${prevFieldValue} are ${validOperators.join(
          ', '
        )}. (line 1, character ${token.errIndex})`,
      };
    }

    if (!nextTokenValue || nextTokenValue.value.trim() === '') {
      return {
        valid: false,
        message: `Error in JQL Query: Expecting either a value before the end of the query.`,
      };
    }

    return {
      valid: true,
      message: null,
    };
  }

  private validateTokenValue(
    token: JqlToken,
    query: JqlToken[],
    type: FILTER_VIEW_TYPE
  ): TokenValidationI {
    const tokenFieldName = query[token.index - 2].value;
    const value = token.value.toLowerCase().replace(/"/g, '');

    const possibleFieldValues = this.jqlConfig[type].fields
      .filter(
        (field) => field.name.toLowerCase() === tokenFieldName && field.values
      )
      .map((field) => field.values)
      .reduce((acc, val) => acc.concat(val), []);

    let errorMsg;
    let fieldIsValid = true;

    if (possibleFieldValues.length) {
      fieldIsValid = possibleFieldValues
        ? possibleFieldValues
            .map((field) => field.toLowerCase())
            .includes(value)
        : true;

      errorMsg =
        // tslint:disable-next-line:max-line-length
        `Query error: The valid values for field ${tokenFieldName} are ${possibleFieldValues.join(
          ', '
        )}. (line 1, character ${token.errIndex})`;

      return {
        valid: fieldIsValid,
        message: errorMsg,
      };
    }

    if (value.trim() === '') {
      errorMsg = `Query error: The value of '${tokenFieldName}' field cannot be empty. (line 1m character ${token.errIndex})`;

      return {
        valid: false,
        message: errorMsg,
      };
    }

    return {
      valid: true,
      message: null,
    };
  }

  private validateTokenKeyword(
    token: JqlToken,
    query: JqlToken[],
    type: FILTER_VIEW_TYPE
  ): TokenValidationI {
    const value = token.value.toLowerCase().replace(/"/g, '');
    const fieldIsValid = this.jqlConfig[type].keywords
      .map((keyword) => keyword.toLowerCase())
      .includes(value);
    const errorMsg = `Query error: Expecting either 'OR' or 'AND' but got '${token.value}'. (line 1, character ${token.errIndex})`;
    const nextTokenValue = query[token.index + 1];

    if (!fieldIsValid) {
      return {
        valid: false,
        message: errorMsg,
      };
    }

    if (!nextTokenValue || nextTokenValue.value.trim() === '') {
      return {
        valid: false,
        message: `Error in the JQL Query: Expecting a field name at the end of the query.`,
      };
    }

    return {
      valid: true,
      message: null,
    };
  }
}
