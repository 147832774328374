import { ProjectPhaseType } from './project.model';
import { ProjectUserRightsSession } from '../service/user-rights/user-rigths';

export enum ChangeRequestSemanticColor {
  PENDING = 'warning',
  APPROVED = 'success',
  REJECTED = 'critical',
  CANCELED = 'neutral',
}

enum ChangeRequestReadableState {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  CANCELED = 'Canceled',
}

export type SemanticTypes = 'critical' | 'success' | 'warning' | 'neutral';
export type ChangeRequestState =
  | 'PENDING'
  | 'APPROVED'
  | 'REJECTED'
  | 'CANCELED';
export type ChangeRequestActionName =
  | 'VIEW'
  | 'EDIT'
  | 'APPROVE'
  | 'REJECT'
  | 'CANCEL';

export interface ChangeRequestAction {
  name: ChangeRequestActionName;
  label: string;
  semanticColor?: SemanticTypes;
  value?: ChangeRequestState;
  canDo?: boolean;
}

export interface IChangeRequest {
  id?: number;
  changeDescription: string;
  changeReason: string;
  changeOutcome: string;
  impactOfChange: string;
  proposedAction: string;
  fileLink?: string;
  state?: ChangeRequestState;
  createdAt?: string;
  closedAt?: string;
  hrefLink?: string;
}

export class ChangeRequest implements IChangeRequest {
  id: number;
  changeDescription: string;
  changeReason: string;
  changeOutcome: string;
  impactOfChange: string;
  proposedAction: string;
  fileLink: string;
  state: ChangeRequestState;
  createdAt: string;
  closedAt: string;
  hrefLink: string;

  readableState: string;
  semanticColor: SemanticTypes;
  actions: ChangeRequestAction[];
  canBeEdited: boolean;
  hasActions: boolean;
  isUpdating: boolean;

  ROUTER_LINK_PREFIX = 'change-requests';

  constructor(
    changeRequest: IChangeRequest,
    userStatus: ProjectUserRightsSession,
    projectPhase: ProjectPhaseType
  ) {
    const {
      changeDescription,
      changeReason,
      changeOutcome,
      impactOfChange,
      proposedAction,
      fileLink,
      state,
      createdAt,
      closedAt,
      id,
    } = changeRequest;

    const { isOwner, isClient, isRepresentative, isAdmin } = userStatus;

    this.id = id;
    this.changeDescription = changeDescription;
    this.changeReason = changeReason;
    this.changeOutcome = changeOutcome;
    this.impactOfChange = impactOfChange;
    this.proposedAction = proposedAction;
    this.fileLink = fileLink;
    this.state = state;
    this.createdAt = createdAt;
    this.closedAt = closedAt;

    this.readableState = ChangeRequestReadableState[state];
    this.semanticColor = ChangeRequestSemanticColor[state];

    // if (projectPhase === 'planAndExecute' || projectPhase === 'review') {
    const canBeEdited =
      state === 'PENDING' && (isOwner || isRepresentative || isAdmin);
    const canBeApproved = state === 'PENDING' && isClient;
    const canBeRejected = state === 'PENDING' && isClient;
    const canBeCanceled =
      state === 'PENDING' && (isOwner || isRepresentative || isAdmin);
    const canBeViewed = true;

    this.canBeEdited = canBeEdited;

    const tempActions: ChangeRequestAction[] = [
      {
        name: 'VIEW',
        label: 'View',
        canDo: canBeViewed,
      },
      {
        name: 'EDIT',
        label: 'Edit',
        canDo: canBeEdited,
      },
      {
        name: 'APPROVE',
        label: 'Approve',
        semanticColor: 'success',
        value: 'APPROVED',
        canDo: canBeApproved,
      },
      {
        name: 'REJECT',
        label: 'Reject',
        semanticColor: 'critical',
        value: 'REJECTED',
        canDo: canBeRejected,
      },
      {
        name: 'CANCEL',
        label: 'Cancel',
        semanticColor: 'warning',
        value: 'CANCELED',
        canDo: canBeCanceled,
      },
    ];

    this.actions = tempActions.filter((action) => action.canDo);

    this.hasActions = !!this.actions.length;

    this.hrefLink = `${this.ROUTER_LINK_PREFIX}/${id}`;
    // }
  }
}
