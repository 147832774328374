<label class="label" [for]="name">
  {{ text }}

  <i
    *ngIf="infoPointText"
    [exosTooltip]="infoPointText"
    class="exos-icon exos-icon-help-18"
    exosTooltipPosition="above"
    style="color: #0b9dcc"
  >
  </i>

  <!--  <a *ngIf="help" class="link link&#45;&#45;helpandlearn" (click)="openHelpModal()">Help</a>-->
</label>
