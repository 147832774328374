import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormEvent } from '@dashboard/exos-form';
import { CustomModule, CustomOption } from 'ngx-quill';
import { BtnConfig } from '@dashboard/exos-form/lib/models/field-config.interface';

export interface ExosWysiwygConfig {
  emptyMsg?: string;
  format?: 'html' | 'text' | 'object' | 'json';
  customToolbarPosition?: 'top' | 'bottom';
  sanitize?: boolean;
  styles?: any;
  trimOnValidation?: boolean;
  customOptions?: CustomOption[];
  customModules?: CustomModule[];
  readOnly?: boolean;
  buttons?: BtnConfig[];
  trim?: number;
}

@Component({
  selector: 'exos-wysiwyg',
  templateUrl: './exos-wysiwyg.component.html',
  styleUrls: ['./exos-wysiwyg.component.scss'],
})
export class ExosWysiwygComponent implements OnInit {
  @Input()
  public group: FormGroup;

  @Input()
  public name: string;

  @Input()
  public label: string;

  @Input()
  public placeholder: string;

  @Input()
  public icon: string;

  @Input()
  public infoPoint: string;

  @Input()
  public config: ExosWysiwygConfig;

  @Output()
  public event = new EventEmitter<FormEvent>();

  constructor() {}

  ngOnInit(): void {}

  handleEvents(ev: Event, action: string) {
    ev.stopPropagation();
    ev.stopImmediatePropagation();

    const formEv: FormEvent = {
      type: action,
      formIsValid: this.group.valid,
      value: this.group.value,
      rawValue: this.group.getRawValue(),
    };

    this.event.emit(formEv);
  }
}
