import { Component, Input } from '@angular/core';
import { StepperItem } from './stepper-item';

@Component({
  selector: 'project-stepper',
  templateUrl: './project-stepper.component.html',
  styleUrls: ['./project-stepper.component.scss'],
})
export class ProjectStepperComponent {
  @Input() steps: StepperItem[];

  constructor() {}
}
