import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Program } from '../../models/program.model';
import { ProgramService } from '../../service/program/program.service';

@Injectable({
  providedIn: 'root',
})
export class ProgramResolver implements Resolve<Program> {
  constructor(private programService: ProgramService, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Program> {
    const programId = route.paramMap.get('id');

    return this.programService.findProgramById(programId).pipe(
      mergeMap((program: Program) => of(program)),
      map((program: Program) => {
        // TODO extract and beautify logic in service
        const { client, owner, representative, projects } = program;

        if (client !== null) {
          program.client.text = client.name;
        }

        if (owner !== null) {
          program.owner.text = owner.name;
        }

        if (representative !== null && !!representative.length) {
          representative.forEach((user) => {
            user.text = user.name;
          });
        }

        if (projects !== null && !!projects.length) {
          projects.forEach((project) => {
            project.text = project.name;
          });
        }

        return program;
      }),
      catchError(() => {
        this.router.navigate(['/programs']);
        return EMPTY;
      })
    );
  }
}
