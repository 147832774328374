import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import { ArrayDataSource, DashTableColumnDefinition } from '@dashboard/table';
import { ExosDialogConfig, DashDialogService } from '@dashboard/exos-dialog';
import { ProjectStakeholder } from 'src/app/shared/models/stakeholder.model';
import { ProjectRisk } from 'src/app/shared/models/project-risk.model';
import { ProjectDependency } from 'src/app/shared/models/project-dependency.model';
import { ProjectRequirement } from 'src/app/shared/models/project-requirement.model';
import { InfoPointConfig } from '@dashboard/exos-form';
import { ProjectLaunchDates } from '../../models/project.model';
import { customColumnsMapping } from 'src/app/shared/config/dialogs/risk-dialog.config';

type EditableTableDatasource =
  | ProjectStakeholder
  | ProjectRisk
  | ProjectDependency
  | ProjectRequirement
  | ProjectLaunchDates;

@Component({
  selector: 'app-editable-table',
  templateUrl: './editable-table.component.html',
  styleUrls: ['./editable-table.component.scss'],
})
export class EditableTableComponent implements OnInit, OnChanges {
  @Input()
  dialogConfig: ExosDialogConfig;

  @Input()
  data: EditableTableDatasource[];

  @Input()
  cols: DashTableColumnDefinition[];

  @Input()
  noResultsFoundMsg: string;

  @Output()
  openAddModal: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  tableCaption: string;

  @Input()
  hasAction: boolean;

  @Input()
  infopointConfig: InfoPointConfig;

  @Input()
  hasTooltip: boolean;

  @Input()
  tooltipColumn: string;

  @Input()
  helpConfig: any;

  @Input()
  defaultSort: boolean = false;

  tableDatasource: ArrayDataSource<EditableTableDatasource>;

  constructor(private dialogService: DashDialogService) {}

  public get customColumns(): typeof customColumnsMapping {
    return customColumnsMapping;
  }

  ngOnChanges() {
    if (this.tableDatasource && this.tableDatasource.isRunning) {
      this.mapData(this.data);
      this.tableDatasource.setData(this.data);
      if (this.defaultSort) {
        this.cols = this.cols.map((col) => {
          if (col != null) {
            col['sortDirection'] = null;
          }
          return col;
        });
        this.tableDatasource.sort('id', 'asc');
      }
    }
  }

  ngOnInit() {
    this.mapData(this.data);
    this.tableDatasource = new ArrayDataSource(this.data || []);
    this.tableDatasource.start();
  }

  mapData(data) {
    data.map((item: any) => {
      if (item['impact']) {
        item['impact'] = customColumnsMapping[item['impact']];
      }

      if (item['probability']) {
        item['probability'] = customColumnsMapping[item['probability']];
      }
      return item;
    });
  }

  onOpenAddModal(event: MouseEvent) {
    event.stopPropagation();
    this.openAddModal.emit(this.dialogConfig);
  }

  openHelpModal(helpConfig) {
    return this.dialogService.open(helpConfig, { data: { title: '' } });
  }
}
