<div exosSheet>
  <div exosSheetSection>
    <div exosSheetBlock>
      <div class="mb-2" fxLayout="row" fxLayoutAlign="space-between center">
        <div
          fxLayout="row wrap"
          fxLayoutAlign="start center"
          fxLayoutGap="16px grid"
        >
          <p class="input-byline"><a class="link" (click)="showCurrentDate()"> Today</a></p>
          <div>
            <exos-select name="zoom" [group]="zoomForm" [options]="zoomLevels">
            </exos-select>
          </div>

          <div>
            <button exosButton (click)="setFullScreen()">Full screen</button>
          </div>
        </div>

        <div
          fxLayout="row wrap"
          fxLayoutAlign="start center"
          fxLayoutGap="16px grid"
        >
          <mat-slide-toggle
            [formControl]="criticalPathToggle"
            (change)="toggleCriticalPathVisibility($event)"
          >
            Show critical path
          </mat-slide-toggle>
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>

<div #gantt_container class="gantt-chart mb-2 z-10"></div>
