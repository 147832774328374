import { Injectable } from '@angular/core';
import { ApiService } from '@dashboard/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JiraIssuePayload } from '../../models/jira.model';
import { GanttChartData } from '../../models/gantt.model';

@Injectable({
  providedIn: 'root',
})
export class JiraService {
  private readonly apiHost = `${this.apiService.getBaseUrl('pmt')}/project`;

  constructor(private apiService: ApiService, private http: HttpClient) {}

  public fetchJiraIssues(
    projectId: number,
    payload: JiraIssuePayload,
    simulate: string = 'false'
  ): Observable<GanttChartData> {
    const httpParams = { simulate: simulate };
    return this.http.post<GanttChartData>(
      `${this.apiHost}/${projectId}/issues/`,
      payload,
      { params: httpParams }
    );
  }
}
