import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectRightsService } from '../user-rights/project-rights/project-rights.service';
import { ProjectModel } from '../../models/project.model';

@Injectable({
  providedIn: 'root',
})
export class RouteProjectColumnsService {
  constructor(
    private router: Router,
    private userRights: ProjectRightsService
  ) {}

  public canViewProjectActions(project: ProjectModel): boolean {
    const tempProject = JSON.parse(JSON.stringify(project));

    tempProject.owner = tempProject._owner;
    tempProject.client = tempProject._client;
    tempProject.phase = tempProject._phase;

    const currentUser = this.userRights.getUserSessionStatus(tempProject);

    return tempProject.confidential
      ? currentUser.isContributor || currentUser.isAdmin
      : true;
  }
}
