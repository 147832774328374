import { FormConfig } from '@dashboard/exos-form';
import { ExosDialogConfig } from '@dashboard/exos-dialog';
import { Subject } from 'rxjs';

const stakeholderFormConfig: FormConfig = {
  action: 'stakeholders',

  fields: {
    userInfo: {
      name: 'userInfo',
      label: 'Stakeholder Name',
      placeholder: 'Stakeholder Name',
      type: 'autocomplete',
      items: null,
      fxFlex: '100',
      value: null,
      icon: 'exos-icon exos-icon-nav-user-16',
      noResultsMessage: 'No user found.',
      service: {
        name: 'usersService',
        action: 'findByName',
      },
    },
    division: {
      name: 'division',
      label: 'Division',
      placeholder: 'Not selected',
      options: [],
      type: 'select',
      fxFlex: '100',
      value: null,
    },
    role: {
      name: 'role',
      label: 'Role',
      type: 'select',
      options: [
        { text: 'Responsible', value: 'Responsible' },
        { text: 'Accountable', value: 'Accountable' },
        { text: 'Consultant', value: 'Consultant' },
        { text: 'Informed', value: 'Informed' },
      ],
      fxFlex: '100',
      value: null,
    },
  },

  buttons: [
    {
      label: 'Save',
      type: 'button',
      action: 'submit',
      hasLoader: true,
      isPrimary: true,
    },
    {
      label: 'Close',
      type: 'button',
      action: 'cancel',
      hasLoader: false,
    },
  ],
};

export const addStakeholderDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Add new stakeholder',
    formConfig: stakeholderFormConfig,
  },
};

export const editStakeholderDialogConfig: ExosDialogConfig = {
  data: {
    title: 'Edit stakeholder',
    formConfig: stakeholderFormConfig,
  },
};
