import { Component } from '@angular/core';
import { ExosDialogConfig, ExosDialogRef } from '@dashboard/exos-dialog';
import { ExosBaseSemanticType } from 'src/app/exos/exos.model';
import { HelpSection } from 'src/app/shared/models/help.config';

export interface HelpItem {
  title: string;
  bodyText: string | string[];
  icon?: string;
  semantic?: ExosBaseSemanticType;
}

export interface ProjectFunctionalHelp {
  [key: string]: HelpItem[];
}

@Component({
  selector: 'app-project-functional-help',
  templateUrl: './project-functional-help-dialog.component.html',
  styleUrls: ['./project-functional-help-dialog.component.scss'],
})
export class ProjectFunctionalHelpDialogComponent {
  public contentText: HelpSection = {
    functionalPlanDefinition: [
      {
        title: 'DEFINITION',
        bodyText:
          'The functional plan is the subset of the overall project plan that’s assignable to a particular function or functional organization (stream). ' +
          'As many of the projects we manage today rely on collaborators from different teams, the functional planning phase includes all different streams ' +
          'needed to successfully implement the project (streams can be business units, divisions or teams). ' +
          'After the initial set-up of the overall project plan, the project owner includes those streams in the functional planning, ' +
          'where they can put together the right set of documentation for their contribution.',
      },
      {
        title: 'RESPONSIBILITY',
        bodyText:
          'The project owner not only takes responsibility in creating and maintaining the overall project plan, ' +
          'but also ensures stakeholder integration in alignment with the required work streams from the project plan. ' +
          'After integrating all needed stakeholders, a SPOC - functioning as the responsible stream lead - is assigned for each stream and ' +
          'noted as ‘stream lead’ in the functional plan. The stream lead takes responsibility for the completeness and quality of the documentation ' +
          'and is keeping track of the stream’s timing in the overall project plan.',
      },
      {
        title: 'DOCUMENTATION',
        bodyText:
          'After aligning with the respective project owner, stream leads may use the functional plan to hand in their deliverables. ' +
          'To do so, stream leads can use one of the predefined templates (currently available: technical concept, go-to-market concept) or ' +
          'work with a new template (currently available: google doc, google sheet) to document their work. ' +
          'When creating new content in the functional plan, all documents automatically move into the respective G Drive folder of the project.',
      },
      {
        title: 'EXAMPLE',
        bodyText:
          'The launch of a new product for our customers requires not only colleagues from the respective product department, ' +
          'but also from other units and cross-functions. The project owner of the product launch plans the required work streams accordingly ' +
          'in the project plan and aligns with representatives from each stream. In this case, involved streams could be: e.g., Shop / Website, ' +
          'Marketing, Finance, Legal and teams from Customer Management to just name a few.',
      },
    ],
  };

  constructor(public config: ExosDialogConfig, public dialog: ExosDialogRef) {}

  close() {
    this.dialog.close();
  }
}
