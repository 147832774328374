import { DashTableColumnDefinition } from '@dashboard/table';

const programGanttAllColumns: DashTableColumnDefinition[] = [
  {
    id: 'text',
    name: 'Task Name',
    sortable: true,
    value: 'text',
    display: true,
    fixed: true,
    colWidth: '150px',
  },
  {
    id: 'start_date',
    name: 'Start Date',
    sortable: true,
    value: 'start_date',
    display: true,
    fixed: false,
    colWidth: '100px',
  },
  {
    id: 'duration',
    name: 'Duration',
    sortable: true,
    value: 'duration',
    display: true,
    fixed: false,
    colWidth: '100px',
  },
  {
    id: 'add',
    name: 'Add',
    sortable: true,
    value: 'add',
    display: true,
    fixed: true,
    colWidth: '50px',
  },
];

export default programGanttAllColumns;
